import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {CmsService, CmsSitemap} from '../../../services/cms.service';

import {CampaignSubscribeDialogComponent} from '../../dialogs/campaign-subscribe/campaign-subscribe.component';
import {CookiesService} from '../../../services/cookies.service';
import {NavigationEnd, Router} from '@angular/router';

declare var $ : any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public cmsSitemap: CmsSitemap = null;
    public sitemapLoading: boolean = true;

    public showDostopnost: boolean = false;

    public themeClass: string;
    public fontStyleClass: string;
    public fontPaddingClass: string;
    public fontNameClass: string;

    public dropdownMenuVisibleSubject: Subject<boolean>;
    public accessibilityMenuVisibleSubject: Subject<boolean>;

    private energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';

    private logoBivanjeLarge = 'assets/images/general/logo-bivanje-big.svg';
    private logoBivanjeLargeBDU = 'assets/images/general/logo-bivanje-big.svg';
    private logoBivanjeBig = 'assets/images/general/logo-big.png';
    private logoBivanjeMiddle = 'assets/images/general/logo-middle.png';
    private logoKlubZvestobaOgreva = 'assets/images/klub-zvestoba-ogreva/logo-big-transparent.png';

    @Output()
    quickAccessMenuFocus = new EventEmitter();
    isMonochromatic: boolean = false;


    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private matDialog: MatDialog,
        private cmsService: CmsService,
        private router: Router,
        private cookiesService: CookiesService,
        private renderer: Renderer2
    ) {
        if (!isPlatformBrowser(this.platformId))
          return;

        this.dropdownMenuVisibleSubject = new Subject();
        this.dropdownMenuVisibleSubject
            .pipe(
                debounceTime(100),
                distinctUntilChanged()
            ).subscribe(val => {

                if (val) {
                    this.openDropdown();
                }
                else {
                    this.closeDropdown();
                }
            });

      this.accessibilityMenuVisibleSubject = new Subject();
      this.accessibilityMenuVisibleSubject
        .pipe(
          debounceTime(100),
          distinctUntilChanged()
        ).subscribe(val => {

        if (val) {
          document.body.style.overflow = 'hidden';

          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
        else {
          document.body.style.overflow = 'auto';
        }

        this.showDostopnost = val;
      });
    }

    public ngOnInit() {

        this.cmsService.getCmsSitemapAsync()
            .subscribe(res => {
                this.cmsSitemap = res;
                this.sitemapLoading = false;
            });

        if (!isPlatformBrowser(this.platformId))
          return;

        // load accessibility settings
        this.themeClass = this.cookiesService.loadAccessibilityTheme();
        this.fontNameClass = this.cookiesService.loadAccessibilityFont();
        this.fontStyleClass = this.cookiesService.loadAccessibilityFontStyle();
        this.fontPaddingClass = this.cookiesService.loadAccessibilityPadding();

        if (!this.themeClass) this.themeClass = 'theme-wcag';
        if (!this.fontNameClass) this.fontNameClass = 'font-system';
        if (!this.fontStyleClass) this.fontStyleClass = 'text-normal';
        if (!this.fontPaddingClass) this.fontPaddingClass = 'text-normal';

        this.initAccessibilitySettings();

      this.router.events.subscribe(e => {

        if (e instanceof NavigationEnd) {
          this.accessibilityMenuVisibleSubject.next(false);
        }
      });

      this.cookiesService.selectedTheme$.subscribe(res => {
        switch (res) {
          case 'theme-wcag':
          case 'theme-normal':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.logoBivanjeLarge = 'assets/images/general/logo-bivanje-big.svg';
            this.logoBivanjeBig = 'assets/images/general/logo-big.png';
            this.logoBivanjeMiddle = 'assets/images/general/logo-middle.png';
            this.logoKlubZvestobaOgreva = 'assets/images/klub-zvestoba-ogreva/logo-big-transparent.png';
            this.isMonochromatic = false;
            break;
          case 'theme-white-black':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-white.png';
            this.logoBivanjeLarge = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-white.svg';
            this.logoBivanjeLargeBDU = 'assets/images/general/wcag/logo-bivanje-big-pt2-white.svg';
            this.logoBivanjeBig = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-big-white.svg';
            this.logoBivanjeMiddle = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-middle-white.svg';
            this.logoKlubZvestobaOgreva = 'assets/images/general/wcag/logo-big-transparent-white.png';
            this.isMonochromatic = true;
            break;
          case 'theme-black-white':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.logoBivanjeLarge = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-black.svg';
            this.logoBivanjeLargeBDU = 'assets/images/general/wcag/logo-bivanje-big-pt2-black.svg';
            this.logoBivanjeBig = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-big-black.svg';
            this.logoBivanjeMiddle = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-middle-black.svg';
            this.logoKlubZvestobaOgreva = 'assets/images/general/wcag/logo-big-transparent-black.png';
            this.isMonochromatic = true;
            break;
          case 'theme-black-beige':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.logoBivanjeLarge = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-black.svg';
            this.logoBivanjeLargeBDU = 'assets/images/general/wcag/logo-bivanje-big-pt2-black.svg';
            this.logoBivanjeBig = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-big-black.svg';
            this.logoBivanjeMiddle = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-middle-black.svg';
            this.logoKlubZvestobaOgreva = 'assets/images/general/wcag/logo-big-transparent-black.png';
            this.isMonochromatic = true;
            break;
          case 'theme-black-yellow':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.logoBivanjeLarge = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-black.svg';
            this.logoBivanjeLargeBDU = 'assets/images/general/wcag/logo-bivanje-big-pt2-black.svg';
            this.logoBivanjeBig = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-big-black.svg';
            this.logoBivanjeMiddle = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-middle-black.svg';
            this.logoKlubZvestobaOgreva = 'assets/images/general/wcag/logo-big-transparent-black.png';
            this.isMonochromatic = true;
            break;
          case 'theme-yellow-blue':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-yellow.png';
            this.logoBivanjeLarge = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-yellow.svg';
            this.logoBivanjeLargeBDU = 'assets/images/general/wcag/logo-bivanje-big-pt2-yellow.svg';
            this.logoBivanjeBig = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-big-yellow.svg';
            this.logoBivanjeMiddle = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-middle-yellow.svg';
            this.logoKlubZvestobaOgreva = 'assets/images/general/wcag/logo-big-transparent-yellow.png';
            this.isMonochromatic = true;
            break;
          case 'theme-lime-black':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-lime.png';
            this.logoBivanjeLarge = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-lime.svg';
            this.logoBivanjeLargeBDU = 'assets/images/general/wcag/logo-bivanje-big-pt2-lime.svg';
            this.logoBivanjeBig = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-big-lime.svg';
            this.logoBivanjeMiddle = 'assets/images/general/wcag/cgp_EnergetikaLjubljana_2021-10-middle-lime.svg';
            this.logoKlubZvestobaOgreva = 'assets/images/general/wcag/logo-big-transparent-lime.png';
            this.isMonochromatic = true;
            break;
        }
      });
    }

  getEnergetikaVDruzbiLogo() {
    return this.energetikaVDruzbiLogo;
  }

  accessibleToggleDropdownLink(event: KeyboardEvent) {
    if (event) {
      switch (event.code) {
        case 'Escape':
          this.dropdownMenuVisibleSubject.next(false);
          break;
        case 'Enter':
          $( this ).click();
          break;
      }
    }
  }

    accessibleToggleDropdown(event: KeyboardEvent) {
      if (event) {
        switch (event.code) {
          case 'ArrowDown':
            this.dropdownMenuVisibleSubject.next(true);
            break;
          case 'Escape':
          case 'ArrowUp':
            this.dropdownMenuVisibleSubject.next(false);
            break;
          case 'Enter':
            this.toggleDropdown();
            break;
        }
      }
    }

    toggleDropdown() {

        this.dropdownMenuVisibleSubject.next(!$('.collapse.navbar-collapse').hasClass('show'));
    }

    openDropdown() {

        if (!isPlatformBrowser(this.platformId))
            return;

        $('.collapse.navbar-collapse').addClass('show');
        $('.dropdown').addClass('show');
        $('#dropdownMenu').addClass('show');
    }

    closeDropdown() {

        if (!isPlatformBrowser(this.platformId))
            return;

        $('.collapse.navbar-collapse').removeClass('show');
        $('.dropdown').removeClass('show');
        $('#dropdownMenu').removeClass('show');
    }

    clickOutside(event: any = null) {

        this.dropdownMenuVisibleSubject.next(false);
    }

    accessMenuClickOutside(event: any = null) {

      this.accessibilityMenuVisibleSubject.next(false);
    }

    mouseEnter() {

        this.dropdownMenuVisibleSubject.next(true);
    }

    mouseLeave() {

        setTimeout(() => {
            if (this.isDropdownOpen())
                this.dropdownMenuVisibleSubject.next(false);
        }, 100);
    }

    anchorClick(event: Event) {

        this.dropdownMenuVisibleSubject.next(false);
    }

    openCampaignSubscribeDialog() {
        this.matDialog.open(CampaignSubscribeDialogComponent);
    }

    private isDropdownOpen(): boolean {

        if (!isPlatformBrowser(this.platformId))
            return false;

        return $('.collapse.navbar-collapse').hasClass('show');
    }

  setFontStyle(fontStyleClass: string) {
      this.fontStyleClass = fontStyleClass;
      this.cookiesService.saveAccessibilityFontStyle(fontStyleClass);
      this.setClass();
      this.accessibilityMenuVisibleSubject.next(false);
  }

  setFontName(fontNameClass: string) {
      this.fontNameClass = fontNameClass;
      this.cookiesService.saveAccessibilityFont(fontNameClass);
      this.setClass();
      this.accessibilityMenuVisibleSubject.next(false);
  }

  setFontPaddingClass(fontPaddingClass: string) {
      this.fontPaddingClass = fontPaddingClass;
      this.cookiesService.saveAccessibilityPadding(fontPaddingClass);
      this.setClass();
      this.accessibilityMenuVisibleSubject.next(false);
  }

  accessibleSetTheme(event: KeyboardEvent, themeClass: string) {
    if (event) {
      if (event.code === 'Enter') {
        this.setTheme(themeClass);
      } else if (event.code === 'Escape') {
        this.accessibilityMenuVisibleSubject.next(false);
      }
    }
  }

  setTheme(themeClass: string) {
      this.themeClass = themeClass;
      this.cookiesService.saveAccessibilityTheme(themeClass);
      this.setClass();
      this.accessibilityMenuVisibleSubject.next(false);
  }

  setClass() {
    document.documentElement.className=this.themeClass + ' ' + this.fontNameClass + ' ' + this.fontStyleClass + ' ' + this.fontPaddingClass;
  }

  accessibleMenuLinkToggle(event: KeyboardEvent) {
    if (event) {
      switch (event.code) {
        case 'Escape':
          this.accessibilityMenuVisibleSubject.next(false);
          break;
        case 'Enter':
          $( this ).click();
          break;
      }
    }
  }

  accessibleMenuToggle(event: KeyboardEvent) {
      if (event) {
        switch (event.code) {
          case 'ArrowDown':
            this.accessibilityMenuVisibleSubject.next(true);
            break;
          case 'Escape':
          case 'ArrowUp':
            this.accessibilityMenuVisibleSubject.next(false);
            break;
          case 'Enter':
            this.toggleDostopnost();
            break;
        }
      }
  }

  toggleDostopnost() {
    this.accessibilityMenuVisibleSubject.next(!this.showDostopnost);
  }

  private initAccessibilitySettings() {
    if (this.themeClass) {
      this.setTheme(this.themeClass);
    }
    if (this.fontNameClass) {
      this.setFontName(this.fontNameClass);
    }
    if (this.fontStyleClass) {
      this.setFontStyle(this.fontStyleClass);
    }
    if (this.fontPaddingClass) {
      this.setFontPaddingClass(this.fontPaddingClass);
    }
  }

  getCurrentUrlContent(): string {
      const currentUrl = this.router.url;
      if (currentUrl.includes("#content")) {
        return currentUrl;
      } else {
        return `${this.router.url}#content`;
      }
  }

  focusOnQuickAccessKeydown(event) {
    if (event) {
      switch (event.code) {
        case 'Enter':
          this.focusOnQuickAccess();
          break;
      }
    }
  }

  focusOnQuickAccess() {
    this.quickAccessMenuFocus.emit();
    this.accessibilityMenuVisibleSubject.next(false);
  }

  getLogoBivanjeLarge() {
    return this.logoBivanjeLarge;
  }

  getLogoBivanjeLargeBDU() {
    return this.logoBivanjeLargeBDU;
  }

  getLogoBivanjeBig() {
    return this.logoBivanjeBig;
  }

  getLogoBivanjeMiddle() {
    return this.logoBivanjeMiddle;
  }

  showDostopnostLastKey(event: KeyboardEvent) {
    if (event) {
      switch (event.code) {
        case 'Tab':
          if (!event.shiftKey) {
            event.preventDefault(); //prevent tab
          }
          break;
        case 'Escape':
          this.accessibilityMenuVisibleSubject.next(false);
          break;
      }
    }
  }

  menuDefaultKeydown(event: KeyboardEvent) {
    if (event) {
      switch (event.code) {
        case 'Escape':
          this.accessibilityMenuVisibleSubject.next(false);
          break;
      }
    }
  }

  getKlubZveztobaLogo() {
    return this.logoKlubZvestobaOgreva;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import * as _ from 'lodash';

import { finalize } from 'rxjs/operators';

import { CmsService } from '../../services/cms.service';

import { NewsTag } from '../../models/cms.model';

@Component({
    selector: 'app-novice',
    templateUrl: './novice.component.html',
    styleUrls: ['./novice.component.scss']
})
export class NoviceComponent implements OnInit {

    public tags: NewsTag[] = [];
    public categories: NewsTag[] = [];
    
    public filtersVisible: boolean = false;
    public loading: boolean = true;

    private queryParams: Params;

    constructor(
        private route: ActivatedRoute,
        private cmsService: CmsService
    ) { }

    public ngOnInit() {

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;
        });

        this.cmsService.getCmsNewsTagsAsync()
            .pipe(
                finalize(() => { 
                    this.loading = false; 
                })
            )
            .subscribe(tags => {
                this.tags = tags.filter(e => !e.kategorija);
                this.categories = tags.filter(e => e.kategorija);
                this.loading = false; 
            });
    }

    isActiveParamKey(id, key) {

        if (!_.isUndefined(this.queryParams[key]) && this.queryParams[key] !== null) {
            if (id === null)
                return false;

            let ids = this.queryParams[key].split(',');

            return ids.indexOf(id.toString()) !== -1;
        } else if (id === null) {
            return true;
        }

        return false;
    }

    updateQueryParams(id, key) {
            
        let queryParams = _.cloneDeep(this.queryParams);

        if (id !== null) {
            let ids;

            if (!_.isUndefined(this.queryParams[key]) && this.queryParams[key] !== null)
                ids = this.queryParams[key].split(',');
            else
                ids = [];

            let idIdx = ids.indexOf(id.toString());
            if (idIdx === -1)
                ids.push(id.toString());
            else
                ids.splice(idIdx, 1);

            queryParams[key] = ids.join(',');
        } else {
            queryParams[key] = null;
        }

        if (queryParams.stran)
            delete queryParams.stran;

        return queryParams;
    }

    toggleFilters() {
        this.filtersVisible = !this.filtersVisible;
    }
}
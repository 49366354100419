import { Directive, OnInit, ElementRef, AfterViewInit, AfterContentInit } from '@angular/core';

import { DocumentsService } from '../services/documents.service';

@Directive({
    selector: '[documents]'
})
export class DocumentsDirective implements OnInit, AfterViewInit {

    constructor(
        private documentsService: DocumentsService,
        private elementRef: ElementRef,
    ) { }

    public ngOnInit() { }

    public ngAfterViewInit() {

        if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.querySelectorAll) {
            let els: any[] = this.elementRef.nativeElement.querySelectorAll('a[dokument-id]');

            for (let i = 0; i < els.length; i++) {
                this.processDocumentElement(els[i]);
            }
        }
    }

    private processDocumentElement(el: any) {

        let isResolved = el.getAttribute('dokument-resolved');

        if (isResolved && isResolved === 'true')
            return;

        let docId = el.getAttribute('dokument-id');
        let docText = el.getAttribute('dokument-text') === 'true';
        let docIcon = el.getAttribute('dokument-icon') === 'true';

        this.documentsService.parseDocumentAnchor(el, docId, docText, docIcon)
    }
}
<main id="content">
  <section class="content">
    <div class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Uporaba piškotkov</h1>
            <div #cookieBotContainer></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mailing-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.scss']
})
export class MailingThankYouComponent implements OnInit {

    constructor(
    ) { }

    public ngOnInit() {
    }
}
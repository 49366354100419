<main id="content">
    <section class="content">
        <div class="background-light-grey">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h1>Izberite kombinacijo storitev in izpolnite obrazec</h1>
                            <p>Po vnosu podatkov in oddaji elektronskega obrazca, vam v podpis pošljemo potrebne dokumente za aktivacijo izbrane storitve.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 akcija narocilo" *ngFor="let offer of offers">
                        <div class="mt-3">
                            <h2>
                              <mat-slide-toggle [checked]="offer.selected" (change)="select($event, offer)"></mat-slide-toggle>
                              {{ offer.akcija.naziv }}
                            </h2>
                            <div documents [innerHTML]="offer.vsebina | trust"></div>

                            <div class="btns" [ngClass]="{ expanded: offer.expanded }">
                                <button (click)="naroci(offer)" class="btn-blue d-inline-block" [disabled]="!offer.selected" [ngClass]="{ disabled: !offer.selected }">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i> Naroči
                                </button>
                                <button *ngIf="offer.vsebinaDodatna1 || offer.vsebinaDodatna2" class="razsiri" (click)="expand(offer)">
                                    <ng-container *ngIf="!offer.expanded"><i class="fa fa-plus-circle" aria-hidden="true"></i><em> Več o ponudbi</em></ng-container>
                                    <ng-container *ngIf="offer.expanded"><i class="fa fa-minus-circle" aria-hidden="true"></i><em> Zapri</em></ng-container>
                                </button>
                            </div>
                        </div>

                        <div class="arrow-down-white background-light-grey" *ngIf="offer.expanded">
                            <div></div>
                        </div>

                        <div class="dodatne-informacije background-light-grey" *ngIf="offer.expanded">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngIf="offer.vsebinaDodatna1 && offer.vsebinaDodatna1.length > 0" documents [innerHTML]="offer.vsebinaDodatna1 | trust"></div>
                                    <div class="mt-4" *ngIf="offer.vsebinaDodatna2 && offer.vsebinaDodatna2.length > 0" documents [innerHTML]="offer.vsebinaDodatna2 | trust"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

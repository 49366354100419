import { Route } from '@angular/router';

import { MailingUnsubscribeComponent } from './unsubscribe.component';

import { Metadata } from '../../../models/metadata.model';

const metadata: Metadata = {
    title: 'Odjava od e-novic',
    description: 'Odjava od e-novic'
};

export const route: Route = {
    path: 'odjava/:type',
    component: MailingUnsubscribeComponent,
    data: {
        metadata: metadata
    }
};
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DecimalPipe } from '@angular/common';

import * as _ from 'lodash';

declare var AmCharts : any;

import { finalize } from 'rxjs/operators';

import { CalculationService } from '../../services/calculation.service';

import { AdditionalServices, AdditionalService, CalculationInput, CalculationOutput } from '../../models/calculation.model';

@Component({
    selector: 'app-informativni-izracun',
    templateUrl: './informativni-izracun.component.html',
    styleUrls: ['./informativni-izracun.component.scss']
})
export class InformativniIzracunComponent implements OnInit {

    public povprasevanjeParams: any = {
        refTitle: 'Informativni izračun', 
        katId: 66, 
        step: 2
    };

    public showResults: boolean = false;
    public showFinish: boolean = false;
    public showGeneralError: boolean = false;

    public loadingCalculation: boolean = true;
    public loadingOrder: boolean = false;

    private additionalServices: AdditionalServices = {};
    public additionalServiceArray: AdditionalService[] = [];

    public infoCalculation: CalculationOutput = null;

    private queryParams: Params;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private route: ActivatedRoute,
        private decimalPipe: DecimalPipe,
        private calculationService: CalculationService
    ) {}

    ngOnInit() {

        if (isPlatformBrowser(this.platformId))
            this.povprasevanjeParams.refUrl = window.location.href;

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;

            this.additionalServices = this.calculationService.getAdditionalServices(qp);
            this.additionalServiceArray = Object.keys(this.additionalServices).map(k => this.additionalServices[k]);
            this.loadCalculation();

            if (isPlatformBrowser(this.platformId))
                this.povprasevanjeParams.refUrl = window.location.href;
        });
    }

    public selectDodatnaStoritev(value: boolean, storitev: AdditionalService) {

        storitev.selected = value;

        let params = _.cloneDeep(this.queryParams);

        if (this.isRefDodatnaStoritevIzbrana(storitev) || storitev.id === 1)
            return;

        params[storitev.param] = value;
        params[this.additionalServices.zdruzenRacun.param] = true;

        this.router.navigate(['./'], {
            queryParams: params,
            relativeTo: this.route
        });
    }

    public isRefDodatnaStoritevIzbrana(storitev: AdditionalService): boolean {
        
        if (storitev.dependentId) {
            for (let key in this.additionalServices) {
                if (this.additionalServices[key].id === storitev.dependentId) 
                    return this.queryParams[this.additionalServices[key].param] === 'true';
            }
        }

        return false;
    }

    public isZemeljskiPlinChoosen() {
        return this.calculationService.isZemljskiPlinOgrevanjeChoosen(this.queryParams);
    }

    public calculateSaving() {

        if (!this.infoCalculation)
            return 0;
        else
            return ((this.infoCalculation.rednaCenaSum - this.infoCalculation.akcijskaCenaSum) / 
                this.infoCalculation.rednaCenaSum) * 100;
    }

    public getNarociloParams() {
        
        let params: any = {
            ee: this.calculationService.isElektricnaEnergijaStoritevChoosen(this.queryParams),
            zp: this.calculationService.isZemeljskiPlinStoritevChoosen(this.queryParams),
            do: this.calculationService.isDaljinskoOgrevanjeStoritevChoosen(this.queryParams)
        };

        for (let st in this.additionalServices) {
            if (this.queryParams[this.additionalServices[st].param] === 'true')
                params[this.additionalServices[st].param] = true;
        }

        return params;
    }

    private loadCalculation() {

        let obj = this.calculationService.paramsToDtoConverter(this.queryParams);

        if (obj !== null) {
            this.showResults = true;
            this.loadingCalculation = true;

            this.calculationService.calculate(obj)
                .pipe(
                    finalize(() => this.loadingCalculation = false)
                )
                .subscribe(res => {
                    this.infoCalculation = res;

                    setTimeout(() => this.setAmcharts(), 200);
                });
        } else {
            this.showResults = false;
        }
    }

    private setAmcharts() {

        if (!isPlatformBrowser(this.platformId))
            return;
        
        let informativniIzracunChart = AmCharts.makeChart("informativniIzracunGraf", {
            "type": "serial",
            "theme": "light",
            "responsive": {
                "enabled": true
            },
            "fontFamily": "Source Sans Pro",
            "legend": {
                "enabled": false
            },
            "balloon": {
                "adjustBorderColor": true,
                "color": "#000000",
                "cornerRadius": 5,
                "fontSize": 14,
                "fillColor": "#FFFFFF"
            },
            "dataProvider": [{
                "paket": 'Nepovezane storitve',
                "ee": this.infoCalculation.elektricnaEnergija ? this.infoCalculation.elektricnaEnergija.rednaCena : 0,
                "do": this.infoCalculation.daljinskoOgrevanje ? this.infoCalculation.daljinskoOgrevanje.rednaCena : 0,
                "zp": this.infoCalculation.zemeljskiPlin ? this.infoCalculation.zemeljskiPlin.rednaCena : 0,
                "sum": 0
            }, {
                "paket": 'Celovita oskrba',
                "ee": 0,
                "do": 0,
                "zp": 0,
                "sum": this.infoCalculation.akcijskaCenaSum
            }],
            "valueAxes": [{
                "stackType": "regular",
                "color": "#5c5c5c",
                "axisColor": "#8c8c8c",
                "fontSize": 13,
                "axisAlpha": 0.8,
                "gridAlpha": 0,
                labelFunction: (value) => {
                    return this.decimalPipe.transform(value, '0.2-2') + " €";
                }
            }],
            "graphs": [{
                "balloonText": "<b>[[title]]: </b>" + this.decimalPipe.transform(this.infoCalculation.elektricnaEnergija ? this.infoCalculation.elektricnaEnergija.rednaCena : 0, '0.2-2') + "€",
                fillColors: "#bbbbbb",
                lineColor: "#fafafa",
                lineThickness: 4,
                "fillAlphas": 1.0,
                "fontSize": 16,
                "labelText": this.decimalPipe.transform(this.infoCalculation.elektricnaEnergija ? this.infoCalculation.elektricnaEnergija.rednaCena : 0, '0.2-2') + "€",
                "lineAlpha": 1.0,
                "title": 'Električna energija',
                "type": "column",
                "color": "#ffffff",
                "valueField": "ee"
            }, {
                "balloonText": "<b>[[title]]: </b>" + this.decimalPipe.transform(this.infoCalculation.daljinskoOgrevanje ? this.infoCalculation.daljinskoOgrevanje.rednaCena : 0, '0.2-2') + "€",
                lineColor: "#fafafa",
                lineThickness: 3,
                fillColors: "#bbbbbb",
                "fillAlphas": 0.8,
                "fontSize": 16,
                "labelText": this.decimalPipe.transform(this.infoCalculation.daljinskoOgrevanje ? this.infoCalculation.daljinskoOgrevanje.rednaCena : 0, '0.2-2') + "€",
                "lineAlpha": 1.0,
                "title": 'Daljinsko ogrevanje',
                "type": "column",
                "color": "#ffffff",
                "valueField": "do"
            }, {
                "balloonText": "<b>[[title]]: </b>" + this.decimalPipe.transform(this.infoCalculation.zemeljskiPlin ? this.infoCalculation.zemeljskiPlin.rednaCena : 0, '0.2-2') + "€",
                lineColor: "#fafafa",
                lineThickness: 3,
                fillColors: "#bbbbbb",
                "fillAlphas": 0.6,
                "fontSize": 16,
                "labelText": this.decimalPipe.transform(this.infoCalculation.zemeljskiPlin ? this.infoCalculation.zemeljskiPlin.rednaCena : 0, '0.2-2') + "€",
                "lineAlpha": 1.0,
                "title": 'Zemeljski plin',
                "type": "column",
                "color": "#ffffff",
                "valueField": "zp"
            }, {
                "balloonText": "<b>[[title]]: </b>" + this.decimalPipe.transform(this.infoCalculation.akcijskaCenaSum, '0.2-2') + "€",
                lineColor: "#fafafa",
                lineThickness: 3,
                fillColors: "#2E5DAD",
                "fillAlphas": 1.0,
                "fontSize": 16,
                "labelText": this.decimalPipe.transform(this.infoCalculation.akcijskaCenaSum, '0.2-2') + "€",
                "lineAlpha": 1.0,
                "title": 'Skupaj',
                "type": "column",
                "color": "#ffffff",
                "valueField": "sum"
            }],
            "categoryField": "paket",
            "categoryAxis": {
                "fontSize": 14,
                "gridPosition": "start",
                "color": "#5c5c5c",
                "axisAlpha": 0,
                "gridAlpha": 0,
                "position": "left",
                "autoWrap": false,
                ignoreAxisWidth: false
            },
            "export": {
                "enabled": false
            }
        });

        let prihrankiChart = AmCharts.makeChart("prihrankiGraf", {
            "type": "serial",
            "theme": "light",
            "fontFamily": "Source Sans Pro",
            "legend": {
                "enabled": false
            },
            "balloon": {
                "adjustBorderColor": true,
                "color": "#000000",
                "cornerRadius": 5,
                "fontSize": 14,
                "fillColor": "#FFFFFF"
            },
            "dataProvider": [{
                "prihranek": 'Prihranek',
                "percentage": this.infoCalculation.prihranek / this.infoCalculation.rednaCenaSum * 100,
                "ballonText": 'Prihranim ob izbiri povezanih storitev in drugih ugodnosti'
            }, {
                "prihranek": 'EKO+',
                "percentage": this.infoCalculation.okoljskiOdtis,
                "ballonText": 'Prispevam k ohranjanju čistejšega okolja'
            }, {
                "prihranek": 'Udobje',
                "percentage": this.infoCalculation.ugodje,
                "ballonText": 'Prihranim čas in sem brez skrbi'
            }],
            "graphs": [{
                "fillColors": "#0BA1E2",
                "fillAlphas": 1.0,
                "type": "column",
                "valueField": "percentage",
                "color": "#ffffff",
                "labelText": "[[value]]",
                "fontSize": 16,
                labelFunction: (value) => {
                    return this.decimalPipe.transform(value.values.close, '0.1-1') + "%";
                },
                balloonFunction : (value) => {
                    return value.dataContext.ballonText;
                }
            }],
            "valueAxes": [{
                "stackType": "regular",
                "axisAlpha": 0,
                "gridAlpha": 0,
                "minorGridEnabled": false,
                "labelsEnabled": false,
                strictMinMax: true,
                minimum: 0,
                maximum: 100
            }],
            "categoryField": "prihranek",
            "categoryAxis": {
                "fontSize": 14,
                "gridPosition": "start",
                "color": "#5c5c5c",
                "fillColor": "#e9e8e8",
                "fillAlpha": 0,
                "axisAlpha": 0,
                "gridAlpha": 0,
                "position": "left",
                "autoWrap": true
            },
            "export": {
                "enabled": false
            }
        });
    }
}
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';

import { ThirdPartyService } from '../../../services/3rd-party.service';

@Component({
    selector: 'app-smarna-gora',
    templateUrl: './smarna-gora.component.html',
    styleUrls: ['./smarna-gora.component.scss']
})
export class EnergetikaVDruzbiSmarnaGoraComponent implements OnInit, AfterViewInit {

    constructor(
        private elementRef: ElementRef,
        private thirdPartyService: ThirdPartyService
    ) {
     }

    ngOnInit() { }

    ngAfterViewInit() {

        this.thirdPartyService.eflsightInit(this.elementRef);
    }
}
import { Route } from '@angular/router';

import { CenikiDokumentiComponent } from './ceniki-dokumenti.component';
import { CenikiDokumentiListComponent } from './list/list.component';

import { Metadata } from '../../models/metadata.model';

const listMetadata: Metadata = {
    title: 'Ceniki in dokumenti',
    description: 'Arhiv cenikov in dokumentov',
    keywords: ['ceniki', 'dokumenti', 'arhiv', 'izjave', 'vloge'],
    canonicalUrl: '/ceniki-dokumenti'
};

export const route: Route = {
    path: 'ceniki-dokumenti',
    component: CenikiDokumentiComponent,
    data: {
        metadata: listMetadata
    },
    children: [{
        path: '',
        component: CenikiDokumentiListComponent,
        data: {
            metadata: listMetadata
        }
    }]
};
<nav class="row" aria-label="Meni za številčenje strani">
    <div class="col-12 col-lg-6 mt-3 text-center text-md-left pagination-count">
        <p *ngIf="showCounts">Prikazani so vnosi {{ (currentPage - 1) * itemsPerPage + 1 }} do {{ currentPage * itemsPerPage < totalCount ? currentPage * itemsPerPage : totalCount }} od {{ totalCount }}.</p>
    </div>
    <div class="col-12 col-lg-6 mt-2 mt-lg-3 pagination-navigation">
        <ul *ngIf="maxPages > (showOnePage ? 0 : 1)" class="pagination justify-content-center justify-content-md-end">
            <li class="page-item" [ngClass]="{ active: currentPage === 1 }"><a tabindex="0" class="outline-offset" (keydown)="accessibleChangePage($event, 1)" (click)="changePage(1)">1</a></li>
            <li *ngIf="pages.length > 0 && pages[0] !== 2" class="page-item"><a>...</a></li>
            <li *ngFor="let page of pages" class="page-item" [ngClass]="{ active: currentPage === page }"><a tabindex="0" class="outline-offset" (keydown)="accessibleChangePage($event, page)" (click)="changePage(page)">{{ page }}</a></li>
            <li *ngIf="pages.length > 0 && pages[pages.length - 1] < maxPages - 1" class="page-item"><a>...</a></li>
            <li *ngIf="maxPages > 1" class="page-item" [ngClass]="{ active: currentPage === maxPages }"><a tabindex="0" class="outline-offset" (keydown)="accessibleChangePage($event, maxPages)" (click)="changePage(maxPages)">{{ maxPages }}</a></li>
        </ul>
    </div>
</nav>

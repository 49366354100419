<section class="content">
    <div class="container-flud" *ngIf="loading">
        <div class="row my-5">
            <div class="col-12">
                <loader></loader>
            </div>
        </div>
    </div>
    <article *ngIf="!loading">

        <div *ngIf="!narocilo" class="pricelist">
            <action-version [offerId]="id"
                [version]="version"
                [preview]="true"
                offerTitle="Predogled akcije"
                contentSegmentTitle="Predogled"
                contentKategorijaTitle="Akcija"
                [left]="true"
                [isExpandable]="true"></action-version>
        </div>

        <div *ngIf="narocilo" class="background-light-grey py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 akcija narocilo">
                        <div class="mt-3">
                            <h3>{{ offer.akcija.naziv }}</h3>
                            <div documents [innerHTML]="offer.vsebina | trust"></div>

                            <div class="btns" [class.expanded]="expanded">
                                <button *ngIf="offer.vsebinaDodatna1 || offer.vsebinaDodatna2" class="razsiri" (click)="expand()">
                                    <span *ngIf="!expanded"><i class="fa fa-plus-circle" aria-hidden="true"></i><em> Več o ponudbi</em></span>
                                    <span *ngIf="expanded"><i class="fa fa-minus-circle" aria-hidden="true"></i><em> Zapri</em></span>
                                </button>
                            </div>
                        </div>

                        <div class="arrow-down background-light-grey" *ngIf="expanded">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </div>

                        <div class="dodatne-informacije background-light-grey" *ngIf="expanded">
                            <div class="row">
                                <div documents [innerHTML]="offer.vsebinaDodatna1 | trust"></div>
                                <div documents [innerHTML]="offer.vsebinaDodatna2 | trust"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </article>
</section>

<main id="content">
  <section class="content">
    <div class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-12 pt-2">
            <h1>Izjava o dostopnosti</h1>
            <p>
              JAVNO PODJETJE ENERGETIKA LJUBLJANA d.o.o. skladno z Zakonom o dostopnosti spletišč in mobilnih aplikacij (Ur. l. RS, št. 30/18) omogoča dostopnost spletnega mesta www.bivanjudajemoutrip.si. Izjava o dostopnosti se nanaša na spletišče JAVNO PODJETJE ENERGETIKA LJUBLJANA d.o.o. na spletnem mestu www.bivanjudajemoutrip.si.
            </p>
            <p class="mb-5">
              Spletna stran sledi smernicam standarda WCAG (Web Content Accesisibility Guidelines) 2.1., standardu AA.
            </p>
            <h2>Opis dostopnih vsebin</h2>
            <p>
              Spletna stran vsebuje namenski vtičnik »Dostopnost«, ki omogoča:
            </p>
            <ul>
              <li>izbiro visoko kontrastnih barvnih shem glede na standard (dostopna, črna na bež, črna na belem, bela na črnem, rumena na modrem, črna na rumenem in zelena na črnem),</li>
              <li>večstopenjsko izbiro velikosti pisave,</li>
              <li>izpis vsega besedila v krepki pisavi (Bold),</li>
              <li>izpis vsega besedila z velikimi črkami (Caps),</li>
              <li>izbiro tipa pisave (sistemska, Arial, Verdana in OpenDyslexic),</li>
              <li>uporabnikom z disleksijo je na voljo možnost preklopa med serifno in ne-serifno pisavo,</li>
              <li>izpis kazala (zemljevid) celotne spletne strani,</li>
              <li>skok na glavno vsebino,</li>
              <li>večstopenjsko povečavo brez izgube informacij za 400%,</li>
              <li>upravljanje spletne strani s tipkovnico s prikazom fokusa na izbrano povezavo.</li>
            </ul>
            <p>Spletna stran ima razumljivo in konsistentno strukturo ter navigacijo.</p>
            <p>Slikovnim materialom je dodan vpis alternativnega teksta, sheme imajo urejeno tekstovno vsebinsko razlago.</p>
            <p>Spletna stran ima razpoznavne povezave. Povezave, ki se odpirajo v novem oknu, so ustrezno označene z ikono. Povezave imajo ustrezno označbo.</p>
            <p>Spletna stran je, razen manjših izjem, optimizirana za bralnike.</p>
            <p class="mb-5">Spletna stran ima dostopne obrazce.</p>
            <h2>Nedostopne vsebine</h2>
            <p class="mb-5">Dostopnost spletnega mesta nenehno spremljamo in sproti izboljšujemo posamezne elemente dostopnosti. Nekatere objavljene vsebine ne izpolnjujejo vseh zahtev glede dostopnosti, ker so objavljene poleg elektronskega obrazca tudi v PDF formatu (obrazci, naročila, izjave, vloge, ceniki). </p>
            <h2>Izjeme</h2>
            <p>V skladu s 6. členom Zakona o dostopnosti spletišč in mobilnih aplikacij (ZDSMA) ni zagotovljena dostopnost za zavihek “Energetika+Nipke” oz. videa youtube, zavihek "Šmarna Gora" oziroma zemljevide poti.</p>
            <p class="mb-5">Captcha (test, ki loči računalnik od človeka) se v dostopnih temah ne prikazuje.</p>
            <h2>Dodatna vprašanja</h2>
            <p class="mb-5">Če naletite na težavo glede dostopnosti vas prosimo, da nas o tem obvestite. V primeru, da imate glede dostopnih/nedostopnih vsebin dodatna vprašanja, nam lahko pišete na <a href="mailto:info@energetika.si">info&#64;energetika.si</a> ali pokličete na 080 2882.</p>
            <h2>Informacije o inšpekcijskem nadzoru</h2>
            <p>Nadzor izvajajo inšpektorji za informacijsko družbo.</p>
            <p>
              Ministrstvo za javno upravo
              <br>
              Uprava za informacijsko varnost
              <br>
              Inšpekcija za informacijsko družbo
              <br>
              Tržaška cesta 21
              <br>
              1000 Ljubljana
              <br>
              <a href="mailto:gp.mju@gov.si">gp.mju&#64;gov.si</a>
            </p>
            <p class="mb-5">
              Datum objave Izjave o dostopnosti: 21. 2. 2024
              <br>
              Zadnja posodobitev Izjave o dostopnosti:  21. 2. 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<div class="my-5" *ngIf="loadingObrazecUpdate || loadingKategorije; else formTemplate">
    <loader></loader>
</div>
<ng-template #formTemplate>
    <povprasevanje-obrazec *ngIf="step === 3 && !obrazecSubmitted"
        [obrazec]="obrazec"
        [vsiObrazci]="obrazci"
        [vrednosti]="podrocjeVrednosti"
        [isLastStep]="false"
        [showErrors]="obrazecShowError"
        [povprasevanje]="povprasevanje"
        (onDescriptionUpdate)="onDescriptionUpdate($event)"
        (onSubmit)="onBasicSubmit($event, obrazec)"
        (onBack)="onBasicBack($event, obrazec)">
    </povprasevanje-obrazec>

    <form [formGroup]="calculationForm" (ngSubmit)="submit()" *ngIf="step === 4 && !obrazecSubmitted">
        <div class="form-group mb-3">
            <strong>Moji podatki</strong>
        </div>

        <div class="form-group my-3">
            Podatke najdete na računu.
        </div>

        <div class="form-group mb-2">
            <input type="number" class="form-control" formControlName="porabaVt"
                [ngClass]="{ 'error-border': calculationForm.get('porabaVt')?.dirty && !calculationForm.get('porabaVt')?.valid }">
          <p *ngIf="calculationForm.get('porabaVt')?.dirty && calculationForm.get('porabaVt')?.errors?.min" class="help-block error"><em>Vrednost mora biti večja od 0.</em></p>
        </div>

        <div class="form-group mb-2">
            <input type="number" class="form-control" formControlName="porabaMt"
                [ngClass]="{ 'error-border': calculationForm.get('porabaMt')?.dirty && !calculationForm.get('porabaMt')?.valid }">
          <p *ngIf="calculationForm.get('porabaMt')?.dirty && calculationForm.get('porabaMt')?.errors?.min" class="help-block error"><em>Vrednost mora biti večja od 0.</em></p>
        </div>

        <div class="form-group mb-2">
            <input type="number" class="form-control" formControlName="porabaEt"
                [ngClass]="{ 'error-border': calculationForm.get('porabaEt')?.dirty && !calculationForm.get('porabaEt')?.valid }">
          <p *ngIf="calculationForm.get('porabaEt')?.dirty && calculationForm.get('porabaEt')?.errors?.min" class="help-block error"><em>Vrednost mora biti večja od 0.</em></p>
        </div>

        <div class="form-group mb-2">
            <select class="form-control" formControlName="obracunskaMoc" [ngClass]="{ 'error-border': calculationForm.get('obracunskaMoc')?.dirty && !calculationForm.get('obracunskaMoc')?.valid }">
                <option value="" disabled selected>Obračunska moč</option>
                <option *ngFor="let om of obracunskaMoc" [value]="om.moc">{{ om.naziv }}</option>
            </select>
          <p *ngIf="calculationForm.get('obracunskaMoc')?.dirty && calculationForm.get('obracunskaMoc')?.errors?.min" class="help-block error"><em>Vrednost mora biti večja od 0.</em></p>
        </div>

      <p *ngIf="calculationForm.dirty && calculationForm.errors?.poraba" class="help-block error"><em>Potrebno je podati podatke za dvotarifno (VT in MT) ali enotarifno (ET) porabo.</em></p>

        <div class="form-group my-3">
            <p>Izračun je narejen na podlagi trenutno veljavnega cenika za dobavo električne energije gospodinjskim in malim poslovnim odjemalcem.</p>
        </div>

        <div class="row">
            <div class="col-4 mt-3">
                <button routerLink="./" [queryParams]="{ step: step - 1 }" queryParamsHandling="merge" class="btn btn-sm as-link" type="button"><i class="fa fa-chevron-left" aria-hidden="true"></i> Nazaj</button>
            </div>
            <div class="col-8 mt-3 text-right">
                <button type="submit" class="btn btn-next">Želim najugodnejšo ponudbo!</button>
            </div>
        </div>
    </form>

    <ng-container *ngIf="!(loadingObrazecUpdate || loadingKategorije) && obrazecSubmitted">
        <ng-container *ngIf="!obrazecSubmitError; else obrazecSubmitErr">
            <p>Vaš obrazec je bil uspešno oddan!</p>
            <p>V primeru, da nadaljevanje postopka zahteva vaš podpis, ste na navedeni elektronski naslov prejeli izpolnjen obrazec in nadaljnja navodila.</p>

            <ng-container *ngIf="result">
                <div class="mb-2">
                    <strong>Izračun za porabljeno elektriko:</strong>
                </div>
                <div class="row mb-2">
                    <div class="col-8">Postavka na računu</div>
                    <div class="col-4 text-right">Znesek brez DDV</div>
                </div>
                <div class="row" *ngFor="let p of result?.postavke">
                    <div class="col-8">{{ p.naziv }}</div>
                    <div class="col-4 text-right">{{ p.skupajBrezDdv |  number: '1.2-2' }} EUR</div>
                </div>
                <div class="row mb-3">
                    <div class="col-8 text-uppercase"><strong>Skupaj</strong></div>
                    <div class="col-4 text-right">{{ result.skupajBrezDdv |  number: '1.2-2' }} EUR</div>
                </div>
                <p>Informativni izračun ne vsebuje 22% DDV. Izračun z vključenim DDV prejmete na vaš elektronski naslov.</p>

                <div class="row justify-content-end">
                    <div class="col-8 mt-3 text-right">
                        <a routerLink="/povprasevanje" [queryParams]="{ katId: 37, step: 3 }" type="submit" class="btn btn-next">Želim postati vaš odjemalec!</a>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #obrazecSubmitErr>
            <div class="alert alert-warning">
                <p>Prišlo je do napake na strežniku med procesiranjem zahteve. Prosimo, preglejte obrazce na prejšnjih korakih ter preverite veljavnost podatkov, ter poskusite ponovno oddati povpraševanje.</p>
            </div>
        </ng-template>
    </ng-container>
</ng-template>

<nav class="text-center mt-4 mt-md-7" aria-label="Navigacijski meni">
    <i class="fa fa-circle-o" aria-hidden="true"></i>
    <i class="fa fa-circle-o" aria-hidden="true"></i>
    <i class="fa" [class.fa-circle]="step === 3" [class.fa-circle-o]="step !== 3" aria-hidden="true"></i>
    <i class="fa" [class.fa-circle]="step === 4" [class.fa-circle-o]="step !== 4" aria-hidden="true"></i>
</nav>

import { Route } from '@angular/router';

import { MailingComponent } from './mailing.component';

import { route as unsubscribeRoute } from './unsubscribe/routes';
import { route as thankYouRoute } from './thank-you/routes';

export const route: Route = {
    path: 'e-novice',
    component: MailingComponent,
    children: [
        unsubscribeRoute,
        thankYouRoute
    ]
};
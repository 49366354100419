<div class="fab-container first" matTooltip="Klub Zvestoba ogreva" matTooltipPosition="left" >
    <a #quickAccessElement routerLink="/klub-zvestoba-ogreva" class="btn btn-secondary outline-offset" aria-label="Klub zvestoba ogreva">
      <div class="div-img zvestoba-klub-img"></div>
    </a>
</div>

<div class="fab-container second" matTooltip="Moj račun" matTooltipPosition="left">
    <a href="https://mojracun.energetika.si/login?next=%2F" target="_blank" class="btn btn-primary outline-offset" aria-label="Moj Račun">
      <div class="div-img racun-img"></div>
    </a>
</div>

<!--<div class="fab-container third" matTooltip="Oddaj naročilo" matTooltipPosition="left">-->
<!--    <a routerLink="/narocilo/akcija" class="btn btn-primary">-->
<!--        <img src="assets/images/ikone/nakupovalni-vozicek-transparent.png">-->
<!--    </a>-->
<!--</div>-->

<div class="fab-container third" matTooltip="Dela na omrežju" matTooltipPosition="left">
    <a routerLink="/dela-na-omrezju" class="btn btn-primary outline-offset" aria-label="Dela na omrežju">
      <div class="div-img delovna-celada-img"></div>
    </a>
</div>

<div class="fab-container fourth" matTooltip="Povpraševanje" matTooltipPosition="left">
    <a routerLink="/povprasevanje" class="btn btn-primary outline-offset" aria-label="Povpraševanje">
      <div class="div-img oblacek-img"></div>
    </a>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public currentYear: number;

    constructor() { }

    public ngOnInit() {

        this.currentYear = new Date().getFullYear();
    }

    public scrollToTop() {
        window.scrollTo(0, 0);
    }
}
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { finalize, mergeMap } from 'rxjs/operators';

import { PovprasevanjeBaseComponent } from './base/base.component';

import { PovprasevanjeService } from '../../services/povprasevanje.service';
import { CookiesService } from '../../services/cookies.service';

import { Kategorija, Obrazec, Povprasevanje, PovprasevanjePodrocjeVrednost } from '../../models/povprasevanje.model';
import { of, Observable, combineLatest } from 'rxjs';

@Component({
    selector: 'app-povprasevanje',
    templateUrl: './povprasevanje.component.html',
    styleUrls: ['./povprasevanje.component.scss']
})
export class PovprasevanjeComponent extends PovprasevanjeBaseComponent implements OnInit {

    @ViewChild('fileInput')
    public fileInputElementRef: ElementRef;

    public isAbstractExtended: boolean = false;

    constructor(
        router: Router,
        route: ActivatedRoute,
        povprasevanjeService: PovprasevanjeService,
        cookiesService: CookiesService
    ) { 
        super(router, route, povprasevanjeService, cookiesService);
    }

    public ngOnInit() {

        this.initStorage();
        this.initPovprasevanje();

        this.generateSteps();

        this.route.queryParamMap.subscribe(pm => {

            if (pm.has('step')) 
                this.step = parseInt(pm.get('step'));
            else
                this.step = 1;
            
            if (pm.has('katId'))
                this.loadKategorija(parseInt(pm.get('katId')));
            else
                this.loadKategorije();

            this.refUrl = pm.has('refUrl') ? pm.get('refUrl') : null;
            this.refTitle = pm.has('refTitle') ? pm.get('refTitle') : null;

            this.obrazecShowError = pm.has('err') && pm.get('err') === 'true';
        });
    }

    public onChildRouteActivate(e: any) {
        this.isChildRouteActivated = true;
    }

    public onChildRouteDeactivate(e: any) {
        this.isChildRouteActivated = false;
    }

    public selectKategorija(kat: Kategorija) {
        this.router.navigate(['./'], { queryParams: { katId: kat.id, step: this.step + 1, err: null }, relativeTo: this.route });
    }

    public onDescriptionUpdate(val: string) {
        
        if (this.povprasevanje === null)
            return;

        this.povprasevanje.opis = val ? val : null;
        this.savePovprasevanjeLocalStorage(this.povprasevanje);
    }

    public onObrazecBack(ev: any, o: Obrazec) {

        this.updatePovprasevanjeFromObrazecEvent(ev, o);
        this.savePovprasevanjeLocalStorage(this.povprasevanje);

        let newStep = this.step - 1;
        let newKatId = this.kategorija.id;

        if (newStep === this.kategorija.nivo + 1 && this.kategorija.stars)
            newKatId = this.kategorija.stars.id;
        else if (newStep === this.kategorija.nivo + 1 && !this.kategorija.stars)
            newKatId = null;

        this.router.navigate(['./'], { queryParams: { step: this.step - 1, katId: newKatId, err: null }, queryParamsHandling: 'merge', relativeTo: this.route });
    }

    public onObrazecSubmit(ev: any, o: Obrazec) {

        if (this.loadingObrazecUpdate)
            return;

        this.loadingObrazecUpdate = true;
        this.obrazecSubmitted = false;
        this.obrazecSubmitError = false;

        this.updatePovprasevanjeFromObrazecEvent(ev, o);

        this.savePovprasevanjeLocalStorage(this.povprasevanje);

        let apiModel: Povprasevanje = _.cloneDeep(this.povprasevanje);

        // remove files (datoteka property)
        apiModel.podrocja[0].vrednosti.forEach(v => {
            if (v.datoteka) {
                if (v.datoteka.name && !this.files.some(f => f.name === v.datoteka.name)) {
                    this.files.push(v.datoteka);
                }

                delete v.datoteka;
            }
        });

        let obs: Observable<any>;

        if (!this.povprasevanje.id)
            obs = this.povprasevanjeService.createPovprasevanje(this.kategorija.id, apiModel);
        else
            obs = this.povprasevanjeService.updatePovprasevanje(apiModel.id, this.kategorija.id, apiModel);

        obs.pipe(
            mergeMap((res: Povprasevanje) => {

                this.povprasevanje = res;
                this.savePovprasevanjeLocalStorage(this.povprasevanje);

                let fileObs = [];

                if (this.povprasevanje.id && this.files.length > 0) {
                    for (let f of this.files) {
                        fileObs.push(this.povprasevanjeService.uploadPovprasevanjeFile(this.povprasevanje.id, this.kategorija.id, f));
                    }

                    this.files = [];
                }

                return fileObs.length > 0 ? combineLatest(fileObs) : of([]);
            }),
            mergeMap(res => {
                let nextStep = this.step + 1;
                let showErr = null;

                if (this.povprasevanje && this.povprasevanje.podrocja) {
                    let podrocje = this.povprasevanje.podrocja.find(p => p.kategorijaId === this.kategorija.id);

                    if (podrocje && podrocje.veljavnostObrazcev) {
                        let notValidIdx = podrocje.veljavnostObrazcev.slice(0, this.step - this.kategorija.nivo - 1).indexOf(false);

                        if (notValidIdx !== -1) {
                            nextStep = this.kategorija.nivo + 2 + notValidIdx;
                            showErr = true;
                        }
                    }
                }

                if (nextStep <= this.maxSteps || showErr) { 
                    this.router.navigate(['./'], { queryParams: { step: nextStep, err: showErr, des: null }, queryParamsHandling: 'merge', relativeTo: this.route });
                    return of(null);
                } else if (nextStep > this.maxSteps) {
                    this.obrazecSubmitted = true;
                    this.step = nextStep;
                    return this.povprasevanjeService.oddajPovprasevanje(this.povprasevanje.id, this.kategorija.id);
                }
            }),
            finalize(() => this.loadingObrazecUpdate = false)
        ).subscribe(res => {
            if (this.obrazecSubmitted) {
                this.deletePovprasevanjeLocalStorage();
                this.povprasevanje = null;
            }
        }, err => {
            console.log(err)
            if (!this.povprasevanje.id && err.status === 400) {
                // goto first step of povprasevanje if not already there
                let nextStep = this.kategorija.nivo + 2;
                let err = true;

                if (nextStep === this.step && this.step + 1 <= this.maxSteps) {
                    nextStep++;
                    err = null;
                }

                this.router.navigate(['./'], { queryParams: { step: nextStep, err: err }, queryParamsHandling: 'merge', relativeTo: this.route });
            } else if (err.status === 404) {
                this.povprasevanje.id = null;
                this.savePovprasevanjeLocalStorage(this.povprasevanje);
            } else if (this.obrazecSubmitted) {
                this.obrazecSubmitError = true;
            }
        });
    }

    public abstractExtend() {
        this.isAbstractExtended = !this.isAbstractExtended;
    }
}
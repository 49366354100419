import { Component, OnInit } from '@angular/core';
import {CookiesService} from '../../services/cookies.service';

@Component({
    selector: 'app-energetika-v-druzbi',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class EnergetikaVDruzbiComponent implements OnInit {

    private energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
    private nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-white.png';
    private topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-white.png';

    constructor(
      private cookiesService: CookiesService
    ) { }

    ngOnInit() {

      this.cookiesService.selectedTheme$.subscribe(res => {
        switch (res) {
          case 'theme-wcag':
          case 'theme-normal':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-white.png';
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-white.png';
            break;
          case 'theme-white-black':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-white.png';
            this.nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-white.png';
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-white.png';
            break;
          case 'theme-black-white':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-black.png';
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-black.png';
            break;
          case 'theme-black-beige':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-black.png';
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-black.png';
            break;
          case 'theme-black-yellow':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-black.png';
            this.nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-black.png';
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-black.png';
            break;
          case 'theme-yellow-blue':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-yellow.png';
            this.nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-yellow.png';
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-yellow.png';
            break;
          case 'theme-lime-black':
            this.energetikaVDruzbiLogo = 'assets/images/energetika-v-druzbi/logo-lime.png';
            this.nipkeLogo = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-lime.png';
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-lime.png';
            break;
        }
      });
    }

    getEnergetikaVDruzbiLogo() {
      return this.energetikaVDruzbiLogo;
    }

  getNipkeLogo() {
    return this.nipkeLogo;
  }

  getTackeLogo() {
    return this.topleTackeLogo;
  }
}

<div class="container py-4">
    <div class="row">
        <div class="col-12 col-lg-3">
            <a class="svetovalec content-box content-box-title" routerLink="/novice">
                <div class="div-img clovek-racunalnik-img"></div>
                <h1>Svetovalec</h1>
            </a>
        </div>
        <div class="col-12 col-lg-9">
            <div class="svetovalec content-box content-box-description height-control">
                <p>Naš Svetovalec združuje <strong>odgovore na pogosto zastavljena vprašanja naših uporabnikov</strong>, ki so povezana z izbiro in izkoriščanjem različnih virov energije ter učinkovito rabo energije v vsakdanjem življenju.</p>
                <p>Ozaveščeni uporabniki energije pomembno prispevamo k energetsko in okoljsko učinkoviti rabi energije ter s tem k višji kakovosti našega bivanja.</p>
            </div>
        </div>
    </div>

    <div class="row">
        <aside class="col-12 col-md-4 col-lg-3">
            <nav class="d-none d-md-flex" aria-label="Meni: Vse kategorije">
                <ul class="sidebar-nav list-group w-100">
                    <li class="list-group-item no-padding" [ngClass]="{ active: isActiveParamKey(null, 'kategorija') }">
                        <a routerLink="./" [queryParams]="{ kategorija: null, stran: null }" queryParamsHandling="merge">Vse kategorije</a>
                    </li>
                    <li *ngFor="let tag of categories" class="list-group-item" [ngClass]="{ active: isActiveParamKey(tag.id, 'kategorija') }">
                        <a routerLink="./" [queryParams]="{ kategorija: tag.id, stran: null }" queryParamsHandling="merge">{{ tag.naziv }}</a>
                    </li>
                </ul>
            </nav>
        </aside>

        <div class="col-12 navigation-mobile py-2 mb-3 d-block d-md-none">
            <div class="col-12">
                <button (click)="toggleFilters()" class="btn btn-blue border-only">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                    <ng-container *ngIf="!filtersVisible">Prikaži filtre</ng-container>
                    <ng-container *ngIf="filtersVisible">Skrij filtre</ng-container>
                </button>
            </div>

            <div *ngIf="filtersVisible" class="col-12 navigation-mobile-kategorije">
                <nav aria-label="Meni: Vse kategorije">
                    <ul class="sidebar-nav list-group w-100">
                        <li class="list-group-item no-padding" [ngClass]="{ active: isActiveParamKey(null, 'kategorija') }">
                            <a routerLink="./" [queryParams]="{ kategorija: null, stran: null }" queryParamsHandling="merge">Vse kategorije</a>
                        </li>
                        <li *ngFor="let tag of categories" class="list-group-item" [ngClass]="{ active: isActiveParamKey(tag.id, 'kategorija') }">
                            <a routerLink="./" [queryParams]="{ kategorija: tag.id, stran: null }" queryParamsHandling="merge">{{ tag.naziv }}</a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div *ngIf="filtersVisible" class="col-12 navigation-mobile-oznake">
                <nav aria-label="Meni: Vse oznake">
                    <ul class="nav nav-pills novice-tags col-12 text-center">
                        <li class="filter" [ngClass]="{ active: isActiveParamKey(null, 'oznake') }">
                            <a class="outline-offset" routerLink="./" [queryParams]="updateQueryParams(null, 'oznake')" queryParamsHandling="merge">Vse oznake</a>
                        </li>
                        <li *ngFor="let tag of tags" class="filter" [ngClass]="{ active: isActiveParamKey(tag.id, 'oznake') }">
                            <a class="outline-offset" routerLink="./" [queryParams]="updateQueryParams(tag.id, 'oznake')" queryParamsHandling="merge">{{ tag.naziv }}</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <main id="content" class="col-12 col-md-8 col-lg-9">
            <div class="container-fluid">
                <div class="row d-none d-md-flex">
                    <div class="col-12">
                        <nav aria-label="Meni: Vse oznake">
                            <ul class="nav nav-pills novice-tags col-12 text-center">
                                <li class="filter" [ngClass]="{ active: isActiveParamKey(null, 'oznake') }" >
                                    <a class="outline-offset" routerLink="./" [queryParams]="updateQueryParams(null, 'oznake')" queryParamsHandling="merge">Vse oznake</a>
                                </li>
                                <li *ngFor="let tag of tags" class="filter" [ngClass]="{ active: isActiveParamKey(tag.id, 'oznake') }">
                                    <a class="outline-offset" routerLink="./" [queryParams]="updateQueryParams(tag.id, 'oznake')" queryParamsHandling="merge">{{ tag.naziv }}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="row" *ngIf="loading">
                    <div class="col-12 my-5">
                        <loader></loader>
                    </div>
                </div>

                <router-outlet></router-outlet>

            </div>
        </main>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="svetovalec content-box content-box-description text-center">
                <p><strong>Hvala, ker nas berete, všečkate in delite.</strong> Vaša mnenja, predloge ter vprašanja nam lahko posredujete na <a href="mailto:info@energetika.si" class="link-style">info&#64;energetika.si</a>, s pripisom: Za svetovalca</p>
            </div>
        </div>
    </div>
</div>

import { Route } from '@angular/router';

import { NoviceComponent } from './novice.component';
import { NoviceListComponent } from './list/list.component';
import { NoviceDetailsComponent } from './details/details.component';

import { Metadata } from '../../models/metadata.model';

const listMetadata: Metadata = {
    title: 'Svetovalec',
    description: 'Naš Svetovalec združuje odgovore na pogosto zastavljena vprašanja naših uporabnikov, ki so povezana z izbiro in izkoriščanjem različnih virov energije ter učinkovito rabo energije v vsakdanjem življenju.',
    keywords: ['svetovalec', 'odgovor', 'pogosto zastavljena vprašanja', 'viri energije', 'vsakdanje življenje'],
    canonicalUrl: '/novice'
};

const detailsMetadata: Metadata = {
    title: 'Svetovalec',
    skipAutoSetup: true
};

export const route: Route = {
    path: 'novice',
    component: NoviceComponent,
    children: [{
        path: '',
        component: NoviceListComponent,
        data: { metadata: listMetadata }
    }, {
        path: ':id/:name',
        component: NoviceDetailsComponent,
        data: { metadata: detailsMetadata }
    }]
};
<main id="content">
    <section class="content">
        <div class="background-light-grey">
            <div class="container">
                <div class="title">
                    <h1>Pogoji in navodila za uporabo storitve »SMS OBVEŠČANJE O DELIH NA OMREŽJU«</h1>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p><strong>1. Ponudnik storitve</strong></p>
                        <p>Ta pravila določajo način izvajanja storitve »SMS OBVEŠČANJE O DELIH NA OMREŽJU« za uporabnike omrežja zemeljskega plina in omrežja toplote (daljinsko ogrevanje) Javnega podjetja Energetika Ljubljana d.o.o., Verovškova ulica 62, 1000 Ljubljana (v nadaljevanju: ponudnik obveščanja). Ponudnik poti je Telekom Slovenije d.d. (v nadaljevanju ponudnik).</p>

                        <p><strong>2. Opis storitve, prijava in odjava na storitev</strong></p>
                        <p>Storitev »SMS OBVEŠČANJE O DELIH NA OMREŽJU« lahko uporabljajo uporabniki vseh omrežij mobilnih operaterjev, ki se na obveščanje prijavijo na spletni strani <a href="https://www.bivanjudajemoutrip.si">www.bivanjudajemoutrip.si</a>.<br />
                            Uporabnik v postopku prijave izbere svojo ulico in hišno številko iz nabora ponujenih v meniju, nakar se mu izpiše, za katere energente se lahko prijavi na obveščanje. V nadaljevanju prijave uporabnik vnese svojo mobilno telefonsko številko in potrdi izbiro »POŠLJI VERIFIKACIJSKO KODO«. Po vnosu verifikacijske kode in potrditve le-te, uporabnik vstopi v urejanje SMS obveščanja na mobilno telefonsko številko za izbrano ulico, kjer lahko označi ali odznači obveščanje za navedene energente. Spremembo uporabnik potrdi s pritiskom gumba »POSODOBI«.<br />
                            V kolikor se uporabnik želi odjaviti od storitve, vstopi v urejanje obvestil za izbrani naslov po zgornjih navodilih in odznači navedene energente.
                        </p>

                        <p><strong>3. Cenik SMS sporočil</strong></p>
                        <p>Vsak poslani SMS s strani uporabnika se zaračuna po vsakokratnem veljavnem ceniku ponudnika poti. Posredovani SMS-i uporabniku s strani ponudnika obveščanja so za uporabnika brezplačni.</p>

                        <p><strong>4. Zasebnost in varstvo podatkov uporabnika</strong></p>
                        <p>Ponudnik obveščanja se zavezuje, da bo skrbno in v skladu z zakonodajo varoval podatke uporabnikov, ki z uporabo storitve »SMS OBVEŠČANJE O DELIH NA OMREŽJU« dovoljujejo zbiranje, obdelovanje in hranjenje zbranih osebnih podatkov za namene izvedbe storitve »SMS OBVEŠČANJE O DELIH NA OMREŽJU« in prejemanje brezplačnih promocijskih SMS sporočil ter zbranih podatkov ne bo posredoval tretji osebi.</p>

                        <p><strong>5. Avtorske pravice</strong></p>
                        <p>Ponudnik obveščanja  ima urejene vse materialne avtorske pravice v zvezi z vsebinami, ki jih bo ponujal v okviru storitve »SMS OBVEŠČANJE O DELIH NA OMREŽJU«.</p>

                        <p><strong>6. Splošni pogoji sodelovanja</strong></p>
                        <p>Uporabnik z naročilom obveščanja pristane na vse pogoje v zvezi s storitvijo. SMS storitev lahko uporabljajo vsi uporabniki ne glede na njihovo starost.</p>

                        <p><strong>7. Pomoč in reklamacije</strong></p>
                        <p>Uporabniki lahko za pomoč pri uporabi storitve pokličejo klicni center ponudnika obveščanja na številko 080 2882.<br />
                            Ponudnik storitve je odgovoren za reševanje reklamacij in prevzema odgovornost za pravilno izvedbo storitev. V primeru utemeljenih reklamacij se ponudnik obveščanja zavezuje, da jih bo odpravil v razumnem času in o tem po potrebi obvestil uporabnika. V kolikor se reklamacije nanašajo na ponudnika poti, bo ponudnik obveščanja predmetne reklamacije posredoval ustrezni službi ponudnika poti. V primeru pozitivno rešene reklamacije, ki se nanaša na plačilo storitev, bo ponudnik poti vrnil uporabniku znesek, na katerega se nanaša predmetna reklamacija v skladu z lastnimi postopki. Ponudnik poti rešuje izključno reklamacije, ki se nanašajo na delovanje omrežja ponudnika poti in plačilo storitev. Takšne reklamacije mora uporabnik v pisni obliki nasloviti na reklamacijsko službo ponudnika poti v roku 15 dni od prejema računa. Vse ostale reklamacije rešuje ponudnik obveščanja. Reklamacijo lahko posredujete na e-mail: <a href="mailto:info@energetika.si">info&#64;energetika.si</a> ali pokličete na številko klicnega centra 080 2882.</p>

                        <p><strong>8. Splošno</strong></p>
                        <p>Ponudnik obveščanja lahko v vsakem trenutku spremeni pogoje storitve, če to zahtevajo vzroki tehnične, komercialne narave ali vzroki, ki so na strani javnosti. Vsaka sprememba bo predhodno ustrezno objavljena na javno dostopnem mediju. Ponudnik obveščanja bo o vseh spremembah in novostih svoje uporabnike obveščal na vseh mestih, kjer so objavljena navodila za uporabo storitev in pogoji. Ponudnik obveščanja ne odgovarja za motnje v poteku storitve, ki so posledica ravnanja uporabnika (npr. izključen telefon, prazna baterija, zadrževanje na območju zelo slabega signala, ...). Ponudnik obveščanja ne more zagotavljati delovanja storitve v primeru izpada omrežja pogodbenih partnerjev, izpada električne energije ali drugih tehničnih motenj, ki bi lahko začasno motile delovanje storitve, še posebej v primerih nedelovanja ponudnikovega dostopa do interneta.</p>

                        <p>Maj 2017</p>

                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { mergeMap } from 'rxjs/operators';

import { CmsService } from '../../services/cms.service';
import { MetadataService } from '../../services/metadata.service';

import { CategoryExtended, Offer, CallToActionType } from '../../models/cms.model';
import {CookiesService} from '../../services/cookies.service';

@Component({
    selector: 'app-cms-vsebina',
    templateUrl: './cms-vsebina.component.html',
    styleUrls: ['./cms-vsebina.component.scss']
})
export class CmsVsebinaComponent implements OnInit {

    public content: CategoryExtended;
    public offers: Offer[] = [];
    public regularPricelists: Offer[] = [];
    public loading: boolean = true;

    public cmsContentBasePath;
    public expanded: boolean = false;

    private segment: string;
    private category: string;

  private energentiImage: string;
  private upravljanjeRacunaImage: string;
  private investicijeImage: string;
  private financneUgodnostiImage: string;
  private ucinkovitaRabaEnergijeImage: string;
  private inzeniringImage: string;
  private klubZvestobaImage: string;

  private filterVsebina: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService,
        private metadataService: MetadataService,
        private cookiesService: CookiesService
    ) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.route.paramMap
            .pipe(
                mergeMap(pm => {
                    this.segment = pm.has('segment') ? pm.get('segment') : null;
                    this.category = pm.has('category') ? pm.get('category') : null;

                    this.expanded = false;
                    this.loading = true;

                    let path = this.segment + (this.category ? '/' + this.category : '');

                    return this.cmsService.getCategoryByPath(path);
                }),
                mergeMap(cat => {

                    let predogled = this.route.snapshot.queryParams.predogled;
                    let verzija = this.route.snapshot.queryParams.verzija;

                    if (cat === null || predogled)
                        return this.cmsService.getContentPage(this.segment, this.category, predogled === 'true', verzija ? verzija : null);
                    else
                        return this.cmsService.getContentPageById(cat.id);
                })
            )
            .subscribe(res => {
                if (!res) {
                  this.loading = false;
                  this.router.navigate(['/'], {replaceUrl: true});
                  return;
                }
                this.content = res;
                this.offers = res.akcije ? res.akcije.filter(a => !a.redniCenik) : [];
                this.regularPricelists = res.akcije ? res.akcije.filter(a => a.redniCenik) : [];

                if (this.content && this.content.cta && this.content.ctaTip && CallToActionType[this.content.ctaTip].toString() === CallToActionType.DOKUMENT.toString())
                    this.content.ctaDokument.url = `${this.cmsService.serviceUrl}/dokument/${this.content.ctaDokument.id}/download`;

                this.loading = false;

                this.metadataService.setTitle(res.naziv);
                this.metadataService.setDescription(res.seoDescription);
                this.metadataService.setKeywords(res.seoKeywords ? res.seoKeywords.split(',') : null);
                this.metadataService.setCanonicalUrl(this.router.url);

                this.metadataService.setImage(res.ozadje ? `${this.cmsContentBasePath}${res.ozadje.url}` : null, null, null, null, true);
            });

      this.cookiesService.selectedTheme$.subscribe(res => {

        switch (res) {
          case 'theme-wcag':
            this.energentiImage = 'assets/images/menu/ikona-plamen-blue-light-wcag.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-light-wcag.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-light-wcag.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-light-wcag.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-light-wcag.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-light-wcag.png';
            this.klubZvestobaImage = 'assets/images/ikone/zvestoba-ogreva-klub-brown.png';
            this.filterVsebina = false;
            break;
          case 'theme-normal':
            this.energentiImage = 'assets/images/ikone/plamen-blue-light.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-light.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-light.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-light.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-light.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-light.png';
            this.klubZvestobaImage = 'assets/images/ikone/zvestoba-ogreva-klub-brown.png';
            this.filterVsebina = false;
            break;
          case 'theme-white-black':
            this.energentiImage = 'assets/images/ikone/wcag/plin-white-black.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-white-black.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-white-black.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-white-black.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-white-black.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-white-black.png';
            this.klubZvestobaImage = 'assets/images/ikone/wcag/zvestoba-ogreva-klub-brown-white-black.png';
            this.filterVsebina = true;
            break;
          case 'theme-black-white':
            this.energentiImage = 'assets/images/ikone/wcag/plin-black.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-black-white.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-black-white.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-black-white.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-black-white.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-black-white.png';
            this.klubZvestobaImage = 'assets/images/ikone/wcag/zvestoba-ogreva-klub-brown-black-white.png';
            this.filterVsebina = true;
            break;
          case 'theme-black-beige':
            this.energentiImage = 'assets/images/ikone/wcag/plin-beige.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-black-beige.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-black-beige.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-black-beige.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-black-beige.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-black-beige.png';
            this.klubZvestobaImage = 'assets/images/ikone/wcag/zvestoba-ogreva-klub-brown-black-beige.png';
            this.filterVsebina = true;
            break;
          case 'theme-black-yellow':
            this.energentiImage = 'assets/images/ikone/wcag/plin-black-yellow.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-black-yellow.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-black-yellow.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-black-yellow.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-black-yellow.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-black-yellow.png';
            this.klubZvestobaImage = 'assets/images/ikone/wcag/zvestoba-ogreva-klub-brown-black-yellow.png';
            this.filterVsebina = true;
            break;
          case 'theme-yellow-blue':
            this.energentiImage = 'assets/images/ikone/wcag/plin-yellow.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-yellow-blue.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-yellow-blue.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-yellow-blue.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-yellow-blue.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-yellow-blue.png';
            this.klubZvestobaImage = 'assets/images/ikone/wcag/zvestoba-ogreva-klub-brown-yellow-blue.png';
            this.filterVsebina = true;
            break;
          case 'theme-lime-black':
            this.energentiImage = 'assets/images/ikone/wcag/plin-lime.png';
            this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-lime-black.png';
            this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-lime-black.png';
            this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-lime-black.png';
            this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-lime-black.png';
            this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-lime-black.png';
            this.klubZvestobaImage = 'assets/images/ikone/wcag/zvestoba-ogreva-klub-brown-lime-black.png';
            this.filterVsebina = true;
            break;
        }
      });
    }

    public expand() {
        this.expanded = !this.expanded;
    }

    // [src]="cmsContentBasePath + kategorija.segment.slika.url"
  getKategorijaImage(imeDatoteke: string) {
    switch (imeDatoteke) {
      case "ikona-nastavitve-blue-light.png":
        return this.investicijeImage;
      case "ikona-cekini-blue-light.png":
        return this.financneUgodnostiImage;
      case "ikona-plamen-blue-light.png":
        return this.energentiImage;
      case "ikona-racun-blue-light.png":
        return this.upravljanjeRacunaImage;
      case "ikona-zvestoba-ogreva-klub-brown.png":
        return this.klubZvestobaImage;
      case "ikona-zarnica-blue-light.png":
        return this.ucinkovitaRabaEnergijeImage;
    }
  }

  getVsebina(vsebina: string): string {

    if (this.filterVsebina) {
      // filter colors and backgrounds on monochromatic
      vsebina = vsebina.replace(/style="backgroun[a-zA-Z0-9:#;\.\s\(\)\-\,]*"/gi, '');
      vsebina = vsebina.replace(/style="colo[a-zA-Z0-9:#;\.\s\(\)\-\,]*"/gi, '');
      vsebina = vsebina.replace(/color="[a-zA-Z0-9:#;\.\s\(\)\-\,]*"/gi, '');

      vsebina = vsebina.replace(/backgroun[a-zA-Z0-9:#;\.\s\(\)\-\,]*;/gi, '');
      vsebina = vsebina.replace(/colo[a-zA-Z0-9:#;\.\s\(\)\-\,]*;/gi, '');
    }
    return vsebina;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import * as _ from 'lodash';

import { CmsService } from '../../../../services/cms.service';
import { OfferExtended } from '../../../../models/cms.model';

@Component({
    selector: 'app-narocilo-akcija-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class NarociloAkcijaListComponent implements OnInit {

    public offers: OfferExtended[] = [];

    private queryParams: Params;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService
    ) { }

    public ngOnInit() { 

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;
        });

        this.cmsService.getOffers()
            .subscribe(res => {
                let ee = this.queryParams.ee === 'true';
                let zp = this.queryParams.zp === 'true';
                let to = this.queryParams.do === 'true';
                let investicijska = this.queryParams.finNaj === 'true' || this.queryParams.plinPak === 'true' || 
                    this.queryParams.kre === 'true';
                let selected = false;

                this.offers = res.map(o => {
                    o.expanded = false;
                    o.selected = false;

                    if (!selected && o.akcija.elektricnaEnergija === ee && o.akcija.zemeljskiPlin === zp && 
                        o.akcija.daljinskoOgrevanje === to && o.akcija.investicijska === investicijska) {
                        o.selected = true;
                        selected = true;
                    }

                    return o;
                });
            });
    }

    expand(offer: OfferExtended) {
        offer.expanded = !offer.expanded;
    }

    select(value: MatSlideToggleChange, offer: OfferExtended) {

        offer.selected = value.checked;
        
        if (!offer.selected)
            return;

        for (let i = 0; i < this.offers.length; i++) {
            if (offer.akcija.id !== this.offers[i].akcija.id)
                this.offers[i].selected = false;
        }
    }

    naroci(offer) {

        let params = _.cloneDeep(this.queryParams);

        this.router.navigate(['./', offer.akcija.id], {
            queryParams: params,
            relativeTo: this.route
        });
    }
}
import { Route } from '@angular/router';

import { EnergetikaVDruzbiComponent } from './layout.component';
import { EnergetikaVDruzbiNipkeComponent } from './energetika-nipke/energetika-nipke.component';
import { EnergetikaVDruzbiTopleTackeComponent } from './tople-tacke/tople-tacke.component';
import { EnergetikaVDruzbiSmarnaGoraComponent } from './smarna-gora/smarna-gora.component';
import { EnergetikaVDruzbiMatevzLenarcicComponent } from './matevz-lenarcic/matevz-lenarcic.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Energetika v družbi',
    description: 'Energetika v družbi z Nipketom in toplimi tačkami',
    keywords: ['energetika', 'druzba', 'nipke', 'tople tacke'],
    imageUrl: 'assets/images/energetika-v-druzbi/energetika-nipke-thumbnail.jpg',
    imageWidth: 570,
    imageHeight: 320
};

const nipkeEnergetikaMetadata: Metadata = {
    title: 'Energetika v družbi: Energetika + Nipke',
    description: 'Poslanstvo Energetike Ljubljana je zanesljiva, učinkovita in okolju prijazna oskrba z energijo. Pomembna energija, kateri daje pozornost tudi Energetika Ljubljana, pa je kreativna energija. Posledica tega je sodelovanje z Nipketom, ki ga za ustvarjanje pesmi navdihuje prav ta energija. Cilj našega sodelovanja je širiti in izbrati pravo energijo.',
    keywords: ['energetika', 'nipke', 'sodelovanje', 'energija', 'zanesljivost'],
    imageUrl: 'assets/images/energetika-v-druzbi/energetika-nipke-thumbnail.jpg',
    imageWidth: 570,
    imageHeight: 320
};

const topleTackeMetadata: Metadata = {
    title: 'Energetika v družbi: Tople tačke',
    description: 'Ker nam je pomembno, da se vsi prebivalci Ljubljane in okolice počutijo v svojem mestu dobro, smo se to zimo odločili poskrbeti za naše štirinožne prijatelje. Razvili smo grelne blazine Tople tačke in jih postavili na desetih lokacijah v Ljubljani in okolici v želji poskrbeti za toplo udobje naših kosmatih štirinožnih prijateljev.',
    keywords: ['tople', 'tacke', 'kosmatinci', 'psi', 'grelne blazine', 'ljubljana', 'lokacije'],
    imageUrl: 'assets/images/energetika-v-druzbi/tople-tacke-thumbnail.jpg',
    imageWidth: 570,
    imageHeight: 320
};

const smarnaGoraMetadata: Metadata = {
    title: 'Energetika v družbi: Poti na Šmarno goro',
    description: 'Energetika Ljubljana (in pred njo TE-TOL) podpira dejavnost turističnih in športnih delavcev izpod Šmarne gore že od jeseni 2004 dalje. V tem času smo praktično vsako leto dodali drobec v mozaik urejenosti te naše najbolj obiskane gore, enega pomembnejših simbolov zelene Ljubljane.',
    keywords: ['šmarna gora', 'turstični delavci', 'športni delavci', 'urejenost', 'simbol', 'ljubljana'],
    imageUrl: 'assets/images/energetika-v-druzbi/smarna-gora-thumbnail.jpg',
    imageWidth: 570,
    imageHeight: 320
};

const matevzLenarcicMetadata: Metadata = {
    title: 'Energetika v družbi: Okoljska misija GreenLightWorldFlight',
    description: 'Matevž Lenarčič je izkušen pilot, diplomiran biolog, alpinist in fotograf. Okoljska misija GreenLight WorldFlight 2012-2020 preučuje vplive črnega ogljika na globalne podnebne spremembe. Energetika Ljubljana je zeleni partner projekta.',
    keywords: ['GreenLightWorldFlight', 'matevž', 'lenarčič', 'okoljska misija', 'greenlight', 'worldflight', 'črni ogljik', 'podnebne spremembe'],
    imageUrl: 'assets/images/energetika-v-druzbi/matevz-lenarcic-thumbnail.jpg',
    imageWidth: 570,
    imageHeight: 320
};

export const route: Route = {
    path: 'energetika-v-druzbi',
    component: EnergetikaVDruzbiComponent,
    data: {
        metadata: metadata
    },
    children: [{
        path: 'energetika-nipke',
        component: EnergetikaVDruzbiNipkeComponent,
        data: {
            metadata: nipkeEnergetikaMetadata
        }
    }, {
        path: 'tople-tacke',
        component: EnergetikaVDruzbiTopleTackeComponent,
        data: {
            metadata: topleTackeMetadata
        }
    }, {
        path: 'smarna-gora',
        component: EnergetikaVDruzbiSmarnaGoraComponent,
        data: {
            metadata: smarnaGoraMetadata
        }
    }, {
        path: 'matevz-lenarcic',
        component: EnergetikaVDruzbiMatevzLenarcicComponent,
        data: {
            metadata: matevzLenarcicMetadata
        }
    }]
};
import { Component, OnInit } from '@angular/core';

import { CmsService } from '../../../services/cms.service';

import { KzoUgodnost } from '../../../models/cms.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'klub-zvestoba-ogreva-ugodnosti',
    templateUrl: './ugodnosti.component.html',
    styleUrls: ['./ugodnosti.component.scss'],
    host: {
        class: 'row'
    }
})
export class KlubZvestobaOgrevaUgodnostiComponent implements OnInit {

    public cmsContentBasePath;
    public ugodnostiAsync: Observable<KzoUgodnost[]>;
    public activeActionId: number = null;

    constructor(private cmsService: CmsService) {
    }

    ngOnInit() {
        this.cmsContentBasePath = this.cmsService.contentBasePath;
        this.ugodnostiAsync = this.cmsService.getKzoUgodnostiAsync();
    }

    public showActionAdditional(id: number) {

        if (this.activeActionId === id)
            this.activeActionId = null;
        else
            this.activeActionId = id;
    }
}

export interface KlubZvestobaOgrevaUgodnost {
    id: number;
    textId: string;
    title: string;
    orderIndex: number;
    descriptions: {
        text: string;
        small?: boolean;
    }[];
    imageUrl: string;
    imageClass?: string;
    imageHref?: string;
    imageRouterLink?: string;
    action?: boolean;
    actionUrl?: string;
    actionRouterLink?: string;
    actionButtonText?: string;
    actionExplanationText?: string;
}

const now = new Date();

export const kzoCenaPlina: string = '0,03590';
export const kzoCenaPlinaOd: Date = new Date(now.getFullYear(), now.getMonth(), 1); // most of the time the validity is fro begining to end of month
export const kzoCenaPlinaDo: Date = new Date(now.getFullYear(), now.getMonth() + 3, 0);

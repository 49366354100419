<article>
    <div class="background-light-grey">
        <div class="container">
            <div class="row title">
                <div class="col-12">
                    <img class="img-fluid" [src]="getEnergetikaNipkeLogo()" alt="Slika s tekstom: energetika + nipke"/>
                </div>
            </div>
            <div class="row content-block">
                <div class="col-12 col-lg-6">
                    <h3 class="left-md">Ustvarjamo kreativno energijo.</h3>
                </div>
                <div class="col-12 col-lg-6">
                    <p>Poslanstvo Energetike Ljubljana je zanesljiva, učinkovita in okolju prijazna oskrba z energijo. Pomembna energija, kateri daje pozornost tudi Energetika Ljubljana, pa je kreativna energija. Rezultat tega je sodelovanje z Nipketom, ki ga za ustvarjanje pesmi navdihuje prav ta energija. Cilj našega sodelovanja je širiti in izbrati pravo energijo.</p>
                </div>
            </div>
            <div class="row content-block video">
                <div class="col-12">
                    <iframe src="https://www.youtube.com/embed/IODWiz96XIc?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="Youtube video z naslovom: 'Energija je med nami'" allowfullscreen></iframe>
                </div>
                <div class="col-12 mt-3">
                    <iframe src="https://www.youtube.com/embed/tZlPTxIrrK0?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="Youtube video z naslovom: 'Kdo si zares?'" allowfullscreen></iframe>
                </div>
            </div>
            <div class="row content-block spoznaj-nipketa">
                <div class="col-12 col-lg-6">
                    <h3 class="left-md">Spoznaj Nipketa!</h3>
                </div>
                <div class="col-12 col-lg-6 extendable-content-container" [ngClass]="{ extended: aboutNipkeExtended }">
                    <p class="content-wrapper">Nipke, slovenski hip hop izvajalec, je na glasbenem prostoru prisoten že precej časa. Prvič se je javnosti predstavil v duetu Overdose s singlom Fiama.com. Nekaj časa po tej predstavitvi se je spoprijateljil z Damjanom Jovičem, posledica tega pa je bila izdaja Nipketovega solo albuma. Album je bil predstavljen v razprodanem Orto baru v Ljubljani, sodelovanje z Jovičem pa je nanizalo precej hitov. Najbolj poznane uspešnice so »Všeč tko k je« in »Noben me ne razume«, za katero je od akademije prejel Zlato Piščal za »Pesem leta«, »Vsi smo na istem«, za katero sta skupaj s Trkajem prejela Žaromet za »Pesem leta«, »Popoln Lajf« , »Ina« in »Bejba iz neta«. S pesmijo »Dej se ustav« se je zavihtel na najvišja mesta na glasbenih lestvicah. Njegovi posnetki na YouTubu imajo več milijonov ogledov, saj so nad Nipketom navdušene prav vse generacije.</p>
                    <div class="read-more">
                        <button (click)="aboutNipkeExtend(true)" *ngIf="!aboutNipkeExtended">Preberi več<br /><i class="fa fa-chevron-down" aria-hidden="true"></i></button>
                        <button (click)="aboutNipkeExtend(false)" *ngIf="aboutNipkeExtended">Skrij<br /><i class="fa fa-chevron-up" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
            <div class="row content-block nipke-priporoca-navigation">
                <div class="col-12">
                    <h3 class="text-left text-lg-center">Nipke priporoča</h3>
                </div>
                <nav class="col-12 horizontal-scroll-container" aria-label="Meni - Nipke priporoča">
                    <div>
                        <a tabindex="0" *ngFor="let r of nipkePriporocaItems" (keydown)="accessibilityNipkePriporocaSelect($event, r)" (click)="nipkePriporocaSelect(r)" [class]="r.iconClass + (nipkePriporocaSelected.iconClass === r.iconClass ? ' active' : '')">
                            <div class="icon"></div>
                            <div class="text">{{ r.title }}</div>
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    </div>

    <div class="background-blue options-list nipke-priporoca-content">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 offset-lg-3">
                    <h4>{{ nipkePriporocaSelected.title }}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6 offset-lg-3" [innerHTML]="nipkePriporocaSelected.content"></div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row pt-4">
                <div class="col-12">
                    <h3 class="text-left text-lg-center">Sledi Nipketu na Instagramu</h3>
                </div>
                <div class="col-sm-12 social-plugin ig">
                    <div class="elfsight-app-a9e9221a-8ebf-4ec4-a4c2-342d704eab85"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey" *ngIf="showFbContent">
        <div class="container">
            <div class="row">
                <div class="col-12 social-plugin fb">
                    <div>
                        <div class="fb-page" data-href="https://www.facebook.com/nipkehollywood/" data-tabs="timeline" data-small-header="false" data-adapt-container-width="true" data-width="500" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/nipkehollywood/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/nipkehollywood/">NIPKE</a></blockquote></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</article>

import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-akcija-vozim-na-plin',
    templateUrl: './vozim-na-plin.component.html',
    styleUrls: ['./vozim-na-plin.component.scss']
})
export class AkcijaVozimNaPlinComponent implements OnInit {

    constructor() { }

    public ngOnInit() { }
}
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { ThirdPartyService } from '../../../services/3rd-party.service';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements AfterViewInit {

    @ViewChild('cookieBotContainer', { static: true }) private cookieBotContainerElementRef: ElementRef;

    constructor(
        private thirdPartyService: ThirdPartyService
    ) { }

    public ngAfterViewInit() {

        this.thirdPartyService.cookiesCookieBotUsageInit(this.cookieBotContainerElementRef);
    }
}
import { Injectable, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var FB: any;

@Injectable()
export class ThirdPartyService {

    private fbLoaded: boolean = false;
    private twitterLoaded: boolean = false;
    private linkedinLoaded: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId
    ) { }

    public eflsightInit(el: ElementRef) {

        if (!this.isBrowser())
            return;

        let elScrs = document.querySelectorAll('script[src*="apps.elfsight.com"]');

        if (elScrs.length > 0) {
            for (let i = 0; i < elScrs.length; i++) {
                elScrs[i].remove();
            }
        }

        let elPops = document.querySelectorAll('div.eapps-instagram-feed-popup');

        if (elPops.length > 0) {
            for (let i = 0; i < elPops.length; i++) {
                elPops[i].remove()
            }
        }

        if (window['eapps']) delete window['eapps'];
        if (window['eappsInstagramFeed']) delete window['eappsInstagramFeed'];
        if (window['EappsInstagramFeedReady']) delete window['EappsInstagramFeedReady'];

        let e = document.createElement("script");
        e.type = "text/javascript";
        e.src = "https://apps.elfsight.com/p/platform.js";
        document.body.appendChild(e);
    }

    public fbSdkInit() {

        if (!this.isBrowser())
            return;
        
        if (this.fbLoaded) {
            if (FB)
                setTimeout(() => FB.XFBML.parse(), 500);
            
            return;
        }

        let divRoot = document.createElement('div');
        divRoot.id = 'fb-root';

        let script = document.createElement('script');
        script.id = 'fb-script';
        script.innerHTML = '(function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "https://connect.facebook.net/sl_SI/sdk.js#xfbml=1&version=v3.2&appId=378321346284449"; fjs.parentNode.insertBefore(js, fjs); }(document, \'script\', \'facebook-jssdk\'));';

        document.body.appendChild(divRoot);
        document.body.appendChild(script);

        this.fbLoaded = true;
    }

    public twitterSdkInit() {

        if (!this.isBrowser())
            return;

        if (this.twitterLoaded) {
            if (window['twttr'])
                setTimeout(() => window['twttr'].widgets.load(), 500);
            
            return;
        }

        let script = document.createElement('script');
        script.id = 'twitter-script';
        script.innerHTML = 'window.twttr = (function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0], t = window.twttr || {}; if (d.getElementById(id)) return t; js = d.createElement(s); js.id = id; js.src = "https://platform.twitter.com/widgets.js"; fjs.parentNode.insertBefore(js, fjs); t._e = []; t.ready = function(f) { t._e.push(f); }; return t; }(document, "script", "twitter-wjs"));';
        document.body.appendChild(script);

        this.twitterLoaded = true;
    }

    public linkedinSdkInit() {

        if (!this.isBrowser())
            return;

        if (this.linkedinLoaded) {
            if (window['IN'])
                 setTimeout(() => window['IN'].parse(), 500);
            
            return;
        }

        let script = document.createElement('script');
        script.id = 'linkedin-script';
        script.src = '//platform.linkedin.com/in.js';
        script.type = 'text/javascript';
        script.innerHTML = 'lang: sl_SI';
        document.body.appendChild(script);

        this.linkedinLoaded = true;
    }

    public cookiesCookieBotUsageInit(el: ElementRef) {

        if (!this.isBrowser())
            return;

        let script = document.createElement('script');
        script.src = 'https://consent.cookiebot.com/66f10d25-7569-4efb-9493-7543cb02b7fc/cd.js';
        script.type = 'text/javascript';
        script.setAttribute('async', 'async');

        el.nativeElement.appendChild(script);
    }

    public emailingUnsubscribeInit() {

        if (!this.isBrowser())
            return;

        let e = document.createElement('script'); 
        e.type = 'text/javascript'; 
        e.async = true; 
        e.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://btn.createsend1.com/js/sb.min.js?v=3'; 
        e.className = 'createsend-script'; 
        
        let s = document.getElementsByTagName('script')[0]; 
        s.parentNode.insertBefore(e, s);
    }

    private isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }
}
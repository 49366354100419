<main id="content" class="container py-4">
  <div class="row">
    <div class="col-12">
      <h1 class="display-4 transform-none">Razlaga računa</h1>

      <p class="font-weight-bold text-justify">Vas zanima kaj pomenijo vse postavke na vašem računu? Ali morda kdaj je bil zadnjič izveden popis vaše merilne naprave in posledično izveden poračun porabe?</p>
      <p class="text-justify">Razumevanje računa za obračun energije je ključno za spremljanje lastne porabe in učinkovit nadzor stroškov. Za boljši pregled in razumevanje smo za vas pripravili razlago računa.</p>

      <p id="select-energent" class="font-weight-bold text-center">Izberite vrsto energenta</p>

      <div class="row mt-3 mb-4">
        <div class="col-12 mb-2 mb-sm-0 col-sm-4 d-flex flex-row align-items-center justify-content-center text-decoration-none energent-icon color-header" [class.active]="activatedChild === razlagaRacunaEnergent.PLIN">
          <img (click)="activateChild(razlagaRacunaEnergent.PLIN)" [src]="getPlinImage()" alt="Ikona Plin">
          <p tabindex="0" (keydown)="accessibleActivateChild($event, razlagaRacunaEnergent.PLIN)" (click)="activateChild(razlagaRacunaEnergent.PLIN)" class="mb-0 ml-3 energent {{ activatedChild === razlagaRacunaEnergent.PLIN ? 'font-weight-bold' : ''}}">Plin</p>
        </div>
        <div class="col-12 mb-2 mb-sm-0 col-sm-4 d-flex flex-row align-items-center justify-content-center text-decoration-none energent-icon color-header" [class.active]="activatedChild === razlagaRacunaEnergent.ELEKTRIKA">
          <img (click)="activateChild(razlagaRacunaEnergent.ELEKTRIKA)" [src]="getElektrikaImage()" alt="Ikona Elektrika">
          <p tabindex="0" (keydown)="accessibleActivateChild($event, razlagaRacunaEnergent.ELEKTRIKA)" (click)="activateChild(razlagaRacunaEnergent.ELEKTRIKA)" class="mb-0 ml-3 energent {{ activatedChild === razlagaRacunaEnergent.ELEKTRIKA ? 'font-weight-bold' : ''}}">Elektrika</p>
        </div>
        <div class="col-12 col-sm-4 d-flex flex-row align-items-center justify-content-center text-decoration-none energent-icon color-header" [class.active]="activatedChild === razlagaRacunaEnergent.TOPLOTA">
          <img (click)="activateChild(razlagaRacunaEnergent.TOPLOTA)" [src]="getToplotaImage()" alt="Ikona Toplota">
          <p tabindex="0" (keydown)="accessibleActivateChild($event, razlagaRacunaEnergent.TOPLOTA)" (click)="activateChild(razlagaRacunaEnergent.TOPLOTA)" class="mb-0 ml-3 energent {{ activatedChild === razlagaRacunaEnergent.TOPLOTA ? 'font-weight-bold' : ''}}">Toplota</p>
        </div>
      </div>

      <ng-container [ngSwitch]="activatedChild">
        <ng-container *ngSwitchCase="razlagaRacunaEnergent.PLIN">
          <app-razlaga-racuna-plin></app-razlaga-racuna-plin>
        </ng-container>
        <ng-container *ngSwitchCase="razlagaRacunaEnergent.ELEKTRIKA">
          <app-razlaga-racuna-elektrika></app-razlaga-racuna-elektrika>
        </ng-container>
        <ng-container *ngSwitchCase="razlagaRacunaEnergent.TOPLOTA">
          <app-razlaga-racuna-toplota></app-razlaga-racuna-toplota>
        </ng-container>
      </ng-container>
    </div>
  </div>
</main>

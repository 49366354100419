<div class="position-relative">
  <button tabindex="0" class="btn btn-blue responsive-width btn-border-radius outline-offset play-pause-button"
          aria-label="Preklop animacije" (click)="toggleRevProgress()" >
    <i *ngIf="!sliderStopped" class="fa-regular fa-circle-pause"></i>
    <i *ngIf="sliderStopped" class="fa-regular fa-circle-play"></i>
  </button>

  <div class="rev_slider_wrapper">

      <div class="rev_slider" data-version="5.0" id="homeSlider">
          <ul>
              <!-- Priključitev -->
            <li data-delay="7000" data-description="Zemeljski plin" data-easein="default" data-easeout="default" data-masterspeed="default" data-param1="test" data-rotate="0" data-slotamount="default" data-title="Priključitev plinovodno omrežje" data-thumb="assets/images/carousel/background_prikljucitev.png" data-transition="slideremoveup">

                <img class="img-overlay-minimal" alt="Zemeljski plin - možnost brezplačne priključitve" data-bgfit="cover" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="assets/images/carousel/background_prikljucitev.png" width="1920">


                <div class="tp-caption carousel-content transparent extra-wide" data-height="['480', '400', '350', '300']" data-y="0" data-x="center">
  <!--                   data-transform_in="opacity:0;s:700;e:Power3.easeOut;" data-transform_out="opacity:0;s:500;e:Power2.easeInOut;"-->
  <!--                   data-speed="500" data-start="1000" data-end="6500" data-endspeed="500"-->
  <!--              >-->


                  <a tabindex="-1" href="/vsebina/investicije-in-vzdrzevanje/prikljucek" class="image-outline no-text-decor" aria-labelledby="zp11 zp12 zp13">
                    <div class="headline mt-3 mt-sm-5">
                      <div class="carousel-heading bigger text-brown-dark text-normal" id="zp11"><span class="pr-2 pl-2">Zemeljski plin</span></div>
                    </div>
                    <div class="description mt-3 mt-xs-2 mt-lg-5">
                      <p class="xx-large-custom text-brown-dark" id="zp12"><span class="pr-2 pl-2">možnost brezplačne</span></p>
                      <p class="xx-large-custom text-brown-dark" id="zp13"><span class="pr-2 pl-2">priključitve</span></p>
                    </div>
                    <div class="button-wrapper d-block mt-3 mt-xs-2 mt-lg-5">
                      <button class="btn button-size-custom btn-border-radius button-brown-dark outline-offset">Več informacij</button>
                    </div>
                  </a>
                </div>

            </li>
              <!-- Zamenjava dobavitelja - plin -->
              <li data-delay="7000" data-description="Zemeljski plin" data-easein="default" data-easeout="default" data-masterspeed="default" data-param1="test" data-rotate="0" data-slotamount="default" data-title="Zemeljski plin" data-thumb="assets/images/carousel/background22.jpg" data-transition="slideremoveup">
                <a tabindex="-1" href="/vsebina/energenti/zemeljski-plin" aria-labelledby="zp21 zp22">

                  <div class="overlay-slider"></div>

                  <img alt="Zemeljski plin - izbira zanesljivega partnerja" data-bgfit="cover" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="assets/images/carousel/background22.jpg" style="background-color:#fff;" width="1920">

                  <div class="tp-caption carousel-content transparent extra-wide" data-height="['480', '400', '350', '300']" data-y="0" data-x="center">
  <!--                     data-transform_in="opacity:0;s:700;e:Power3.easeOut;" data-transform_out="opacity:0;s:500;e:Power2.easeInOut;" data-speed="500" data-start="1000" data-end="6500"-->
  <!--                     data-endspeed="500"-->
  <!--                >-->
                      <div class="headline mt-4 mt-sm-5 mt-lg-6 mt-xl-7">
                        <div class="carousel-heading text-white text-normal" id="zp21"><span class="pr-2 pl-2">Zemeljski plin</span></div>
                      </div>
                      <div class="description mt-3 mt-xs-4">
                        <p class="xx-large text-white" id="zp22"><span class="pr-2 pl-2">izberite zanesljivega partnerja</span></p>
                      </div>
                      <div class="button-wrapper d-block mt-3 mt-xs-4 mt-lg-5">
                          <button class="btn btn-border-radius outline-offset"><strong>Menjava dobavitelja</strong></button>
                      </div>
                  </div>
                </a>
              </li>

              <!-- Zamenjava dobavitelja - elektrika -->
              <li data-delay="7000" data-description="Elektrika" data-easein="default" data-easeout="default" data-masterspeed="default" data-param1="test" data-rotate="0" data-slotamount="default" data-title="Elektrika" data-thumb="assets/images/carousel/background23.jpg" data-transition="slideremoveup">
                <a tabindex="-1" href="/vsebina/energenti/elektricna-energija" aria-labelledby="el1 el2">

                  <div class="overlay-slider"></div>

                  <img alt="Slika Elektrika - vsi energenti na istem računu" data-bgfit="cover" data-bgposition="top center" data-bgrepeat="no-repeat" data-no-retina="" src="assets/images/carousel/background23.jpg" style="background-color:#fff;" width="1920">

                  <div class="tp-caption carousel-content transparent extra-wide" data-height="['480', '400', '350', '300']" data-y="0" data-x="center">
  <!--                     data-transform_in="opacity:0;s:700;e:Power3.easeOut;" data-transform_out="opacity:0;s:500;e:Power2.easeInOut;"-->
  <!--                     data-speed="500" data-start="1000" data-end="6500" data-endspeed="500"-->
  <!--                >-->
                      <div class="headline mt-4 mt-sm-5 mt-lg-6 mt-xl-7">
                          <div class="carousel-heading text-white text-normal" id="el1"><span class="pr-2 pl-2">Elektrika</span></div>
                      </div>
                      <div class="description mt-3 mt-xs-4">
                        <p class="xx-large text-white" id="el2"><span class="pr-2 pl-2">vsi energenti na istem računu</span></p>
                      </div>
                      <div class="button-wrapper d-block mt-3 mt-xs-4 mt-lg-5">
                        <button class="btn btn-border-radius outline-offset"><strong>Menjava dobavitelja</strong></button>
                      </div>
                  </div>
                </a>
              </li>

              <!-- EMO -->
              <li data-delay="7000" data-description="Enakomerni mesečni obrok" data-easein="default" data-easeout="default" data-masterspeed="default" data-param1="test" data-rotate="0" data-slotamount="default" data-title="Enakomerni mesečni obrok" data-thumb="assets/images/carousel/background24.jpg" data-transition="slideremoveup">
                <a tabindex="-1" href="/vsebina/upravljanje-racuna/enakomerni-mesecni-znesek" aria-labelledby="ob1 ob2 ob3">

                  <div class="overlay-slider"></div>

                  <img alt="Enakomerni mesečni obrok" data-bgfit="cover" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="assets/images/carousel/background24.jpg" style="background-color:#fff;" width="1920">

                  <div class="tp-caption carousel-content transparent extra-wide" data-height="['480', '400', '350', '300']" data-y="0" data-x="center">
  <!--                     data-transform_in="opacity:0;s:700;e:Power3.easeOut;" data-transform_out="opacity:0;s:500;e:Power2.easeInOut;" -->
  <!--                     data-speed="500" data-start="1000" data-end="6500" data-endspeed="500"-->
  <!--                >-->
                      <div class="headline mt-3 mt-sm-5 mt-lg-6 mt-xl-7">
                        <div class="carousel-heading text-white text-normal" id="ob1"><span class="pr-2 pl-2">Enakomerni mesečni obrok</span></div>
                      </div>
                      <div class="description d-none d-sm-block mt-4">
                        <p class="xx-large text-white" id="ob2"><span class="pr-2 pl-2">razdelite stroške ogrevanja na 12 mesecev</span></p>
                      </div>
                      <div class="description d-block d-sm-none mt-3">
                        <p class="x-large text-white" id="ob3"><span class="pr-2 pl-2">razdelite stroške<br />ogrevanja na 12 mesecev</span></p>
                      </div>
                      <div class="button-wrapper d-block mt-3 mt-xs-4 mt-lg-5">
                          <button class="btn btn-border-radius outline-offset"><strong>Vklopi storitev</strong></button>
                      </div>
                  </div>
                </a>
              </li>
          </ul>
      </div>
  </div>

  <main id="content">
      <div class="pt-3 background-light-grey">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <div class="povprasevanja p-3">
                          <h1 class="mb-0">Kako vam lahko pomagamo?</h1>
                          <p class="mt-0">Izpolnite naš spletni obrazec in prihranite čas.</p>
                        <a routerLink="/povprasevanje" class="btn btn-sm btn-blue border-only border-white text-white responsive-width d-inline-block px-5">Začni</a>

                        <h2 class="mt-3">Najpogosteje izpolnjeni postopki</h2>

                          <div class="col-12 mt-3">
                              <ul class="nav nav-pills novice-tags text-center">
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 58, step: 3 }">Menjava plačnika</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 57, step: 3 }">Menjava lastnika</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 59, step: 3 }">Sprememba osebnih podatkov</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 47, step: 4 }">E-račun</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 48, step: 4 }">Enakomerni mesečni obrok</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 50, step: 4 }">Trajnik</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 2, step: 3 }">Menjava dobavitelja plina</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 37, step: 4 }">Menjava dobavitelja elektrike</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 49, step: 4 }">Združeni račun</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 22, step: 3 }">Plinska peč "na ključ"</a></li>
                                  <li class="filter"><a routerLink="/povprasevanje" [queryParams]="{ katId: 11, step: 3 }">Priključek za plin</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="py-3 background-light-grey">
          <div class="container novice">

              <div class="row">
                  <div class="col-12 col-md-4 col-lg-3">
                      <a class="svetovalec content-box content-box-title" routerLink="./novice">
                          <div class="div-img clovek-racunalnik-img"></div>
                          <h2>Svetovalec</h2>
                      </a>
                  </div>
                  <div class="col-12 col-md-8 col-lg-9">
                      <div class="svetovalec content-box content-box-description height-control">
                          <p>Naš Svetovalec združuje <strong>odgovore na pogosto zastavljena vprašanja naših uporabnikov</strong>, ki so povezana z izbiro in izkoriščanjem različnih virov energije ter učinkovito rabo energije v vsakdanjem življenju.</p>
                          <p>Ozaveščeni uporabniki energije pomembno prispevamo k energetsko in okoljsko učinkoviti rabi energije ter s tem k višji kakovosti našega bivanja.</p>
                      </div>
                  </div>

                  <div class="col-12 my-5" *ngIf="newsLoading">
                      <loader></loader>
                  </div>

                  <div class="col-12 mt-3" *ngIf="!newsLoading && newsTags.length > 0">
                      <ul class="nav nav-pills novice-tags text-center">
                          <li class="filter"><a routerLink="/novice">Vse oznake</a></li>
                          <li *ngFor="let tag of newsTags" class="filter"><a routerLink="/novice" [queryParams]="{ oznake: tag.id }">{{ tag.naziv }}</a></li>
                      </ul>
                  </div>
              </div>

              <div class="row" *ngIf="!newsLoading">
                  <div *ngFor="let article of news" class="col-12 col-sm-6 col-md-4">
                      <svetovalec-list-item [article]="article"></svetovalec-list-item>
                  </div>
              </div>
          </div>
      </div>
  </main>

  <div class="container-fluid">
      <div class="row">
          <div class="col-12 px-0">
              <div class="svetovalec content-box-thank-you">
                  <p><strong>Hvala, ker nas berete, všečkate in delite.</strong> Vaša mnenja, predloge ter vprašanja nam lahko posredujete na <a href="mailto:info@energetika.si">info&#64;energetika.si</a>, s pripisom: Za svetovalca</p>
              </div>
          </div>
      </div>
  </div>

  <div class="pb-4 border-top background-light-grey" *ngIf="!sitemapLoading">
      <nav class="container" aria-label="Meni dinamičnih vsebin, ki so na voljo na spletni strani">
          <div *ngFor="let row of cmsSitemap.abstract" class="row bottom-menu">
              <div *ngFor="let segment of row" class="col-12 col-md-4 fadeInUp-animated text-center segment mt-2">
                  <img [src]="getMenuImage(segment.naziv)" [alt]="segment.slika.altOpis"/>
                  <p class="link-header mt-2">{{ segment.naziv }}</p>
                  <ul class="p-2 m-0">
                      <li *ngFor="let kategorija of segment.kategorijas">
                          <i class="fa fa-chevron-right mr-1" aria-hidden="true"></i>
                          <a [routerLink]="['/vsebina', segment.urlPot, kategorija.urlPot]">{{ kategorija.naziv }}</a>
                      </li>
                  </ul>
              </div>
          </div>
      </nav>
  </div>
</div>

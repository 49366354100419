import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-klub-zvestoba-ogreva-energetska-izkaznica',
    templateUrl: './energetska-izkaznica.component.html',
    styleUrls: ['./energetska-izkaznica.component.scss']
})
export class KlubZvestobaOgrevaEnergetskaIzkaznicaComponent implements OnInit {

    public showAdditionalTxt: boolean = false;

    constructor(
    ) { }

    ngOnInit() { }

    public toggleAdditionalTxt() {
        this.showAdditionalTxt = !this.showAdditionalTxt;
    }
}
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import * as _ from 'lodash';

import { finalize } from 'rxjs/operators';

import { CmsService } from '../../../services/cms.service';
import { MetadataService } from '../../../services/metadata.service';

import { DeloNaOmrezju } from '../../../models/cms.model';
import { HtmlUtils } from '../../../utils/html.utils';

@Component({
    selector: 'app-dela-na-omrezju-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DelaNaOmrezjuDetailsComponent implements OnInit {

    public networkJob: DeloNaOmrezju = null;
    public loading: boolean = true;

    private id: number;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService,
        private metadataService: MetadataService
    ) { }

    public ngOnInit() {

        this.route.params.subscribe(p => {
            this.id = parseInt(p.id);

            this.loadItem();
        });
    }

    public loadItem() {

        this.loading = true;

        this.cmsService.getNetworkJobsByIdAsync(this.id)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(res => {
                this.networkJob = res;

                this.metadataService.setTitle('Dela na omrežju: ' + res.naslov);
                this.metadataService.setOgTitle(res.naslov);
                this.metadataService.setDescription(isPlatformBrowser(this.platformId) ? 
                    HtmlUtils.stripHtmlToMaxLength(res.vsebina, 150) :
                    HtmlUtils.stripHtmlToMaxLengthNoDocument(res.vsebina, 150));
                this.metadataService.setKeywords(null);
                this.metadataService.setCanonicalUrl(this.router.url);

                let date = typeof res.aktivenOd === 'string' ? res.aktivenOd.substring(0, 10) : 
                    res.aktivenOd.toISOString().substring(0, 10);
                let tags: string[] = [];

                if (this.networkJob.daljinskoOgrevanje)
                    tags.push('Vročevodno omrežje');
                if (this.networkJob.zemeljskiPlin)
                    tags.push('Plinovodno omrežje');

                if (res.ulice) {
                    var divEl = document.createElement('div');
                    divEl.innerHTML = res.ulice;

                    var liEls = divEl.querySelectorAll('li');
                    
                    for (let i = 0; i < liEls.length; i++) {
                        let liEl = liEls[i];

                        let street: any = liEl.querySelector('span.street-name');
                        let municipality: any = liEl.querySelector('span.municipality');

                        if (street) street = street.textContent;
                        if (municipality) municipality = municipality.textContent;

                        let houseNums = liEl.textContent;

                        if (street) houseNums = houseNums.replace(street, '');
                        if (municipality) houseNums = houseNums.replace(municipality, '');

                        houseNums = houseNums.trim();

                        liEl.innerHTML = `<span class="street-name">${street ? street: ''}</span>, <span class="municipality">${municipality ? municipality.substring(1, municipality.length - 1) : ''}</span>${houseNums ? `<br />${houseNums}` : ''}`;
                    }

                    res.ulice = divEl.innerHTML;
                }
                
                this.metadataService.setImage(null);
                this.metadataService.setOgType('article');
                this.metadataService.setOgArticle('article', 'Dela na omrežju', date, tags);
            });
    }

    public getCurrLocation() {
        return this.router.url;
    }
}
/**
 * STRUKTURA STRANI
 */
export interface Segment {
    id: number;
    naziv: string;
    urlPot: string;
    abstrakten: boolean;
    aktiven: boolean;
    vrstniRed: number;
    slika?: Image;
    kategorijas: Category[];
}

export interface Category {
    id: number;
    naziv: string;
    urlPot: string;
    seoTitle?: string;
    seoKeywords?: string;
    seoDescription?: string;
    kratekOpis?: string;
    aktiven: boolean;
    vrstniRed: number;
    povezanaNaziv?: string;
    povezanaOpis?: string;
    segment?: Segment;
}

export interface CategoryExtended {
    id: number;
    kategorijaId: number;
    verzija: number;
    naziv: string;
    urlPot: string;
    seoTitle: string;
    seoKeywords?: string;
    seoDescription?: string;
    podnaziv?: string;
    vsebina?: string;
    vsebinaDodatno?: string;
    kratekOpis?: string;
    cta?: boolean;
    ctaNaziv?: string;
    ctaOpis?: string;
    ctaTip?: CallToActionType;
    ctaPovezava?: string;
    ctaQueryParams?: any;
    aktiven?: boolean;
    aktivenDo?: Date;
    aktivenOd: Date;
    vrstniRed: number;
    ctaDokument?: Document;
    segment?: Segment;
    ozadje?: Image;
    infografike?: Image[];
    akcije?: Offer[];
    povezaneKategorije: CategoryExtended[];
}

/**
 * AKCIJE
 */
export interface Offer {
    id: number;
    naziv: string;
    redniCenik: boolean;
    zemeljskiPlin: boolean;
    daljinskoOgrevanje: boolean;
    elektricnaEnergija: boolean;
    investicijska: boolean;
    narocanje: boolean;
}

export interface OfferExtended {
    id: number;
    verzija: number;
    vsebina?: string;
    vsebinaDodatna1?: string;
    vsebinaDodatna2?: string;
    ctaNaziv?: string;
    ctaTip?: CallToActionType;
    ctaPovezava?: string;
    ctaQueryParams?: any;
    aktiven: boolean;
    aktivenOd: Date;
    aktivenDo?: Date;
    akcija?: Offer;
    ctaDokument?: Document;
    slika?: Image;
    expanded?: boolean;
    selected?: boolean;
}

/**
 * NOVICE
 */
export interface NewsArticle {
    id: number;
    naslov: string;
    urlPot: string;
    seoTitle?: string;
    seoKeywords?: string;
    seoDescription?: string;
    predogled?: string;
    vsebina?: string;
    cta: boolean;
    ctaNaziv?: string;
    ctaTip?: CallToActionType;
    ctaPovezava?: string;
    ctaDokument?: Document;
    ctaQueryParams?: any;
    aktiven: boolean;
    aktivenOd: Date | string;
    aktivenDo?: Date;
    slika?: Image;
    oznake: NewsTag[];
    kategorije: NewsTag[];
}

export interface NewsTag {
    id: number;
    naziv: string;
    opis?: string;
    kategorija: boolean;
}

/**
 * DELO NA OMREZJU
 */
export interface DeloNaOmrezju {
    id: number;
    naslov: string;
    urlPot: string;
    vsebina?: string;
    ulice?: string;
    zemeljskiPlin?: boolean;
    daljinskoOgrevanje?: boolean;
    aktivenOd: Date | string;
}

/**
 * KLUB ZVESTOBA OGREVA
 */
 export interface KzoUgodnost {
    id: number;
    naziv: string;
    vsebina: string;
    cta: boolean;
    ctaNaziv?: string;
    ctaVsebina?: string;
    ctaTip?: KzoCallToActionType;
    ctaPovezava?: string;
    slikaCta: boolean;
    slikaCtaTip?: KzoCallToActionType;
    slikaCtaPovezava?: string;
    aktiven: boolean;
    aktivenOd: Date;
    aktivenDo: Date;
    slika?: Image;
}

export enum KzoCallToActionType {
    NAROCILO_UGODNOSTI = 1,
    POVEZAVA = 2,
    KZO_PRIJAVA = 3
}

/**
 * OSTALO
 */
export interface Image {
    id: string;
    vsebina?: string;
    url: string;
    imeDatoteke: string;
}

export interface Document {
    id: string;
    naziv: string;
    kategorije?: string[];
    cenik: boolean;
    arhiviran: boolean;
    url?: string;
}

export interface DocumentCategory {
    id: string;
    naziv: string;
    opis?: string;
    splosno: boolean;
    cenik: boolean;
}

export interface DocumentExtended {
    id: string;
    verzija: number;
    naziv: string;
    imeDatoteke: string;
    format: string;
    velikost?: number;
    dokument: Document;
    downloadUrl?: string;
}

export enum CallToActionType {
    SVETOVANJE = 1, //deprecated
    POVPRASEVANJE = 2,
    DOKUMENT = 3,
    POVEZAVA = 4,
    NAROCILO_AKCIJA = 5,
    NAROCILO_KLUB_ZVESTOBA_OGREVA = 7
}
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

import { EObvescanjeService } from '../../../services/e-obvescanje.service';
import { CookiesService } from '../../../services/cookies.service';
import { finalize, first } from 'rxjs/operators';

@Component({
    selector: 'campaign-subscribe',
    templateUrl: './campaign-subscribe.component.html',
    styleUrls: ['./campaign-subscribe.component.scss']
})
export class CampaignSubscribeDialogComponent implements OnInit {

    public form: UntypedFormGroup;
    public loading: boolean = false;

    public get naziv() { return this.form.get('naziv') }
    public get email() { return this.form.get('email') }
    public get anketiranjeProfiliranje() { return this.form.get('anketiranjeProfiliranje') }
    public get akcijeNovosti() { return this.form.get('akcijeNovosti') }
    public get kzo() { return this.form.get('kzo') }

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<CampaignSubscribeDialogComponent>,
        private cookiesService: CookiesService,
        private eObvescanjeService: EObvescanjeService
    ) {}

    ngOnInit() { 
        this.form = this.fb.group({
            naziv: ['', [Validators.required, Validators.maxLength(300)]],
            email: ['', [Validators.required, Validators.email]],
            anketiranjeProfiliranje: [false],
            akcijeNovosti: [false],
            kzo: [false]
        });
    }

    formSubmit(event) {

        if (this.loading)
            return;

        if (this.form.valid) {
            this.loading = true;

            this.cookiesService.storeCampaignMonitorSubscribe(true);
            this.eObvescanjeService.prijavaMesecneNovice(this.form.value)
                .pipe(
                    finalize(() => this.loading = false),
                    first()
                )
                .subscribe(res => {
                    this.dialogRef.close();
                    this.router.navigate(['/e-novice/zahvala']);
                });
        } else {
            for (let k in this.form.controls)
                this.form.get(k).markAsDirty();
        }
    }

    close() {
        this.cookiesService.storeCampaignMonitorSubscribe(true);
        this.dialogRef.close();
    }
    
}
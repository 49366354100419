import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import * as _ from 'lodash';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

    @Input()
    public currentPage: number = 1;

    @Input()
    public totalCount: number = 0;

    @Input()
    public itemsPerPage: number = 20;

    @Input()
    public showCounts: boolean = true;

    @Input()
    public showOnePage: boolean = true;

    @Output()
    public onPageChange: EventEmitter<number> = new EventEmitter();

    public pages: number[] = [];
    public maxPages: number = 0;

    constructor() { }

    public ngOnInit() {
        this.calculatePages();
    }

    public ngOnChanges() {
        this.calculatePages();
    }

    public changePage(pageNum) {
        this.onPageChange.emit(pageNum);
    }

    private calculatePages() {

        let currPosition = (this.currentPage - 1) * this.itemsPerPage;
        this.maxPages = Math.ceil(this.totalCount / this.itemsPerPage);

        if (this.totalCount > this.itemsPerPage) {
            this.pages = [];

            for (let i = currPosition; i <= currPosition + 2 * this.itemsPerPage; i += this.itemsPerPage) {
                let page = Math.ceil(i / this.itemsPerPage);
                if (page > 1 && page < this.maxPages)
                    this.pages.push(page);
            }

            if (this.pages.length === 1) {
                if (this.pages[0] === 2 && 3 < this.maxPages - 1)
                    this.pages.push(3);
                else if (this.pages[0] - 1 > 1)
                    this.pages.splice(0, 0, this.pages[0] - 1);
                else if (this.pages[0] === 2 && 3 === this.maxPages - 1)
                    this.pages.push(3);
            }
        } else {
            this.pages = [];
        }
    };

  accessibleChangePage(event: KeyboardEvent, number: number) {
    if (event) {
      if (event.code === 'Enter') {
        this.changePage(number);
      }
    }
  }
}

import { Injectable, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {Observable, ReplaySubject} from 'rxjs';

declare var FB: any;

@Injectable()
export class CookiesService {

    private readonly clientIdStorageKey = 'gaClientId';
    private readonly campaignMonitorSubscribeStorageKey = 'campaignMonitorSubscribeHide';

    private gaId = 'UA-74608053-1';
    private gaStorage = null;
    private cookiebot = null;

    private readonly selectedThemeSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
    readonly selectedTheme$: Observable<string> = this.selectedThemeSubject.asObservable();

    constructor(
        @Inject(PLATFORM_ID) private platformId
    ) { }

    public init() {

        if (!this.isBrowser())
            return;

        if (window.sessionStorage)
            this.gaStorage = window.sessionStorage;

        this.initCookieBot();

        if (!this.cookiebot || !this.cookiebot.consent || !this.cookiebot.consent.marketing) {
            this.deleteAllCookies();
        }

        if (this.cookiebot && !this.isConsentGiven('ga')) {
            if (window.localStorage) {
                localStorage.removeItem(this.clientIdStorageKey);
            }
        }

        this.initGa();
    }

    public isConsentGiven(type) {

        if (!this.isBrowser())
            return false;

        switch (type) {
            case 'ga':
                return this.cookiebot && this.cookiebot.consent && this.cookiebot.consent.statistics;
            case 'fb':
            case 'twitter':
            case 'linkedin':
                return this.cookiebot && this.cookiebot.consent && this.cookiebot.consent.marketing;
            case 'povprasevanje':
            case 'importantNotice':
            case 'campaignMonitor':
                return true;
                return this.cookiebot && this.cookiebot.consent && this.cookiebot.consent.preferences;
            default:
                return false;
        }
    }

    public loadCampaignMonitorSubscribe(): boolean {

        const ls = window.localStorage;

        if (!localStorage || !this.isConsentGiven('campaignMonitor'))
            return null;

        let val = ls.getItem(this.campaignMonitorSubscribeStorageKey);

        return val === null ? null : val === 'true';
    }

    public storeCampaignMonitorSubscribe(value: boolean) {

        const ls = window.localStorage;

        if (!localStorage || !this.isConsentGiven('campaignMonitor'))
            return;

        ls.setItem(this.campaignMonitorSubscribeStorageKey, value.toString());
    }

    private initCookieBot() {

        if (window['Cookiebot'])
            this.cookiebot = window['Cookiebot'];
        else
            return;

        this.cookiebot.onaccept = () => {
            this.enableGaLocalStorage();
        }

        this.cookiebot.ondeclient = () => {
            this.enableGaLocalStorage();
        }
    }

    private enableGaLocalStorage() {

        if (window.localStorage) {
            let newStorage = window.localStorage;
            let clientId = null;

            if (this.gaStorage) {
                clientId = this.gaStorage.getItem(this.clientIdStorageKey);
                this.gaStorage.removeItem(this.clientIdStorageKey);
            }

            if (clientId) {
                newStorage.setItem(this.clientIdStorageKey, clientId);
            }

            this.gaStorage = newStorage;
        }
    }

    private disableGaLocalStorage() {

        if (window.sessionStorage) {
            let newStorage = window.sessionStorage;
            let clientId = null;

            if (this.gaStorage) {
                clientId = this.gaStorage.getItem(this.clientIdStorageKey);
                this.gaStorage.clear();
            }

            if (clientId) {
                newStorage.setItem(this.clientIdStorageKey, clientId);
            }

            this.gaStorage = newStorage;
        } else if (this.gaStorage) {
            this.gaStorage.clear();
        }
    }

    private initGa() {

        let hasConsent = this.isConsentGiven('ga');

        if (hasConsent && window.localStorage)
            this.gaStorage = window.localStorage;

        window['ga']('create', this.gaId, {
            'storage': 'none',
            'storeGac': hasConsent,
            'clientId': this.getClientId()
        });

        window['ga']('set', 'anonymizeIp', true);

        window['ga'](function (tracker) {

            if (this.gaStorage)
                this.gaStorage.setItem(this.clientIdStorageKey, tracker.get('clientId'));
        });
    }

    private generateUuid() { // Public Domain/MIT

        let date = new Date().getTime();

        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            date += performance.now(); //use high-precision timer if available
        }

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let random = (date + Math.random() * 16) % 16 | 0;
            date = Math.floor(date / 16);
            return (c === 'x' ? random : (random & 0x3 | 0x8)).toString(16);
        });
    }

    private getClientId() {

        let clientId = null;
        let storeClientId = false;

        if (this.gaStorage) {
            clientId = this.gaStorage.getItem(this.clientIdStorageKey);
        }

        if (!clientId) {
            storeClientId = true;
            clientId = this.generateUuid();
        }

        if (this.gaStorage && storeClientId) {
            this.gaStorage.setItem(this.clientIdStorageKey, clientId);
        }

        return clientId;
    }

    private deleteAllCookies() {

        let cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf('=');
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    private isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

  public saveAccessibilityTheme(theme: string) {
     localStorage.setItem('accessibility-theme', theme);
     this.selectedThemeSubject.next(theme);
  }

  public saveAccessibilityFont(font: string) {
     localStorage.setItem('accessibility-font', font);
  }

  public saveAccessibilityFontStyle(fontStyle: string) {
    localStorage.setItem('accessibility-font-style', fontStyle);
  }

  public saveAccessibilityPadding(padding: string) {
    localStorage.setItem('accessibility-padding', padding);
  }


  public loadAccessibilityTheme(): string {
    return localStorage.getItem('accessibility-theme');
  }

  public loadAccessibilityFont(): string {
    return localStorage.getItem('accessibility-font');
  }

  public loadAccessibilityFontStyle(): string {
    return localStorage.getItem('accessibility-font-style');
  }

  public loadAccessibilityPadding(): string {
    return localStorage.getItem('accessibility-padding');
  }
}

import { Route } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout.component';

import { Metadata } from '../models/metadata.model';

import { route as informativniIzracunRoutes } from './informativni-izracun/routes';
import { route as cmsVsebinaRoutes } from './cms-vsebina/routes';
import { route as noviceRoutes } from './novice/routes';
import { route as delaNaOmrezjuRoutes } from './dela-na-omrezju/routes';
import { route as narociloRoutes } from './narocilo/routes';
import { route as poslovniOdjemRoutes } from './poslovni-odjem/routes';
import { route as energetikaVDruzbiRoutes } from './energetika-v-druzbi/routes';
import { route as kzoRoutes } from './klub-zvestoba-ogreva/routes';
import { route as akcijeRoutes } from './akcije/routes';
import { route as izjavaOdostopnosti } from './legal/accessility-statement/routes';
import { route as cookiesRoutes } from './legal/cookies/routes';
import { route as legalRoutes } from './legal/terms-use/routes';
import { route as privacyRoutes } from './legal/privacy/routes';
import { route as notFoundRoutes } from './not-found/routes';
import { route as mailingRoutes } from './mailing/routes';
import { route as previewRoutes } from './predogled/routes';
import { route as npnPaketiRoutes } from './npn-paketi/routes';
import { route as povprasevanjeRoutes } from './povprasevanje/routes';
import { route as cenikiDokumentiRoutes } from './ceniki-dokumenti/routes';
import { route as razlagaRacunaRoutes } from './razlaga-racuna/routes';
import { route as kazaloRoutes } from './toc/routes';

const homeMetadata: Metadata = {
    title: 'Bivanju Dajemo Utrip',
    description: 'Energetika Ljubljana, d.o.o., opravlja dejavnost daljinske oskrbe s toploto (daljinsko ogrevanje), hladom, oskrbe z zemeljskim plinom in oskrbe z električno energijo',
    keywords: ['energetika ljubljana', 'bivanju dajemo utrip', 'informativni izračun', 'svetovalec', 'daljinsko ogrevanje', 'toplota', 'zemeljski plin', 'električna energija', 'cng', 'metan', 'zvestoba ogreva'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background3.jpg',
    imageWidth: 1920,
    imageHeight: 1370
};

export const route: Route = {
    path: '',
    component: LayoutComponent,
    data : { metadata: { title: 'Domov'} },
    children: [
        {
            path: '',
            component: HomeComponent,
            data: {
                metadata: homeMetadata
            }
        },
        cmsVsebinaRoutes,
        informativniIzracunRoutes,
        narociloRoutes,
        noviceRoutes,
        delaNaOmrezjuRoutes,
        poslovniOdjemRoutes,
        energetikaVDruzbiRoutes,
        kzoRoutes,
        akcijeRoutes,
        izjavaOdostopnosti,
        cookiesRoutes,
        legalRoutes,
        privacyRoutes,
        notFoundRoutes,
        mailingRoutes,
        // zelenaElektrikaRoutes,
        previewRoutes,
        npnPaketiRoutes,
        povprasevanjeRoutes,
        cenikiDokumentiRoutes,
        razlagaRacunaRoutes,
        kazaloRoutes,
        {
            path: 'akcija-vozim-na-plin',
            redirectTo: '/akcije/vozim-na-plin',
            pathMatch: 'full'
        },
        {
            path: '**',
            redirectTo: '/404'
        }
    ]
};

import {Component, OnInit, PLATFORM_ID, Inject, ViewChild} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { CookiesService } from '../services/cookies.service';

import { CampaignSubscribeDialogComponent } from '../components/dialogs/campaign-subscribe/campaign-subscribe.component';
import {QuickAccessComponent} from '../components/general/quick-access/quick-access.component';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    public campaignMonitorSubscribe: boolean = false;

    @ViewChild('quickAccess', {static: true})
    quickAccessComponent: QuickAccessComponent;

    constructor(
        @Inject(PLATFORM_ID) private platformId: string,
        private matDialog: MatDialog,
        private cookiesService: CookiesService
    ) { }

    ngOnInit() {

        if (isPlatformBrowser(this.platformId)) {
            let cmVal = this.cookiesService.loadCampaignMonitorSubscribe();

            if (!cmVal) {
                setTimeout(() => {
                    this.matDialog.open(CampaignSubscribeDialogComponent);
                }, 10000);
            }
        }
    }

    focusOnQuickAccess() {
      this.quickAccessComponent.focusOnQuickAccessMenu();
    }
}

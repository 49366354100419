import {Component, Input, OnInit} from '@angular/core';
import { RazlagaRacunaEnergent } from '../../content/razlaga-racuna/razlaga-racuna.component';

@Component({
  selector: 'app-razlaga-racuna-tocka',
  templateUrl: './razlaga-racuna-tocka.component.html',
  styleUrls: ['./razlaga-racuna-tocka.component.scss']
})
export class RazlagaRacunaTockaComponent implements OnInit {

  constructor() { }

  @Input()
  energent: RazlagaRacunaEnergent;

  @Input()
  number: number;

  @Input()
  title: string;

  @Input()
  description: string;

  ngOnInit() {
  }

}

import { Route } from '@angular/router';

import { CookiesComponent } from './cookies.component';

import { Metadata } from '../../../models/metadata.model';

const metadata: Metadata = {
    title: 'Uporaba piškotkov',
    description: 'Nastavitve in izjava za uporabo piškotkov na spletnem portalu'
};

export const route: Route = {
    path: 'uporaba-piskotki',
    component: CookiesComponent,
    data: {
        metadata: metadata
    }
};
import { Component } from '@angular/core';

@Component({
    selector: 'app-akcije-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class AkcijeLayoutComponent {

    
}
<div class="row" *ngIf="documents.length > 0 && !loading">
    <div class="col-12">
        <div class="pb-3">
            <pagination [showCounts]="false" [showOnePage]="false" [currentPage]="currentPage" [totalCount]="totalCount" (onPageChange)="changePage($event)"></pagination>
        </div>
        <div class="dotted-divider my-2"></div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div *ngFor="let doc of documents" class="col-12 my-2" documents>
        <a [dokument-id]="doc.id" dokument-text="true" dokument-icon="true" href="">{{ doc.naziv }}</a>
    </div>
</div>

<div class="row" *ngIf="loading">
    <div class="col-12 my-5">
        <loader></loader>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="dotted-divider my-2"></div>
        <div class="pb-4" *ngIf="documents.length > 0 && !loading">
            <pagination [showOnePage]="false" [currentPage]="currentPage" [totalCount]="totalCount" (onPageChange)="changePage($event)"></pagination>
        </div>
    </div>

    <div class="col-12 list-no-results py-3" *ngIf="documents.length === 0 && !loading">
        <p class="icon"><i class="fa fa-frown-o" aria-hidden="true"></i></p>
        <p class="text">Najden ni bil noben dokument pod izbranimi iskalnimi pogoji.</p>
    </div>
</div>

<main id="content">
  <section class="content theme-2">
    <article>
      <div class="top-banner" style="background-image: url('assets/images/npn/background-top.jpg')">
        <div class="layer">
          <div class="title">
            <h1>Paketi za izgradnjo ali obnovo<br /><strong>notranje plinske napeljave</strong></h1>
          </div>
        </div>
      </div>

      <div class="background-light-grey">
        <div class="container">
          <div class="row content-block pt-5">
            <div class="col-12 col-md-6">
              <h2>Finančne ugodnosti in subvencije.<br />Obročno odplačevanje.</h2>
            </div>
            <div class="col-12 col-md-6">
              <p>Za izvedbo vaše naložbe vam nudimo obročno odplačevanje s pomočjo finančnega najema. Le-ta je namenjen lastnikom stanovanjskih enot, ki se priključujejo na distribucijsko omrežje Energetike Ljubljana ali obnavljajo obstoječe naprave, priključene na to omrežje. Paket za ogrevanje na zemeljski plin lahko odplačujete po izbiri od 2 do največ 7 let.</p>
              <p class="mb-0">Za več informacij se posvetujte z našim svetovalcem.</p>
            </div>
          </div>
          <div class="row content-block pb-0">
            <div class="col-12 col-md-6">
              <h2>Strokovnjaki za plin že 160 let.</h2>
            </div>
            <div class="col-12 col-md-6">
              <p class="mb-0">V Energetiki Ljubljana se s plinom ukvarjamo že od leta 1861, zaupajte nam ogrevanje svojega doma tudi vi! Pokličite naš kontaktni center na 080 2882 še danes ali nam pišite na <a href="mailto:info@energetika.si">info&#64;energetika.si</a>.</p>
            </div>
          </div>
          <div class="row content-block pb-0">
            <div class="col-12 col-md-6 offset-md-6">
              <button class="btn btn-blue btn-lg mt-4 mb-5 outline-offset" (click)="sendSvetovanjePackage(null, $event)">Želim brezplačno svetovanje!</button>
            </div>
          </div>
        </div>
      </div>

      <div class="background-light-grey">
        <div class="container">

          <div class="row pt-5 pb-3">
            <div class="col-6 text-center">
              <button class="btn btn-blue btn-lg text-uppercase outline-offset" [class.active]="izgradnja === true" (click)="selectIzgradnja(true)"><strong>Izgradnja</strong></button>
            </div>
            <div class="col-6 text-center">
              <button class="btn btn-blue btn-lg text-uppercase outline-offset" [class.active]="izgradnja === false" (click)="selectIzgradnja(false)"><strong>Obnova</strong></button>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-12 col-sm-6 col-lg-3 text-center my-1">
              <button class="btn btn-blue btn-lighter btn-full-height py-3 outline-offset" [class.active]="pretStv" (click)="selectPretocnaStv()">
                <strong>Pretočna priprava</strong><br />sanitarne tople vode.
              </button>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 text-center my-1">
              <button class="btn btn-blue btn-lighter btn-full-height py-3 outline-offset" [class.active]="akBojStv" (click)="selectAkumulatorskaBojlerStv()">
                <strong>Akumulatorska priprava</strong><br />sanitarne tople vode.
              </button>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 text-center my-1">
              <button class="btn btn-blue btn-lighter btn-full-height py-3 outline-offset" [class.active]="komAkSolStv" (click)="selectKombAkumulatorskaSolarnaStv()">
                <strong>Kombinirana akumulatorska</strong><br />priprava sanitarne tople vode<br /><strong>s solarnim sistemom.</strong>
              </button>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 text-center my-1">
              <button class="btn btn-blue btn-lighter btn-full-height py-3 outline-offset" [class.active]="akStv" (click)="selectAkumulatorskaStv()">
                <strong>Akumulatorska priprava</strong><br />sanitarne tople vode<br /><small>(brez bojlerja).</small>
              </button>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 text-center my-1">
              <button class="btn btn-blue btn-lighter btn-full-height py-3 outline-offset" [class.active]="pretStvVec" (click)="selectPretocnaStvVecStan()">
                <strong>Pretočna priprava</strong><br />sanitarne tople vode<br /><strong>za stanovanja<br />v večstanovanjskih<br />stavbah</strong><br /><small>(vertikala brez dimnika).</small>
              </button>
            </div>
            <div class="col-12 text-center more mt-4">
              <p><a href="javascript:void(0)" (click)="deselectStv()">Poglej vse lastnosti.</a></p>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-6 col-sm-4 col-lg-3 text-center my-1" *ngFor="let znamka of znamke">
              <button class="btn btn-blue btn-lightest py-3 outline-offset" [class.active]="isZnamkaActive(znamka.id)" (click)="selectZnamka(znamka.id)">{{ znamka.ime }}</button>
            </div>

            <div class="col-12 text-center more mt-1">
              <p><a href="javascript:void(0)" (click)="selectZnamka(null)">Poglej vse blagovne znamke.</a></p>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-12 col-sm-6 col-md-4 col-xl-3" *ngFor="let paket of paketiShown">
              <div class="card my-2" (click)="selectPackage(paket)" [class.active]="paket.selected">
                <div class="card-header">
                  {{ paket.znamkaIme }} {{ paket.oznaka }}
                </div>
                <div class="card-body">
                  <p>{{ paket.opis }}</p>
                  <ul>
                    <li *ngIf="paket.kotel"><strong>Kotel</strong>: {{ paket.kotelOpis }}</li>
                    <li *ngIf="paket.bojler"><strong>Bojler</strong>: {{ paket.bojlerOpis }}</li>
                    <li *ngIf="paket.regulator"><strong>Regulacija</strong>: {{ paket.regulatorOpis }}</li>
                    <li *ngIf="paket.solar && paket.solarOpis"><strong>Sprejemnik sončne energije</strong>: {{ paket.solarOpis }}</li>
                    <li *ngIf="paket.toplotnaCrpalka && paket.toplotnaCrpalkaOpis"><strong>Toplotna črpalka</strong>: {{ paket.toplotnaCrpalkaOpis }}</li>
                    <li *ngIf="paket.zunanjaKondenzacijskaEnota && paket.zunanjaKondenzacijskaEnotaOpis"><strong>Zunanja kondenzacijska enota topl. črp.</strong>: {{ paket.zunanjaKondenzacijskaEnotaOpis }}</li>
                  <li *ngIf="paket.h2ready"><strong>H<sub>2</sub> Ready</strong></li>
                </ul>

                  <p *ngIf="paket.cena">
                    Cena že od: {{ paket.cena | currency: 'EUR' : 'symbol': '0.2-2' }}<br />
                    <small>(cena vključuje DDV*)</small>
                  </p>

                  <button [tabIndex]="(paket.selected) ? 0 : -1" class="btn btn-blue btn-lightest btn-sm px-2 mt-4 outline-offset" (click)="sendSvetovanjePackage(paket, $event)">Naroči svetovanje</button>

                  <div class="see-more">
                    <button *ngIf="!paket.selected" aria-label="Prikaži podrobnosti"><i class="fa fa-chevron-down" aria-hidden="true"></i></button>
                    <button *ngIf="paket.selected" aria-label="Skrij podrobnosti"><i class="fa fa-chevron-up" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 text-center mt-1 mb-3" *ngIf="paketiShown.length === 0">
              <p><em>Noben paket ne ustreza iskalnim kriterijem.</em></p>
            </div>

            <div class="col-12 text-center more mt-1 mb-3" *ngIf="noFilters">
              <p><a href="javascript:void(0)" (click)="showMore()">Poglej več paketov.</a></p>
            </div>
          </div>
        </div>
      </div>

      <div class="background-light-grey">
        <div class="container">
          <div class="row content-block pt-5">
            <div class="col-12 col-md-6">
              <h2>Kaj paketi vključujejo?</h2>
            </div>
            <div class="col-12 col-md-6">
              <p>Vsi paketi vključujejo:</p>
              <ul class="">
                <li>pridobitev dokumentacije za izvedbo pogodbenih del,</li>
                <li>dobavo, montažo, priključitev in zagon novega plinskega kotla,</li>
                <li>demontažo obstoječe kurilne naprave (brez iznosa demontirane opreme iz stavbe in odvoza na deponijo),</li>
                <li>pridobitev soglasja pristojne dimnikarske službe za dimovodne naprave in vpis v evidenco kurilnih naprav,</li>
                <li>tesnostni preizkus in zaplinjanje notranje plinske napeljave,</li>
                <li>koordinacijo pogodbenih del.</li>
              </ul>
              <p>Posamezni paketi* lahko vključujejo tudi:</p>
              <ul class="">
                <li>dobavo in montažo bojlerja (samo v paketih z bojlerjem),</li>
                <li>izdelavo sistema dovoda zraka in odvoda dimnih plinov skozi obstoječo dimno tuljavo (do višine 12 m). Dela ne vsebujejo sanacije obstoječega dimnika. Ne velja za pakete z oznako št. 14 (stanovanja), kjer je potrebno k paketu dodatno naročiti izdelavo sistema dovoda zraka in odvoda dimnih plinov za več plinskih kondenzacijskih grelnikov v vertikali.</li>
                <li>izvedbo cevnih povezav na obstoječi sistem centralnega ogrevanja (izgradnja: dovod in povratek do 5 m vsak; obnova: dovod in povratek do 2 m vsak) in na obstoječi sistem sanitarne vode STV (izgradnja: dovod mrzle vode in navezava tople vode do 5 m vsak; obnova: dovod in navezava vsak do 2 m)</li>
                <li>izvedbo notranje plinske napeljave (izgradnja: do 8 m cevi; pri obnovi: do 2 m cevi).</li>
              </ul>
              <p class="mb-2">V ceno posameznega paketa nista vključena priklop odjemnega mesta na plinovodno omrežje ter dobava plinomera in regulatorja tlaka, če se lastnik stanovanjske enote odloči za njuno lastništvo. Te stroške se poravna posebej.</p>
              <p class="mb-2">* Za podrobne podatke o ponudbi se obrnite na našega svetovalca, ki vam bo predstavil kaj vaš izbrani paket vsebuje.</p>
              <p class="mb-0">** 9,5 % davčna stopnja velja v primeru, da je uporabna površina stanovanjskega prostora manjša od 250 m2 (individualna stavba) oziroma od 120 m2 (za stanovanje v večstanovanjski stavbi) in da se prostor ne uporablja v poslovni namen. V nasprotnem primeru je davčna stopnja 22 %.</p>
            </div>
          </div>
          <div class="row content-block pb-5">
            <div class="col-12 col-md-6 offset-md-6">
              <button class="btn btn-blue btn-lg mt-4 mb-5 outline-offset" (click)="sendSvetovanjePackage(null, $event)">Želim brezplačno svetovanje!</button>
            </div>
          </div>
        </div>
      </div>

    </article>
  </section>
</main>

<main id="content">
    <section class="content">
        <div class="py-3">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Politika varstva osebnih podatkov v družbi Energetika Ljubljana d.o.o.</h1>

                        <p class="mt-5 mb-4">
                            <a dokument-id="D303184F-9865-4EE3-924E-66030204E6C9" dokument-icon="true" dokument-text="true" href=""></a>
                        </p>

                        <h2>Zasebnost in varstvo osebnih podatkov</h2>
                        <p>V družbi Energetika Ljubljana d.o.o. zagotavljamo celovito energetsko oskrbo ter izvajamo določene sistemske dejavnosti, v okviru katerih ob izvajanju naših storitev za naše uporabnike, ob komuniciranju s strankami in poslovnimi partnerji, zagotavljanju posebnih ugodnosti za člane Kluba zvestoba ogreva kot tudi izvajanju pravic in obveznosti do naših zaposlenih, obdelujemo njihove osebne podatke.</p>
                        <p>Pravica do zasebnosti je ena najpomembnejših človekovih pravic. V družbi Energetika Ljubljana d.o.o. se tega zavedamo in spoštujemo zasebnost naših zaposlenih, uporabnikov, strank in članov Kluba Zvestoba ogreva, zato z njihovimi osebnimi podatki ravnamo odgovorno, pazljivo in skladno z veljavnimi predpisi, z našimi internimi akti in z ustreznimi organizacijskimi in tehničnimi ukrepi pa skrbimo za ustrezno raven varnosti osebnih podatkov.</p>

                        <h2>Podatki o upravljavcu</h2>
                        <p>JAVNO PODJETJE ENERGETIKA LJUBLJANA d.o.o.<br />
                            Verovškova ulica 62, Ljubljana<br />
                            T: +386 (0)1 588 90 00<br />
                            F: +386 (0)1 588 91 09</p>
                        <p>Klicni center: 080 28 82<br />
                            E-pošta: <a href="mailto:info@energetika.si">info&#64;energetika.si</a><br />
                            Internet: <a href="https://www.energetika.si/" target="_blank">www.energetika.si</a>;  <a href="https://www.bivanjudajemoutrip.si/" target="_blank">www.bivanjudajemoutrip.si</a></p>

                        <h2>Pooblaščena oseba za varstvo osebnih podatkov</h2>
                        <p>V Energetiki Ljubljana imamo pooblaščeno za varstvo osebnih podatkov, ki je dosegljiva po elektronski pošti na naslovu <strong><a href="mailto:dpo@energetika.si">dpo&#64;energetika.si</a></strong> oz. navadni pošti na naslovu <strong>ENERGETIKA LJUBLJANA d.o.o., Verovškova ulica 62, Ljubljana</strong> , s pripisom »za DPO«.</p>
                        <p>Podatki o pooblaščeni osebi so objavljeni na spletnih straneh družbe <a href="https://www.energetika.si/" target="_blank">www.energetika.si</a>; <a href="https://www.bivanjudajemoutrip.si/" target="_blank">www.bivanjudajemoutrip.si</a>.</p>
                        <p>Posamezniki, na katere se nanašajo osebni podatki, lahko s pooblaščeno osebo za varstvo podatkov sami stopijo v stik glede vseh vprašanj, povezanih z obdelavo njihovih osebnih podatkov in uresničevanjem njihovih pravic na podlagi veljavnih predpisov, ki urejajo varstvo osebnih podatkov.</p>

                        <h2>Katere podatke obdelujemo, na kakšni podlagi in zakaj?</h2>
                        <p>Osebne podatke posameznikov obdelujemo v okviru in za namen <strong>izvajanja pogodb</strong>, kot so npr. pogodbe o dobavi energije, pogodbe o dostopu do omrežja, pogodbe za pridobivanje služnostne pravice, pogodbe o finančnem najemu idr.</p>
                        <p>V okviru izvrševanja pogodbenih pravic in izpolnjevanja pogodbenih obveznosti obdelujemo vaše osebne podatke za naslednje namene: identifikacijo posameznika, pripravo ponudbe, sklenitev pogodbe, za zagotovitev naročenih storitev, obveščanje o morebitnih spremembah, dodatnih podrobnostih in navodilih za uporabo storitev, obračunavanje storitev ter za druge namene, potrebne za izvajanje ali sklepanje pogodbenega razmerja.</p>
                        <p>Za namen izvajanja pogodb obdelujemo osebne podatke, kot so ime in priimek, datum in kraj rojstva, stalno ali začasno prebivališče (ulica in kraj), hišna številka, dodatek k hišni številki, EMŠO, davčna številka, naslov za dostavo računov, telefonska številka, fax številka, naslov elektronske pošte, oznaka stanovanja (v več stanovanjskih hišah), nadstropje, št. stanovanja, številka odjemalca, številka odjemnega mesta, tekoči račun, transakcijski račun idr.</p>
                        <p>Na podlagi pogodbenega pravnega temelja se osebni podatki v potrebnem obsegu obdelujejo tudi za namen sklenitve pogodb, v fazi pogajanj, po prejemu ponudbe oz. povpraševanja posameznika.</p>
                        <p>Osebne podatke obdelujemo zaradi <strong>izpolnitve zakonske obveznosti</strong>, primer na podlagi Zakona o delovnih razmerjih (ZDR-1), za namen izvajanja pravic in obveznosti iz delovnega razmerja, Zakona o graditvi objektov (ZGO-1), za namen izdaje projektnih pogojev za pridobitev gradbenega dovoljenja, Zakona o graditvi objektov (ZGO-1), za namen izdaje soglasja k projektnim rešitvam za pridobitev gradbenega dovoljenja, Energetskega zakona (EZ-1), za namen izdaje soglasja za priključitev na distribucijsko omrežje, Energetskega zakona (EZ-1), za namen izdaje soglasja za priključitev na distribucijski sistem toplote ali na podlagi drugih mednarodnih pogodb, predpisov EU ter nacionalnih zakonov, ki terjajo od nas, da v določenih primerih posreduje osebne podatke posameznikov državnim organom in drugim upravljavcem za izpolnjevanje svojih ali njihovih zakonskih obveznosti ali pristojnosti. Na tej podlagi obdelujemo tiste osebne podatke in za tiste namene, kot nam to nalagajo veljavni predpisi.</p>
                        <p>Na osnovi <strong>opravljanja nalog v javnem interesu</strong> obdelujemo osebne podatke v okviru izvajanja gospodarske javne službe za namen izvajanja gospodarske javne službe distribucije in dobave toplote v mestni občini Ljubljana, izvajanja dejavnosti sistemskega operaterja distribucijskega omrežja za zemeljski plin v MOL in primestnih občinah Medvode, Dol pri Ljubljani, Dobrova - Polhov Gradec, Škofljica, Brezovica, Ig, z občino Log - Dragomer pa smo marca 2010 podpisali koncesijsko pogodbo; oskrbo s plinom širimo tudi v občino Grosuplje; izvajanja sistemskih storitev za slovenski elektroenergetski sistem, vodenja katastra energetskih naprav in objektov idr.</p>
                        <p>Za ta namen obdelujemo osebne podatke, kot so npr. lastništvo odjemnih mest, ime in priimek odjemalca energije, ime in priimek, datum in kraj rojstva, stalno ali začasno prebivališče (ulica, hišna številka, dodatek hišni številki), hišna številka, poštna številka, EMŠO, davčna številka, naslov za dostavo računov, vrsta prostora (stanovanje , poslovni prostor), telefonska številka, fax številka, naslov elektronske pošte, oznaka stanovanja (v več stanovanjskih hišah), nadstropje, št. stanovanja, identifikacijski znak nepremičnine, številka odjemalca, številka odjemnega mesta, priključna moč, šifra priključka z dodatkom, status priključka, tehnični in gradbeni podatki priključka (občina, naselje, ulica, hišna številka, hišni dodatek na kateri se nahaja priključek), vrsta zgradbe, parcelna številka, katastrska občina, zazidalni otok, gradbeno področje, centroid njegova lega in status, transakcijski račun, tip zavezanca, št. trajnika ), ime in priimek in naslov lastnikov priključkov in lastnikov zemljišč po katerih poteka infrastruktura upravljavca; identifikacijski znak nepremičnine idr.</p>
                        <p>V določenih primerih posameznike zaprosimo tudi za podajo PRIVOLITEV za obdelavo njihovih osebnih podatkov za ocenjevanje nekaterih osebnih vidikov v zvezi s posameznikom, zlasti za namen ohranjanja stika s strankami, izvajanja nagradnih iger, obveščanja prek elektronske pošte, prilagajanja ponudbe kupcem, obveščanja o novostih in izboljšanju ponudbe in kakovosti naših storitev, obveščanje o ugodnostih, izvajanje statističnih obdelav in trženjskih raziskav ter segmentiranega in neposrednega trženja. V teh primerih obdelava osebnih podatkov poteka v okviru s posameznikovo izjavo dopuščenega obsega osebnih podatkov, namena in dogovorjenih kanalov obveščanja vse do preklica.</p>
                        <p>Osebne podatke posameznikov za namene zagotavljanja boljše funkcionalnosti in uporabniške izkušnje, varnosti, nemotenega delovanja spletnih strani oz. portalov ter štetja uporabnikov na spletnem mestu oz. portalu zbiramo tudi ob njihovem obisku naših spletnih strani preko <strong>piškotkov</strong>. Več o uporabi piškotkov si lahko preberete na povezavi <a href="http://www.energetika.si/piskotki" target="_blank">http://www.energetika.si/piskotki</a>.</p>
                        <p>Energetika Ljubljana d.o.o. lahko na številkah, kot je 080 2882, z namenom preverbe pogovora med stranko in delavcem Energetike Ljubljana d.o.o. snema telefonske klice, namenjene kontaktiranju družbe.</p>
                        <p>Energetika Ljubljana d.o.o. lahko zaradi zagotavljanja varnosti izvaja videonadzor nad poslovnimi prostori. Posnetki se uporabljajo le v primeru varnostnih incidentov in brišejo po preteku šestih (6) mesecev.</p>
                        <p>Osebni podatki niso obdelani preko <strong>avtomatiziranega sprejemanja odločitev</strong>.</p>

                        <h2>Uporabniki osebnih podatkov</h2>
                        <p>Podatki se posredujejo drugim uporabnikom, če nam to nalaga zakon, če obstaja za to zakonit interes ali če imamo za to uporabnikovo privolitev.</p>

                        <h2>Obdelovalci osebnih podatkov</h2>
                        <p>V našem imenu in za naš račun lahko osebne podatke obdelujejo tudi obdelovalci, kot so podjetja, ki nam nudijo tehnično podporo pri izvajanju podpornih storitev za naše podjetje, in obdelovalci, ki jih angažiramo za zagotavljanje storitev, potrebnih za izvajanje pogodb, kot so na primer: izvajalci notranjih plinskih napeljav, izvajalci gradbenih storitev, izvajalci informacijskih storitev, izvajalci tiskarskih storitev, dostavne službe.</p>
                        <p>V kolikor obdelavo osebnih podatkov zaupamo takšnim zunanjim obdelovalcem, imamo z njimi sklenjeno ustrezno pisno pogodbo, s katero obdelovalci podatkov zagotavljajo, da podatke obdelujejo v skladu z vsakokrat veljavnimi predpisi.</p>

                        <h2>Prenos osebnih podatkov v tretje države</h2>
                        <p>Nekatere organizacije oz. podjetja, ki jim kot našim obdelovalcem lahko razkrivamo uporabnikove osebne podatke, imajo sedež zunaj Evropske unije, v državah, ki morda nimajo zakonov, ki varujejo pravico do varstva osebnih podatkov na enak način, kot v Evropski uniji oz. Sloveniji. V primeru, da bodo osebni podatki preneseni v tretje države (izven EU in EGP), bomo poskrbeli za zagotovitev in uresničevanje ustreznih ukrepov za zagotavljanje varnosti osebnih podatkov ter temeljnih pravic in svoboščin posameznikov, skladno z veljavnimi nacionalnimi in EU predpisi in to Politiko zasebnosti, tako da bomo uporabili predvidene mehanizme, kot so zavezujoča poslovna pravila, standardne pogodbene klavzule idr.</p>

                        <h2>Pravice posameznikov</h2>
                        <p>S pisno zahtevo, poslano na naslov <strong>ENERGETIKA LJUBLJANA d.o.o., Verovškova ulica 62, Ljubljana</strong>, s pripisom »za DPO«, lahko posameznik zahteva:</p>
                        <ul>
                            <li><strong>dostop</strong> do svojih osebnih podatkov, ki so bili zbrani v povezavi z njim;</li>
                            <li><strong>popravek</strong> netočnih podatkov, zbranih v povezavi z njim;</li>
                            <li><strong>omejitev obdelave</strong> osebnih podatkov;</li>
                            <li><strong>izbris</strong> vseh podatkov, če so izpolnjeni pogoji iz 17. člena <a href="https://eur-lex.europa.eu/legal-content/SL/TXT/?uri=CELEX%3A32016R0679" target="_blank">Uredbe GDPR</a>, ali</li>
                            <li>obdelavi <strong>ugovarja</strong>.</li>
                        </ul>
                        <p>Na njegovo zahtevo bomo odgovorili brez nepotrebnega odlašanja in najpozneje v enem mesecu od prejema zahteve. Ta rok se lahko ob upoštevanju kompleksnosti in števila zahtev po potrebi podaljša za največ dva dodatna meseca. V tem primeru bomo posameznika, na katerega se nanašajo osebni podatki, o podaljšanju v enem mesecu po prejemu zahteve skupaj z razlogi za zamudo tudi obvestili. Za potrebe zanesljive identifikacije v primeru uveljavljanja pravic v zvezi z osebnimi podatki lahko od njega zahtevamo dodatne podatke, ukrepanje pa lahko zavrnemo le v primeru, da dokažemo, da ga ne moremo zanesljivo identificirati.</p>
                        <p>Posameznik lahko dane <strong>privolitve v obdelavo</strong> osebnih kadarkoli trajno ali začasno, v celoti ali delno prekliče s pisno zahtevo, poslano na naslov <strong>ENERGETIKA LJUBLJANA d.o.o., Verovškova ulica 62, Ljubljana</strong>, s pripisom »za DPO«, ali z elektronsko zahtevo, poslano na <a href="mailto:dpo@energetika.si">dpo&#64;energetika.si</a>.</p>
                        <p>Preklic privolitve ne vpliva na zakonitost obdelave, ki se je na podlagi privolitve izvajala do njenega preklica.</p>
                        <p>Posameznik ima pravico vložiti <strong>pritožbo</strong> pri Informacijskem pooblaščencu RS (Informacijski pooblaščenec, Dunajska cesta 22, 1000 Ljubljana, Slovenija, e-pošta: <a href="mailto:gp.ip@ip-rs.si">gp.ip&#64;ip-rs.si</a>) če meni, da se njegovi osebni podatki obdelujejo v nasprotju z veljavnimi predpisi, ki urejajo varstvo osebnih podatkov.</p>

                        <h2>Roki hrambe osebnih podatkov</h2>
                        <p>Osebne podatke posameznikov bomo v Energetiki Ljubljana d.o.o. obdelovali v obsegu, ki je potreben za uresničevanje namenov obdelave in le tako dolgo, dokler bo to potrebno za dosego zasledovanega cilja.</p>
                        <p>Osebne podatke tako obdelujemo do izpolnitve namena, zaradi katerih so bili zbrani, v okviru zastaralnih rokov za obveznosti, ki bi lahko izvirale iz obdelave teh osebnih podatkov, še posebej, ko je obdelava osebnih podatkov potrebna za izvajanje pogodbe, oziroma v rokih, ki so določenih s predpisi. Če se osebni podatki obdelujejo na podlagi privolitve, se hranijo do preklica.</p>

                        <h2>Spremembe politike zasebnosti</h2>
                        <p>Spremembe Politike zasebnosti se objavijo na spletni strani <a href="https://www.energetika.si/" target="_blank">www.energetika.si</a> in <a href="https://www.bivanjudajemoutrip.si/" target="_blank">www.bivanjudajemoutrip.si</a> in se v spremenjeni vsebini uporabljajo od dneva objave dalje.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

import { Component, OnInit } from '@angular/core';
import {CookiesService} from '../../services/cookies.service';

@Component({
  selector: 'app-razlaga-racuna',
  templateUrl: './razlaga-racuna.component.html',
  styleUrls: ['./razlaga-racuna.component.scss']
})
export class RazlagaRacunaComponent implements OnInit {

  activatedChild: RazlagaRacunaEnergent = RazlagaRacunaEnergent.PLIN;
  razlagaRacunaEnergent = RazlagaRacunaEnergent;

  public toplotaImage: string = 'assets/images/ikone/toplota-white.png';
  public elektrikaImage: string = 'assets/images/ikone/elektrika-white.png';
  public plinImage: string = 'assets/images/ikone/plin-white.png';

  constructor(
    private cookiesService: CookiesService
  ) { }

  ngOnInit() {

    this.cookiesService.selectedTheme$.subscribe(res => {
      switch (res) {
        case 'theme-wcag':
        case 'theme-normal':
          this.toplotaImage = "assets/images/ikone/toplota-white.png";
          this.elektrikaImage = "assets/images/ikone/elektrika-white.png";
          this.plinImage = "assets/images/ikone/plin-white.png";
          break;
        case 'theme-white-black':
          this.toplotaImage = "/assets/images/ikone/wcag/toplota-white-black.png";
          this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-white-black.png";
          this.plinImage = "/assets/images/ikone/wcag/plin-white-black.png";
          break;
        case 'theme-black-white':
          this.toplotaImage = "/assets/images/ikone/wcag/toplota-black.png";
          this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-black.png";
          this.plinImage = "/assets/images/ikone/wcag/plin-black.png";
          break;
        case 'theme-black-beige':
          this.toplotaImage = "/assets/images/ikone/wcag/toplota-beige.png";
          this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-beige.png";
          this.plinImage = "/assets/images/ikone/wcag/plin-beige.png";
          break;
        case 'theme-black-yellow':
          this.toplotaImage = "/assets/images/ikone/wcag/toplota-black-yellow.png";
          this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-black-yellow.png";
          this.plinImage = "/assets/images/ikone/wcag/plin-black-yellow.png";
          break;
        case 'theme-yellow-blue':
          this.toplotaImage = "/assets/images/ikone/wcag/toplota-yellow.png";
          this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-yellow.png";
          this.plinImage = "/assets/images/ikone/wcag/plin-yellow.png";
          break;
        case 'theme-lime-black':
          this.toplotaImage = "/assets/images/ikone/wcag/toplota-lime.png";
          this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-lime.png";
          this.plinImage = "/assets/images/ikone/wcag/plin-lime.png";
          break;
        default:
          break;
      }
    });
  }

  accessibleActivateChild(event: KeyboardEvent, energent: RazlagaRacunaEnergent) {
    if (event) {
      if (event.code === 'Enter') {
        this.activateChild(energent);
      }
    }
  }

  activateChild(energent: RazlagaRacunaEnergent) {
    this.activatedChild = energent;
  }

  getToplotaImage(): string {
    return this.toplotaImage;
  }

  getElektrikaImage(): string {
    return this.elektrikaImage;
  }

  getPlinImage(): string {
    return this.plinImage;
  }

}

export enum RazlagaRacunaEnergent {
  PLIN = 'plin',
  ELEKTRIKA = 'elektrika',
  TOPLOTA = 'toplota'
}

import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';

import { ThirdPartyService } from '../../../services/3rd-party.service';

@Component({
    selector: 'app-matevz-lenarcic',
    templateUrl: './matevz-lenarcic.component.html',
    styleUrls: ['./matevz-lenarcic.component.scss']
})
export class EnergetikaVDruzbiMatevzLenarcicComponent implements OnInit, AfterViewInit {

    public interviewExtended: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private thirdPartyService: ThirdPartyService
    ) {
     }

    ngOnInit() { }

    ngAfterViewInit() {

        this.thirdPartyService.eflsightInit(this.elementRef);
    }

    public interviewExtend() {
        this.interviewExtended = !this.interviewExtended;
    }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import * as _ from 'lodash';
import { finalize, first } from 'rxjs/operators';

import { PovprasevanjeBaseComponent } from '../base/base.component';

import { PovprasevanjeService } from '../../../services/povprasevanje.service';
import { CodelistService } from '../../../services/codelist.service';
import { CookiesService } from '../../../services/cookies.service';

import { OdjemnaSkupina } from '../../../models/codelist.model';
import { Obrazec, PovprasevanjeIzracunZp } from '../../../models/povprasevanje.model';
import { CalculationExactOutput } from '../../../models/calculation.model';

@Component({
    selector: 'app-povprasevanje-inf-izracun-zp',
    templateUrl: './inf-izracun-zp.component.html',
    styleUrls: ['./inf-izracun-zp.component.scss']
})
export class PovprasevanjeInfIzracunZpComponent extends PovprasevanjeBaseComponent implements OnInit {

    public customerForm: UntypedFormGroup;
    public calculationForm: UntypedFormGroup;

    public odjemnaSkupina: OdjemnaSkupina[] = [];
    public obrazec: Obrazec = obrazec;

    public result: CalculationExactOutput = null;

    constructor(
        router: Router,
        route: ActivatedRoute,
        povprasevanjeService: PovprasevanjeService,
        cookiesService: CookiesService,
        private fb: UntypedFormBuilder,
        private codelistService: CodelistService
    ) { 
        super(router, route, povprasevanjeService, cookiesService);

        this.maxSteps = 4;
    }

    public ngOnInit() {

        this.obrazci = [obrazec];
        this.initStorage();
        this.initPovprasevanje();

        this.calculationForm = this.fb.group({
            poraba: ['', { validators: [Validators.required, Validators.min(1)], updateOn: 'blur' }],
            skupina: ['', { validators: [Validators.required, Validators.min(1)], updateOn: 'blur' }]
        });

        this.codelistService.getOdjemnaSkupina()
            .pipe(first())
            .subscribe(res => {
                this.odjemnaSkupina = res;
            });

        this.route.queryParamMap.subscribe(pm => {

            if (pm.has('katId')) {
                this.loadKategorija(parseInt(pm.get('katId')));
            }

            let step = 3;

            if (pm.has('step'))
                step = parseInt(pm.get('step'));
            
            if (step !== 3 && step !== 4)
                step = 3;

            this.step = step;
        });
    }

    public submit() {

        if (this.loadingObrazecUpdate)
            return;

        this.obrazecSubmitted = false;
        this.obrazecSubmitError = false;

        if (this.calculationForm.valid) {
            this.loadingObrazecUpdate = true;

            let apiModel: PovprasevanjeIzracunZp = _.cloneDeep(this.povprasevanje);
            apiModel.izracun = this.calculationForm.value;

            this.povprasevanjeService.oddajPovprasevanjeIzracunZp(this.kategorija.id, apiModel)
                .pipe(
                    first(),
                    finalize(() => this.loadingObrazecUpdate = false)
                )
                .subscribe(res => {
                    this.deletePovprasevanjeLocalStorage();
                    this.povprasevanje = null;
                    this.obrazecSubmitted = true;
                    this.result = res;
                }, err => {
                    this.obrazecSubmitError = true;
                });
        } else {
            for (const k in this.calculationForm.controls) {
                this.calculationForm.get(k).markAsDirty();
            }
        }
    }

    public reset() {
        this.calculationForm.reset();
    }

    onBasicBack(ev: any, o: Obrazec) {
        this.router.navigate(['../../'], { relativeTo: this.route, queryParams: { katId: this.kategorija ? this.kategorija.stars.id : null, step: this.step - 1 } });
    }

    onBasicSubmit(ev: any, o: Obrazec) {

        this.updatePovprasevanjeFromObrazecEvent(ev, o);
        this.savePovprasevanjeLocalStorage(this.povprasevanje);

        this.router.navigate(['./'], { queryParams: { step: this.step + 1 }, queryParamsHandling: 'merge', relativeTo: this.route });
    }
}

const obrazec: Obrazec = {
    koda: 'forma_inf_izr_zp_basic',
    id: null,
    // naziv: 'Informativni izračun EE',
    polja : [{
      naziv: 'ime in priimek / naziv',
      koda: 'ime_priimek_naziv',
      tip:'text',
      dolzina: 200,
      autocomplete: 'name',
      obvezno: true
    }, {
      naziv: 'elektronski naslov',
      koda: 'elektronski_naslov',
      tip: 'email',
      dolzina: 255,
      autocomplete: 'email',
      obvezno: true
    }, {
      naziv: 'telefonska številka',
      koda: 'telefonska_stevilka',
      tip:'phone',
      dolzina: 20,
      autocomplete: 'tel',
      obvezno: true
    }, {
      naziv: 'ulica in hišna številka',
      koda: 'ulica_hisna_stevilka',
      tip:'text',
      dolzina: 300,
      autocomplete: 'shipping street-address',
      obvezno: true
    }, {
      naziv: 'pošta',
      koda: 'posta_kraj',
      tip:'postal_code_city',
      dolzina: 100,
      autocomplete: 'shipping postal-code,shipping locality',
      obvezno: true
    }]
};
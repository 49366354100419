<article>
    <div class="top-banner ugodnost" style="background-image: url('assets/images/klub-zvestoba-ogreva/background-sport-ljubljana-1.jpg')">
        <div class="layer">
            <img class="img-fluid" src="assets/images/klub-zvestoba-ogreva/logo-big-white.png" alt="Logo Klub zvestoba ogreva">
            <h1>
              <strong>10 % ugodnejša rekreacija</strong> v okviru Šport Ljubljana
            </h1>
        </div>
    </div>

    <div class="pas-vsebina">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1 offset-lg-2 offset-xl-3">
                    <p>
                        Člani Kluba Zvestoba ogreva ste upravičeni do <strong>10-odstotnega popusta</strong> na vse vrste rekreacije v okviru Šport Ljubljana, ki upravlja z več kot 100 športnimi objekti v lasti Mestne občine Ljubljana. Športno rekreacijske površine nudijo pestro izbiro rekreacije na prostem in v pokritih objektih.
                    </p>
                    <button (click)="toggleAdditionalTxt()" class="btn-blue border-only inline-block">Želim izkoristiti popust</button>
                    <div *ngIf="showAdditionalTxt" class="additional-explanation">
                        <div class="arrow"></div>
                        <div class="text">
                            <p>Pokličite Kontaktni center Energetike Ljubljana na številko 080 28 82 (od 8.00 do 15.00 ure) ali pošljite email na <a href="mailto:klub@bivanjudajemoutrip.si">klub&#64;bivanjudajemoutrip.si</a>. Navedite ime in priimek, naslov ter odjemno mesto.</p><p>Na vaš elektronski naslov boste nato prejeli potrditev o koriščenju popusta.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-xl-3">
                    <img src="assets/images/klub-zvestoba-ogreva/sport-ljubljana-logo.jpg" alt="Logo Sport Ljubljana">
                </div>

            </div>
        </div>
    </div>

    <div class="pas-vsebina background-light-grey">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1 offset-lg-2 offset-xl-3">
                    <p>
                        Najbolj priljubljen način vadbe v pokritih objektih je obisk fitnesa in skupinskih vadb, ki so na voljo skozi celo leto v <a href="http://www.sport-ljubljana.si/Park_Tivoli_Fitnes_Tivoli/" target="blank">Fitnesu Tivoli</a> in <a href="http://www.sport-ljubljana.si/Fitnes_Vic_4/" target="_blank">Fitnesu Vič</a>. Rekreativno plavanje je mogoče na <a href="http://www.sport-ljubljana.si/Park_Tivoli_Kopalisce_Tivoli/" target="_blank">Kopališču Tivoli</a>, v toplejših mesecih pa tudi na <a href="http://www.sport-ljubljana.si/Kopalisce_Kolezija/http://www.sport-ljubljana.si/Kopalisce_Kolezija/" target="_blank">Kopališču Kolezija</a> in <a href="http://www.sport-ljubljana.si/Park_Kodeljevo_Letno_kopalisce/" target="_blank">Kopališču Kodeljevo</a>. V Tivoliju na voljo tudi kompleks savn <a href="http://www.sport-ljubljana.si/Park_Tivoli_Savna_Zlati_Klub/" target="_blank">Zlati klub</a>, ki so odprte skozi vse leto.
                    </p>
                    <p class="mt-3">
                        Ljubiteljem tenisa so na voljo igrišča v <a href="http://www.sport-ljubljana.si/Teniska_igrisca_Kodeljevo_1/" target="_blank">Parku Kodeljevo</a> in <a href="http://www.sport-ljubljana.si/Park_Tivoli_Teniska_igrisca_Tivoli/" target="_blank">Parku Tivoli</a>. Ena od zanimivih idej za preživljanje prostega časa je športno streljanje na <a href="http://www.sport-ljubljana.si/Park_Rudnik_Strelisce/" target="_blank">Strelišču</a>. Ljubiteljem golfa se oči zasvetijo ob pogledu na čudovite zelenice in novo vadbišče v <a href="http://www.sport-ljubljana.si/Golf_center/" target="_blank">Golf centru Stanežiče</a>, ki spada med eno najdaljših v Sloveniji.
                    </p>
                    <p class="mt-3">
                        V hladnejših mesecih vas vabimo na drsanje v <a href="http://www.sport-ljubljana.si/Park_Tivoli_Hala_Tivoli/" target="_blank">Halo Tivoli</a> in <a href="http://www.sport-ljubljana.si/Park_Zalog_Drsalisce_Zalog/" target="_blank">Dvorano Zalog</a>, namenjeno tako odraslim, kot tudi otrokom. Tekom celega leta pa se lahko rekreirate in sprostite ob igranju namiznega tenisa (<a href="http://www.sport-ljubljana.si/Park_Rudnik_Sportna_dvorana_Krim/" target="_blank">Park Rudnik</a> in <a href="http://www.sport-ljubljana.si/Park_Kodeljevo_Vecnamenska_dvorana_Kodeljevo/" target="_blank">Park Kodeljevo</a>) ali dvoranskih športov (Dvorana Stožice, Hala Tivoli, Dvorana Kodeljevo, Dvorana Staničeva, Dvorana Ježica).
                    </p>
                    <p class="mt-3"><a dokument-id="4E2BEFB8-FADB-440F-8BC4-CF95204D6A81" dokument-icon="true" dokument-text="true" href=""></a><br /></p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-xl-3">
                    <img src="assets/images/klub-zvestoba-ogreva/infografika-sport-ljubljana.png" alt="Slika Sport Ljubljana: pingpong in kolesarjenje">
                </div>
            </div>
        </div>
    </div>

</article>

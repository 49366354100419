import { Route } from '@angular/router';

import { InformativniIzracunComponent } from './informativni-izracun.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Informativni izračun',
    description: 'Že imate najugodnejšo kombinacijo energetske oskrbe? Poleg ugodne cene vam nudimo tudi številne dodatne storitve in finančne ugodnosti.',
    keywords: ['informativni izračun', 'kalkulator', 'najugodnejša kombinacija', 'energetska oskrba', 'zemeljski plin', 'daljinsko ogrevanje', 'toplota', 'električna energija', 'elektrika', 'naročilo', 'povpraševanje'],
    canonicalUrl: '/informativni-izracun'
};

export const route: Route = {
    path: 'informativni-izracun',
    component: InformativniIzracunComponent,
    data: { metadata: metadata }
};
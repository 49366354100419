<div class="row" *ngIf="!loading && newsArticle">
    <div class="col-12 col-xl-5 image mt-4">
        <img class="img-fluid" [src]="cmsContentBasePath + newsArticle.slika.url" [alt]="newsArticle.slika.altOpis ? newsArticle.slika.altOpis : ' '" >
    </div>
    <div class="col-12 col-xl-7">
        <div class="novica">
            <div class="heading">
                <h2>{{ newsArticle.naslov }}</h2>
            </div>
            <div class="date">
                {{ newsArticle.aktivenOd | date: 'dd. MM. yyyy' }}
            </div>
            <div class="tags">
                <div *ngFor="let tag of newsArticle.oznake" class="label label-primary">
                    <a class="outline-offset-large" routerLink="/novice" [queryParams]="{ oznake: tag.id }">{{ tag.naziv }}</a>
                </div>
            </div>
            <div class="content" documents [innerHTML]="newsArticle.vsebina | trust"></div>

            <div *ngIf="newsArticle.cta" class="ctas">
              <a [href]="newsArticle.ctaDokument.url" target="_blank" class="povprasevanje outline-offset-large" *ngIf="newsArticle.ctaTip === 'DOKUMENT'"><em>{{ newsArticle.ctaNaziv }}</em></a>
              <a [href]="newsArticle.ctaPovezava" target="_blank" class="povprasevanje outline-offset-large" *ngIf="newsArticle.ctaTip === 'POVEZAVA'"><em>{{ newsArticle.ctaNaziv }}</em></a>
              <a routerLink="/klub-zvestoba-ogreva/prijava" class="povprasevanje outline-offset-large" *ngIf="newsArticle.ctaTip === 'NAROCILO_KLUB_ZVESTOBA_OGREVA'"><em>{{ newsArticle.ctaNaziv }}</em></a>
              <a routerLink="/povprasevanje" [queryParams]="newsArticle.ctaQueryParams" class="povprasevanje outline-offset-large" *ngIf="newsArticle.ctaTip === 'POVPRASEVANJE' || newsArticle.ctaTip === 'SVETOVANJE'"><em>{{ newsArticle.ctaNaziv }}</em></a>
            </div>

            <div class="socials mt-3" *ngIf="!loading && hasSocialsConsent" #socials>
                <span class="fb">
                    <div #fbShareButton class="fb-share-button" data-layout="button"></div>
                </span>
                <span class="twitter">
                    <a #twitterShareButton class="twitter-share-button" [href]="'https://twitter.com/intent/tweet?text=' + newsArticle.naslov"></a>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading && networkJob">
    <div class="col-12 single">
        <div class="delo-na-omrezju">
            <div class="heading">
                <h2>{{ networkJob.naslov }}</h2>
            </div>
            <div class="date">
                {{ networkJob.aktivenOd | date: 'dd. MM. yyyy' }}
            </div>
            <div class="tags" *ngIf="networkJob.daljinskoOgrevanje || networkJob.zemeljskiPlin">
                <span *ngIf="networkJob.zemeljskiPlin" class="label label-primary">Plinovodno omrežje</span>
                <span *ngIf="networkJob.daljinskoOgrevanje" class="label label-primary">Vročevodno omrežje</span>
            </div>
            <div class="caption" [innerHTML]="networkJob.vsebina"></div>
            <div class="locations" [innerHTML]="networkJob.ulice"></div>
        </div>
    </div>
</div>

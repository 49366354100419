import { Route } from '@angular/router';

import { KlubZvestobaOgrevaComponent } from './layout.component';
import { KlubZvestobaOgrevaLandingComponent } from './landing/landing.component';
import { KlubZvestobaOgrevaArhivComponent } from './arhiv/arhiv.component';
import { KlubZvestobaOgrevaUgodnostiComponent } from './ugodnosti/ugodnosti.component';
import { KlubZvestobaOgrevaEnergetskaIzkaznicaComponent } from './energetska-izkaznica/energetska-izkaznica.component';
import { KlubZvestobaOgrevaGeodetskiNacrtComponent } from './geodetski-nacrt/geodetski-nacrt.component';
import { KlubZvestobaOgrevaMinicityComponent } from './minicity/minicity.component';
import { KlubZvestobaOgrevaSportLjubljanaComponent } from './sport-ljubljana/sport-ljubljana.component';
import { KlubZvestobaOgrevaPrijavaComponent } from './prijava/prijava.component';
import { KlubZvestobaOgrevaUspesnaPrijavaComponent } from './uspesna-prijava/uspesna-prijava.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Klub Zvestoba ogreva',
    description: '',
    keywords: ['klub zvestoba ogreva', 'zvestoba ogreva', 'klub', 'akcija', 'ugodnosti', 'zemeljski plin', 'električna energija'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background11.jpg',
    imageWidth: 1920,
    imageHeight: 602
};

const prijavaMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Prijava',
    description: 'Pristopna izjava za Klub zvestoba ogreva Energetike Ljubljana',
    keywords: ['klub zvestoba ogreva', 'zvestoba ogreva', 'klub', 'akcija', 'ugodnosti', 'zemeljski plin', 'električna energija'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background11.jpg',
    imageWidth: 1920,
    imageHeight: 602
};

const uspesnaPrijavaMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Uspešna prijava',
    description: 'Pristopna izjava za Klub zvestoba ogreva Energetike Ljubljana',
    keywords: ['klub zvestoba ogreva', 'zvestoba ogreva', 'klub', 'akcija', 'ugodnosti', 'zemeljski plin', 'električna energija'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background11.jpg',
    imageWidth: 1920,
    imageHeight: 602
};

const ugodnostiMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Ugodnosti',
    description: '',
    keywords: ['klub zvestoba ogreva', 'zvestoba ogreva', 'klub', 'akcija', 'ugodnosti', 'zemeljski plin', 'električna energija'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background11.jpg',
    imageWidth: 1920,
    imageHeight: 602
};

const arhivMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Arhiv ugodnosti',
    description: 'Arhiv seznamov ugodnosti za člane Kluba zvestoba ogreva',
    keywords: ['klub zvestoba ogreva', 'zvestoba ogreva', 'klub', 'arhiv ugodnosti', 'zemeljski plin', 'električna energija'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background11.jpg',
    imageWidth: 1920,
    imageHeight: 602
};

const energetskaIzkaznicaMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Izdelava energetske izkaznice',
    description: 'Do 30 odstotni popust pri izdelavi energetske izkaznice za člane Kluba Zvestoba ogreva.',
    keywords: ['30 odstotni popust', 'izdelava energetske izkaznice', 'izračun'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/ugodnosti-energetska-izkaznica.jpg',
    imageWidth: 450,
    imageHeight: 300
};

const geodetskiNacrtMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Izdelava geodetskega načrta',
    description: '30 % popust na izdelavo geodetskega načrta pri Energetiki Ljubljana za člane Kluba Zvestoba ogreva.',
    keywords: ['geodetski načrt', 'izdelava'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/geodetski-nacrt.jpg',
    imageWidth: 600,
    imageHeight: 958
};

const minicityMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Minicity',
    description: '40 % popust pri nakupu celodnevne vstopnice za obisk Minicityja v Ljubljani.',
    keywords: ['minicity', 'celodnevna vstopnice', 'ljubljana', 'btc city'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background-minicity.jpg',
    imageWidth: 1920,
    imageHeight: 621
};

const sportLjubljanaMetadata: Metadata = {
    title: 'Klub Zvestoba ogreva: Šport Ljubljana',
    description: 'Do 10 % popust na vse vrste rekreacije v okviru Šport Ljubljana.',
    keywords: ['sport ljubljana', 'rekreacija', 'popust', 'kopalisce', 'tenis', 'fitnes', 'vadba'],
    imageUrl: 'assets/images/klub-zvestoba-ogreva/background-sport-ljubljana-1.jpg',
    imageWidth: 1920,
    imageHeight: 458
};

export const route: Route = {
    path: 'klub-zvestoba-ogreva',
    component: KlubZvestobaOgrevaComponent,
    data: {
        metadata: metadata
    },
    children: [{
        path: '',
        component: KlubZvestobaOgrevaLandingComponent,
        data: { metadata: metadata }
    }, {
        path: 'arhiv-ugodnosti',
        component: KlubZvestobaOgrevaArhivComponent,
        data: { metadata: arhivMetadata }
    }, {
        path: 'ugodnosti',
        component: KlubZvestobaOgrevaUgodnostiComponent,
        data: { metadata: ugodnostiMetadata }
    }, {
        path: 'energetska-izkaznica',
        component: KlubZvestobaOgrevaEnergetskaIzkaznicaComponent,
        data: { metadata: energetskaIzkaznicaMetadata }
    }, {
        path: 'geodetski-nacrt',
        component: KlubZvestobaOgrevaGeodetskiNacrtComponent,
        data: { metadata: geodetskiNacrtMetadata }
    }, {
        path: 'minicity',
        component: KlubZvestobaOgrevaMinicityComponent,
        data: { metadata: minicityMetadata }
    }, {
        path: 'sport-ljubljana',
        component: KlubZvestobaOgrevaSportLjubljanaComponent,
        data: { metadata: sportLjubljanaMetadata }
    }]
};

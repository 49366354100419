import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { Subject } from 'rxjs';
import { finalize, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { SmsObvescanjeService } from '../../services/sms-obvescanje.service';

import { Ulica, HisnaStevilka, Naslov, Narocnik } from '../../models/sms-obvescanje.model';

@Component({
    selector: 'sms-obvescanje',
    templateUrl: './sms-obvescanje.component.html',
    styleUrls: ['./sms-obvescanje.component.scss']
})
export class SmsObvescanjeComponent implements OnInit {

    @Input() public naslovId: number = null;
    @Output() public onNaslovSelected: EventEmitter<Naslov> = new EventEmitter();

    public showNaslov: boolean = false;
    public showTelefon: boolean = false;
    public showAuth: boolean = false;
    public showDetails: boolean = false;

    public loadingUlice: boolean = false;
    public showUliceDropdown: boolean = false;
    public loadingHisneStevilke: boolean = false;
    public showHisneStevilkaDropdown: boolean = false;
    public loadingNaslov: boolean = false;
    public loadingAvtentikacija: boolean = false;
    public loadingDetails: boolean = false;
    public loadingUpdate: boolean = false;

    public errorGeneralFail: boolean = false;
    public errorAuthReqFail: boolean = false;
    public errorAuthConfFail: boolean = false;
    public errorAuthReqExpiredFail: boolean = false;

    public ulice: Ulica[] = [];
    public hisneStevilke: HisnaStevilka[] = [];

    public msisdn: string;
    public msisdnKoda: string;
    public changedNarocnik: boolean = false;

    public selectedUlica: Ulica = null;
    public selectedHisnaStevilka: HisnaStevilka = null;
    public ulicaSearch: string = '';
    public selectedNaslov: Naslov = null;
    public narocnik: Narocnik;

    public telefonForm: UntypedFormGroup;
    public authForm: UntypedFormGroup;
    public get telefonFormTelefonskaSt() { return this.telefonForm.get('telefonskaSt') }
    public get authFormVerikacijskaKoda() { return this.authForm ? this.authForm.get('verikacijskaKoda') : null }

    private focusEventTime: Date = null;
    private ulicaSearchSubject: Subject<string>;

    constructor(
        private fb: UntypedFormBuilder,
        private smsObvescanjeService: SmsObvescanjeService
    ) {

        this.telefonForm = this.fb.group({
            telefonskaSt: ['', [Validators.required, Validators.minLength(9)]]
        });

        this.authForm = this.fb.group({
            verikacijskaKoda: ['', [Validators.required, Validators.minLength(4)]]
        });

        this.ulicaSearchSubject = new Subject();
        this.ulicaSearchSubject
            .pipe(
                distinctUntilChanged(),
                debounceTime(300)
            )
            .subscribe(v => {
                this.selectedUlica = null;
                this.loadUlice(v);
            });
    }

    ngOnInit() {

        this.showNaslov = _.isUndefined(this.naslovId) || this.naslovId === null;

        if (!this.showNaslov) {
            this.showTelefon = !this.showNaslov && !this.msisdn;
            this.loadNaslov(this.naslovId);
        }
    }

    public ulicaSearchChange(value: string) {

        if (!value || value.length < 2)
            return;

        this.ulicaSearchSubject.next(value);

        this.showUliceDropdown = true;
    }

    uliceInputToggle(event: KeyboardEvent) {
      if (event) {
        let activeEl = document.activeElement;
        switch (event.code) {
          case 'ArrowDown':
            this.uliceInputOpen();
            event.preventDefault();
            setTimeout(() => {
              this.tabNextElement(activeEl.nextElementSibling.firstElementChild);
            },100);
            break;
          case 'Escape':
          case 'ArrowUp':
            this.uliceInputClose();
            break;
          case 'Enter':
            if (this.showUliceDropdown === true) {
              this.uliceInputClose();
            } else {
              this.uliceInputOpen();
            }
            break;
        }
      }
    }

  checkUlicaTab(event: KeyboardEvent, index, size) {
    if (event) {
      let activeEl = document.activeElement;
      switch (event.code) {
        case 'Escape':
          this.uliceInputClose();
          break;
        case 'Enter':
          if (index !== -1) {
            this.selectUlica(this.ulice[index]);
          } else {
            this.selectUlica(null);
          }
          break;
        case 'ArrowDown':
          event.preventDefault(); // prevent scrolling
          this.tabNextElement(activeEl.parentElement.nextElementSibling);
          break;
        case 'ArrowUp':
          event.preventDefault(); // prevent scrolling
          this.tabNextElement(activeEl.parentElement.previousElementSibling);
          break;
        case 'Tab':
          if (!event.shiftKey) {
            if (index === size - 1) {
              this.uliceInputClose();
            }
          }
          break;
      }
    }
  }

  private tabNextElement(siblingElement: Element) {
    if (siblingElement) {
      let nextAnchor = siblingElement.firstElementChild;
      if (nextAnchor) {
        (nextAnchor as HTMLElement).focus();
      }
    }
  }

    private uliceInputOpen() {
        this.showUliceDropdown = true;
        this.focusEventTime = new Date();
    }

    private uliceInputClose() {

        setTimeout(() => {
            this.showUliceDropdown = false;
            this.focusEventTime = new Date();
        }, 200);
    }

    public toggleUlicaDropdown() {

        if (this.focusEventTime && new Date().getTime() - this.focusEventTime.getTime() > 150)
            this.showUliceDropdown = !this.showUliceDropdown;
    }

    public closeUlicaDropdown() {
        this.showUliceDropdown = false;
    }

    public toggleHisneStevilkeDropdown() {
        this.showHisneStevilkaDropdown = !this.showHisneStevilkaDropdown;
    }

    public openHisneStevilkeDropdown() {
      this.showHisneStevilkaDropdown = true;
    }

    public closeHisneStevilkeDropdown() {
        this.showHisneStevilkaDropdown = false;
    }

  hisneStevilkeKeydown(event: KeyboardEvent) {
    if (event) {
      let activeEl = document.activeElement;
      switch (event.code) {
        case 'ArrowDown':
          this.openHisneStevilkeDropdown();
          event.preventDefault();
          setTimeout(() => {
            this.tabNextElement(activeEl.nextElementSibling.firstElementChild);
          },100);
          break;
        case 'ArrowUp':
          this.closeHisneStevilkeDropdown();
          event.preventDefault();
          break;
      }
    }
  }

  checkHsTab(event: KeyboardEvent, index, size) {
    if (event) {
      let activeEl = document.activeElement;
      switch (event.code) {
        case 'Escape':
          this.showHisneStevilkaDropdown = false;
          break;
        case 'Enter':
          if (index !== -1) {
            this.selectHisnaStevilka(this.hisneStevilke[index]);
          } else {
            this.selectHisnaStevilka(null);
          }
          break;
        case 'ArrowDown':
          this.openHisneStevilkeDropdown();
          event.preventDefault();
          this.tabNextElement(activeEl.parentElement.nextElementSibling);
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.tabNextElement(activeEl.parentElement.previousElementSibling);
          break;
        case 'Tab':
          if (!event.shiftKey) {
            if (index === size - 1) {
              this.closeHisneStevilkeDropdown();
            }
          }
          break;
      }
    }
  }

    public selectUlica(ulica: Ulica) {

        this.selectedUlica = ulica;

        if (this.selectedUlica !== null) {
            this.loadNaslovi(this.selectedUlica.id, true);
            this.ulicaSearch = ulica.label;
        } else {
          this.ulicaSearch = null;
        }

        this.showUliceDropdown = false;
    }

    public selectHisnaStevilka(hs: HisnaStevilka) {

        this.selectedHisnaStevilka = hs;

        if (this.selectedHisnaStevilka !== null)
            this.loadNaslov(this.selectedHisnaStevilka.id);

        this.showHisneStevilkaDropdown = false;
    }

    public shraniTelefonskaStevilka(event: Event) {

        event.preventDefault();
        this.errorAuthReqFail = false;

        if (this.telefonForm.valid) {

            let telefonskaStevilka = this.telefonFormTelefonskaSt.value.replace(/\s/g, '');

            if (telefonskaStevilka.indexOf('00386') === 0)
                telefonskaStevilka = telefonskaStevilka.substring(2);
            else if (telefonskaStevilka.indexOf('0386') === 0)
                telefonskaStevilka = telefonskaStevilka.substring(1);
            else if (telefonskaStevilka.indexOf('+386') === 0)
                telefonskaStevilka = telefonskaStevilka.substring(1);
            else if (telefonskaStevilka.indexOf("0") === 0)
                telefonskaStevilka = '386' + telefonskaStevilka.substring(1);

            if (telefonskaStevilka.indexOf('386') !== 0)
                telefonskaStevilka = '386' + telefonskaStevilka;

            this.msisdn = telefonskaStevilka;

            this.loadingAvtentikacija = true;

            this.smsObvescanjeService.telefonskaStevilkaVerifikacija({
                msisdn: this.msisdn,
                naslovId: this.selectedNaslov.id
            }).pipe(
                finalize(() => this.loadingAvtentikacija = false))
            .subscribe(res => {
                this.showTelefon = false;
                this.showAuth = true;
            }, err => {
                this.errorAuthReqFail = true;
            })
        } else {
            for (let key in this.telefonForm.controls) {
                this.telefonForm.controls[key].markAsDirty();
            }
        }
    }

    public shraniVerifikacijo(event: Event) {

        event.preventDefault();

        this.errorAuthConfFail = false;
        this.errorGeneralFail = false;

        if (this.authForm.valid) {
            this.loadNarocnik(this.authFormVerikacijskaKoda.value)
        } else {
            for (let key in this.authForm.controls) {
                this.authForm.controls[key].markAsDirty();
            }
        }
    }

    public changeNarocnikToplota(value: boolean) {
        this.changedNarocnik = true;
        this.narocnik.toplota = value;
    }

    public changeNarocnikPlin(value: boolean) {
        this.changedNarocnik = true;
        this.narocnik.plin = value;
    }

    public updateNarocnik() {

        if (!this.changedNarocnik || this.errorAuthReqExpiredFail)
            return;

        this.errorAuthConfFail = false;
        this.errorGeneralFail = false;

        if (!this.msisdnKoda || !this.selectedNaslov || !this.msisdn)
            return;

        this.loadingUpdate = true;

        this.smsObvescanjeService.putNarocnik({
            msisdn: this.msisdn,
            msisdnKoda: this.msisdnKoda,
            naslovId: this.selectedNaslov.id,
            plin: this.narocnik.plin,
            toplota: this.narocnik.toplota
        }).pipe(
            finalize(() => this.loadingUpdate = false)
        ).subscribe(res => {
            this.narocnik = res;
            this.changedNarocnik = false;
        }, err => {

            if (err.status === 403)
                this.errorAuthReqExpiredFail = true;
            else
                this.errorGeneralFail = true;
        });
    }

    private loadUlice(q: string, reset: boolean = false) {

        if (reset) {
            this.selectedUlica = null;
            this.ulicaSearch = '';
        }

        this.loadingUlice = true;

        this.smsObvescanjeService.getUlice(q)
            .pipe(
                finalize(() => this.loadingUlice = false)
            )
            .subscribe(res => {
                this.ulice = res;
            });
    }

    private loadNaslovi(ulicaId: number, reset: boolean = false) {

        if (reset)
            this.selectedHisnaStevilka = null;

        this.loadingHisneStevilke = true;

        this.smsObvescanjeService.getHisneStevilke(ulicaId)
            .pipe(
                finalize(() => this.loadingHisneStevilke = false)
            )
            .subscribe(res => {
                this.hisneStevilke = res;
            });
    }

    private loadNaslov(naslovId: number) {

        this.loadingNaslov = true;

        this.smsObvescanjeService.getNaslov(naslovId)
            .pipe(
                finalize(() => this.loadingNaslov = false)
            )
            .subscribe(res => {
                this.selectedNaslov = res;

                this.onNaslovSelected.emit(res);
            });
    }

    private loadNarocnik(msisdnKoda: string = this.msisdnKoda) {

        if (!msisdnKoda || !this.selectedNaslov || !this.msisdn)
            return;

        this.loadingDetails = true;

        this.smsObvescanjeService.getNarocnik({
            msisdn: this.msisdn,
            msisdnKoda: msisdnKoda,
            naslovId: this.selectedNaslov.id
        }).pipe(
            finalize(() => this.loadingDetails = false)
        ).subscribe(res => {
            this.narocnik = res;
            this.msisdnKoda = msisdnKoda;
            this.showAuth = false;
            this.showDetails = true;
            this.changedNarocnik = false;
        }, err => {

            if (err.status === 403)
                this.errorAuthConfFail = true;
            else
                this.errorGeneralFail = true;
        });
    }
}

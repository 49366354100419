import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { MesecneNovicePrijava } from '../models/e-obvescanje.model';

@Injectable()
export class EObvescanjeService {

    private baseUrl: string;

    constructor(private http: HttpClient) { 
        this.baseUrl = `${environment.serviceBasePath}/e-obvescanje`;
    }

    public prijavaMesecneNovice(data: MesecneNovicePrijava): Observable<any> {
        return this.http.post(`${this.baseUrl}/mesecno-obvescanje/prijava`, data);
    }
}
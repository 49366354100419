import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { OfferOrder, PoslovniOdjem, KZOClan } from '../models/order.model';

@Injectable()
export class OrderService {

    private baseUrlNarocilo: string;
    private baseUrlSvetovanje: string;

    constructor(private http: HttpClient) { 

        this.baseUrlNarocilo = `${environment.serviceBasePath}/narocilo`;
        this.baseUrlSvetovanje = `${environment.serviceBasePath}/svetovanje`;
    }

    public orderOffer(id: number, order: OfferOrder): Observable<any> {

        return this.http.post<any>(`${this.baseUrlNarocilo}/akcija/${id}`, order);
    }

    public orderPoslovniOdjem(po: PoslovniOdjem): Observable<any> {

        return this.http.post<any>(`${this.baseUrlSvetovanje}/poslovni-odjem`, po);
    }

    public newZvestobaOgrevaClan(kzo: KZOClan): Observable<any> {

        return this.http.post<any>(`${this.baseUrlNarocilo}/klub-zvestoba-ogreva`, kzo);
    }
}
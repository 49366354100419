import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Kategorija, Povprasevanje, PovprasevanjeIzracunEe, PovprasevanjeIzracunZp } from '../models/povprasevanje.model';
import { CalculationExactOutput } from '../models/calculation.model';

@Injectable()
export class PovprasevanjeService {

    private baseUrl: string;

    constructor(
        private http: HttpClient
    ) { 
        this.baseUrl = `${environment.serviceBasePath}/povprasevanje`;
    }

    public getKategorije(): Observable<Kategorija[]> {

        return this.http.get<Kategorija[]>(`${this.baseUrl}/kategorija`);
    }

    public getKategorija(id: number): Observable<Kategorija> {

        return this.http.get<Kategorija>(`${this.baseUrl}/kategorija/${id}`);
    }

    public getKategorijaObrazci(id: number): Observable<any> {

        return this.http.get<any>(`${this.baseUrl}/kategorija/${id}/obrazci`);
    }

    public createPovprasevanje(kategorijaId: number, povprasevanje: Povprasevanje): Observable<Povprasevanje> {

        let queryParams: any = { katId: kategorijaId };
        
        return this.http.post<Povprasevanje>(`${this.baseUrl}`, povprasevanje, { params: queryParams });
    }

    public uploadPovprasevanjeFile(id: number, kategorijaId: number, file: File): Observable<Povprasevanje> {

        let queryParams: any = { katId: kategorijaId };
        
        let fd = new FormData();
        fd.append('file', file);
        fd.append('fileName', file.name);

        return this.http.post<any>(`${this.baseUrl}/${id}`, fd, { params: queryParams });
    }

    public updatePovprasevanje(id: number, kategorijaId: number, povprasevanje: Povprasevanje): Observable<Povprasevanje> {

        let queryParams: any = { katId: kategorijaId };
        
        return this.http.put<Povprasevanje>(`${this.baseUrl}/${id}`, povprasevanje, { params: queryParams });
    }

    public oddajPovprasevanje(id: number, kategorijaId: number): Observable<any> {

        let queryParams: any = { katId: kategorijaId };
        
        return this.http.post<any>(`${this.baseUrl}/${id}/oddaj`, null, { params: queryParams });
    }

    public oddajPovprasevanjeIzracunEe(kategorijaId: number, item: PovprasevanjeIzracunEe): Observable<CalculationExactOutput> {
        
        let queryParams: any = { katId: kategorijaId };
        
        return this.http.post<CalculationExactOutput>(`${this.baseUrl}/informativni-izracun/elektrika/oddaj`, item, { params: queryParams });
    }

    public oddajPovprasevanjeIzracunZp(kategorijaId: number, item: PovprasevanjeIzracunZp): Observable<CalculationExactOutput> {
        
        let queryParams: any = { katId: kategorijaId };
        
        return this.http.post<CalculationExactOutput>(`${this.baseUrl}/informativni-izracun/plin/oddaj`, item, { params: queryParams });
    }
}
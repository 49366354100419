<main id="content" class="container">
  <h1>Vsebine</h1>
  <div class="d-flex flex-row flex-wrap justify-content-start">
    <div>
      <h2>Splošno</h2>
      <ul class="ul-style">
        <li>
          <a routerLink="/novice"><i class="fa fa-chevron-right" aria-hidden="true"></i> Novice</a>
        </li>
        <li>
          <a routerLink="/klub-zvestoba-ogreva"><i class="fa fa-chevron-right" aria-hidden="true"></i> Klub zvestoba ogreva</a>
        </li>
        <li>
          <a routerLink="/povprasevanje"><i class="fa fa-chevron-right" aria-hidden="true"></i> Povpraševanje</a>
        </li>
        <li>
          <a routerLink="/energetika-v-druzbi"><span class="fa fa-chevron-right" aria-hidden="true"></span> Energetika v družbi</a>
        </li>
        <li>
          <a routerLink="/npn-paketi"><i class="fa fa-chevron-right" aria-hidden="true"></i> Npn paketi</a>
        </li>
        <li>
          <a routerLink="/dela-na-omrezju"><i class="fa fa-chevron-right" aria-hidden="true"></i> Dela na omrežju</a>
        </li>
        <li>
          <a routerLink="/narocilo/sms-obvescanje"><i class="fa fa-chevron-right" aria-hidden="true"></i> SMS obvestila o delih na omrežju</a>
        </li>
        <li>
          <a routerLink="/poslovni-odjem"><i class="fa fa-chevron-right" aria-hidden="true"></i> Za podjetja</a>
        </li>
        <li>
          <a routerLink="/ceniki-dokumenti"><i class="fa fa-chevron-right" aria-hidden="true"></i> Ceniki in dokumenti</a>
        </li>
        <li>
          <a (click)="openCampaignSubscribeDialog()" href="javascript:void(0)"><i class="fa fa-chevron-right" aria-hidden="true"></i> Prijava na obveščanje</a>
        </li>
        <li>
          <a routerLink="/razlaga-racuna"><i class="fa fa-chevron-right" aria-hidden="true"></i> Razlaga računa</a>
        </li>
      </ul>
    </div>
    <ng-container *ngIf="cmsSitemap !== null">
      <ng-container *ngFor="let row of cmsSitemap.abstract">
        <div *ngFor="let segment of row; let idx = index">
          <h2>{{ segment.naziv }}</h2>
          <ul class="ul-style">
<!--            <li>{{ segment.naziv }}</li>-->
            <li *ngFor="let kategorija of segment.kategorijas">
              <a [routerLink]="['/vsebina', segment.urlPot, kategorija.urlPot]">
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                {{ kategorija.naziv }}
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </ng-container>
  </div>
</main>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { CmsService } from '../../services/cms.service';
import { MetadataService } from '../../services/metadata.service';

import { OfferExtended } from '../../models/cms.model';

@Component({
    selector: 'app-preview-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss']
})
export class PreviewActionComponent implements OnInit {

    public id: number;
    public version: number;

    public offer: OfferExtended;
    
    public loading: boolean = true;
    public expanded: boolean = false;
    public narocilo: boolean = false;

    public cmsContentBasePath;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService,
        private metadataService: MetadataService
    ) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.narocilo = this.route.snapshot.queryParams.narocilo === 'true';
        this.id = this.route.snapshot.params.id ? parseInt(this.route.snapshot.params.id) : null;
        this.version = this.route.snapshot.params.version ? parseInt(this.route.snapshot.params.version) : null;

        if (this.narocilo) {
            this.cmsService.getOfferById(this.id, this.version)
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe(res => {
                    this.offer = res;
                });
        } else {
            this.loading = false;
        }
    }

    public expand() {
        this.expanded = !this.expanded;
    }
}
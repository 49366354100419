<article>
    <div class="top-banner" style="background-image: url(assets/images/klub-zvestoba-ogreva/background11.jpg">
        <div class="layer">
            <img class="img-fluid" src="assets/images/klub-zvestoba-ogreva/logo-big-white.png" alt="Klub Zvestoba ogreva">
        </div>
    </div>

    <div class="dodatne-informacije background-light-grey border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <h1>Arhiv seznamov ugodnosti za člane Kluba zvestoba ogreva</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <p *ngFor="let d of ugodnostiDocs">
                        <a [dokument-id]="d.id" dokument-icon="true" dokument-text="true" href=""></a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</article>

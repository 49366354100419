<div class="form-group"
    *ngIf="skupina === polje.skupina"
    [class.row]="polje.tip === 'postal_code_city'"
    [class.mb-1]="skupina"
    [class.mb-2]="!skupina && polje.tip !== 'merilna_mesta'"
    [class.mb-3]="!skupina && polje.tip === 'merilna_mesta'"
    [class.d-none]="polje.obveznoPovezanoPolje && polje.skrito">
    <ng-container *ngIf="polje.tip !== 'group' && (polje.tip === 'text' || polje.tip === 'email' || polje.tip === 'phone' || polje.tip === 'iban' || polje.tip === 'date' || polje.tip === 'tax_number')">
        <label class="transform-capitalize">{{ polje.naziv }} <strong *ngIf="polje.obvezno">*</strong></label>
        <input type="text" class="form-control"
            [formControl]="polje.control"
            [ngClass]="{ 'error-border': polje.control.dirty && !polje.control.valid }"
            [autocomplete]="polje.autocomplete ? polje.autocomplete : 'off'"
            [placeholder]="polje.placeholder ? polje.placeholder : ''">
        <small *ngIf="polje.pomoc" class="form-text text-muted">
            {{ polje.pomoc }}
        </small>
      <p *ngIf="polje.control?.dirty && polje.control?.errors?.required" class="help-block error" role="alert"><em>Vnesite {{polje.naziv}}</em></p>
      <p *ngIf="polje.dolzinaMin && polje.control?.dirty && polje.control.errors?.minlength" class="help-block error" role="alert"><em>Vnos je prekratek, dolžina je omejena na vsaj {{ polje.dolzinaMin }} znakov</em></p>
      <p *ngIf="polje.dolzina && polje.control?.dirty && polje.control.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na {{ polje.dolzina }} znakov</em></p>
      <p *ngIf="polje.tip === 'email' && polje.control.dirty && polje.control.errors?.email" class="help-block error" role="alert"><em>Vnesite pravilen e-poštni format</em></p>
      <p *ngIf="polje.tip === 'iban' && polje.control.dirty && polje.control.errors?.pattern" class="help-block error" role="alert"><em>Zahtevan je vnos v formatu SI56 1920 0123 4567 892.</em></p>
      <p *ngIf="polje.tip === 'phone' && polje.control.dirty && polje.control.errors?.pattern" class="help-block error" role="alert"><em>Vnesite pravilno telefonsko številko</em></p>
      <p *ngIf="polje.tip === 'tax_number' && polje.control.dirty && polje.control.errors?.pattern" class="help-block error" role="alert"><em>Vnesite pravilen format davčne številke (SI12345678 ali 12345678)</em></p>
      <p *ngIf="polje.tip === 'date' && polje.control.dirty && polje.control.errors?.pattern" class="help-block error" role="alert"><em>Zahtevan je vnos datuma v formatu datum.mesec.leto (npr. {{ today | date: 'dd.MM.yyyy' }})</em></p>
    </ng-container>
    <ng-container *ngIf="polje.tip !== 'group' && polje.tip === 'number'">
        <label class="transform-capitalize">{{ polje.naziv }} <strong *ngIf="polje.obvezno">*</strong></label>
        <input type="number" class="form-control"
            [formControl]="polje.control"
            [ngClass]="{ 'error-border': polje.control.dirty && !polje.control.valid }"
            [autocomplete]="polje.autocomplete ? polje.autocomplete : 'off'"
            [placeholder]="polje.placeholder ? polje.placeholder : ''">
        <small *ngIf="polje.pomoc" class="form-text text-muted">
            {{ polje.pomoc }}
        </small>
    </ng-container>
    <ng-container *ngIf="polje.tip !== 'group' && polje.tip === 'textarea'">
        <label class="transform-capitalize">{{ polje.naziv }} <strong *ngIf="polje.obvezno">*</strong></label>
        <textarea type="text" class="form-control" rows="5"
            [formControl]="polje.control"
            [ngClass]="{ 'error-border': polje.control.dirty && !polje.control.valid }"
            [placeholder]="polje.placeholder ? polje.placeholder : ''"></textarea>
        <small *ngIf="polje.pomoc" class="form-text text-muted">
            {{ polje.pomoc }}
        </small>
      <p *ngIf="polje.control?.dirty && polje.control?.errors?.required" class="help-block error" role="alert"><em>Vnesite {{polje.naziv}}</em></p>
      <p *ngIf="polje.dolzinaMin && polje.control?.dirty && polje.control.errors?.minlength" class="help-block error" role="alert"><em>Vnos je prekratek, dolžina je omejena na vsaj {{ polje.dolzinaMin }} znakov</em></p>
      <p *ngIf="polje.dolzina && polje.control?.dirty && polje.control.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na {{ polje.dolzina }} znakov</em></p>
    </ng-container>
    <ng-container *ngIf="polje.tip !== 'group' && polje.tip === 'select'">
        <select class="form-control"
            [formControl]="polje.control"
            [ngClass]="{ 'error-border': polje.control.dirty && !polje.control.valid }">
            <option *ngIf="polje.naziv" value="" disabled selected>{{ polje.naziv }}</option>
            <option *ngFor="let o of polje.selectVrednosti" [value]="o">{{ o }}</option>
        </select>
        <small *ngIf="polje.pomoc" class="form-text text-muted">
            {{ polje.pomoc }}
        </small>
    </ng-container>
    <ng-container *ngIf="polje.tip !== 'group' && polje.tip === 'postal_code_city'">
        <label class="col-12 transform-capitalize">{{ polje.naziv }} <strong *ngIf="polje.obvezno">*</strong></label>
        <div class="col-7 col-md-9">
            <input type="text" class="form-control"
                [formControl]="polje.control"
                [ngClass]="{ 'error-border': (polje.control.dirty || polje.secondaryControl.dirty) && !polje.control.valid }"
                [autocomplete]="polje.autocomplete ? polje.autocomplete : 'off'">
        </div>
        <div class="col-5 col-md-3 pl-0">
            <input type="number" class="form-control" [placeholder]="polje.placeholder ? polje.placeholder : ''"
            [formControl]="polje.secondaryControl"
            [ngClass]="{ 'error-border': (polje.control.dirty || polje.secondaryControl.dirty) && !polje.secondaryControl.valid }"
            [autocomplete]="polje.autocompleteSecondary ? polje.autocompleteSecondary : 'off'">
        </div>
        <div class="col-12">
            <small *ngIf="polje.pomoc" class="form-text text-muted">
                {{ polje.pomoc }}
            </small>
          <p *ngIf="(polje.secondaryControl.dirty || polje.control.dirty) && (!polje.control.valid || !polje.secondaryControl.valid)" class="help-block error" role="alert"><em>Vnesite pravilno ime pošte in pravilno poštno številko</em></p>
          <p *ngIf="polje.control.dirty && polje.control.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na {{ polje.dolzina }} znakov</em></p>
        </div>
    </ng-container>

    <ng-container *ngIf="polje.tip !== 'group' && polje.tip === 'merilna_mesta'">
        <label class="transform-capitalize">{{ polje.naziv }} <strong *ngIf="polje.obvezno">*</strong></label>
        <ng-container *ngFor="let omc of polje.control.controls; let omi = index">
            <div class="input-group mb-1">
                <input type="text" class="form-control"
                    [formControl]="omc"
                    [ngClass]="{ 'error-border': omc.dirty && !omc?.valid }">
                <div class="input-group-append" *ngIf="polje.formArrayMax > 1">
                    <button (click)="polje.formArrayRemove(omi)" [disabled]="polje.control.controls.length <= 1" type="button" class="btn btn-default"><i class="fa fa-minus" aria-hidden="true"></i></button>
                </div>
            </div>
          <p *ngIf="omc.dirty && omc.errors?.minlength" class="help-block error" role="alert"><em>Vnos je prekratek, dolžina je omejena na vsaj 6 znakov</em></p>
          <p *ngIf="omc.dirty && omc.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 11 znakov</em></p>
        </ng-container>
        <a *ngIf="polje.control.controls.length < polje.formArrayMax" class="text add-more" (click)="polje.formArrayAdd()" href="javascript:void(0)"><i class="fa fa-plus" aria-hidden="true"></i> dodaj polje za vpis</a>
    </ng-container>

    <ng-container *ngIf="polje.tip !== 'group' && (polje.tip === 'merilna_mesta_stanje' || polje.tip === 'merilna_mesta_stanje_datum' || polje.tip === 'merilna_mesta_stanje_datum_znesek')">
        <label>{{ polje.naziv }} <strong *ngIf="polje.obvezno">*</strong></label>
        <ng-container *ngFor="let omc of polje.control.controls; let omi = index">
            <div class="input-group mb-1">
                <input type="text" class="form-control"
                    [formControl]="omc.get('no')"
                    [ngClass]="{ 'error-border': omc.get('no').dirty && !omc.get('no').valid }">
            </div>
          <p *ngIf="omc.get('no').dirty && omc.get('no').errors?.minlength" class="help-block error" role="alert"><em>Vnos je prekratek, dolžina je omejena na vsaj 6 znakov</em></p>
          <p *ngIf="omc.get('no').dirty && omc.get('no').errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 11 znakov</em></p>

            <div class="input-group mb-1">
                <input type="text" class="form-control"
                    [formControl]="omc.get('st')"
                    [ngClass]="{ 'error-border': omc.get('st').dirty && !omc.get('st').valid }">
                <div *ngIf="polje.tip === 'merilna_mesta_stanje'" class="input-group-append">
                    <button (click)="polje.formArrayRemove(omi)" [disabled]="polje.control.controls.length <= 1" type="button" class="btn btn-default"><i class="fa fa-minus" aria-hidden="true"></i></button>
                </div>
            </div>
          <p *ngIf="omc.get('st').dirty && omc.get('st').errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 50 znakov</em></p>

            <div *ngIf="omc.get('ds')" class="input-group mb-1">
                <input type="text" class="form-control"
                    [formControl]="omc.get('ds')"
                    [ngClass]="{ 'error-border': omc.get('ds').dirty && !omc.get('ds').valid }">
                <div *ngIf="polje.tip === 'merilna_mesta_stanje_datum'" class="input-group-append">
                    <button (click)="polje.formArrayRemove(omi)" [disabled]="polje.control.controls.length <= 1" type="button" class="btn btn-default"><i class="fa fa-minus" aria-hidden="true"></i></button>
                </div>
            </div>
          <p *ngIf="omc.get('ds') && omc.get('ds').dirty && omc.get('ds').errors?.pattern" class="help-block error" role="alert"><em>Zahtevan je vnos datuma v formatu datum.mesec.leto (npr. {{ today | date: 'dd.MM.yyyy' }})</em></p>

            <div *ngIf="omc.get('zo')" class="input-group mb-1">
                <input type="text" class="form-control"
                    [formControl]="omc.get('zo')"
                    [ngClass]="{ 'error-border': omc.get('zo').dirty && !omc.get('zo').valid }">
                <div *ngIf="polje.tip === 'merilna_mesta_stanje_datum_znesek'" class="input-group-append">
                    <button (click)="polje.formArrayRemove(omi)" [disabled]="polje.control.controls.length <= 1" type="button" class="btn btn-default"><i class="fa fa-minus" aria-hidden="true"></i></button>
                </div>
            </div>
          <p *ngIf="omc.get('zo') && omc.get('zo').dirty && omc.get('zo').errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 50 znakov</em></p>
        </ng-container>
        <a *ngIf="polje.control.controls.length < polje.formArrayMax" class="text add-more" (click)="polje.formArrayAdd()" href="javascript:void(0)"><i class="fa fa-plus" aria-hidden="true"></i> dodaj polje za vpis</a>
    </ng-container>

    <ng-container *ngIf="polje.tip !== 'group' && polje.tip === 'file'">
        <div class="input-group">
            <input type="text" class="form-control" disabled="disabled"
                [formControl]="polje.control"
                [ngClass]="{ 'error-border': polje.control.dirty && !polje.control.valid }">
            <input #fileInput type="file" (change)="onFileChange($event, polje)" style="display: none;">
            <div class="input-group-append">
                <button (click)="browseFiles()" type="button" class="btn btn-default">Naloži</button>
            </div>
        </div>
        <small *ngIf="polje.pomoc" class="form-text text-muted">
            {{ polje.pomoc }}
        </small>
    </ng-container>
    <ng-container *ngIf="polje.tip !== 'group' && (polje.tip === 'checkbox' || polje.tip === 'radio' || polje.tip === 'yes/no')">
        <div class="form-check mb-0">
            <input class="form-check-input" type="checkbox"
                [formControl]="polje.control">
            <label class="form-check-label">
                {{ polje.naziv }}
            </label>
        </div>
    </ng-container>
    <ng-container *ngIf="polje.tip === 'group'">
        <span [ngClass]="{ 'error-color': polje.control.dirty && !polje.control?.valid }">{{ polje.naziv }} <strong *ngIf="polje.obvezno">*</strong></span>
        <povprasevanje-obrazec-polje [polje]="gPolje" [skupina]="polje.koda" *ngFor="let gPolje of polje.polja"></povprasevanje-obrazec-polje>
    </ng-container>
</div>

import { Route } from '@angular/router';

import { MailingThankYouComponent } from './thank-you.component';

import { Metadata } from '../../../models/metadata.model';

const metadata: Metadata = {
    title: 'Zahvala za prijavo na e-novice',
    description: 'Zahvala za prijavo na e-novice'
};

export const route: Route = {
    path: 'zahvala',
    component: MailingThankYouComponent,
    data: {
        metadata: metadata
    }
};
import { Route } from '@angular/router';

import { PoslovniOdjemComponent } from './poslovni-odjem.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Poslovni odjem',
    description: 'Poslovni odjem',
    keywords: ['poslovni odjem', 'poslovni odnos', 'ponudba', 'povpraševanje', 'naročilo', 'svetovalec']
};

export const route: Route = {
    path: 'poslovni-odjem',
    component: PoslovniOdjemComponent,
    data: {
        metadata: metadata
    }
};
<article class="akcija" [class.border-bottom]="!isExpandable || (isBrowser && expanded)" *ngIf="!loading">
    <div class="container">
        <div class="row ordering">
            <div tabindex="0" class="col-12" (click)="expand()" (keydown)="accessibilityExpand($event)">
                <h3 [class.clickable]="isExpandable && isBrowser"
                    [class.mt-0]="isExpandable && isBrowser"
                    [class.mb-0]="isExpandable && isBrowser && !expanded"
                    [class.mb-4]="isExpandable && isBrowser && expanded">
                    {{ offerTitle }}
                    <i *ngIf="isExpandable && isBrowser && !expanded" class="fa fa-chevron-down ml-2 ml-lg-3"></i>
                    <i *ngIf="isExpandable && isBrowser && expanded" class="fa fa-chevron-up ml-2 ml-lg-3"></i>
                </h3>
            </div>
            <ng-container *ngIf="!isExpandable || !isBrowser || expanded">
                <div *ngIf="offer.slika" class="infografika col-12 col-lg-4" [ngClass]="{ 'offset-lg-1': left, left: !left, right: left }">
                    <div>
                        <img class="img-fluid" [src]="cmsContentBasePath + offer.slika.url" align="right">
                    </div>
                </div>
                <div class="col-12" [ngClass]="{ 'col-lg-7': offer.slika, 'col-xl-12': !offer.slika, 'offset-lg-1': !left && offer.slika, left: left, right: !left }">
                    <div>
                        <div *ngIf="offer.vsebina && offer.vsebina.length > 0" documents [innerHTML]="getVsebina(offer.vsebina) | trust"></div>
                        <div *ngIf="offer.vsebinaDodatna1 && offer.vsebinaDodatna1.length > 0" documents [innerHTML]="getVsebina(offer.vsebinaDodatna1) | trust"></div>

                        <div class="ctas">
                            <ng-container [ngSwitch]="offer.ctaTip">
                              <a *ngSwitchCase="'DOKUMENT'" [href]="offer.ctaDokument.url" target="_blank" class="povprasevanje"><em>{{ offer.ctaNaziv }}</em></a>
                              <a *ngSwitchCase="'POVEZAVA'" [href]="offer.ctaPovezava" target="_blank" class="povprasevanje"><em>{{ offer.ctaNaziv }}</em></a>
                              <a *ngSwitchCase="'NAROCILO_AKCIJA'" [routerLink]="['/narocilo/akcija', offer.akcija.id]" class="povprasevanje"><em>{{ offer.ctaNaziv }}</em></a>
                              <a *ngSwitchCase="'NAROCILO_KLUB_ZVESTOBA_OGREVA'" routerLink="/klub-zvestoba-ogreva/prijava" class="povprasevanje"><em>{{ offer.ctaNaziv }}</em></a>
                              <a *ngSwitchCase="'POVPRASEVANJE'" routerLink="/povprasevanje" [queryParams]="offer.ctaQueryParams" class="povprasevanje"><em>{{ offer.ctaNaziv }}</em></a>
                              <a *ngSwitchCase="'SVETOVANJE'" routerLink="/povprasevanje" [queryParams]="offer.ctaQueryParams" class="povprasevanje"><em>{{ offer.ctaNaziv }}</em></a>
                            </ng-container>

                            <button class="razsiri" (click)="expandDetails()" *ngIf="offer.vsebinaDodatna2 && offer.vsebinaDodatna2.length > 0">
                                <ng-container *ngIf="!expandedDetails"><i class="fa fa-plus-circle" aria-hidden="true"></i><em> Več o ponudbi</em></ng-container>
                                <ng-container *ngIf="expandedDetails"><i class="fa fa-minus-circle" aria-hidden="true"></i><em> Zapri</em></ng-container>
                            </button>
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="dodatne-informacije background-light-grey dark" *ngIf="(!isExpandable || expanded) && offer.vsebinaDodatna2 && offer.vsebinaDodatna2.length > 0 && expandedDetails">
        <div class="container">
            <div class="row">
                <div class="col-xs-12" documents [innerHTML]="getVsebina(offer.vsebinaDodatna2) | trust"></div>
            </div>
        </div>
    </div>
</article>

import { NgModule, Inject, LOCALE_ID, PLATFORM_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData, DecimalPipe, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router, Scroll, NavigationEnd } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import localeSl from '@angular/common/locales/sl';
import localeSiExtra from '@angular/common/locales/extra/si';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { Angulartics2Module } from 'angulartics2';

registerLocaleData(localeSl, 'sl-SI', localeSiExtra);

// routes
import { routes as appRoutes } from './app.routes';

// modules
import { DirectivesModule } from './directives/directives.module';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';

// components
import { AppComponent } from './app.component';
import { LayoutComponent } from './content/layout.component';
import { HomeComponent } from './content/home/home.component';
import { NoviceComponent } from './content/novice/novice.component';
import { NoviceListComponent } from './content/novice/list/list.component';
import { NoviceDetailsComponent } from './content/novice/details/details.component';
import { DelaNaOmrezjuComponent } from './content/dela-na-omrezju/dela-na-omrezju.component';
import { DelaNaOmrezjuListComponent } from './content/dela-na-omrezju/list/list.component';
import { DelaNaOmrezjuDetailsComponent } from './content/dela-na-omrezju/details/details.component';
import { CmsVsebinaComponent } from './content/cms-vsebina/cms-vsebina.component';
import { VsebinaEnergentiLngComponent } from './content/cms-vsebina/energenti-lng/energenti-lng.component';
import { InformativniIzracunComponent } from './content/informativni-izracun/informativni-izracun.component';
import { NarociloAkcijaListComponent } from './content/narocilo/akcija/list/list.component';
import { NarociloAkcijaOrderComponent } from './content/narocilo/akcija/order/order.component';
import { NarociloSmsObvescanjeFindComponent } from './content/narocilo/sms-obvescanje/find/find.component';
import { NarociloSmsObvescanjeOrderComponent } from './content/narocilo/sms-obvescanje/order/order.component';
import { NarociloSmsObvescanjeBrezNaslovaComponent } from './content/narocilo/sms-obvescanje/brez-naslova/brez-naslova.component';
import { NarociloSmsObvescanjePogojiComponent } from './content/narocilo/sms-obvescanje/pogoji/pogoji.component';
import { PoslovniOdjemComponent } from './content/poslovni-odjem/poslovni-odjem.component';
import { NotFoundComponent } from './content/not-found/not-found.component';
import { MailingComponent } from './content/mailing/mailing.component';
import { MailingUnsubscribeComponent } from './content/mailing/unsubscribe/unsubscribe.component';
import { MailingThankYouComponent } from './content/mailing/thank-you/thank-you.component';
import { PreviewActionComponent } from './content/predogled/action.component';
import { PovprasevanjeComponent } from './content/povprasevanje/povprasevanje.component';
import { PovprasevanjeInfIzracunEeComponent } from './content/povprasevanje/inf-izracun-ee/inf-izracun-ee.component';
import { PovprasevanjeInfIzracunZpComponent } from './content/povprasevanje/inf-izracun-zp/inf-izracun-zp.component';
import { CenikiDokumentiComponent } from './content/ceniki-dokumenti/ceniki-dokumenti.component';
import { CenikiDokumentiListComponent } from './content/ceniki-dokumenti/list/list.component';

// akcije custom pages
import { AkcijeLayoutComponent } from './content/akcije/layout.component';
import { AkcijaVozimNaPlinComponent } from './content/akcije/vozim-na-plin/vozim-na-plin.component';

// energetika v druzbi
import { EnergetikaVDruzbiComponent } from './content/energetika-v-druzbi/layout.component';
import { EnergetikaVDruzbiNipkeComponent } from './content/energetika-v-druzbi/energetika-nipke/energetika-nipke.component';
import { EnergetikaVDruzbiTopleTackeComponent } from './content/energetika-v-druzbi/tople-tacke/tople-tacke.component';
import { EnergetikaVDruzbiSmarnaGoraComponent } from './content/energetika-v-druzbi/smarna-gora/smarna-gora.component';
import { EnergetikaVDruzbiMatevzLenarcicComponent } from './content/energetika-v-druzbi/matevz-lenarcic/matevz-lenarcic.component';

// kzo
import { KlubZvestobaOgrevaComponent } from './content/klub-zvestoba-ogreva/layout.component';
import { KlubZvestobaOgrevaLandingComponent } from './content/klub-zvestoba-ogreva/landing/landing.component';
import { KlubZvestobaOgrevaArhivComponent } from './content/klub-zvestoba-ogreva/arhiv/arhiv.component';
import { KlubZvestobaOgrevaUgodnostiComponent } from './content/klub-zvestoba-ogreva/ugodnosti/ugodnosti.component';
import { KlubZvestobaOgrevaEnergetskaIzkaznicaComponent } from './content/klub-zvestoba-ogreva/energetska-izkaznica/energetska-izkaznica.component';
import { KlubZvestobaOgrevaGeodetskiNacrtComponent } from './content/klub-zvestoba-ogreva/geodetski-nacrt/geodetski-nacrt.component';
import { KlubZvestobaOgrevaMinicityComponent } from './content/klub-zvestoba-ogreva/minicity/minicity.component';
import { KlubZvestobaOgrevaSportLjubljanaComponent } from './content/klub-zvestoba-ogreva/sport-ljubljana/sport-ljubljana.component';
import { KlubZvestobaOgrevaPrijavaComponent } from './content/klub-zvestoba-ogreva/prijava/prijava.component';
import { KlubZvestobaOgrevaUspesnaPrijavaComponent } from './content/klub-zvestoba-ogreva/uspesna-prijava/uspesna-prijava.component';

// custom
import { KlubskaCenaPlinaComponent } from './content/custom/klubska-cena-plina/klubska-cena-plina.component';
import { ZelenaElektrikaComponent } from './content/custom/zelena-elektrika/zelena-elektrika.component';

// razlaga racuna
import { RazlagaRacunaComponent } from './content/razlaga-racuna/razlaga-racuna.component';
import { RazlagaRacunaBaseComponent } from './content/razlaga-racuna/razlaga-racuna-base/razlaga-racuna-base.component';
import { RazlagaRacunaPlinComponent } from './content/razlaga-racuna/razlaga-racuna-plin/razlaga-racuna-plin.component';
import { RazlagaRacunaElektrikaComponent } from './content/razlaga-racuna/razlaga-racuna-elektrika/razlaga-racuna-elektrika.component';
import { RazlagaRacunaToplotaComponent } from './content/razlaga-racuna/razlaga-racuna-toplota/razlaga-racuna-toplota.component';

// legal
import { CookiesComponent } from './content/legal/cookies/cookies.component';
import { TermsUseComponent } from './content/legal/terms-use/terms-use.component';
import { PrivacyComponent } from './content/legal/privacy/privacy.component';

// services
import { CmsService } from './services/cms.service';
import { DocumentsService } from './services/documents.service';
import { CalculationService } from './services/calculation.service';
import { OrderService } from './services/order.service';
import { SmsObvescanjeService } from './services/sms-obvescanje.service';
import { MetadataService } from './services/metadata.service';
import { ThirdPartyService } from './services/3rd-party.service';
import { CookiesService } from './services/cookies.service';
import { AssetsDataService } from './services/assets-data.service';
import { PovprasevanjeService } from './services/povprasevanje.service';
import { EObvescanjeService } from './services/e-obvescanje.service';
import { CodelistService } from './services/codelist.service';

// errors
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './error/rollbar.error-handler';
import {NpnPaketiComponent} from './content/npn-paketi/npn-paketi.component';
import {TocComponent} from './content/toc/toc.component';
import {AccessibilityStatementComponent} from './content/legal/accessility-statement/accessibility-statement.component';

@NgModule({ declarations: [
        LayoutComponent,
        HomeComponent,
        NoviceComponent,
        NoviceListComponent,
        NoviceDetailsComponent,
        DelaNaOmrezjuComponent,
        DelaNaOmrezjuListComponent,
        DelaNaOmrezjuDetailsComponent,
        CmsVsebinaComponent,
        VsebinaEnergentiLngComponent,
        InformativniIzracunComponent,
        NarociloAkcijaListComponent,
        NarociloAkcijaOrderComponent,
        NarociloSmsObvescanjeFindComponent,
        NarociloSmsObvescanjeOrderComponent,
        NarociloSmsObvescanjeBrezNaslovaComponent,
        NarociloSmsObvescanjePogojiComponent,
        PoslovniOdjemComponent,
        NotFoundComponent,
        MailingComponent,
        MailingUnsubscribeComponent,
        MailingThankYouComponent,
        PreviewActionComponent,
        NpnPaketiComponent,
        PovprasevanjeComponent,
        PovprasevanjeInfIzracunEeComponent,
        PovprasevanjeInfIzracunZpComponent,
        CenikiDokumentiComponent,
        CenikiDokumentiListComponent,
        AkcijeLayoutComponent,
        AkcijaVozimNaPlinComponent,
        EnergetikaVDruzbiComponent,
        EnergetikaVDruzbiNipkeComponent,
        EnergetikaVDruzbiTopleTackeComponent,
        EnergetikaVDruzbiSmarnaGoraComponent,
        EnergetikaVDruzbiMatevzLenarcicComponent,
        KlubZvestobaOgrevaComponent,
        KlubZvestobaOgrevaLandingComponent,
        KlubZvestobaOgrevaArhivComponent,
        KlubZvestobaOgrevaUgodnostiComponent,
        KlubZvestobaOgrevaEnergetskaIzkaznicaComponent,
        KlubZvestobaOgrevaGeodetskiNacrtComponent,
        KlubZvestobaOgrevaMinicityComponent,
        KlubZvestobaOgrevaSportLjubljanaComponent,
        KlubZvestobaOgrevaPrijavaComponent,
        KlubZvestobaOgrevaUspesnaPrijavaComponent,
        KlubskaCenaPlinaComponent,
        ZelenaElektrikaComponent,
        RazlagaRacunaComponent,
        RazlagaRacunaBaseComponent,
        RazlagaRacunaPlinComponent,
        RazlagaRacunaElektrikaComponent,
        RazlagaRacunaToplotaComponent,
        TocComponent,
        AccessibilityStatementComponent,
        CookiesComponent,
        TermsUseComponent,
        PrivacyComponent
    ], imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, {
            anchorScrolling: 'enabled',
            initialNavigation: 'enabledBlocking'
        }),
        MatSliderModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatDialogModule,
        RecaptchaModule,
        Angulartics2Module.forRoot(),
        ComponentsModule,
        DirectivesModule,
        PipesModule], providers: [
        { provide: LOCALE_ID, useValue: 'sl-SI' },
        { provide: RECAPTCHA_LANGUAGE, useValue: 'sl' },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        DecimalPipe,
        CmsService,
        DocumentsService,
        CalculationService,
        OrderService,
        SmsObvescanjeService,
        MetadataService,
        ThirdPartyService,
        CookiesService,
        AssetsDataService,
        PovprasevanjeService,
        EObvescanjeService,
        CodelistService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

  private prevPath: string;
  private currPath: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    router: Router,
    viewportScroller: ViewportScroller
    ) {

    if (isPlatformBrowser(this.platformId) && window.location) {
      this.currPath = window.location.pathname;

      router.events.subscribe(e => {

        if (e instanceof NavigationEnd) {
          // navigation changed, new path in order
          this.prevPath = this.currPath;
          this.currPath = window.location.pathname;
        } else if (e instanceof Scroll) {
          if (e.position) {
            // backward navigation
            viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            // anchor navigation
            viewportScroller.scrollToAnchor(e.anchor);
          } else if (this.prevPath !== this.currPath) {
            // forward navigation only if path changes
            viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
    }
  }
}

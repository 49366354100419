<main id="content">
    <section class="content">
        <div class="background-light-grey">
            <div class="container">
                <div class="title">
                    <h1>SMS obvestila o delih na omrežju</h1>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <img class="img-fluid" src="assets/images/sms-obvescanje.jpg" alt="Slika SMS obveščanje">
                        <p class="mt-3">Obrazec je namenjen uporabnikom, ki v postopku naročanja na SMS obvestila o delih na omrežju niso našli svojega naslova oziroma hišna številka ni bila na voljo. Ostali uporabniki za pomoč pri prijavi na SMS obveščanje prosimo pokličite 080 28 82.</p>
                    </div>

                    <div class="col-12 col-md-6">
                        <form [formGroup]="form" (ngSubmit)="submit($event)">

                            <div *ngIf="loading">
                                <loader></loader>
                            </div>

                            <div *ngIf="!showZakljucek && !loading">
                                <div class="form-group">
                                    <label for="ime">Ime <strong>*</strong></label>
                                    <input id="ime" type="text" [formControl]="ime" class="form-control" autocomplete="given-name" placeholder="Janez" [ngClass]="{ 'error-border': ime?.dirty && !ime?.valid }">
                                  <p *ngIf="ime?.dirty && ime?.errors?.required" class="help-block error" role="alert"><em>Vnesite ime naročnika</em></p>
                                  <p *ngIf="ime?.dirty && ime?.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 100 znakov</em></p>
                                </div>

                                <div class="form-group">
                                    <label for="priimek">Priimek <strong>*</strong></label>
                                    <input id="priimek" type="text" [formControl]="priimek" class="form-control" autocomplete="family-name" placeholder="Novak" [ngClass]="{ 'error-border': priimek?.dirty && !priimek?.valid }">
                                  <p *ngIf="priimek?.dirty && priimek?.errors?.required" class="help-block error" role="alert"><em>Vnesite priimek naročnika</em></p>
                                  <p *ngIf="priimek?.dirty && priimek?.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 100 znakov</em></p>
                                </div>

                                <div class="form-group">
                                    <label for="naslov">Naslov <strong>*</strong></label>
                                    <input id="naslov" type="text" [formControl]="naslov" class="form-control" autocomplete="street-address" placeholder="Ljubljanska cesta 1, Ljubljana" [ngClass]="{ 'error-border': naslov?.dirty && !naslov?.valid }">
                                  <p *ngIf="naslov?.dirty && naslov?.errors?.required" class="help-block error" role="alert"><em>Vnesite pravilni naslov</em></p>
                                  <p *ngIf="naslov?.dirty && naslov?.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 300 znakov</em></p>
                                </div>

                                <div class="form-group">
                                    <label for="email">E-pošta <strong>*</strong></label>
                                    <input id="email" type="text" [formControl]="email" class="form-control" placeholder="janez.novak@email.si" [ngClass]="{ 'error-border': email?.dirty && !email?.valid }">
                                  <p *ngIf="email?.dirty && email?.errors?.required" class="help-block error" role="alert"><em>Vnesite e-pošto</em></p>
                                  <p *ngIf="email?.dirty && email?.errors?.email" class="help-block error" role="alert"><em>Vnesite pravilen e-poštni format</em></p>
                                  <p *ngIf="email?.dirty && email?.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 254 znakov</em></p>
                                </div>

                                <div class="form-group">
                                    <label for="telefonskaStevilka">Številka mobilnega telefona <strong>*</strong></label>
                                    <input id="telefonskaStevilka" type="text" [formControl]="telefonskaStevilka" class="form-control" autocomplete="tel" placeholder="031 555 555" [ngClass]="{ 'error-border': telefonskaStevilka?.dirty && !telefonskaStevilka?.valid }">
                                  <p *ngIf="telefonskaStevilka?.dirty && telefonskaStevilka?.errors?.required" class="help-block error" role="alert"><em>Vnesite pravilno telefonsko številko</em></p>
                                  <p *ngIf="telefonskaStevilka?.dirty && telefonskaStevilka?.errors?.pattern" class="help-block error" role="alert"><em>{{ $translations.vnesitePravilenFormatTelefonske }}</em></p>
                                  <p *ngIf="telefonskaStevilka?.dirty && telefonskaStevilka?.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 20 znakov</em></p>
                                </div>

                                <div class="form-group" role="group" aria-labelledby="obvestilaZelimLabel">
                                    <label id="obvestilaZelimLabel">Obvestila želim prejemati za energent: <strong>*</strong></label>
                                    <div class="form-group form-check mb-0">
                                        <input id="daljinskoOgrevanje" type="checkbox" class="form-check-input" [formControl]="daljinskoOgrevanje">
                                        <label for="daljinskoOgrevanje" class="form-check-label">Daljinsko ogrevanje (toplota)</label>
                                    </div>
                                    <div class="form-group form-check">
                                        <input id="zemeljskiPlin" type="checkbox" class="form-check-input" [formControl]="zemeljskiPlin">
                                        <label for="zemeljskiPlin" class="form-check-label">Zemeljski plin</label>
                                    </div>
                                  <p *ngIf="showEnergentiError && (zemeljskiPlin?.dirty || daljinskoOgrevanje?.dirty)" class="help-block error" role="alert"><em>Izbrati je potrebno vsaj en energent</em></p>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="showZakljucek">
                                <h2><i class="fa fa-check-circle" aria-hidden="true"></i> Zahteva za naročanje oddana</h2>
                                <p class="zakljucek">Zahteva za naročanje na SMS sporočila za podani naslov in energente je bila oddana.</p>
                                <a class="btn-blue" routerLink="/">
                                    <i class="fa fa-home" aria-hidden="true"></i> Nazaj domov
                                </a>
                            </div>

                            <div *ngIf="!showZakljucek && !loading">
                                <div class="row">
                                    <div class="col-12 mt-3">
                                        <p class="help-block">Polja označena z * so obvezna.</p>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <re-captcha (resolved)="captchaResolved($event)" [siteKey]="captchaKey"></re-captcha>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12 alert alert-danger" *ngIf="showGeneralError" role="alert">
                                        <h2><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka pri procesiranju zahteve</h2>
                                        <p>Vaša zahteva žal ni bila sprocesirana, ker ne moremo zagotoviti, da niste robot. Prosimo, poskusite znova, ali pa nas pokličite na 080 28 82.</p>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12 alert alert-danger" *ngIf="showCaptchaError" role="alert">
                                        <h2><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka pri procesiranju zahteve</h2>
                                        <p>Vaša zahteva žal ni bila sprocesirana zaradi težav na strežniku. Prosimo poskusite kasneje ali pa nas pokličite na 080 28 82. Hvala za razumevanje.</p>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12 alert alert-warning" *ngIf="showFormValidationError && (!form.valid || daljinskoOgrevanje.value !== true && zemeljskiPlin.value !== true)" role="alert">
                                        <h2><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Vneseni podatki so nepopolni</h2>
                                        <p>Obrazec je nepopolno izpolnjen. Prosimo preverite vnešene podatke. Nepopolno izpolnjena polja so označena z rdečo barvo.</p>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <button class="btn-blue btn-border-radius responsive-width mr-2" type="submit">
                                            <i class="fa fa-paper-plane-o" aria-hidden="true"></i> Pošlji
                                        </button>
                                        <a routerLink="../" class="btn-blue btn-border-radius border-only responsive-width">
                                            <i class="fa fa-times" aria-hidden="true"></i> Prekliči
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-klub-zvestoba-ogreva-minicity',
    templateUrl: './minicity.component.html',
    styleUrls: ['./minicity.component.scss']
})
export class KlubZvestobaOgrevaMinicityComponent implements OnInit {

    public showAdditionalTxt: boolean = false;

    constructor(
    ) { }

    ngOnInit() { }

    public toggleAdditionalTxt() {
        this.showAdditionalTxt = !this.showAdditionalTxt;
    }
}
<h1 mat-dialog-title>Prijava na obveščanje</h1>
<mat-dialog-content>
  <div class="container-fluid">
    <form [formGroup]="form">
      <p>Prijavite se na mesečno obveščanje!</p>

      <div class="form-group">
          <label for="naziv" >Ime in priimek <strong>*</strong></label>
          <input id="naziv" type="text" [formControl]="naziv" class="form-control" required autocomplete="name" [ngClass]="{ 'error-border': naziv?.dirty && !naziv?.valid }">
        <p role="alert" *ngIf="naziv?.dirty && naziv?.errors?.required" class="help-block error"><em>Vnesite ime in priimek</em></p>
        <p role="alert" *ngIf="naziv?.dirty && naziv?.errors?.maxlength" class="help-block error"><em>Vnos je predolg, dolžina je omejena na 300 znakov</em></p>
      </div>

      <div class="form-group">
        <label for="email">E-poštni naslov <strong>*</strong></label>
        <input id="email" type="text" [formControl]="email" class="form-control" required [ngClass]="{ 'error-border': email?.dirty && !email?.valid }">
        <p role="alert" *ngIf="email?.dirty && email?.errors?.required" class="help-block error"><em>Vnesite e-poštni naslov</em></p>
        <p role="alert" *ngIf="email?.dirty && email.errors?.email" class="help-block error"><em>Vnesite pravilen e-poštni format</em></p>
        <p role="alert" *ngIf="email?.dirty && email.errors?.maxlength" class="help-block error"><em>Vnos je predolg, dolžina je omejena na 254 znakov</em></p>
      </div>

      <div role="group" aria-labelledby="prejematiLabel">
        <label id="prejematiLabel">Prejemati želim tudi:</label>
        <div class="form-group form-check mb-0">
          <input id="anketiranjeProfiliranje" type="checkbox" class="form-check-input" [formControl]="anketiranjeProfiliranje">
          <label for="anketiranjeProfiliranje" class="form-check-label">Anketiranje in profiliranje</label>
        </div>
        <div class="form-group form-check mb-0">
          <input id="akcijeNovosti" type="checkbox" class="form-check-input" [formControl]="akcijeNovosti">
          <label for="akcijeNovosti" class="form-check-label">Obvestila o akcijah, novostih, nagradnih igrah</label>
        </div>
        <div class="form-group form-check mb-0">
          <input id="kzo" type="checkbox" class="form-check-input" [formControl]="kzo">
          <label for="kzo" class="form-check-label">Ugodnosti Kluba Zvestoba ogreva</label>
        </div>
      </div>

    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-blue btn-border-radius outline-offset responsive-width mr-2" (click)="formSubmit($event)" type="button"><i class="fa fa-check" aria-hidden="true"></i> Potrdi</button>
  <button class="btn btn-blue border-only btn-border-radius responsive-width" type="button" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i> Zapri</button>
</mat-dialog-actions>

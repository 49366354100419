import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {CookiesService} from '../../../services/cookies.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'quick-access',
    templateUrl: './quick-access.component.html',
    styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent implements OnInit{

  themeClass = 'theme-wcag';

  @ViewChild('quickAccessElement', {static: true}) quickAccessElement: ElementRef;

    constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      private cookiesService: CookiesService
    ) {
    }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId))
      return;
    
    this.themeClass = this.cookiesService.loadAccessibilityTheme();
  }

  public focusOnQuickAccessMenu() {

    setTimeout(() => {
      this.quickAccessElement.nativeElement.focus();
    },100);
  }


  getZvestobaImage() {
    switch (this.themeClass) {
      case 'theme-wcag':
      case 'theme-normal':
      case 'theme-white-black':
        return "assets/images/ikone/zvestoba-ogreva-klub-transparent.png";
      case 'theme-black-white':
      case 'theme-black-beige':
      case 'theme-black-yellow':
        return "assets/images/ikone/wcag/zvestoba-ogreva-klub-black.png";
      case 'theme-yellow-blue':
        return "assets/images/ikone/wcag/zvestoba-ogreva-klub-yellow.png";
      case 'theme-lime-black':
        return "assets/images/ikone/wcag/zvestoba-ogreva-klub-green.png";
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MetadataService } from '../../../../services/metadata.service';

import { Naslov } from '../../../../models/sms-obvescanje.model'

@Component({
    selector: 'app-narocilo-sms-obvescanje-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class NarociloSmsObvescanjeOrderComponent implements OnInit {

    public naslovId: number;

    constructor(
        private route: ActivatedRoute,
        private metadataService: MetadataService
    ) { }

    ngOnInit() {

        this.route.params.subscribe(p => {
            
            if (p.id)
                this.naslovId = parseInt(p.id)
        });
    }

    public onNaslovSelected(naslov: Naslov) {

        this.metadataService.setTitle('SMS obvestila o delih na omrežju');
        this.metadataService.setDescription('SMS obvestila o delih na omrežju na naslovu ' + naslov.street + ' ' + naslov.number + ', ' + naslov.municipality + '.');
        this.metadataService.setKeywords('narocilo,energetika ljubljana,akcija,zemeljski plin,elektricna energija,toplotno ogrevanje,daljinsko ogrevanje,elektrika,plin,toplota'.split(','));
        this.metadataService.setImage('assets/images/sms-obvescanje.jpg', undefined, 749, 500);
    }
}
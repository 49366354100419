<main id="content">
    <section class="content">
        <div class="py-0 py-md-3 py-lg-5 py-xl-7 pt-3 border-top">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 unsubscribe">
                        <h1>
                            Hvala za vaše zaupanje
                        </h1>
                        <p>
                            {{ contentTypes[type] }}
                        </p>
                        <div class="accident">
                            <h2><i class="fa fa-info-circle" aria-hidden="true"></i> Ste se izpisali po nesreči?</h2>
                            <p>Kliknite na spodnji gumb za ponovni vpis na seznam naročnikov.</p>
                            <div *ngIf="type === 'novice'" class="createsend-button" style="height:22px;display:inline-block;" data-listid="d/E9/50C/E27/97B0D55805861AE3">
                                Ponovni vpis</div>
                            <div *ngIf="type === 'stanje-plinomera'" class="createsend-button" style="height:22px;display:inline-block;" data-listid="d/44/1E1/657/AD9C33621B954439">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

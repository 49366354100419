import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { CmsService } from '../../../services/cms.service';
import { MetadataService } from '../../../services/metadata.service';
import { ThirdPartyService } from '../../../services/3rd-party.service';
import { CookiesService } from '../../../services/cookies.service';

import { NewsArticle } from '../../../models/cms.model';

@Component({
    selector: 'app-novice-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class NoviceDetailsComponent implements OnInit {

    @ViewChild('fbShareButton') set fbShareButtonElementRef(el: ElementRef) {
        if (el && isPlatformBrowser(this.platformId) && this.cookiesService.isConsentGiven('fb')) {
            el.nativeElement.setAttribute('data-href', this.getCurrLocation());
            this.thirdPartyService.fbSdkInit();
        }
    }

    @ViewChild('twitterShareButton') set twitterShareButtonElementRef(el: ElementRef) {
        if (el && isPlatformBrowser(this.platformId) && this.cookiesService.isConsentGiven('twitter')) {
            this.thirdPartyService.twitterSdkInit();
        }
    }

    @ViewChild('socials') set socialsElementRef(el: ElementRef) {
        if (el && isPlatformBrowser(this.platformId) && this.cookiesService.isConsentGiven('linkedin')) {
            let liEl = document.createElement('script');
            liEl.setAttribute('type', 'IN/Share');
            liEl.setAttribute('data-url', this.getCurrLocation());
            liEl.setAttribute('data-size', 'large');

            let containerEl = document.createElement('span');
            containerEl.setAttribute('class', 'linkedin');

            containerEl.appendChild(liEl);
            el.nativeElement.appendChild(containerEl);

            this.thirdPartyService.linkedinSdkInit();
        }
    }

    public cmsContentBasePath: string;

    public newsArticle: NewsArticle = null;
    public loading: boolean = true;

    public hasSocialsConsent: boolean = false;

    private id: number;
    private name: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService,
        private metadataService: MetadataService,
        private thirdPartyService: ThirdPartyService,
        private cookiesService: CookiesService
    ) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.route.params.subscribe(p => {
            this.id = parseInt(p.id);
            this.name = p.name;

            this.loadItem();
        });
    }

    public loadItem() {

        this.loading = true;
        let preview = this.route.snapshot.queryParams.predogled === 'true';

        this.cmsService.getNewsByIdAsync(this.id, this.name, preview)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(res => {
                this.newsArticle = res;

                this.metadataService.setTitle('Svetovalec: ' + res.naslov);
                this.metadataService.setOgTitle(res.naslov);
                this.metadataService.setDescription(res.seoDescription);
                this.metadataService.setKeywords(res.seoKeywords ? res.seoKeywords.split(',') : null);
                this.metadataService.setCanonicalUrl(this.router.url);

                let date = typeof res.aktivenOd === 'string' ? res.aktivenOd.substring(0, 10) :
                    res.aktivenOd.toISOString().substring(0, 10);
                let tags = res.kategorije.map(k => k.naziv).concat(res.oznake.map(o => o.naziv));
                this.metadataService.setImage(`${this.cmsContentBasePath}${res.slika.url}`, null, null, null, true);
                this.metadataService.setOgType('article');
                this.metadataService.setOgArticle('article', 'Svetovalec', date, tags);
            });
    }

    public getCurrLocation() {

        let url = this.router.url;

        if (url.startsWith('/'))
            url = url.substring(1);

        return `${environment.pageBaseUrl}/${url}`;
    }
}

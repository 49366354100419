<main id="content">
    <section class="content theme-2">
        <article>

            <div class="top-banner" style="background-image: url('assets/images/poslovni-odjem/background-top.jpg')">
                <div class="layer">
                    <div class="title">
                      <div class="title-bg">
                        <h1><strong>Energija za uspešno poslovanje</strong><br />poslovni odjem</h1>
                      </div>
                    </div>
                </div>
            </div>

            <div class="background-light-grey">
                <div class="container">
                    <div class="row content-block pt-5">
                        <div class="col-12 col-md-6">
                            <h2>Energetika Ljubljana - vaš zanesljivi partner.</h2>
                        </div>
                        <div class="col-12 col-md-6">
                            <p class="mb-0">Zagotovite vašemu poslovanju zanesljivo, varno, ekonomsko učinkovito in okolju prijazno oskrbo z energijo. Brezskrbnost in udobje vam zagotavljamo vse ure v dnevu in vse dni v letu. Ponudba energentov in energetskih rešitev po vaši meri.</p>
                        </div>
                    </div>
                    <div class="row content-block pb-0">
                        <div class="col-12 col-md-6">
                            <h2>Strokovnjaki za plin že več kot 155 let.</h2>
                        </div>
                        <div class="col-12 col-md-6">
                            <p class="mb-0">Proizvajamo, distribuiramo in dobavljamo vročo vodo, paro in hlad (toplotno energijo), distribuiramo in dobavljamo zemeljski plin, CNG, LNG ter proizvajamo in dobavljamo električno energijo iz visoko učinkovite soproizvodnje in obnovljivih virov energije.</p>
                        </div>
                    </div>
    <!--                <div class="row content-block pt-3">-->
    <!--                    <div class="col-12 col-md-6 offset-md-6">-->
    <!--                        <button class="btn btn-cta btn-w100 py-3" (click)="expand(null)">Pridobi ponudbo.</button>-->
    <!--                    </div>-->
    <!--                </div>-->
                </div>
            </div>

            <div class="background-light-grey">
                <div class="container">
                    <div class="row content-block pt-5">
    <!--                    <a (click)="selectStoritev('plin')" class="col-12 col-sm-6 col-md-3 tile mb-3 mb-md-0 text-center">-->
                        <div class="col-12 col-sm-6 col-md-3 tile mb-3 mb-md-0 text-center">
                            <img class="img-fluid" src="assets/images/poslovni-odjem/tile-plin.jpg" alt="Slika plin">
                            <div class="text"><span>Plin</span></div>
                        </div>
    <!--                    </a>-->
    <!--                    <a (click)="selectStoritev('elektrika')" class="col-12 col-sm-6 col-md-3 tile mb-3 mb-md-0 text-center">-->
                        <div class="col-12 col-sm-6 col-md-3 tile mb-3 mb-md-0 text-center">
                            <img class="img-fluid" src="assets/images/poslovni-odjem/tile-elektrika.jpg" alt="Slika električna energija">
                            <div class="text h-2"><span>Električna<br />energija</span></div>
                        </div>
    <!--                    </a>-->
                        <a tabindex="0" (keydown)="selectStoritev('ogrevanje')" (click)="selectStoritev('ogrevanje')" class="col-12 col-sm-6 col-md-3 tile mb-3 mb-md-0 text-center">
                            <img class="img-fluid" src="assets/images/poslovni-odjem/tile-ogrevanje.jpg" alt="Slika daljinsko ogrevanje">
                            <div class="text h-2"><span>Daljinsko<br />ogrevanje</span></div>
                        </a>
                        <a tabindex="0" (keydown)="selectStoritev('inzeniring')" (click)="selectStoritev('inzeniring')" class="col-12 col-sm-6 col-md-3 tile mb-3 mb-md-0 text-center">
                            <img class="img-fluid" src="assets/images/poslovni-odjem/tile-inzeniring.jpg" alt="Slika storitve in inženering">
                            <div class="text h-2"><span>Storitve in<br />inženiring</span></div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="background-light-grey" *ngIf="selectedStoritev">
                <div class="container">
                    <div class="row content-block pt-3">
                        <div class="col-12">
                            <div class="big-tile small-text text-left light pt-4 pb-3">
                                <ng-container [ngSwitch]="selectedStoritev">
                                    <ng-container *ngSwitchCase="'elektrika'">
                                        <h2 class="text-left text-uppercase"><strong>Elektrika</strong></h2>
                                        <p>Elektrika je najbolj vsestransko uporaben energent, ki ga ni mogoče nadomestiti z nobenim drugim virom energije. Osvetljuje prostore, napaja aparate, naprave, telefone, avtomobile… Brez elektrike si delovanja današnje družbe sploh ne moremo več zamisliti.</p>
                                        <p><strong>Zagotovite si elektriko za vaše poslovanje, ki je proizvedena na energetsko najbolj učinkovit način izrabe goriva, saj jo proizvajamo sočasno s toploto za potrebe sistema daljinskega ogrevanja.</strong></p>
                                        <p>Brezplačno za vas uredimo odnose z vašim dosedanjim dobaviteljem ter posredujemo pri sklepanju pogodb o dostopu do distribucijskega omrežja in pri plačilu uporabe distribucijskega omrežja. Pred koncem pogodbenega razmerja vas kontaktiramo, da skupaj poiščemo optimalne pogoje za naše nadaljnje sodelovanje.</p>
                                        <p>Ne glede na to kako veliko podjetje ste in s kakšno dejavnostjo se ukvarjate, bomo glede na vaše posebnosti poslovanja, za vas pripravili individualno ponudbo. Z nami boste <strong>prihranili čas</strong>, ki je v poslu še kako dragocen.</p>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'plin'">
                                        <h2 class="text-left text-uppercase"><strong>Zemeljski plin</strong></h2>
                                        <p>Ogrevanje na zemeljski plin predstavlja enega izmed najbolj gospodarnih načinov ogrevanja objektov.</p>
                                        <p>Z zemeljskim plinom po konkurenčnih cenah in ugodnih pogodbenih pogojih z veseljem oskrbimo vaš posel. Brezplačno za vas uredimo odnose z vašim dosedanjim dobaviteljem ter posredujemo pri sklepanju pogodb o dostopu do distribucijskega omrežja in pri plačilu uporabe distribucijskega omrežja. Pred koncem pogodbenega razmerja vas kontaktiramo, da skupaj poiščemo optimalne pogoje za naše nadaljnje sodelovanje.</p>
                                        <p>Ne glede na to kako veliko podjetje ste in s kakšno dejavnostjo se ukvarjate, bomo glede na vaše posebnosti poslovanja, za vas pripravili individualno ponudbo. Z nami boste prihranili čas, ki je v poslu še kako dragocen.</p>
                                        <p>Zemeljski plin je:</p>
                                        <ul>
                                            <li>vsestransko uporaben (ogrevanje in hlajenje prostorov, priprava sanitarne tople vode, kuhanje, pogon avtomobilov)</li>
                                            <li>z vidika emisij najčistejše fosilno gorivo</li>
                                            <li>cenovno konkurenčen</li>
                                            <li>varen za uporabo</li>
                                            <li>z visoko energijsko vrednostjo</li>
                                        </ul>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'ogrevanje'">
                                        <h2 class="text-left text-uppercase"><strong>Daljinsko ogrevanje</strong></h2>
                                        <p>Daljinski sistemi spadajo med najzanesljivejše, okoljsko in stroškovno sprejemljive sisteme oskrbe s toploto.</p>
                                        <ul>
                                            <li>ogrevanje, hlajenje, priprava tople sanitarne vode</li>
                                            <li>najudobnejši sistem ogrevanja</li>
                                            <li>prijazen do okolja</li>
                                            <li>preprosto obratovanje in vzdrževanje</li>
                                            <li>tih in zanesljiv sistem</li>
                                            <li>na voljo 24/7</li>
                                            <li>cenovno stabilen sistem</li>
                                            <li>možnost pridobitve nepovratne podpore za izvedbo</li>
                                        </ul>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'inzeniring'">
                                        <h2 class="text-left text-uppercase"><strong>Storitve in inženiring</strong></h2>
                                        <p>Zagotavljamo energetske vire in storitve, ki niso samo cenovno ugodni, ampak zagotavljajo kakovostno poslovanje ob najmanjšem možnem obremenjevanju okolja.</p>
                                        <p>Iščemo najugodnejše rešitve za vsak primer posebej.</p>
                                    </ng-container>
                                </ng-container>
                                <div class="row mt-4 align-items-center">
                                    <div class="col-12 col-md-6">
                                        <p class="mb-0">Stopite v stik z nami še danes in zahtevajte ponudbo!</p>
                                    </div>
                                    <div class="col-12 col-md-6 mt-3 mt-md-0">
                                        <button class="btn outline-inverse btn-cta btn-w100 py-3" (click)="expand(selectedStoritev)">Pridobi ponudbo.</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="background-light-grey">
                <div class="container">
                    <div class="row content-block pt-4 pb-5">
                        <div class="col-12">
                            <div class="big-tile text-center py-5">
                                <p>
                                    <strong>Čas je v poslovnem svetu pomemben.</strong><br />
                                    Stopite v kontakt z našim oddelkom za poslovni odjem še danes.
                                </p>
    <!--                            <p>-->
    <!--                                <strong>01 5889 107</strong>-->
    <!--                            </p>-->
                                <p class="mb-0">
                                    <strong><a class="outline-inverse" href="mailto:poslovni.odjem@energetika.si">poslovni.odjem&#64;energetika.si</a></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="expanded">

                <div class="background-light-grey arrow-down-white">
                    <div class="container"></div>
                </div>

                <div class="dodatne-informacije background-light-grey border-bottom" id="poslovniOdjemPovprasevanje">
                    <div class="container">
                        <form (ngSubmit)="posljiPovprasevanje($event)">
                            <div class="row">
                                <div class="col-12 col-lg-8 offset-lg-2 obrazec">
                                    <div *ngIf="showZakljucek">
                                        <h2 class="text-left"><i class="fa fa-check-circle" aria-hidden="true"></i> Povpraševanje je bilo uspešno oddano</h2>
                                        <p class="zakljucek">Vaše povpraševanje je bilo oddano. V najkrajšem možnem času vas bo kontaktiral naš svetovalec, ki Vam bo pripravil ponudbo.</p>
                                        <a class="btn-blue" routerLink="/">
                                            <i class="fa fa-home" aria-hidden="true"></i> Nazaj domov
                                        </a>
                                    </div>
                                    <div class="row my-5" *ngIf="loading">
                                        <div class="col-12">
                                            <loader></loader>
                                        </div>
                                    </div>
                                    <div *ngIf="!showZakljucek && !loading">
                                        <div class="row mb-2">
                                            <div class="col-12 col-md-8 col-lg-9 offset-md-4 offset-lg-3">
                                                <h3><i class="fa fa-user" aria-hidden="true"></i> Podatki o odjemalcu</h3>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="nazivPodjetja" class="col-12 col-form-label text-left">Naziv podjetja <strong>*</strong></label>
                                            <div class="col-12">
                                              <input id="nazivPodjetja" type="text" required [formControl]="nazivPodjetja" class="form-control" placeholder="Podjetje" [ngClass]="{ 'error-border': nazivPodjetja?.dirty && !nazivPodjetja.valid }">
                                              <p *ngIf="nazivPodjetja?.dirty && nazivPodjetja.errors?.required" class="help-block error" role="alert"><em>Vnesite pravilen naziv podjetja</em></p>
                                              <p *ngIf="nazivPodjetja?.dirty && nazivPodjetja.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 150 znakov</em></p>
                                            </div>
                                        </div>
                                        <div class="form-group row" role="group" aria-labelledby="naslovLabel">
                                            <label id="naslovLabel" class="col-12 col-form-label text-left">Naslov <strong>*</strong></label>
                                            <div class="col-8">
                                                <input title="Ulica" type="text" required [formControl]="ulica" class="form-control" placeholder="Ljubljanska cesta" [ngClass]="{ 'error-border': (ulica?.dirty || hisnaStevilka?.dirty) && !ulica?.valid }">
                                            </div>
                                            <div class="col-4">
                                                <input title="Hišna številka" type="text" required [formControl]="hisnaStevilka" class="form-control" placeholder="1a" [ngClass]="{ 'error-border': (ulica?.dirty || hisnaStevilka?.dirty) && !hisnaStevilka?.valid }">
                                            </div>
                                            <div class="col-12">
                                              <p *ngIf="(ulica?.dirty || hisnaStevilka?.dirty) && (!ulica?.valid || !hisnaStevilka?.valid)" class="help-block error" role="alert"><em>Vnesite pravilno ulico in hišno številko</em></p>
                                              <p *ngIf="ulica?.dirty && ulica.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 200 znakov</em></p>
                                            </div>
                                        </div>
                                        <div class="form-group row" role="group" aria-labelledby="postaLabel">
                                            <label id="postaLabel" class="col-12 col-form-label text-left">Pošta <strong>*</strong></label>
                                            <div class="col-7">
                                                <input title="Pošta" type="text" required [formControl]="posta" class="form-control" placeholder="Ljubljana" [ngClass]="{ 'error-border': (posta?.dirty || postnaStevilka?.dirty) && !posta?.valid }">
                                            </div>
                                            <div class="col-5">
                                                <input title="Poštna številka" type="number" required [formControl]="postnaStevilka" class="form-control" placeholder="1000" [ngClass]="{ 'error-border': (posta?.dirty || postnaStevilka?.dirty) && !postnaStevilka?.valid }">
                                            </div>
                                            <div class="col-12">
                                              <p *ngIf="(postnaStevilka?.dirty || posta?.dirty) && (!posta?.valid || !postnaStevilka?.valid)" class="help-block error" role="alert"><em>Vnesite pravilno ime pošte in pravilno poštno številko</em></p>
                                              <p *ngIf="posta?.dirty && posta.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 100 znakov</em></p>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="kontaktnaOseba" class="col-12 col-form-label text-left">Kontaktna oseba <strong>*</strong></label>
                                            <div class="col-12">
                                                <input id="kontaktnaOseba" type="text" required [formControl]="kontaktnaOseba" class="form-control" placeholder="Janez Novak" [ngClass]="{ 'error-border': kontaktnaOseba?.dirty && !kontaktnaOseba.valid }">
                                              <p *ngIf="kontaktnaOseba?.dirty && kontaktnaOseba.errors?.required" class="help-block error" role="alert"><em>Vnesite kontaktno osebo</em></p>
                                              <p *ngIf="kontaktnaOseba?.dirty && nazivPodjetja.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 150 znakov</em></p>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="tel" class="col-12 col-form-label text-left ">Telefon <strong>*</strong></label>
                                            <div class="col-12">
                                                <input id="tel" type="text" required [formControl]="telefonskaStevilka" class="form-control" placeholder="031 555 555" [ngClass]="{ 'error-border': telefonskaStevilka.dirty && !telefonskaStevilka.valid }">
                                              <p *ngIf="telefonskaStevilka?.dirty && telefonskaStevilka.errors?.required" class="help-block error" role="alert"><em>Vnesite pravilno telefonsko številko</em></p>
                                              <p *ngIf="telefonskaStevilka.dirty && telefonskaStevilka.errors?.pattern" class="help-block error" role="alert"><em>Vnesite pravilno telefonsko številko</em></p>
                                              <p *ngIf="telefonskaStevilka.dirty && telefonskaStevilka.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 20 znakov</em></p>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="eposta" class="col-12 col-form-label text-left">E-pošta <strong>*</strong></label>
                                            <div class="col-12">
                                                <input id="eposta" type="text" required [formControl]="email" class="form-control" placeholder="janez.novak@email.si" [ngClass]="{ 'error-border': email?.dirty && !email.valid }">
                                              <p *ngIf="email?.dirty && email.errors?.required" class="help-block error" role="alert"><em>Vnesite e-pošto</em></p>
                                              <p *ngIf="email?.dirty && email.errors?.email" class="help-block error" role="alert"><em>Vnesite pravilen e-poštni format</em></p>
                                              <p *ngIf="email?.dirty && email.errors?.maxlength" class="help-block error" role="alert"><em>Vnos je predolg, dolžina je omejena na 254 znakov</em></p>
                                            </div>
                                        </div>
                                        <div class="form-group row" role="group" aria-labelledby="zanimaLabel">
                                            <label id="zanimaLabel" class="col-12 col-form-label text-left">Zanima nas</label>
                                            <div class="col-12">
                                                <div class="form-group form-check mt-2 mb-0">
                                                    <input id="zemeljskiPlin" type="checkbox" class="form-check-input" [formControl]="zemeljskiPlin">
                                                    <label for="zemeljskiPlin" class="form-check-label">Zemeljski plin</label>
                                                </div>
                                                <div class="form-group form-check mb-0">
                                                    <input id="daljinskoOgrevanje" type="checkbox" class="form-check-input" [formControl]="daljinskoOgrevanje">
                                                    <label for="daljinskoOgrevanje" class="form-check-label">Daljinsko ogrevanje</label>
                                                </div>
                                                <div class="form-group form-check mb-0">
                                                    <input id="elektricnaEnergija" type="checkbox" class="form-check-input" [formControl]="elektricnaEnergija">
                                                    <label for="elektricnaEnergija" class="form-check-label">Električna energija</label>
                                                </div>
                                                <div class="form-group form-check mb-0">
                                                    <input id="para" type="checkbox" class="form-check-input" [formControl]="para">
                                                    <label for="para" class="form-check-label">Para</label>
                                                </div>
                                                <div class="form-group form-check mb-0">
                                                    <input id="drugo" type="checkbox" class="form-check-input" [formControl]="drugo">
                                                    <label for="drugo" class="form-check-label">Drugo</label>
                                                </div>
                                                <div class="mt-2" *ngIf="drugo.value">
                                                    <input title="Drugo opis" type="text" [formControl]="drugoOpis" class="form-control" [ngClass]="{ 'error-border': drugoOpis?.dirty && !drugoOpis.valid }">
                                                  <p *ngIf="drugoOpis?.dirty && !drugoOpis?.valid" class="help-block error" role="alert"><em>Vnesite opis</em></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!showZakljucek && !loading">
                                        <div class="row">
                                            <div class="col-12 col-md-8 col-lg-9 offset-md-4 offset-lg-3 mt-3">
                                                <p class="help-block">Polja označena z * so obvezna</p>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-9 offset-md-4 offset-lg-3 mt-3">
                                                <re-captcha (resolved)="captchaResolved($event)" [siteKey]="captchaKey"></re-captcha>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12 col-md-8 col-lg-9 offset-md-4 offset-lg-3 alert alert-warning" *ngIf="showGeneralError" role="alert">
                                                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka pri procesiranju zahteve</h4>
                                                <p>Vaša zahteva žal ni bila sprocesirana zaradi težav na strežniku. Prosimo poskusite kasneje ali pa nas pokličite na 080 28 82. Hvala za razumevanje.</p>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12 col-md-8 col-lg-9 offset-md-4 offset-lg-3 alert alert-danger" *ngIf="showCaptchaError" role="alert">
                                                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka pri procesiranju zahteve</h4>
                                                <p>Vaša zahteva žal ni bila sprocesirana, ker ne moremo zagotoviti, da niste robot. Prosimo, poskusite znova, ali pa nas pokličite na 080 28 82.</p>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12 col-md-8 col-lg-9 offset-md-4 offset-lg-3 alert alert-warning" *ngIf="showFormValidationError && !$valid" role="alert">
                                                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Vneseni podatki so nepopolni</h4>
                                                <p>Obrazec je nepopolno izpolnjen. Prosimo preverite vnešene podatke. Nepopolno izpolnjena polja so označena z rdečo barvo.</p>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12 col-md-8 col-lg-9 offset-md-4 offset-lg-3">
                                                <button class="btn-blue outline-offset" type="submit">
                                                    <i class="fa fa-paper-plane-o" aria-hidden="true"></i> Pošlji povpraševanje
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </article>
    </section>
</main>

<main id="content">
    <section class="content">
        <div class="py-3">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Pogoji uporabe</h1>
                        <p><strong>JAVNO PODJETJE ENERGETIKA LJUBLJANA d.o.o.</strong><br />Verovškova ulica 62<br />1000 Ljubljana<br />matična št. 5226406<br />ID za DDV SI 23034033<br />Tel.: (01) 588 90 00<br />Faks: (01) 588 91 09<br/><a href="mailto:info@energetika.si">info&#64;energetika.si</a></p>
                        <h2>Splošni pogoji uporabe in zasebnost</h2>
                        <p>Ti splošni pogoji urejajo način dostopa do spletnih strani ENERGETIKE LJUBLJANA d.o.o., način uporabe podatkov in dokumentov, uporabo spletnih storitev in odgovornosti ENERGETIKE LJUBLJANA d.o.o. in uporabnikov.</p>
                        <p>Informacije ENERGETIKA LJUBLJANA d.o.o. in njenih partnerjev na teh spletnih straneh so dane izključno v splošno izobraževalne in informativne namene. Določene informacije na teh spletnih straneh vsebujejo tudi splošne podatke o storitvah ENERGETIKA LJUBLJANA d.o.o. in njenih partnerjev in so tudi namenjene le splošni predstavitvi storitev, zaradi česar vsebujejo samo splošne informacije o le-teh. Vse informacije in podobe, vsebovane na teh spletnih straneh, so v zakonsko dovoljenem okviru predmet avtorske zaščite ali druge oblike zaščite intelektualne lastnine. Vse informacije, fotografije in podobe, ki jih vsebujejo spletne strani www.bivanjudajemoutrip.si, so v zakonsko dovoljenem okviru predmet avtorske zaščite ali druge oblike zaščite intelektualne lastnine.</p>
                        <p><strong>Ta spletni portal je intelektualna lastnina ENERGETIKA LJUBLJANA d.o.o. Vse informacije, podatki, dokumenti, opisi storitev ter druga vsebina, ki so na voljo na teh spletnih straneh, so zaščiteni z avtorskimi pravicami. Uporabniki ne smejo spreminjati, kopirati, distribuirati, prenašati, prikazovati, objavljati, prodajati, licencirati, preoblikovati v izvedene produkte ENERGETIKE LJUBLJANA d.o.o.  ali uporabljati katere koli vsebine, ki je dostopna na spletnem portalu ali preko njega, za komercialne ali javne namene.</strong></p>
                        <p>Vsa imena partnerjev in sodelavcev, ki se pojavljajo v besedilu na teh spletnih straneh v tisku, ki je drugačen od ostalega tiska ali s simbolom, znamko ali znakom, vključno z logotipom ENERGETIKA LJUBLJANA d.o.o. , so registrirane znamke, katerih imetnik ali uporabnik licence je ENERGETIKA LJUBLJANA d.o.o.,  ali partnerji s katerimi sodeluje. Uporaba teh znamk je izrecno prepovedana, razen s pisno privolitvijo ENERGETIKE LJUBLJANA d.o.o. oz. imetnika licence.</p>
                        <p>ENERGETIKA LJUBLJANA d.o.o. bo z vso skrbnostjo storila vse, da bi bile informacije na spletnih straneh točne in podatki ažurni, a za njihovo točnost in celovitost ne more jamčiti in ne prevzema nikakršne odgovornosti. ENERGETIKA LJUBLJANA d.o.o. si tudi pridržuje pravico, da kadarkoli spremeni vsebino teh spletnih strani, na kakršenkoli način, ne glede na razlog ter brez predhodnega opozorila. Vsi uporabniki vso objavljeno vsebino uporabljajo na lastno odgovornost.</p>
                        <p>NITI ENERGETIKA LJUBLJANA D.O.O. NITI KATERAKOLI DRUGA PRAVNA ALI FIZIČNA OSEBA, KI JE SODELOVALA PRI NASTANKU IN IZDELAVI TEH SPLETNIH STRANI, V NOBENEM PRIMERU NE ODGOVARJA TRETJIM OSEBAM ZA KAKRŠNOKOLI ŠKODO, KI BI IZHAJALA IZ ALI BI BILA V ZVEZI Z OBSTOJEM, DOSTOPOM DO IN/ALI UPORABO TEH SPLETNIH STRANI IN/ALI INFORMACIJ, KI JIH TE SPLETNE STRANI VSEBUJEJO IN/ALI ZARADI NEZMOŽNOSTI UPORABE INFORMACIJ NA TEH SPLETNIH STRANEH IN/ALI KAKRŠNEKOLI NAPAKE ALI POMANJKLJIVOSTI V NJIHOVI VSEBINI, NE GLEDE NA TO, ČE SO BILI OBVEŠČENI O MOŽNOSTI TAKE ŠKODE.</p>
                        <p>Te spletne strani lahko nudijo tudi povezave s spletnimi stranmi tretjih oseb in/ali vsebujejo informacije o tretjih osebah. Ker določene povezave na tej strani vodijo do virov, ki se nahajajo na strežnikih, ki jih vzdržujejo tretje osebe, nad katerimi ENERGETIKA LJUBLJANA d.o.o.  nima nadzora, ne moremo jamčiti in tudi ne prejemati ali posredovati pritožb glede točnosti katerekoli spletne strani, za katero nudimo povezavo ali referenco.</p>
                        <p>Nekatere informacije, ki jih imamo na našem spletnem strežniku so tiste, ki jih prostovoljno dajete preko formularjev, nekatere pa se zbirajo avtomatično. Za vsakega obiskovalca naših spletnih strani naš strežnik avtomatično prepozna ime obiskovalčevega področja, ne pa tudi njegovega elektronskega naslova. Zbiramo samo skupne informacije o tem, katere strani obiskovalci odpirajo in obiskujejo in o informacijah, ki jih obiskovalec posreduje, na primer pregledne informacije in/ali prijave na spletne strani. Analiziramo samo skupne informacije, ki jih posredujejo obiskovalci naših spletnih strani, da bi izboljšali vsebino in usluge, ki jih ponuja naša spletna stran.></p>
                        <p>Če bi se katerikoli obiskovalec naše spletne strani odzval s kakršnimikoli informacijami, bodo take informacije veljale za nezaupne in nezaščitene, pri čemer ENERGETIKA LJUBLJANA d.o.o. v zvezi z njimi ne bo imel nikakršne odgovornosti ter jih bo lahko prosto uporabljal, razmnoževal, razkrival in/ali širil drugim brez omejitev in brez navedbe vira, vključno, vendar ne omejeno, z idejami, koncepti, znanji, izkušnjami ali tehnikami, ki jih bodo take informacije vsebovale, za kakršenkoli namen, vključno, vendar ne omejeno, za razvoj, proizvodnjo in trženje proizvodov in/ali storitev, ki vsebujejo take informacije, razen v primeru izrecne navedbe obiskovalca, da naj se posredovane informacije štejejo za zaupne in/ali izrecne navedbe o prepovedi uporabe posredovanih informacij.</p>
                        <p>ENERGETIKA LJUBLJANA d.o.o. bo varovala osebne podatke (registriranih) uporabnikov, posredovane neposredno ENERGETIKI LJUBLJANA d.o.o. ter jih uporabljala pri posredovanju storitev in za potrebe, določene z zakonom. Uporabnik tako dovoljuje ENERGETIKI LJUBLJANA d.o.o., da njegove osebne podatke obdeluje v svojih zbirkah ter jih uporablja za namene trženja oziroma druge zakonsko dovoljene namene</p>
                        <h3>Piškotki</h3>
                        <p>Piškotki so male besedilne datoteke, ki jih večina spletnih mest shrani v naprave uporabnikov s katerimi dostopajo do interneta z namenom prepoznavanja posameznih naprav, ki so jih uporabniki uporabili pri dostopu. Njihovo shranjevanje je pod popolnim nadzorom brskalnika, ki ga uporablja uporabnik - ta lahko shranjevanje piškotkov po želji omeji ali onemogoči.</p>
                        <h4><strong>Zakaj so piškotki potrebni?</strong></h4>
                        <p>Najpogostejše funkcije e-poslovanja ne bi bile mogoče brez piškotkov. Interakcija med spletnim uporabnikom in spletno stranjo je s pomočjo piškotkov hitrejša in enostavnejša. Z njihovo pomočjo si spletna stran zapomni posameznikove preference in izkušnje, s tem je prihranjen čas, brskanje po spletnih straneh pa je s tem bolj učinkovito in prijetno. Piškotke uporablja večina spletnih strani, so priročno sredstvo za ohranjanje sveže in primerne vsebine, ki je v skladu z interesi in preferencami posameznega spletnega uporabnika. Razlogov za uporabo piškotkov je tako več. Kot prvi, njihova lastnost shranjevanja podatkov o stanju posamezne spletne strani (podrobnosti o prilagoditvah posamezne spletne strani), pomagajo pri izvajanju raznih spletnih storitev (spletne trgovine), pomagajo pri zbiranju raznih statistik, o navadah spletnega uporabnika – saj se le z njimi lahko spremlja pogostost obiskovanja določene spletne strani. Podjetja lahko s pomočjo piškotkov ocenijo učinkovitost zasnove svojih spletnih strani, kot tudi ustreznost vrste in števila oglasov, ki jih ponujajo uporabnikom na svojih spletnih straneh.</p>
                        <h4><strong>Katere podatke vsebuje spletno mesto?</strong></h4>
                        <table class="table">
                            <caption>Tabela piškotov, ki jih vsebuje spletno mesto</caption>
                            <thead>
                                <tr>
                                    <th scope="col">Podjetje</th>
                                    <th scope="col">Naziv piškotka</th>
                                    <th scope="col">Namen</th>
                                    <th scope="col">Trajanje</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Naše podjetje</td>
                                    <td>language</td>
                                    <td>Izbran jezikovni paket za spletno mesto</td>
                                    <td>1 mesec</td>
                                </tr>
                                <tr>
                                    <td>Naše podjetje</td>
                                    <td>currency</td>
                                    <td>Izbrana valuta spletnega mesta</td>
                                    <td>14 dni</td>
                                </tr>
                                <tr>
                                    <td>Naše podjetje</td>
                                    <td>CL_SIST</td>
                                    <td>Pogoji o piškotkih</td>
                                    <td>1 mesec</td>
                                </tr>
                                <tr>
                                    <td>Naše podjetje</td>
                                    <td>PHPSESSID</td>
                                    <td>Identifikacija uporabnikove seje</td>
                                    <td>Do konca seje</td>
                                </tr>
                                <tr>
                                    <td>Google Analitics</td>
                                    <td>_ga</td>
                                    <td>Analiza spletne atrani</td>
                                    <td>2 leti</td>
                                </tr>
                                <tr>
                                    <td>Google Analitics</td>
                                    <td>_gat</td>
                                    <td>Analiza spletne atrani</td>
                                    <td>Do konca seje</td>
                                </tr>
                            </tbody>
                        </table>
                        <h4><strong>Pobriši Piškotke</strong></h4>
                        <p>ENERGETIKA LJUBLJANA d.o.o. bo vse pridobljene podatke o obiskovalcih spletnih strani varovalo v skladu z zakonom o varstvu osebnih podatkov ter ostalo slovensko in evropski zakonodajo. S pošiljanjem osebnih podatkov preko obrazcev obiskovalec spletnih strani pristane, da ENERGETIKA LJUBLJANA d.o.o. te podatke uporablja za interne potrebe statistike in obdelave podatkov. Pri tem se podjetje zavezuje, da podatkov ne bo posredovalo tretjim osebam ali podjetjem s ciljem komercialnega izkoriščanja osebnih podatkov.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

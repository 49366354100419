<div class="row" *ngIf="!loading">
    <div *ngFor="let job of networkJobs" class="col-12">
        <div class="delo-na-omrezju">
            <a [routerLink]="['./', job.id]" class="heading">
                <h2>{{ job.naslov }}</h2>
            </a>
            <div class="date">
                {{ job.aktivenOd | date: 'dd. MM. yyyy' }}
            </div>
            <div class="tags" *ngIf="job.daljinskoOgrevanje || job.zemeljskiPlin">
                <span *ngIf="job.zemeljskiPlin" class="label label-primary">Plinovodno omrežje</span>
                <span *ngIf="job.daljinskoOgrevanje" class="label label-primary">Vročevodno omrežje</span>
            </div>
            <div class="caption" [innerHTML]="job.vsebina"></div>
            <a [routerLink]="['./', job.id]">Več informacij</a>
        </div>
    </div>
</div>


<div class="row" *ngIf="loading">
    <div class="col-12 my-5">
        <loader></loader>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="col-12">
            <div class="dotted-divider my-2"></div>
        </div>
        <div class="col-12 pb-4" *ngIf="networkJobs.length > 0 && !loading">
            <pagination [currentPage]="currentPage" [totalCount]="totalCount" (onPageChange)="changePage($event)"></pagination>
        </div>
    </div>

    <div class="col-12 list-no-results py-3" *ngIf="networkJobs.length === 0 && !loading">
        <p class="icon"><i class="fa fa-frown-o" aria-hidden="true"></i></p>
        <p class="text">Najdene ni bilo nobene novice pod izbranimi iskalnimi pogoji.</p>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { first, map } from 'rxjs/operators';

import { CmsService } from '../../../services/cms.service';

import { Document } from '../../../models/cms.model';
import {CookiesService} from '../../../services/cookies.service';

@Component({
    selector: 'app-klub-zvestoba-ogreva-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class KlubZvestobaOgrevaLandingComponent implements OnInit {

    public povprasevanjeParams: any = {
        refTitle: 'Klub Zvestoba ogreva',
        katId: 21,
        step: 4
    };

    public currentUgodnostiDoc: Document = null;
    public toplotaImage: string = 'assets/images/ikone/toplota.png';
    public elektrikaImage: string = 'assets/images/ikone/elektrika.png';
    public plinImage: string = 'assets/images/ikone/plin.png';

    constructor(
        private cmsService: CmsService,
        private cookiesService: CookiesService,
    ) { }

    ngOnInit() {

        this.cmsService.getKzoSeznamUgodnostiAsync()
            .pipe(
                map(res => res.length >= 1 ? res[0] : null),
                first()
            )
            .subscribe(res => {
                this.currentUgodnostiDoc = res;
            });

      this.cookiesService.selectedTheme$.subscribe(res => {
        switch (res) {
          case 'theme-wcag':
          case 'theme-normal':
            this.toplotaImage = "assets/images/ikone/toplota.png";
            this.elektrikaImage = "assets/images/ikone/elektrika.png";
            this.plinImage = "assets/images/ikone/plin.png";
            break;
          case 'theme-white-black':
            this.toplotaImage = "/assets/images/ikone/wcag/toplota-white-black.png";
            this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-white-black.png";
            this.plinImage = "/assets/images/ikone/wcag/plin-white-black.png";
            break;
          case 'theme-black-white':
            this.toplotaImage = "/assets/images/ikone/wcag/toplota-black.png";
            this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-black.png";
            this.plinImage = "/assets/images/ikone/wcag/plin-black.png";
            break;
          case 'theme-black-beige':
            this.toplotaImage = "/assets/images/ikone/wcag/toplota-beige.png";
            this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-beige.png";
            this.plinImage = "/assets/images/ikone/wcag/plin-beige.png";
            break;
          case 'theme-black-yellow':
            this.toplotaImage = "/assets/images/ikone/wcag/toplota-black-yellow.png";
            this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-black-yellow.png";
            this.plinImage = "/assets/images/ikone/wcag/plin-black-yellow.png";
            break;
          case 'theme-yellow-blue':
            this.toplotaImage = "/assets/images/ikone/wcag/toplota-yellow.png";
            this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-yellow.png";
            this.plinImage = "/assets/images/ikone/wcag/plin-yellow.png";
            break;
          case 'theme-lime-black':
            this.toplotaImage = "/assets/images/ikone/wcag/toplota-lime.png";
            this.elektrikaImage = "/assets/images/ikone/wcag/elektrika-lime.png";
            this.plinImage = "/assets/images/ikone/wcag/plin-lime.png";
            break;
          default:
            break;
        }
      });
    }

  getToplotaImage(): string {
    return this.toplotaImage;
  }

  getElektrikaImage(): string {
    return this.elektrikaImage;
  }

  getPlinImage(): string {
    return this.plinImage;
  }

}

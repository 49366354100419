<article>
    <div class="background-light-grey">
        <div class="container">
            <div class="row title">
                <div class="col-12">
                    <img class="img-fluid" [src]="getTopleTackeLogo()" alt="Slika s tekstom Tople tačke"/>
                </div>
            </div>
            <div class="row content-block description">
                <div class="col-12 col-lg-6 text-center">
                    <img class="img-fluid" [src]="getTopleTackeImage()" alt="Slika s psom in tekstom 'Da vašega kužka ne bo zeblo kot psa.'" />
                </div>
                <div class="col-12 col-lg-6 content-wrapper">
                    <div>
                        <p>Ker nam je pomembno, da se vsi prebivalci Ljubljane in okolice počutijo v svojem mestu dobro, smo se to zimo odločili poskrbeti za naše štirinožne prijatelje. Razvili smo grelne blazine Tople tačke in jih postavili na desetih lokacijah v Ljubljani in okolici v želji poskrbeti za toplo udobje naših kosmatih štirinožnih prijateljev. Med našim kramljanjem s prijatelji v lokalih, kjer so psi dobrodošli, pri nizkih temperaturah grelne blazine Tople tačke poskrbijo, da je našemu štirinožnemu prijatelju toplo in udobno. Tako bo tresenje vašega kosmatinca samo še oddaljeni neprijetni spomin.</p>
                        <p>Preizkusite naše Tople tačke in peljite svojega štirinožnega prijatelja na kavo. Naj bo aktivno preživljanje skupnega prostega časa prijetno za oba.</p>
                    </div>
                </div>
            </div>
            <div class="row content-block">
                <div class="col-12">
                    <h3 class="text-left text-lg-center">Lokacije, kjer najdete Tople tačke</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="background-blue-dark options-list no-title">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 offset-lg-3">
                    <p><strong><a class="outline-offset outline-inverse" href="https://www.facebook.com/GostilnicaKaval/" target="_blank">Gostilnica Kaval</a></strong></p>
                    <p><strong><a class="outline-offset outline-inverse" href="https://www.facebook.com/LolitaKavalGroup/" target="_blank">Lolita</a></strong></p>
                    <p><strong><a class="outline-offset outline-inverse" href="https://www.facebook.com/SpicaCaffe/" target="_blank">Špica</a></strong></p>
                    <p><strong>Kavarna MAO</strong></p>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block nagradna-igra pt-4">
                <div class="col-12 col-lg-6">
                    <h3 class="left-md">#topletacke</h3>
                </div>
                <div class="col-12 col-lg-6">
                    <p>Svoje foto utrinke lahko delite z nami na #TopleTacke in si zagotovite zelo praktične nagrade.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 social-plugin ig">
                    <div class="elfsight-app-0b0203b7-c9d1-40a2-9e05-efef5bd4b4c8"></div>
                </div>
            </div>
            <div class="row content-block">
                <div class="col-12 col-lg-6">
                </div>
                <div class="col-12 col-lg-6">
                    <p>Akcijo Tople tačke sta podprla velika ljubitelja psov pevka Alenka Godec in njena navihanka Chilly ter igralec in TV voditelj Anže Zevnik in lepotička z očmi različnih barv, Zela. Oba sta se že znašla v situacijah, kjer sta zaradi nelagodja in zmrzljivosti svojega psička svoj čas za kavo skrajšala in hitro odvihrala domov na toplo. Zahvaljujoč Toplim tačkam lahko sedaj s svojimi prijatelji, tudi štirinožnimi, bolj sproščeno uživajo v druženju na zimskih vrtovih v Centru Ljubljane in nekaterih okoliških krajih.</p>
                </div>
            </div>
            <div class="row content-block ljubitelj">
                <div class="col-12 col-md-6 text-center img-margin text-md-right">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/tople-tacke-ljubitelj-1.jpg" alt="Slika Tople tačke - ljubitelj s psom"/>
                </div>
                <div class="col-12 col-md-6 img-margin text-center-sm">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/tople-tacke-ljubitelj-2.jpg" alt="Slika Tople tačke - ljubitelj s psom"/>
                </div>
                <div class="col-12 col-md-6 text-center text-md-right img-margin-sm">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/tople-tacke-ljubitelj-3.jpg" alt="Slika Tople tačke - pes sedi na toplo tačko"/>
                </div>
                <div class="col-12 col-md-6 text-center">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/tople-tacke-ljubitelj-4.jpg" alt="Slika Tople tačke - ljubitelji s več psom"/>
                </div>
            </div>
            <div class="row content-block">
                <div class="col-12 col-lg-6 offset-lg-6">
                    <p>Spremljate novosti, prigode lastnikov in njihovih kužkov s Toplimi tačkami na naši <a href="https://www.facebook.com/energetika.ljubljana/" target="_blank">Facebook strani</a> in <a href="https://www.instagram.com/energetikaljubljana/" target="_blank">Instagram profilu</a>.</p>
                </div>
            </div>

            <div class="row content-block">
                <div class="col-12 col-lg-6">
                    <h3 class="left-md">#topletacke v medijih</h3>
                </div>
                <div class="col-12 col-lg-6">
                    <p>
                        <a href="https://www.sta.si/2609694/v-ljubljani-stirinozcev-ob-postankih-njihovih-lastnikov-na-kavi-ne-bo-vec-zeblo" target="_blank">sta.si</a><br/>
                        <a href="https://www.slovenskenovice.si/novice/slovenija/clanek/novica-za-lastnike-psov-v-ljubljani-vas-caka-novost-154395" target="_blank">slovenskenovice.si</a><br/>
                        <a href="https://www.dnevnik.si/1042876311" target="_blank">dnevnik.si</a><br/>
                        <a href="https://reporter.si/clanek/magazin/pasje-mrzlo-nic-vec-psi-se-bodo-v-ljubljani-poslej-greli-688811" target="_blank">reporter.si</a><br/>
                        <a href="https://siol.net/trendi/odnosi/ljubljana-ima-vse-vec-do-kuzkov-prijaznih-lokalov-491423" target="_blank">siol.net</a><br/>
                        <a href="https://micna.slovenskenovice.si/uzivanje-doma/ljubljana-ima-vse-vec-kuzkom-prijaznih-lokalov/" target="_blank">micna.slovenskenovice.si</a><br/>
                        <a href="http://www.etiketamagazin.com/lifestyle/ljubljana-ima-vse-vec-kuzkom-prijaznih-lokalov/" target="_blank">etiketamagazin.com</a><br/>
                        <a href="https://www.ljubljana.si/sl/aktualno/energetika-ljubljana-je-na-desetih-lokacijah-postavila-grelne-blazine-tople-tacke-za-ogrevanje-kosmatih-tack-hisnih-ljubljenckov/" target="_blank">ljubljana.si</a><br/>
                        <a href="https://style.over.net/ljubljana-ima-vse-vec-kuzkom-prijaznih-lokalov/" target="_blank">style.over.net</a><br/>
                        <a href="https://govori.se/zanimivosti/v-nekaterih-ljubljanskih-lokalih-stirinozce-ne-bo-vec-zeblo-za-njih-imajo-grelne-blazine/" target="_blank">govori.se</a><br/>
                        <a href="https://m.planet-lepote.com/ljubljana-ima-vse-vec-kuzkom-prijaznih-lokalov/" target="_blank">planet-lepote.com</a><br/>
                        <a href="https://lj-times.com/kuzkom-bo-toplo-tudi-med-druzenjem-v-hladnejsih-dneh/" target="_blank">lj-times.com</a><br/>
                        <a href="https://www.pasjagajba.com/sl/vsebine/blog" target="_blank">pasjagajba.com</a><br/>
                        <a href="https://www.mojpes.com/aktualno/ljubljana-ima-10-lokacij-z-grelnimi-blazinami-za-pse/" target="_blank">mojpes.com</a><br/>
                        <a href="https://www.pesmojprijatelj.si/clanek/ljubljana-je-dobila-grelne-blazine-za-pse" target="_blank">pesmojprijatelj.si</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

</article>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable, ReplaySubject, of } from 'rxjs';
import { map, reduce } from 'rxjs/operators';

import { NpnPaket, NpnPaketFlat, NpnPaketZnamka } from '../models/npn.model';

@Injectable()
export class AssetsDataService {

    private baseUrl: string = '/assets/data';

    private npnPaketSubject: ReplaySubject<NpnPaketFlat[]> = null;

    constructor(private http: HttpClient) {
    }

    public getNpnPaketi(izgradnja: boolean = null, pret: boolean = false, akBoj: boolean = false, kom: boolean = false, akNoBoj: boolean = false, pretStvVec: boolean = false,
        znamke: number[] = []): Observable<NpnPaketFlat[]> {
        return this.loadNpnPaketi().pipe(
            map(res => {
                return res.filter(p => {

                    let isVisible = false;

                    if (pret || akBoj || kom || akNoBoj || pretStvVec) {
                        if (pret) isVisible = p.pretocnaStv;
                        if (akBoj && !isVisible) isVisible = p.akumStvBojl;
                        if (kom && !isVisible) isVisible = p.akumStvSolar;
                        if (akNoBoj && !isVisible) isVisible = p.akumStvBrezBojl;
                        if (pretStvVec && !isVisible) isVisible = p.pretocnaStvVecstan;
                    } else {
                        isVisible = true;
                    }

                    isVisible = isVisible && (znamke.length === 0 || znamke.indexOf(p.znamkaId) !== -1) && (izgradnja === null || (izgradnja && p.izgradnja || !izgradnja && p.obnova));

                    return isVisible;
                });
            })
        )
    }

    public getNpnZnamke(): Observable<NpnPaketZnamka[]> {
        return of(npnZnamke);
    }

    private loadNpnPaketi(): Observable<NpnPaketFlat[]> {

        if (this.npnPaketSubject === null) {
            this.npnPaketSubject = new ReplaySubject(1);

          this.http.get<NpnPaket[]>(`${this.baseUrl}/npn-paketi-20231108.json`)
                .pipe(
                    map(v => this.flattenNpnPaketi(v))
                )
                .subscribe(res => {
                    this.npnPaketSubject.next(res);
                });
        }

        return this.npnPaketSubject.asObservable();
    }

    private flattenNpnPaketi(paketi: NpnPaket[]): NpnPaketFlat[] {

        return paketi.reduce((acc, p) => acc.concat(p.znamke.map(z => <NpnPaketFlat>{
            id: p.id,
            oznaka: p.oznaka,
            obnova: p.obnova,
            izgradnja: p.izgradnja,
            pretocnaStv: p.pretocnaStv,
            akumStvBojl: p.akumStvBojl,
            akumStvSolar: p.akumStvSolar,
            akumStvBrezBojl: p.akumStvBrezBojl,
            pretocnaStvVecstan: p.pretocnaStvVecstan,
            kotel: p.kotel,
            regulator: p.regulator,
            bojler: p.bojler,
            solar: p.solar,
            opis: z.opis,
            znamkaId: z.id,
            znamkaIme: npnZnamke.find(nz => nz.id === z.id).ime,
            kotelOpis: z.kotelOpis,
            regulatorOpis: z.regulatorOpis,
            bojlerOpis: z.bojlerOpis,
            solarOpis: z.solarOpis,
            financniNajem: z.financniNajem,
            toplotnaCrpalka: z.toplotnaCrpalka,
            toplotnaCrpalkaOpis: z.toplotnaCrpalkaOpis,
            zunanjaKondenzacijskaEnota: z.zunanjaKondenzacijskaEnota,
            zunanjaKondenzacijskaEnotaOpis: z.zunanjaKondenzacijskaEnotaOpis,
            cena: z.cena,
            h2ready: z.h2ready
        })), []);
    }
}

const npnZnamke: NpnPaketZnamka[] = [{
    id: 1,
    ime: 'Beretta'
}, {
    id: 2,
    ime: 'Unical'
}, {
    id: 3,
    ime: 'Immergas'
}, {
    id: 4,
    ime: 'Bosch'
}, {
    id: 5,
    ime: 'Viessmann'
}, {
    id: 6,
    ime: 'Radiant'
}, {
    id: 7,
    ime: 'Vaillant'
}];

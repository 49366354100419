import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import {
    AdditionalService,
    AdditionalServices,
    CalculationInput,
    CalculationOutput
} from '../models/calculation.model';

@Injectable()
export class CalculationService {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.serviceBasePath}/informativni-izracun`;
    }

    public getAdditionalServices(params: any, zp: boolean = null, ee: boolean = null, to: boolean = null, akcijaId: number = null): AdditionalServices {

        let countEnergenti = 0;

        if (zp === true)
            countEnergenti++;

        if (ee === true)
            countEnergenti++;

        if (to === true)
            countEnergenti++;

        let obj: AdditionalServices = {};

        for (let key in additionalServices) {
            let service: AdditionalService = _.cloneDeep(additionalServices[key]);

            service.selected = params[service.param] === 'true' || service.id === 1;

            if (akcijaId === 22) {
              service.description = 'V kolikor že prejemate račun za drug energent, računa lahko združite';
            }

            if ((zp !== null && service.zpOnly && !zp) || (akcijaId === 22)) {
                service.selected = false;
            }

            if (service.minEnergentiCnt && service.minEnergentiCnt > countEnergenti && akcijaId !== 22) {
                service.selected = false;
                service.description = 'Za izbiro dodatne storitve je potrebno izbrati več energentov';
            }

            obj[key] = service;
        }

        return obj;
    }

    public isZemljskiPlinOgrevanjeChoosen(params: any): boolean {
        return params.zpOgr === 'true';
    }

    public isZemeljskiPlinStoritevChoosen(params: any): boolean {
        return params.zpOgr === 'true' || params.zpStv === 'true' || params.zpKuh === 'true';
    }

    public isDaljinskoOgrevanjeStoritevChoosen(params: any): boolean {
        return params.doOgr === 'true' || params.doStv === 'true';
    }

    public isElektricnaEnergijaStoritevChoosen(params: any): boolean {
        return params.eeTar === 'et' || params.eeTar === 'dt';
    }

    public paramsToDtoConverter(params: any): CalculationInput {

        let data = <CalculationInput>{
            gospodinjstvoStOseb: params.stOseb ? parseInt(params.stOseb) : null,
            gospodinjstvoBivalisceTip: params.bivTip ? parseInt(params.bivTip) : null,
            gospodinjstvoBivalisceVelikost: params.bivVel ? parseInt(params.bivVel) : null,
            storitve: [],
            dodatneStoritve: []
        };

        let eeIzbran = false;
        let ogrIzbrano = false;

        if (params.eeTar && (params.eeTar === 'et' || params.eeTar === 'dt')) {
            data.storitve.push(params.eeTar === 'et' ? 1 : 2);
            eeIzbran = true;
        }

        if (params.doOgr === 'true') {
            data.storitve.push(3);
            ogrIzbrano = true;
        }

        if (params.doStv === 'true')
            data.storitve.push(4);

        if (params.zpOgr === 'true') {
            data.storitve.push(6);
            ogrIzbrano = true;
        }

        if (params.zpStv === 'true')
            data.storitve.push(7);

        if (params.zpKuh === 'true')
            data.storitve.push(5);

        for (let key in additionalServices) {
            if (additionalServices[key].id === 1 || (!additionalServices[key].zpOnly || this.isZemljskiPlinOgrevanjeChoosen(params)) &&
                params[additionalServices[key].param] && params[additionalServices[key].param] === 'true')
                data.dodatneStoritve.push(additionalServices[key].id);
        }

        if (data.gospodinjstvoStOseb === null || data.gospodinjstvoBivalisceVelikost === null || data.gospodinjstvoBivalisceTip === null ||
            !eeIzbran || !ogrIzbrano)
            return null;
        else
            return data;
    }

    public calculate(obj: CalculationInput): Observable<CalculationOutput> {
        return this.http.post<CalculationOutput>(this.baseUrl, obj);
    }
}

const additionalServices: AdditionalServices = {
    zdruzenRacun: {
        id: 1,
        title: 'Združen račun',
        description: 'Dodatna storitev je že avtomatsko vključena.',
        zpOnly: false,
        minEnergentiCnt: 2,
        param: 'zdrRac'
    },
    financniNajem: {
        id: 2,
        title: 'Finančni najem',
        description: 'Ugodno odplačevanje investicije s finančnim najemom',
        zpOnly: false,
        dependentId: 3,
        param: 'finNaj'
    },
    kredit: {
        id: 3,
        title: 'Posojilo',
        description: 'Posojilo s subvencionirano obrestno mero',
        zpOnly: false,
        dependentId: 2,
        param: 'kre'
    },
    plinskiPaket: {
        id: 4,
        img: 'cng',
        title: 'Paket za ogrevanje na plin',
        description: 'Cenovno ugodni Paketi ogrevanja na zemeljski plin "na ključ"',
        zpOnly: true,
        param: 'plinPak'
    },
    eRacun: {
        id: 5,
        img: 'e-racun',
        title: 'E-račun',
        description: 'Prejemanje e-računa na želeni el. naslov',
        zpOnly: false,
        param: 'eRac'
    },
    trajnik: {
        id: 10,
        img: 'trajnik',
        title: 'Trajnik',
        description: 'Poenostavljeno plačevanje računa preko trajnika oziroma direktne bremenitve',
        zpOnly: false,
        param: 'traj'
    },
    brezgotovniskoPlacevanje: {
        id: 6,
        img: 'brezgotovinsko-placevanje',
        title: 'Brezprovizijsko plačevanje',
        description: 'Plačevanje stroškov storitev Energetike Ljubljana brez provizije',
        zpOnly: false,
        param: 'bgp'
    },
    enakomerniMesecniObrok: {
        id: 7,
        img: 'enakomerni-mesecni-obrok',
        title: 'Enakomerni mesečni obrok',
        description: 'Letna poraba zemeljskega plina je lahko razdeljena na 12 enakih delov',
        zpOnly: true,
        param: 'emo'
    },
    brezplacnoSvetovanje: {
        id: 8,
        img: 'svetovanje',
        title: 'Brezplačno svetovanje',
        description: 'Naši svetovalci so vam na voljo za brezplačno svetovanje o ponudbi Energetike Ljubljana',
        zpOnly: false,
        param: 'brSv'
    },
    subvencije: {
        id: 9,
        img: 'subvencije',
        title: 'Subvencije',
        description: 'Nepovratne finančne spodbude za ukrepe v večjo energetsko učinkovitost v letu 2016',
        zpOnly: false,
        param: 'sub'
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { RecaptchaComponent } from 'ng-recaptcha';

import { environment } from '../../../../../environments/environment';

import { SmsObvescanjeService } from '../../../../services/sms-obvescanje.service';

import { NaslovNarocnikInput } from '../../../../models/sms-obvescanje.model';

@Component({
    selector: 'app-narocilo-sms-obvescanje-brez-naslova',
    templateUrl: './brez-naslova.component.html',
    styleUrls: ['./brez-naslova.component.scss']
})
export class NarociloSmsObvescanjeBrezNaslovaComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptchaCmp: RecaptchaComponent;

    public form: UntypedFormGroup;

    public loading: boolean = false;
    public showZakljucek: boolean = false;
    public showGeneralError: boolean = false;
    public showCaptchaError: boolean = false;
    public showEnergentiError: boolean = false;
    public showFormValidationError: boolean = false;

    public get ime() { return this.form.get('ime') }
    public get priimek() { return this.form.get('priimek') }
    public get naslov() { return this.form.get('naslov') }
    public get email() { return this.form.get('email') }
    public get telefonskaStevilka() { return this.form.get('telefonskaStevilka') }
    public get daljinskoOgrevanje() { return this.form.get('daljinskoOgrevanje') }
    public get zemeljskiPlin() { return this.form.get('zemeljskiPlin') }

    public captchaKey: string = environment.captchaKey;
    private captchaResponse: string;

    private naslovId: number = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private smsObvescanjeService: SmsObvescanjeService
    ) { 

        this.form = this.fb.group({
            ime: ['', [Validators.required, Validators.maxLength(100)]],
            priimek: ['', [Validators.required, Validators.maxLength(100)]],
            naslov: ['', [Validators.required, Validators.maxLength(300)]],
            email: ['', [Validators.required, Validators.maxLength(254), Validators.email]],
            telefonskaStevilka: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^[0-9-/+() ]+$/)]],
            daljinskoOgrevanje: [false],
            zemeljskiPlin: [false]
        });

        this.daljinskoOgrevanje.valueChanges.subscribe(val => {
            this.showEnergentiError = !this.areEnergentiValid();
        });

        this.zemeljskiPlin.valueChanges.subscribe(val => {
            this.showEnergentiError = !this.areEnergentiValid();
        });
    }

    public ngOnInit() { 

        this.activatedRoute.queryParams.subscribe(qp => {
            
            if (qp.naslovId) {
                this.naslovId = parseInt(qp.naslovId);

                this.loadNaslov();
            }
        });
    }

    public captchaResolved(captchaResponse: string) {
        this.captchaResponse = captchaResponse;
    }

    public submit(event: Event) {

        if (this.loading)
                return;

        this.showCaptchaError = false;
        this.showGeneralError = false;
        this.showEnergentiError = false;

        if (this.form.valid && this.areEnergentiValid() && this.captchaResponse) {
            this.loading = true;

            let narocilo: NaslovNarocnikInput = {
                recaptchaResponse: this.captchaResponse,
                naslov: {
                    ime: this.ime.value,
                    priimek: this.priimek.value,
                    naslov: this.naslov.value,
                    email: this.email.value,
                    telefonskaStevilka: this.telefonskaStevilka.value,
                    daljinskoOgrevanje: this.daljinskoOgrevanje.value,
                    zemeljskiPlin: this.zemeljskiPlin.value
                }
            };

            this.smsObvescanjeService.postMissingAddress(narocilo)
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe(res => {
                    this.showZakljucek = true;
                }, err => {

                    if (err.status === 403) {
                        this.showCaptchaError = true;
                    } else {
                        this.showGeneralError = true;
                    }

                    this.recaptchaCmp.reset();
                });
        } else {
            event.preventDefault();

            for (let key in this.form.controls) {
                this.form.controls[key].markAsDirty();
            }

            if (!this.areEnergentiValid())
                this.showEnergentiError = true;

            this.recaptchaCmp.reset();
            this.showFormValidationError = true;
        }
    }

    private loadNaslov() {

        this.smsObvescanjeService.getNaslov(this.naslovId).subscribe(res => {

            this.naslov.setValue(`${res.street} ${res.number}, ${res.municipality}`);
            this.naslov.updateValueAndValidity();
        });
    }

    private areEnergentiValid(): boolean {
        return this.daljinskoOgrevanje.value === true || this.zemeljskiPlin.value === true;
    }
}
import { Component, OnInit } from '@angular/core';
import { RazlagaRacunaEnergent } from '../razlaga-racuna.component';

@Component({
  selector: 'app-razlaga-racuna-base',
  templateUrl: './razlaga-racuna-base.component.html',
  styleUrls: ['./razlaga-racuna-base.component.scss']
})
export class RazlagaRacunaBaseComponent implements OnInit {

  aktivno: RazlagaSegment = RazlagaSegment.NONE;
  razlagaSegmentEnum = RazlagaSegment;
  razlagaEnergentEnum = RazlagaRacunaEnergent;

  constructor() { }

  ngOnInit() {
  }

  accessibillityAktiviraj(event: KeyboardEvent, segment: RazlagaSegment) {
    if (event) {
      if (event.code === 'Enter') {
        this.aktiviraj(segment);
      }
    }
  }

  aktiviraj(segment: RazlagaSegment) {
    this.aktivno = this.aktivno === segment ? RazlagaSegment.NONE : segment;
  }

  zapri() {
    this.aktiviraj(RazlagaSegment.NONE);
  }

}

enum RazlagaSegment {
  NONE,
  GLAVA,
  ODM_PODATKI,
  KLJUCNI_PODATKI,
  SPECIFIKACIJA,
  GRAFI
}

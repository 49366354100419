import { Directive, OnInit, ElementRef, Input, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit {

    @Output()
    public clickOutside = new EventEmitter();

    @Input()
    public blockEmits: boolean = false

    constructor(
        private elementRef: ElementRef,
    ) { }

    public ngOnInit() { }
 
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {

        if (this.blockEmits)
            return;

        let clickedInside = this.elementRef.nativeElement.contains(targetElement);

        if (!clickedInside)
            this.clickOutside.emit(targetElement);
    }
}
<article>
    <div class="top-background">
        <div class="subpage-title">
            <h1>Okrog edinega sveta<br />
            <strong>za ohranjanje njegove edinstvenosti</strong></h1>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block py-4 py-md-6">
                <div class="col-12 col-lg-6">
                    <h3 class="left-md">Matevž Lenarčič, Okrog edinega sveta, Didakta 2006</h3>
                </div>
                <div class="col-12 col-lg-6">
                    <p>"Kot kaže, je naš planet v vesolju edini daleč naokrog, kjer so pogoji za življenje, kakršnega živimo zemljani, primerni. Zelo težko je razumeti, da na njem prebiva več milijard ljudi, ki se sploh ne zavedajo, da nimajo kam zbežati, če ga uničijo. Skupaj se peljemo na okrušku vesolja v razsežnost, kjer naša umska in materialna naprezanja ne pomenij o nič.</p>
                    <p class="mb-0">Ujeti smo v minljivosti in drvimo zanesljivemu koncu naproti. Vse nas čaka enaka usoda. Biti prvi ali zadnji, ne pomeni nič. Pomenita samo ljubezen in spoštovanje do sebe in drugih. Pomenijo lahko samo naša dejanja, ki ostanejo zapisana v srcih zanamcev. Zato bi moral vsak od nas enkrat na pot okoli sveta, da bi spoznal, da smo ujeti v čudoviti kletki, iz katere ni izhoda. Vsako dejanje, dobro ali slabo, os tane v njej."</p>
                </div>
            </div>
        </div>
    </div>

    <div class="options-list no-title py-6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="text-notice">Matevž Lenarčič je izkušen pilot, diplomiran biolog, alpinist in fotograf.</p>
                    <p class="text-notice"><strong>Okoljska misija GreenLight WorldFlight 2012-2020 preučuje vplive črnega ogljika na globalne podnebne spremembe.</strong></p>
                    <p class="pb-0 text-notice">Energetika Ljubljana je zeleni partner projekta.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey pt-4 pt-md-6">
        <div class="container">
            <div class="row content-block pics">
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-01.jpg" alt="Slika Gora Olga" />
                    <p>
                      <span>Gora Olga, najvišji vrh v nacionalnem parku Uluru – Kata Tjuta v Avstraliji<br />Matevž Lenarčič, 2018</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-02.jpg" alt="Slika črni dim nad Kairom" />
                    <p>
                      <span>Črn dim nad Kairom<br />Matevž Lenarčič, 2016</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-03.jpg" alt="Slika otoka Moorea" />
                    <p>
                      <span>Otok Moorea zahodno od Tahitija v Francoski Polineziji<br />Matevž Lenarčič, 2018</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-04.jpg" alt="Slika plasti črnega ogljika v ozračju nad Malezijo" />
                    <p>
                      <span>Plast črnega ogljika v ozračju nad Malezijo<br />Matevž Lenarčič, 2012</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-05.jpg" alt="Slika vrha Hornsundtind"/>
                    <p>
                      <span>Hornsundtind, 1435 m, na otočju Svalbard<br />Matevž Lenarčič, 2018</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-06.jpg" alt="Slika krčenja ledenikov na Antartiki" />
                    <p>
                      <span>Krčenje ledenikov, Antarktika<br />Matevž Lenarčič, 2012</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-07.jpg" alt="Slika narodnega parka West Side" />
                    <p>
                      <span>Narodni park West Side na otoku Andros na Bahamih<br />Matevž Lenarčič, 2018</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-08.jpg" alt="Slika plasti črnega ogljika v ozračju nad Kongom"/>
                    <p>
                      <span>Plast črnega ogljika v ozračju nad Kongom<br />Matevž Lenarčič, 2012</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-09.jpg" alt="Slika brezovi gaji med polji" />
                    <p>
                      <span>Brezovi gaji med polji zahodno od Omska<br />Matevž Lenarčič, 2018</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-10.jpg" alt="Slika izpodrivanje puščave s poljodeljstvom"/>
                    <p>
                      <span>Izpodrivanje puščave s poljedelstvom, Libija<br />Matevž Lenarčič, 2012</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-11.jpg" alt="Slika Jeza Tamesna" />
                    <p>
                      <span>Jez Tamesna na reki Oued Zamrin južno od Rabata – Maroko<br />Matevž Lenarčič, 2018</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 img-margin position-relative">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-pic-12.jpg" alt="Slika izkoriščanja naravnega bogatstva v ZDA" />
                    <p>
                      <span>Izkoriščanje naravnega bogastva v ZDA<br />Matevž Lenarčič, 2012</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block pt-3 pt-md-4 pb-3">
                <div class="col-12 col-lg-6">
                    <h3 class="left-md">Pogovor s pilotom Matevžem Lenarčičem</h3>
                    <h3 class="left-md mt-lg-5">"Pomemben je obrat v miselnosti in dojemanju sveta."</h3>
                </div>
                <div class="col-12 col-lg-6">
                    <p>Matevž Lenarčič je pilot, okoljevarstvenik, fotograf, alpinist ... Je mož zelo jasnih nazorov in odločnih korakov. Ali bolje rečeno, odločnih, pogumnih letov … Iz ptičje perspektive, pravi, ima naš planet neko prav posebno lepoto. Tam zgoraj je čisto sam, a nikoli osamljen. Ob zavedanju, da je naše bivanje na tem svetu časovno zelo omejeno, užije sleherno njegovo podobo in z njimi prenaša sporočila o pomenu strpnosti, sonaravnega bivanja in spoštovanja.</p>
                </div>
            </div>
            <div class="row content-block">
                <div class="col-12 col-lg-6">
                    <div class="alert-box mb-3">"Če ne bomo naredili nič, se bo planet do leta 2045 segrel za več kot dve stopinji Celzija. Če bi odstranili črni ogljik, bi se to obdobje zamaknilo skoraj do leta 2065."</div>
                </div>
                <div class="col-12 col-lg-6 extendable-content-container" [class.extended]="interviewExtended">
                    <div class="content-wrapper mt-0 text-justify">
                        <p class="mb-0"><strong>Pred vami je nova pot okoli sveta: skoraj 45 tisoč kilometrov boste preleteli z zgolj 13-imi postanki, vse s točno določenim okoljevarstvenim namenom – merili boste vsebnost črnega ogljika v atmosferi. Zakaj?</strong></p>
                        <p>Iz več razlogov. Prvi je, kot smo že večkrat izpostavili, ker je črni ogljik kot onesnaževalec nekoliko zanemarjen in ker nikoli do zdaj niso bila opravljene meritve na tako široki, globalni ravni. Pri prejšnjih potovanjih smo že opravili meritve na različnih delih planeta, nekatere med njimi so prinesle presenetljive podatke, visoke vrednosti črnega ogljika na krajih, kjer jih ne bi niti pričakovali. Take primere smo zasledili tudi v bližini Severnega tečaja, kar dokazuje, da je problem črnega ogljika veliko več kot nekakšen lokalni okoljski problem.</p>
                        <p class="mb-0"><strong>A lahko rečeva, da se je cel svet do sedaj preveč ukvarjal z ogljikovim dioksidom in ob tem zanemaril to prežečo nevarnost iz sence?</strong></p>
                        <p>Ogljikov dioksid je še vedno ključni onesnaževalec. Črni ogljik je drugi po vrsti. Glede na posledice sta primerljiva. Razlika je v tem, da je življenjska doba ogljikovega dioksida sto let, življenjska doba črnega ogljika pa je od kakšnega tedna do meseca. Če bi nam uspelo odstraniti črni ogljik iz ozračja, bi si s tem zelo hitro kupili čas za zmanjšanje emisij ogljikovega dioksida. Če ne bomo naredili nič, se bo planet do leta 2045 segrel za več kot dve stopinji Celzija. Če bi odstranili črni ogljik, bi se to obdobje zamaknilo skoraj do leta 2065. Tehnologija za odstranjevanje črnega ogljika že obstaja, zato je namen naše misije, da opozori na resnost tega problema.</p>
                        <p class="mb-0"><strong>Kje na svetu imajo zaradi črnega ogljika največ težav in kakšne?</strong></p>
                        <p>Najvišje koncentracije črnega ogljika, ki so javno dostopne, so bile izmerjene v Pekingu in New Delhiju. Težave s črnim ogljikom imamo vsi, saj je povezan z nezaželenimi vplivi z delci onesnaženega zraka na zdravje bolj kot delci PM10, katerih del je. Črni ogljik je bil povezan s pljučnimi in predvsem kardiovaskularnimi boleznimi, z nevrološkimi spremembami in s poslabšanjem bolezni imunskega sistema kot je astma. Mehanizem, s katerim črni ogljik deluje v našem telesu, vključuje vnetne procese.</p>
                        <p class="mb-0"><strong>Kako je s Slovenijo oziroma Ljubljano, kako z Evropo?</strong></p>
                        <p>Ljubljanske koncentracije črnega ogljika (pozimi) so za evropske razmere visoke. Imamo to nesrečo, da Ljubljana leži v kotlini in da se zrak ne meša močno, zato viri (predvsem promet in kurjenje lesa za ogrevanje individualnih hiš) močno vplivajo na kvaliteto zraka. V Ljubljani z delci onesnažen zrak statistično skrajša življenje za več kot eno leto, kar je veliko.</p>
                        <p class="mb-0"><strong>Kaj so dobre prakse in kako odmevni oz. učinkoviti so lahko ukrepi na lokalni ravni za globalne cilje?</strong></p>
                        <p>Ukrepi, povezani z zmanjševanjem izpustov črnega ogljika, so lokalni, posledice pa so lokalne, regionalne in globalne. Ukrepe lahko sprejme vsak sam, lahko pa vire ureja lokalna skupnost. Črni ogljik je onesnaževalo, kjer lahko največ naredimo s spremembo načina življenja: ne vozimo se z osebnimi avti (sploh če so stara in nevzdrževana), pač pa uporabljamo javni prevoz; individualne hiše priklopimo na daljinsko ogrevanje, če je le mogoče na tako z visoko učinkovitim zgorevanjem, ki uporablja biomaso kot gorivo.</p>
                        <p class="mb-0"><strong>To je vaša tretja pot okoli sveta, a tokratna raziskava bo nekaj povsem novega, ne samo v Sloveniji, temveč tudi na svetu. Nam lahko na kratko predstavite, kako bo potekala?</strong></p>
                        <p>Kar nekaj novosti je v tej misiji, če jo primerjamo s prejšnjimi. Prvič letim z ultralahkim letalom Dynamic WT9, na poti, ki smo jo določili, je kar nekaj krajev, kjer podobnih meritev še ni bilo in potek misije bo mogoče spremljati v živo. Tudi ekipa, ki tokrat skrbi za potek misije, je okrepljena, več ljudi je v njej, več strokovnjakov. Na naši spletni strani sta dva zemljevida. Na enem bo vidno, kje se letalo ta hip nahaja, na drugem bomo objavljali trenutne rezultate meritev.</p>
                        <p class="mb-0"><strong>Kdo so vaši partnerji v projektu in zakaj?</strong></p>
                        <p>Naši največji podporniki so podjetja Akrapovič, Javno podjetje Energetika Ljubljana, in Aerovizija, ki zagotovi letalo. Jaz sem še posebej ponosen na partnerje, s katerimi delimo način mišljenja, poslovno filozofijo in odnos do okolja. Ravno Energetika je dober primer pozitivnih učinkov na življenjsko okolje, ki so nastali skozi odgovoren odnos, ki zadeve ureja proaktivno in preprečuje nastanek večjih problemov. Ko govorimo o onesnaževanju, ljudje najprej pomislijo na velike dimnike, iz katerih se kadi, tako kot se iz vaše toplarne. Veliko je še drugih pomembnih partnerjev, kot na primer Petrol, pa globalna letalska podjetja kot Garmin, Rotax, MT, Aerospool ...</p>
                        <p class="mb-0"><strong>V letalstvu ste osvojili že več mejnikov, med drugim ste z ultralahkim letalom kar šestkrat prečkali ekvator, preleteli tri oceane, pet najvišjih vrhov celin in severni pol, kot prvi z njim pristali na Antarktiki … (zagotovo sem še katerega izpustila). Ko pomislim na vas, zato ne morem mimo tega, da si ne rečem, da rek »Le nebo je meja« za vas zagotovo ne drži. Kje so sploh vaše meje?</strong></p>
                        <p>Vsi ljudje smo omejeni, če že ne z drugim pa zagotovo z okoljem, v katerem živimo. Ob tem vašem vprašanju sem se hitro spomnil težav, ki smo jih imeli pri registraciji letala z domačimi uradniki. Lahko so še tako pomembni projekti, a nič vam ne bo pomagalo, če boste na drugi strani naleteli na birokratsko togost, ki vas učinkovito omejuje in s pozicije oblasti in moči. Banalna vsakdanjost brez težav zrelativizira pomen velikih projektov.</p>
                        <p class="mb-0"><strong>Z vsake odprave lahko občudujemo tudi podobe, ki jih ujamete v fotografski objektiv. Kaj vam predstavlja taka fotografija – opomnik, da moramo svet zaščititi ali tolažbo, da le še ni tako hudo? Morda oboje?</strong></p>
                        <p>Fotografija je zame tudi način razmišljanja. V teh statičnih slikah so zajete tudi naše refleksije in naša dejanja. Na neki način so fotografije, ki jih naredim iz letala, tudi ogledalo naših prejšnjih ravnanj, na njih so čudovite naravne stvaritve, prepletene s sledi našega bivanja. Človekovi posegi v naravo niso vedno ravno v ponos. A ne manjka tudi lepote, ta planet, na katerem živimo, ima iz ptičje perspektive neko posebno lepoto.</p>
                        <p class="mb-0"><strong>Ali še drži "povsod je lepo, a doma je najlepše"?</strong></p>
                        <p>Doma je zagotovo lepo, vprašanje pa je, kje je za koga dom. Zame je dom tam, kjer se dobro počutim v družbi ljudi s sorodnim življenjskim nazorom. V Sloveniji je to zaenkrat še mogoče, prihodnost pa lahko prinese marsikaj.</p>
                        <p class="mb-0"><strong>Ko ste tam zgoraj, daleč stran od doma in raziskujete drobovje strupov, ki nam škodujejo - ali vas je česa strah in če, zakaj?</strong></p>
                        <p>Strah je na duhovnem nivoju tisto kar pomeni bolečina v materialnem svetu. Postavlja nam meje, do kam lahko gremo. Z leti se naučiš upravljati s strahom, kot kontrolirati bolečino.<br />Meje se pomikajo navzgor, umetnost pa je najti pravo mero.</p>
                        <p class="mb-0"><strong>Vsaka pot človeka nekaj nauči in ga spremeni – kaj nauči vas in kako vas spremeni?</strong></p>
                        <p>Pogled od zgoraj je drugačen, širši, dojemanje sveta in odnosov v družbi bolj globalno. Vse bolj se postavlja vprašanje smiselnosti nacionalnih držav in omejenosti prebivalcev. Ali narod res potrebuje lastno državo, ali je bolj pomembna lastna kultura. Iz zraka nobenih političnih meja ni videti, niti tiste, ki so jih okrepili z nožastimi ograjami. Politična, korporativna manipulacija je dosegla nivo, ko se zabrišejo meje med realnostjo in fikcijo. Upravljajo nas s korenčkom in palico, kjer prvi pomeni potrošništvo, drugo pa eksistenčni strah. Govorijo, da nas je preveč na svetu, celo v Sloveniji naj ne bi bilo prostora za nove naseljence. Temu seveda ni tako. Večina sveta je še vedno prazna, tudi Slovenija zlahka preživi enkrat večjo populacijo ljudi. Energentov je dovolj, hrane in vode tudi. Problem tiči v pohlepnem genu človeka, ki je v liberalni ekonomiji ušel izpod nadzora.</p>
                        <p class="mb-0"><strong>Kaj želite, da vaša pot nauči ali spremeni svet in kaj nas, posameznike?</strong></p>
                        <p>Predvsem strpnosti, sonaravnega bivanja, spoštovanja. Tekma je pomembna dokler je igra, zmaga pa breme. Če družba slavi zmagovalce, pristaja na omejeno populacijo motiviranih posameznikov znotraj frustrirane množice poražencev. Takšna družba ni zdrava in je zlahka žrtev manipulacij oblasti željnih posameznikov. Zato je pomemben obrat v miselnosti ter dojemanju sveta. Navsezadnje je svet resnično lep, doba bivanja posameznika pa resnično kratka.</p>
                        <p>Pogovor z Matevžem Lenarčičem je bil objavljen v naši interni reviji Megavat (<a href="https://www.energetika.si/sites/www.jhl.si/files/energetika_lj_si/stran/datoteke/megavat_49_2016.pdf" target="_blank">marec, 2016</a>).</p>
                    </div>
                    <div class="read-more d-block mt-0">
                        <button (click)="interviewExtend(true)" *ngIf="!interviewExtended"><i class="fa fa-chevron-down" aria-hidden="true"></i></button>
                        <button (click)="interviewExtend(false)" *ngIf="interviewExtended"><i class="fa fa-chevron-up" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block pt-3">
                <div class="col-12 col-md-8 offset-md-2 text-center">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-glwf-logo.png" alt="Slika logoa 'GreenLight WorldFlight'" />
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block pt-4 pt-md-6">
                <div class="col-12 col-lg-6 pb-md-3">
                    <h3 class="left-md">Green Light World Flight spletna stran</h3>
                </div>
                <div class="col-12 col-lg-6 pb-3">
                    <p><a href="https://www.worldgreenflight.com/" target="_blank">https://www.worldgreenflight.com/</a></p>
                </div>
                <div class="col-12 col-lg-6 pb-md-3">
                    <h3 class="left-md">Green Light World Flight facebook profil</h3>
                </div>
                <div class="col-12 col-lg-6 pb-3">
                    <p><a href="https://www.facebook.com/GreenLightWorldFlight/" target="_blank">https://www.facebook.com/GreenLightWorldFlight/</a></p>
                </div>
                <div class="col-12 col-lg-6">
                    <h3 class="left-md">Green Light World Flight instagram feed.</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row pb-4 pb-md-6">
                <div class="col-12 social-plugin ig">
                    <div class="elfsight-app-e885a992-e4f6-4795-9660-d163df5b2aab"></div>
                </div>
            </div>
        </div>
    </div>

</article>

import { Route } from '@angular/router';

import { DelaNaOmrezjuComponent } from './dela-na-omrezju.component';
import { DelaNaOmrezjuListComponent } from './list/list.component';
import { DelaNaOmrezjuDetailsComponent } from './details/details.component';

import { Metadata } from '../../models/metadata.model';

const listMetadata: Metadata = {
    title: 'Dela na omrežju',
    description: 'Dela na omrežju Energetike Ljubljana',
    keywords: ['energetika ljubljana', 'dela na omrežju', 'omrežje', 'delo', 'plinovod', 'vročevod'],
    canonicalUrl: '/dela-na-omrezju'
};

const detailsMetadata: Metadata = {
    title: 'Dela na omrežju',
    skipAutoSetup: true
};

export const route: Route = {
    path: 'dela-na-omrezju',
    component: DelaNaOmrezjuComponent,
    data: {
        metadata: listMetadata
    },
    children: [{
        path: '',
        component: DelaNaOmrezjuListComponent,
        data: {
            metadata: listMetadata
        }
    }, {
        path: ':id',
        component: DelaNaOmrezjuDetailsComponent,
        data: {
            metadata: detailsMetadata
        }
    }]
};
import { ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { finalize, mergeMap } from 'rxjs/operators';

import { PovprasevanjeService } from '../../../services/povprasevanje.service';
import { CookiesService } from '../../../services/cookies.service';

import { Kategorija, Obrazec, Povprasevanje, PovprasevanjePodrocjeVrednost } from '../../../models/povprasevanje.model';
import { of, Observable, combineLatest } from 'rxjs';

export class PovprasevanjeBaseComponent {

    protected readonly referentialFormCode = "REFERENTIAL_FORM";
    protected readonly referentialFormLinkCode = "referenceLink";
    protected readonly referentialFormTitleCode = "referenceTitle";

    protected readonly localStoragePovpKey: string = 'povprasevanje';
    protected readonly localStorageMaxTime: number = 2 * 24 * 60 * 60 * 1000; // two days

    protected files: File[] = [];
    protected storage;

    public kategorija: Kategorija = null;
    public kategorije: Kategorija[] = [];
    public hierarhija: Kategorija[] = [];
    public loadingKategorije: boolean = true;
    public loadingObrazecUpdate: boolean = false;
    public obrazecSubmitted: boolean = false;
    public obrazecSubmitError: boolean = false;
    public obrazecShowError: boolean = false;
    public obrazci: Obrazec[] = null;
    public refUrl: string = null;
    public refTitle: string = null;
    public podrocjeVrednosti: PovprasevanjePodrocjeVrednost[] = [];
    public povprasevanje: Povprasevanje = null;

    public step: number = 1;
    public steps: number[] = [];
    public maxSteps: number = 7;

    public isChildRouteActivated: boolean = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected povprasevanjeService: PovprasevanjeService,
        protected cookiesService: CookiesService
    ) { 
    }


    public reset() {
        this.router.navigate(['./'], { queryParams: { katId: null, step: null, err: null }, relativeTo: this.route });
    }

    public onDescriptionUpdate(val: string) {
        
        if (this.povprasevanje === null)
            return;

        this.povprasevanje.opis = val ? val : null;
        this.savePovprasevanjeLocalStorage(this.povprasevanje);
    }

    public onObrazecBack(ev: any, o: Obrazec) {

        this.updatePovprasevanjeFromObrazecEvent(ev, o);
        this.savePovprasevanjeLocalStorage(this.povprasevanje);

        let newStep = this.step - 1;
        let newKatId = this.kategorija.id;

        if (newStep === this.kategorija.nivo + 1 && this.kategorija.stars)
            newKatId = this.kategorija.stars.id;
        else if (newStep === this.kategorija.nivo + 1 && !this.kategorija.stars)
            newKatId = null;

        this.router.navigate(['./'], { queryParams: { step: this.step - 1, katId: newKatId, err: null }, queryParamsHandling: 'merge', relativeTo: this.route });
    }

    public onObrazecSubmit(ev: any, o: Obrazec) {

        if (this.loadingObrazecUpdate)
            return;

        this.loadingObrazecUpdate = true;
        this.obrazecSubmitted = false;
        this.obrazecSubmitError = false;

        this.updatePovprasevanjeFromObrazecEvent(ev, o);

        this.savePovprasevanjeLocalStorage(this.povprasevanje);

        let apiModel: Povprasevanje = _.cloneDeep(this.povprasevanje);

        // remove files (datoteka property)
        apiModel.podrocja[0].vrednosti.forEach(v => {
            if (v.datoteka) {
                if (v.datoteka.name && !this.files.some(f => f.name === v.datoteka.name)) {
                    this.files.push(v.datoteka);
                }

                delete v.datoteka;
            }
        });

        let obs: Observable<any>;

        if (!this.povprasevanje.id)
            obs = this.povprasevanjeService.createPovprasevanje(this.kategorija.id, apiModel);
        else
            obs = this.povprasevanjeService.updatePovprasevanje(apiModel.id, this.kategorija.id, apiModel);

        obs.pipe(
            mergeMap((res: Povprasevanje) => {

                this.povprasevanje = res;
                this.savePovprasevanjeLocalStorage(this.povprasevanje);

                let fileObs = [];

                if (this.povprasevanje.id && this.files.length > 0) {
                    for (let f of this.files) {
                        fileObs.push(this.povprasevanjeService.uploadPovprasevanjeFile(this.povprasevanje.id, this.kategorija.id, f));
                    }

                    this.files = [];
                }

                return fileObs.length > 0 ? combineLatest(fileObs) : of([]);
            }),
            mergeMap(res => {
                let nextStep = this.step + 1;
                let showErr = null;

                if (this.povprasevanje && this.povprasevanje.podrocja) {
                    let podrocje = this.povprasevanje.podrocja.find(p => p.kategorijaId === this.kategorija.id);

                    if (podrocje && podrocje.veljavnostObrazcev) {
                        let notValidIdx = podrocje.veljavnostObrazcev.slice(0, this.step - this.kategorija.nivo - 1).indexOf(false);

                        if (notValidIdx !== -1) {
                            nextStep = this.kategorija.nivo + 2 + notValidIdx;
                            showErr = true;
                        }
                    }
                }

                if (nextStep <= this.maxSteps || showErr) { 
                    this.router.navigate(['./'], { queryParams: { step: nextStep, err: showErr, des: null }, queryParamsHandling: 'merge', relativeTo: this.route });
                    return of(null);
                } else if (nextStep > this.maxSteps) {
                    this.obrazecSubmitted = true;
                    this.step = nextStep;
                    return this.povprasevanjeService.oddajPovprasevanje(this.povprasevanje.id, this.kategorija.id);
                }
            }),
            finalize(() => this.loadingObrazecUpdate = false)
        ).subscribe(res => {
            if (this.obrazecSubmitted) {
                this.deletePovprasevanjeLocalStorage();
                this.povprasevanje = null;
            }
        }, err => {
            console.log(err)

            if (!this.povprasevanje.id && err.status === 400) {
                // goto first step of povprasevanje if not already there
                let nextStep = this.kategorija.nivo + 2;
                let err = true;

                if (nextStep === this.step && this.step + 1 <= this.maxSteps) {
                    nextStep++;
                    err = null;
                }

                this.router.navigate(['./'], { queryParams: { step: nextStep, err: err }, queryParamsHandling: 'merge', relativeTo: this.route });
            } else if (err.status === 404) {
                this.povprasevanje.id = null;
                this.savePovprasevanjeLocalStorage(this.povprasevanje);
            } else if (this.obrazecSubmitted) {
                this.obrazecSubmitError = true;
            }

            this.loadingObrazecUpdate = false;
        });
    }

    protected initStorage() {
        if (this.cookiesService.isConsentGiven('povprasevanje')) {
            this.storage = window.localStorage;
        } else {
            this.storage = window.sessionStorage;
        }
    }

    protected updatePovprasevanjeFromObrazecEvent(ev: any, o: Obrazec) {

        // mark last change
        this.povprasevanje.zadnjaSprememba = new Date();
        let podrocje = this.povprasevanje.podrocja.length > 0 ? this.povprasevanje.podrocja[0] : null;

        if (podrocje && podrocje.kategorijaId !== this.kategorija.id) {
            podrocje.kategorijaId = this.kategorija.id;
        }

        // add podrocje for new kategorija id if not exists
        if (!podrocje) {
            podrocje = {
                kategorijaId: this.kategorija.id,
                vrednosti: []
            };
            this.povprasevanje.podrocja = [podrocje];
        }

        // transform submitted values in event to array of key-value pairs
        for (let key in ev) {
            let valObj: PovprasevanjePodrocjeVrednost = podrocje.vrednosti.find(v => v.koda === key && v.obrazec === o.koda && v.id === o.id);
            let valVal: any = ev[key] ? ev[key] : null;
            let file: File = null;
                
            if (_.isArray(valVal)) { // array at moment can be array of strings or array of objects when merilna mesta is used with stanje, datum or znesek
                let validItems = valVal.filter(v => _.isObject(v) ? Object.keys(v).some(k => this.hasValue(v[k])) : this.hasValue(v))
                valVal = validItems.length > 0 ? JSON.stringify(validItems) : null;
            } else if (_.isObject(valVal)) { // for now object can only be when file is also included
                file = valVal.file ? valVal.file : null;
                valVal = valVal.value;
            } else if (valVal !== null) {
                valVal = valVal.toString();
            }

            if (!valObj) {
                valObj = {
                    obrazec: o.koda,
                    koda: key,
                    vrednost: valVal,
                    id: o.id ? o.id : null
                };

                podrocje.vrednosti.push(valObj);
            }

            valObj.vrednost = this.hasValue(valVal) ? valVal : null;
            if (file) valObj.datoteka = file;
        }

        if (this.refTitle) {
            let refTitleVal = podrocje.vrednosti.find(v => v.koda === this.referentialFormTitleCode && v.obrazec === this.referentialFormCode);

            if (!refTitleVal) {
                podrocje.vrednosti.push({
                    obrazec: this.referentialFormCode,
                    koda: this.referentialFormTitleCode,
                    vrednost: this.refTitle,
                    id: null
                });
            } else {
                refTitleVal.vrednost = this.refTitle;
            }
        }

        if (this.refUrl) {
            let refUrlVal = podrocje.vrednosti.find(v => v.koda === this.referentialFormLinkCode && v.obrazec === this.referentialFormCode);

            if (!refUrlVal) {
                podrocje.vrednosti.push({
                    obrazec: this.referentialFormCode,
                    koda: this.referentialFormLinkCode,
                    vrednost: this.refUrl,
                    id: null
                });
            } else {
                refUrlVal.vrednost = this.refUrl;
            }
        }

        this.povprasevanje.podrocja.forEach(p => {
            p.vrednosti = p.vrednosti.filter(v => v.vrednost !== null);
        });
    }

    protected hasValue(val: any) {
        return !_.isUndefined(val) && val !== null && (typeof val !== 'string' || val.length > 0);
    }

    protected loadKategorije() {

        this.loadingKategorije = true;
        this.obrazecSubmitted = false;
        this.kategorija = null;

        this.povprasevanjeService.getKategorije()
            .pipe(
                finalize(() => this.loadingKategorije = false)
            )
            .subscribe(res => {
                this.kategorije = res;
                this.hierarhija = [];
            });
    }

    protected loadKategorija(id: number) {

        this.loadingKategorije = true;
        this.obrazecSubmitted = false;

        this.povprasevanjeService.getKategorija(id)
            .pipe(
                mergeMap((pk => {
                    this.processKategorija(pk);

                    return this.kategorija.koncnaKategorija && this.kategorija.obrazec ? this.povprasevanjeService.getKategorijaObrazci(id) :
                        of(null);
                })),
                finalize(() => this.loadingKategorije = false)
            )
            .subscribe(res => {
                this.obrazci = res;

                if (this.kategorija.koncnaKategorija)
                    this.initPovprasevanje();

                this.generateSteps();
            });
    }

    protected buildHierarchy(kat: Kategorija): Kategorija[] {
        return kat.stars ? this.buildHierarchy(kat.stars).concat([kat]) : [kat];
    }

    protected processKategorija(kat: Kategorija) {

        this.kategorija = kat;
        this.kategorije = this.kategorija.otroci;

        this.generateSteps();
        this.hierarhija = this.buildHierarchy(this.kategorija);
    }

    protected initPovprasevanje() {

        if (this.isChildRouteActivated)
            return;

        this.povprasevanje = this.loadPovprasevanjeLocalStorage();

        if (this.povprasevanje === null) {
            this.povprasevanje = {
                zadnjaSprememba: new Date(),
                podrocja: []
            };
        } else {
            let now = new Date();
            let save: boolean = false;

            if (now.getTime() - this.povprasevanje.zadnjaSprememba.getTime() > this.localStorageMaxTime) {
                this.povprasevanje.podrocja = [];
                this.povprasevanje.id = null;
                this.povprasevanje.naziv = null;
                this.povprasevanje.email = null;
                this.povprasevanje.telefonskaStevilka = null;
                this.povprasevanje.opis = null;
                this.povprasevanje.zadnjaSprememba = new Date();
                save = true;
            }

            if (this.povprasevanje.podrocja && this.povprasevanje.podrocja.length > 1) {
                this.povprasevanje.podrocja = [this.povprasevanje.podrocja[this.povprasevanje.podrocja.length - 1]];
                save = true;
            }

            if (save)
                this.savePovprasevanjeLocalStorage(this.povprasevanje);
        }

        if (!this.kategorija)
            return;

        let podrocje = this.povprasevanje.podrocja.length > 0 ? this.povprasevanje.podrocja[0] : null;

        if (podrocje && podrocje.kategorijaId !== this.kategorija.id && podrocje.vrednosti && podrocje.vrednosti.length > 0) {
            let currVrednosti = podrocje.vrednosti;
            let newVrednosti: PovprasevanjePodrocjeVrednost[] = [];

            if (this.obrazci && this.obrazci.length > 0) {
                this.obrazci.forEach(o => {
                    o.polja.forEach(p => {
                        let cv = currVrednosti.find(cv => cv.koda === p.koda);

                        if (cv) {
                            newVrednosti.push({
                                obrazec: o.koda,
                                id: o.id ? o.id : null,
                                koda: cv.koda,
                                vrednost: cv.vrednost
                            });
                        }
                    }) 
                });
            }

            podrocje.vrednosti = newVrednosti;
            this.savePovprasevanjeLocalStorage(this.povprasevanje);
        }

        if (podrocje && podrocje.vrednosti)
            this.podrocjeVrednosti = podrocje.vrednosti;
        else
            this.podrocjeVrednosti = [];
    }

    protected loadPovprasevanjeLocalStorage(): Povprasevanje {

        let item: any = this.storage.getItem(this.localStoragePovpKey);

        if (!item)
            return null;

        item = JSON.parse(item);

        if (item.zadnjaSprememba)
            item.zadnjaSprememba = new Date(item.zadnjaSprememba);

        return item;
    }

    protected savePovprasevanjeLocalStorage(item: Povprasevanje) {

        this.storage.setItem(this.localStoragePovpKey, JSON.stringify(item));
    }

    protected deletePovprasevanjeLocalStorage() {
        this.storage.removeItem(this.localStoragePovpKey);
    }

    protected generateSteps() {
        
        let steps = 1;

        if (this.kategorija && this.kategorija.koncnaKategorija)
            steps += this.kategorija.nivo;
        else
            steps = 6;

        if (this.obrazci && this.obrazci.length > 0)
            steps += this.obrazci.length;

        if (this.kategorija && this.kategorija.koncnaKategorija && this.kategorija.povezava && this.kategorija.povezavaNaziv)
            steps++;

        let newSteps = []
        for (let i = 1; i <= steps; i++)
            newSteps.push(i);

        this.maxSteps = steps;
        this.steps = newSteps;
    }
}
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot, RouterOutlet } from '@angular/router';

import { Angulartics2GoogleAnalytics } from 'angulartics2';

import { MetadataService } from './services/metadata.service';
import { CookiesService } from './services/cookies.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'enlj-webapp',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private route: ActivatedRoute,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private metadataService: MetadataService,
    private cookiesService: CookiesService
  ) { }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId))
      return;

    this.cookiesService.init();

    this.angulartics2GoogleAnalytics.startTracking();

    this.router.events.subscribe(e => {

      if (e instanceof NavigationEnd) {

        if (e.urlAfterRedirects.indexOf('#!') !== -1) {
          let redirectUrl = e.urlAfterRedirects.substring(e.urlAfterRedirects.indexOf('#!') + 2);
          let queryParams = {};

          if (!redirectUrl.startsWith('/'))
            redirectUrl = `/${redirectUrl}`;

          if (e.urlAfterRedirects.indexOf('?') !== -1) {
            let queryParamsUrlSplit = e.urlAfterRedirects.split('?')[1].split('#!')[0].split('#')[0].split('&');

            for (let param of queryParamsUrlSplit) {
              let paramSplit = param.split('=');
              queryParams[paramSplit[0]] = queryParams[paramSplit[1]];
            }
          }

          console.log(`Redirecting due to hasbang use: ${redirectUrl}`);

          this.router.navigate([redirectUrl], { replaceUrl: true, queryParams: queryParams });
        } else {
          this.metadataService.setFromRouteData(this.findLastChild(this.route.snapshot));
        }
      }
    });
  }

  private findLastChild(snapshot: ActivatedRouteSnapshot): any {

    if (snapshot.firstChild) {
      return this.findLastChild(snapshot.firstChild);
    } else {
      return snapshot.data;
    }
  }
}

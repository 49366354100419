import { Route } from '@angular/router';

import { CmsVsebinaComponent } from './cms-vsebina.component';
import { VsebinaEnergentiLngComponent } from './energenti-lng/energenti-lng.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Svetovalec',
    skipAutoSetup: true
}

const lngMetadata: Metadata = {
    title: 'LNG - za tovorna vozila',
    description: 'Javna LNG polnilnica v Ljubljani se nahaja na Podutiški cesti 99 (Ljubljana - Koseze).',
    keywords: ['lng', 'energenti', 'metan', 'tovorna vozila', 'utekočinjen zemeljski plin'],
    imageUrl: 'assets/images/vsebina/energenti-lng-grafika-6.png',
    imageWidth: 583,
    imageHeight: 450
};

export const route: Route = {
    path: 'vsebina',
    children: [{
        path: ':segment',
        component: CmsVsebinaComponent,
        data: { metadata: metadata }
    // }, {
    //     path: 'energenti/lng-za-tovorna-vozila',
    //     component: VsebinaEnergentiLngComponent,
    //     data: { metadata: lngMetadata }
    }, {
        path: ':segment/:category',
        component: CmsVsebinaComponent,
        data: { metadata: metadata }
    }]
};

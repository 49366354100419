<article>
    <div class="top-banner" style="background-image: url('assets/images/klub-zvestoba-ogreva/background11.jpg')">
        <div class="layer">
            <h1><img class="img-fluid" src="assets/images/klub-zvestoba-ogreva/logo-big-white.png" alt="Energetika Klub Zvestoba ogreva"></h1>
            <a routerLink="/povprasevanje" [queryParams]="povprasevanjeParams" class="btn-blue border-only d-inline-block">Osebno svetovanje</a>
        </div>

    </div>

    <div class="background-light-grey ugodnosti">
        <div class="container-fluid">
            <klub-zvestoba-ogreva-ugodnosti></klub-zvestoba-ogreva-ugodnosti>

            <div class="row">
                <div class="col-12" *ngIf="currentUgodnostiDoc">
                    <p><a [dokument-id]="currentUgodnostiDoc.id" dokument-icon="true" dokument-text="true" href=""></a></p>
                </div>

                <div class="col-12 mt-2">
                    <p><a routerLink="./arhiv-ugodnosti">Arhiv seznamov ugodnosti za člane Kluba zvestoba ogreva</a></p>
                </div>
            </div>
        </div>
    </div>



    <div class="background-light-grey who-can">
        <div class="container">
            <div class="row">
                <div class="col-12 title">
                    <h2>V Klub Zvestoba ogreva se lahko brezplačno včlanite <strong>kupci storitev ENERGETIKE LJUBLJANA</strong></h2>
                </div>
                <div class="col-12 col-md-4">
                    <img [src]="getToplotaImage()" alt="Ikona gospodinjski odjemalci toplote">
                    <p>Gospodinjski <strong>odjemalci toplote*</strong></p>
                </div>
                <div class="col-12 col-md-4">
                    <img [src]="getElektrikaImage()" alt="Ikona gospodinjski odjemalci električne energije">
                    <p>Gospodinjski <strong>odjemalci električne energije*</strong></p>
                </div>
                <div class="col-12 col-sm-4">
                    <img [src]="getPlinImage()" alt="Ikona gospodinjski ali negospodinjski odjemalci zemeljskega plina">
                    <p>Gospodinjski ali negospodinjski <strong>odjemalci zemeljskega plina*</strong><br /><small>z letno porabo zemeljskega plina do 106.781 kWh (prodajne skupine PS1 do PS6).</small></p>
                </div>
                <div class="col-12">
                    <p><small>* V KLUB se lahko včlani kupec storitev ENERGETIKE LJUBLJANA, ki je gospodinjski odjemalec toplote, odjemalec zemeljskega plina z letno porabo zemeljskega plina do 106.781 (prodajne skupine PS1 do PS6), odjemalec stisnjenega zemeljskega plina (Metan/CNG) in/ali gospodinjski odjemalec električne energije, na katerega se glasijo računi za dobavo toplote, električne energije, zemeljskega plina in stisnjenega zemeljskega plina, ki:<br/>a.) ima individualno odjemno mesto za zemeljski plin in/ali električno energijo, na katerih se meri poraba zemeljskega plina in/ali električne energije za enega odjemalca in/ali<br />b.) ima odjemno mesto za zemeljski plin na skupnem merilnem mestu in so vsi člani delilnika na skupnem merilnem mestu vključeni v KLUB in/ali<br />c.) ima individualno merilno mesto s 100 % deležem za toploto, na katerem se meri poraba toplote za enega odjemalca in/ali<br />d.) ima merilno mesto za toploto na skupnem merilnem mestu in/ali <br />e.) uporablja stisnjen zemeljski plin na polnilnicah Energetike Ljubljana in/ali<br />g.) ima z Energetiko Ljubljana sklenjeno Pogodbo o dobavi toplote in/ali Pogodbo o dobavi/ oskrbi z zemeljskim plinom in/ali Pogodbo o prodaji električne energije in/ali Pogodbo o prodaji stisnjenega zemeljskega plina na polnilnici za stisnjen zemeljski plin in/ali<br />f.) ima za merilno mesto za dobavo toplote sam ali preko upravnika stavbe oziroma drugega pooblaščenca za skupno odjemno mesto za dobavo toplote sklenjeno Pogodbo o dobavi toplote in/ali za dobavo električne energije Pogodbo o prodaji električne energije in/ali za dobavo zemeljskega plina Pogodbo o dobavi zemeljskega plina za isto nepremičnino.</small></p>
                </div>
                <div class="col-12">
                    <p>
                        <small documents>
                            Pogoj za včlanitev v Klub Zvestoba ogreva je izpolnjevanje
                            <a class="small-font" dokument-id="C3482AA3-05E0-49B4-83B1-BC11564BEE42" href="">Splošnih pogojev Kluba Zvestoba ogreva</a>.
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="delimiter">
        <div class="buttons-center">
            <a routerLink="/povprasevanje" [queryParams]="povprasevanjeParams" class="btn-blue border-only d-inline-block">Osebno svetovanje</a>
        </div>
    </div>

</article>

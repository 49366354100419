import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-narocilo-sms-obvescanje-find',
    templateUrl: './find.component.html',
    styleUrls: ['./find.component.scss']
})
export class NarociloSmsObvescanjeFindComponent implements OnInit {

    constructor() { }

    public ngOnInit() { }
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import * as _ from 'lodash';

import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CmsService, CmsSitemap } from '../../../services/cms.service';

import { NewsArticle, NewsTag } from '../../../models/cms.model';

@Component({
    selector: 'app-novice-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class NoviceListComponent implements OnInit {

    public newsArticles: NewsArticle[] = [];
    public loading: boolean = true;
    public pages: number[] = [];
    public currentPage: number = 1;
    public lastPage = null;
    public totalCount: number = 0;
    public maxPages: number = 0;
    public maxItemsPerPage: number = 12;

    public cmsContentBasePath;

    private queryParams: Params;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService
    ) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;
            this.currentPage = qp.stran ? parseInt(qp.stran) : 1;

            this.loadItems();
        });
    }

    public loadItems() {

        this.loading = true;

        let skip = null;

        if (this.currentPage !== 1)
            skip = (this.currentPage - 1) * this.maxItemsPerPage;

        this.cmsService.getNewsAsync(this.queryParams.kategorija, this.queryParams.oznake, skip)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(res => {
                this.newsArticles = res.items;
                this.totalCount = res.count;
            });
    }

    changePage(pageNum) {
            
        this.currentPage = pageNum;
        window.scrollTo(0, 0);

        this.router.navigate(['./'], { 
            queryParams: { stran : pageNum !== 1 ? pageNum : null }, 
            queryParamsHandling: 'merge', 
            relativeTo: this.route 
        });
    }
}
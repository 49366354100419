<div class="row">
    <div *ngFor="let article of newsArticles" class="col-12 col-md-6 col-xl-4">
        <svetovalec-list-item [article]="article" [isNovicePage]="true"></svetovalec-list-item>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="col-12">
            <div class="dotted-divider my-2"></div>
        </div>
        <div class="col-12 pb-4" *ngIf="newsArticles.length > 0 && !loading">
            <pagination [currentPage]="currentPage" [totalCount]="totalCount" [itemsPerPage]="maxItemsPerPage" (onPageChange)="changePage($event)"></pagination>
        </div>
    </div>

    <div class="col-12 list-no-results py-3" *ngIf="newsArticles.length === 0 && !loading">
        <p class="icon"><i class="fa fa-frown-o" aria-hidden="true"></i></p>
        <p class="text">Najdene ni bilo nobene novice pod izbranimi iskalnimi pogoji.</p>
    </div>
</div>

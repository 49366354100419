import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Observable, of, ReplaySubject} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { CtaUtils } from '../utils/cta.utils';

import {
    Segment,
    NewsArticle,
    NewsTag,
    DeloNaOmrezju,
    CategoryExtended,
    OfferExtended,
    Category,
    CallToActionType,
    DocumentCategory,
    Document,
    KzoUgodnost
} from '../models/cms.model';
import { PageItems } from '../models/pagination.model';

@Injectable()
export class CmsService {

    private baseUrl: string;

    private sitemapSubject: ReplaySubject<CmsSitemap> = null;
    private noviceOznakaSubject: ReplaySubject<NewsTag[]> = null;
    private documentCategoriesSubject: ReplaySubject<DocumentCategory[]> = null;
    private kzoUgodnostiSubject: ReplaySubject<KzoUgodnost[]> = null;
    private kzoSeznamUgodnostiSubject: ReplaySubject<Document[]> = null;

    public contentBasePath = environment.cmsContentBasePath;
    public serviceUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.cmsServiceBasePath}/public`;
        this.serviceUrl = this.baseUrl;
    }

    getCmsSitemapAsync(): Observable<CmsSitemap> {

        if (this.sitemapSubject === null) {
            this.sitemapSubject = new ReplaySubject(1);

            this.http.get<Segment[]>(`${this.baseUrl}/struktura`).subscribe(res => {

                let abstracts = res.filter(p => p.abstrakten);
                let unabstracts = res.filter(p => !p.abstrakten);
                let abstractsByRow = [];

                for (let i = 0; i < abstracts.length; i++) {
                    if (i % 3 === 0)
                        abstractsByRow.push([]);

                    abstractsByRow[abstractsByRow.length - 1].push(abstracts[i]);
                }

                // inserts subpage in cms generated menu
                for (let i = 0; i < abstracts.length; i++) {
                    if (abstracts[i].urlPot === 'energenti') {

                        // find cng subcategory
                        let katCng = null;
                        for (let j = 0; j < abstracts[i].kategorijas.length; j++) {
                            if (abstracts[i].kategorijas[j].urlPot === 'cng-vozim-na-metan') {
                                katCng = abstracts[i].kategorijas[j];
                                break;
                            }
                        }

                        // // place lng
                        // abstracts[i].kategorijas.push({
                        //     id: 1001,
                        //     urlPot: 'lng-za-tovorna-vozila',
                        //     vrstniRed: katCng !== null ? katCng.vrstniRed + 0.1 : 4.1,
                        //     naziv: 'LNG - za tovorna vozila',
                        //     aktiven: true
                        // });

                        // resort kategorijas
                        abstracts[i].kategorijas.sort((k1, k2) => k1.vrstniRed - k2.vrstniRed);

                        break;
                    }
                }

                this.sitemapSubject.next({
                    original: res,
                    abstract: abstractsByRow,
                    unabstract: unabstracts
                });
            });
        }

        return this.sitemapSubject.asObservable();
    }

    getContentPage(segment: string, category: string = null, preview: boolean = false, version: string = null): Observable<CategoryExtended> {

        let path = `${segment}${category ? `/${category}` : ''}`;

        let params: any = {
            urlPot: path
        };

        if (preview) {
            if (version)
                params.verzija = version;
            else
                params.vsi = true;
        }

        return this.http.get<CategoryExtended>(`${this.baseUrl}/stran`, { params: params })
            .pipe(
              map(c => this.mapCategoryExtended(c)),
              catchError( _err => of(null))
            );
    }

    getContentPageById(id: number): Observable<CategoryExtended> {

        return this.http.get<CategoryExtended>(`${this.baseUrl}/stran/${id}`)
            .pipe(map(c => this.mapCategoryExtended(c)));
    }

    getCategoryByPath(path: string): Observable<Category|Segment> {

        let pathSplit = path.split('/');
        let segmentPath = pathSplit[0];
        let kategorijaPath = pathSplit.length > 1 ? pathSplit[1] : null;

        let segment = null;

        return this.getCmsSitemapAsync().pipe(
            map(sitemap => {

                for (let i = 0; i < sitemap.original.length; i++) {
                    if (sitemap.original[i].urlPot === segmentPath) {
                        segment = sitemap.original[i];
                        break;
                    }
                }

                if (!segment)
                    return null;

                if (!segment.abstrakten) {
                    return segment.kategorijas[0];
                } else if (kategorijaPath) {
                    for (let i = 0; i < segment.kategorijas.length; i++) {
                        let kategorija = segment.kategorijas[i];

                        if (kategorija.urlPot === kategorijaPath)
                            return kategorija;
                    }
                }

                return null;
            })
        )
    }

    getOffers(): Observable<OfferExtended[]> {
        return this.http.get<OfferExtended[]>(`${this.baseUrl}/akcija`);
    }

    getOfferById(id: number, version: number = null): Observable<OfferExtended> {

        let url = `${this.baseUrl}/akcija/${id}`;

        if (version)
            url += `/verzija/${version}`;

        return this.http.get<OfferExtended>(url)
            .pipe(map(o => this.mapOfferExtended(o)));
    }

    getNewsAsync(kategorija: number = null, oznake: string = null, skip: number = null): Observable<PageItems<NewsArticle>> {

        let params: any = {};

        if (kategorija)
            params.kategorija = kategorija;

        if (oznake)
            params.oznake = oznake;

        if (skip)
            params.skip = skip;

        return this.http.get<any>(`${this.baseUrl}/novica`, { params: params, observe: 'response' })
            .pipe(
                map(res => <PageItems<NewsArticle>>{
                    count: parseInt(res.headers.get('X-Total-Count')),
                    items: res.body
                })
            );
    }

    getNewsByIdAsync(id: number, name: string, preview: boolean = false): Observable<NewsArticle> {

        let params: any = {};

        if (preview)
            params.vsi = true;

        return this.http.get<NewsArticle>(`${this.baseUrl}/novica/${id}/${name}`, { params: params })
            .pipe(map(n => this.mapNewsArticle(n)));
    }

    getNewsLatestAsync(): Observable<NewsArticle[]> {
        return this.http.get<NewsArticle[]>(`${this.baseUrl}/novica/top`);
    }

    getCmsNewsTagsAsync(): Observable<NewsTag[]> {

        if (this.noviceOznakaSubject === null) {
            this.noviceOznakaSubject = new ReplaySubject(1);

            this.http.get<NewsTag[]>(`${this.baseUrl}/novica/oznake`).subscribe(res => {
                this.noviceOznakaSubject.next(res);
            });
        }

        return this.noviceOznakaSubject.asObservable();
    }

    getDocumentsAsync(cenik: boolean = null, arhiv: boolean = null, kategorije: string = null, skip: number = null,
        kzoUgodnost: boolean = null, sort: string = null, order: string = null): Observable<PageItems<Document>> {

        let params: any = {};

        if (kategorije)
            params.kategorije = kategorije;

        if (cenik !== null)
            params.cenik = cenik;

        if (arhiv !== null)
            params.arhiv = arhiv;

        if (kzoUgodnost != null)
            params.kzoUgodnost = kzoUgodnost;

        if (skip)
            params.skip = skip;

        if (sort)
            params.sort = sort;

        if (order)
            params.order = order;

        return this.http.get<any>(`${this.baseUrl}/dokument`, { params: params, observe: 'response' })
            .pipe(
                map(res => <PageItems<Document>>{
                    count: parseInt(res.headers.get('X-Total-Count')),
                    items: res.body
                })
            );
    }

    getKzoSeznamUgodnostiAsync(): Observable<Document[]> {

        if (this.kzoSeznamUgodnostiSubject === null) {

            this.kzoSeznamUgodnostiSubject = new ReplaySubject(1);

            this.http.get<Document[]>(`${this.baseUrl}/dokument/kzo-seznam-ugodnosti`)
                .pipe(first())
                .subscribe(res => {
                    this.kzoSeznamUgodnostiSubject.next(res);
                });
        }

        return this.kzoSeznamUgodnostiSubject.asObservable();
    }

    getCmsDocumentCategoriesAsync(): Observable<DocumentCategory[]> {

        if (this.documentCategoriesSubject === null) {
            this.documentCategoriesSubject = new ReplaySubject(1);

            this.http.get<DocumentCategory[]>(`${this.baseUrl}/dokument/kategorije`).subscribe(res => {
                this.documentCategoriesSubject.next(res);
            });
        }

        return this.documentCategoriesSubject.asObservable();
    }

    getNetworkJobsAsync(energent: number = null, skip: number = null): Observable<PageItems<DeloNaOmrezju>> {

        let params: any = {};

        if (energent)
            params.energent = energent;

        if (skip)
            params.skip = skip;

        return this.http.get<any>(`${this.baseUrl}/delo-na-omrezju`, { params: params, observe: 'response' })
            .pipe(
                map(res => <PageItems<DeloNaOmrezju>>{
                    count: parseInt(res.headers.get('X-Total-Count')),
                    items: res.body
                })
            );
    }

    getNetworkJobsByIdAsync(id: number): Observable<DeloNaOmrezju> {
        return this.http.get<DeloNaOmrezju>(`${this.baseUrl}/delo-na-omrezju/${id}`);
    }

    getKzoUgodnostiAsync(): Observable<KzoUgodnost[]> {

        if (this.kzoUgodnostiSubject === null) {
            this.kzoUgodnostiSubject = new ReplaySubject(1);

            this.http.get<KzoUgodnost[]>(`${this.baseUrl}/kzo-ugodnost`)
                .pipe(first())
                .subscribe(res => this.kzoUgodnostiSubject.next(res));
        }

        return this.kzoUgodnostiSubject.asObservable();
    }

    private mapCategoryExtended(c: CategoryExtended): CategoryExtended {

        if (c.ctaTip && (CallToActionType[c.ctaTip].toString() === CallToActionType.POVPRASEVANJE.toString() ||
            CallToActionType[c.ctaTip].toString() === CallToActionType.SVETOVANJE.toString()))
            c.ctaQueryParams = CtaUtils.getPovprasevanjeQueryParams(c.ctaPovezava);

        return c;
    }

    private mapOfferExtended(o: OfferExtended): OfferExtended {

        if (o.ctaTip && (CallToActionType[o.ctaTip].toString() === CallToActionType.POVPRASEVANJE.toString() ||
            CallToActionType[o.ctaTip].toString() === CallToActionType.SVETOVANJE.toString()))
            o.ctaQueryParams = CtaUtils.getPovprasevanjeQueryParams(o.ctaPovezava);

        return o;
    }

    private mapNewsArticle(n: NewsArticle): NewsArticle {

        if (n.ctaTip && (CallToActionType[n.ctaTip].toString() === CallToActionType.POVPRASEVANJE.toString() ||
            CallToActionType[n.ctaTip].toString() === CallToActionType.SVETOVANJE.toString()))
            n.ctaQueryParams = CtaUtils.getPovprasevanjeQueryParams(n.ctaPovezava);

        return n;
    }
}

export interface CmsSitemap {
    original: Segment[];
    abstract: Segment[];
    unabstract: Segment[]
}

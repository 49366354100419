import { Component, Input, OnInit, HostBinding } from '@angular/core';

@Component({
    selector: 'loader',
    template: `
<div class="royal-loader" [class.small]="small"></div>
    `,
    styles: [`
:host {
    height: 64px;
    display: block;
    position: relative;
}

:host(.small) {
    height: 32px;
}
    `]
})
export class LoaderComponent implements OnInit {

    @Input() small: boolean = false;
    @HostBinding('class.small') hostSmall: boolean = false;

    ngOnInit() {
        this.hostSmall = this.small;
    }
}
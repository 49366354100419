import { Inject, Injectable, InjectionToken, ErrorHandler } from '@angular/core';

import Rollbar from 'rollbar';

import { environment } from '../../environments/environment';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
  
@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

    constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

    handleError(err:any) : void {
      this.rollbar.error(err.originalError || err);

      if (!environment.production) {
        console.error(err);
      }
    }
}

const rollbarConfig: Rollbar.Configuration = {
    enabled: environment.production,
    accessToken: '014689bb717046549c83a2f68bab4313',
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: 'anonymize',
    environment: environment.envName
  };
  
  // factories
  export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
  }
  
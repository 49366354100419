import { Route } from '@angular/router';

import { PreviewActionComponent } from './action.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Predogled akcije',
    skipAutoSetup: true
}

export const route: Route = {
    path: 'predogled',
    children: [{
        path: 'akcije/:id/:version',
        component: PreviewActionComponent,
        data: { metadata: metadata }
    }]
};
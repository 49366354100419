import { Route } from '@angular/router';

import { AkcijeLayoutComponent } from './layout.component';
import { AkcijaVozimNaPlinComponent } from './vozim-na-plin/vozim-na-plin.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Akcija: Vozim na plin',
    description: 'Vozim na metan in polnim kar doma. Ugodno, udobno in okolju prijazno',
    keywords: ['vozim na plin', 'metan', 'cng', 'stisnjen zemeljski plin', 'ugodno', 'udobno', 'okolju prijazno'],
    imageUrl: 'assets/images/vozim-na-metan/infografika-cng-primerjava-2018-06-11.png',
    imageWidth: 591,
    imageHeight: 230
};

export const route: Route = {
    path: 'akcije',
    component: AkcijeLayoutComponent,
    children: [{
        path: 'vozim-na-plin',
        component: AkcijaVozimNaPlinComponent,
        data: { metadata: metadata }
    }]
};
<main id="content">
    <section class="content">
        <div class="container">
            <div class="row mt-5">
                <div class="col-12 col-md-5">
                    <h1><i class="fa fa-frown-o" aria-hidden="true"></i> 404</h1>
                </div>
                <div class="col-12 col-md-7">
                    <h2>
                        Stran, ki ste jo želeli obiskati, ne obstaja.
                    </h2>
                </div>
            </div>
        </div>
        <div class="mt-5 pt-5 pb-4 background-light-grey" *ngIf="!sitemapLoading">
            <nav aria-label="Meni dinamičnih vsebin, ki so na voljo na spletni strani">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h3>Vsebine, ki so na voljo na spletni strani:</h3>
                        </div>
                    </div>
                    <div *ngFor="let row of sitemap.abstract" class="row bottom-menu">
                        <div *ngFor="let segment of row" class="col-12 col-md-4 text-center segment mt-3">
                            <img [src]="cmsContentBasePath + segment.slika.url" />
                            <h4 class="mt-2">{{ segment.naziv }}</h4>
                            <ul class="p-2 m-0">
                                <li *ngFor="let kategorija of segment.kategorijas">
                                    <i class="fa fa-chevron-right mr-1" aria-hidden="true"></i>
                                    <a [routerLink]="['/vsebina', segment.urlPot, kategorija.urlPot]">{{ kategorija.naziv }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

        <div class="pt-4 pb-5 background-light-grey border-top">
            <nav aria-label="Meni najbolj pogosto uporabljene vsebine">
                <div class="container">
                    <div class="row justify-content-center bottom-menu">
                        <div class="col-12 col-md-4 text-center segment mt-3">
                            <div class="no-image">
                                <i class="fa fa-home" aria-hidden="true"></i>
                            </div>
                            <h4 class="mt-3"><a routerLink="/">Domov</a></h4>
                        </div>
                        <div class="col-12 col-md-4 text-center segment mt-3">
                            <img class="unabstract" src="assets/images/ikone/zvestoba-ogreva-klub-brown.png" alt="Ikona Klub zvestoba ogreva">
                            <h4 class="mt-3"><a routerLink="/klub-zvestoba-ogreva">Klub Zvestoba ogreva</a></h4>
                        </div>
                        <div class="col-12 text-center segment mt-3">
                            <h4 class="mt-3"><a routerLink="/energetika-v-druzbi">Energetika v družbi</a></h4>
                            <h4 class="mt-3"><a routerLink="/npn-paketi">Plinski kotel "na ključ"</a></h4>
                            <h4 class="mt-3"><a routerLink="/dela-na-omrezju">Dela na omrežju</a></h4>
                            <h4 class="mt-3"><a routerLink="/poslovni-odjem">Za podjetja</a></h4>

                            <ng-container *ngIf="!sitemapLoading">
                                <h4 *ngFor="let segment of sitemap.unabstract" class="mt-3">
                                    <a [routerLink]="['/vsebina', segment.urlPot]">{{ segment.naziv }}</a>
                                </h4>
                                <h4 class="mt-3">
                                    <a routerLink="/ceniki-dokumenti">Ceniki in dokumenti</a>
                                </h4>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </section>
</main>

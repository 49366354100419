import { Component, OnInit } from '@angular/core';
import { RazlagaRacunaBaseComponent } from '../razlaga-racuna-base/razlaga-racuna-base.component';

@Component({
  selector: 'app-razlaga-racuna-plin',
  templateUrl: './razlaga-racuna-plin.component.html',
  styleUrls: ['../razlaga-racuna-base/razlaga-racuna-base.component.scss', './razlaga-racuna-plin.component.scss']
})
export class RazlagaRacunaPlinComponent extends RazlagaRacunaBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}

<div id="main" class="w-100 p-2 p-sm-3">
  <div class="row">
    <div class="col-12">
      <!-- GLAVA -->
      <div id="glava" class="overlay {{ aktivno === razlagaSegmentEnum.GLAVA ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.GLAVA ? 'hidden' : ''}}">
        <img src="assets/images/razlaga-racuna/plin/plin_glava_open.png" alt="Glava računa" class="w-100 hidden">
      </div>
      <!-- <div class="row my-3" *ngIf="aktivno === razlagaSegmentEnum.GLAVA">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>GLAVA</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="1"
                                    description="Identifikacijska številka odjemalca in naziv odjemalca"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="2"
                                    description="Uradna številka izdanega računa (dokumenta)"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 px-5 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div> -->

      <!-- ODJEMNO MESTO & KLJUCNI PODATKI -->
      <div class="w-100 d-flex flex-row">
        <div id="odm-container">
          <div tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.ODM_PODATKI)"
               (click)="aktiviraj(razlagaSegmentEnum.ODM_PODATKI)" id="odm" class="overlay {{ aktivno === razlagaSegmentEnum.ODM_PODATKI ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.ODM_PODATKI ? 'hidden' : ''}}">
            <img src="assets/images/razlaga-racuna/plin/plin_odm_open.png" alt="Podatki odjemnega mesta" class="w-100 hidden">
          </div>
        </div>
        <div id="kljucni-container">
          <div id="kljucni" class="overlay {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.KLJUCNI_PODATKI ? 'hidden' : ''}}">
            <img src="assets/images/razlaga-racuna/plin/plin_kljucni_open.png" alt="Ključni podatki" class="w-100 hidden">
          </div>
        </div>
      </div>

      <!-- ODJEMNO MESTO -->
      <div class="row my-3" *ngIf="aktivno === razlagaSegmentEnum.ODM_PODATKI">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>PODATKI O ODJEMNEM MESTU</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="1"
                                    title="GSRN:">
            <p>Identifikacijska številka merilne naprave, določena ob priklopu naprave na omrežje</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="2"
                                    title="PRIKLJUČNA MOČ:">
            <p>Predviden obseg uporabe priključka izražen kot vsota predvidene moči plinskih trošil</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="3"
                                    title="ODJEMNA SKUPINA:">
            <p>Skupina, v katero operater distribucijskega sistema razvrsti posamezno odjemno mesto končnega odjemalca glede na letno predvideno distribuirano količino</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="4"
                                    title="OPERATER DISTRIBUCIJSKEGA OMREŽJA:">
            <p>Operater distribucijskega omrežja (ODS) je upravljalec sistema preko katerega poteka transport plina do končnega odjemalca. Nanj se obrnete v primeru morebitnih okvar (uhajanje plina, prekinitev dobave, vonj po plinu)</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- KLJUCNI PODATKI -->
      <div *ngIf="aktivno === razlagaSegmentEnum.KLJUCNI_PODATKI" class="row my-3">
          <div class="col-12 px-4 px-sm-5">
            <h4 class="category-title"><strong>KLJUČNI PODATKI</strong></h4>
          </div>
          <div class="col-12 col-sm-4 px-4 pl-sm-5">
            <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                      number="1"
                                      title="MESEC POPISA"></app-razlaga-racuna-tocka>
          </div>
          <div class="col-12 col-sm-4 px-4 px-sm-0">
            <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                      number="2"
                                      title="ROK PLAČILA"></app-razlaga-racuna-tocka>
          </div>
          <div class="col-12 col-sm-4 px-4 pr-sm-5">
            <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                      number="3"
                                      title="ZA PLAČILO"></app-razlaga-racuna-tocka>
          </div>
          <div class="col-12 my-2 my-sm-3">
            <div class="text-center">
              <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
            </div>
          </div>
      </div>

      <!-- SPECIFIKACIJA -->
      <div id="specifikacija" tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.SPECIFIKACIJA)"
           (click)="aktiviraj(razlagaSegmentEnum.SPECIFIKACIJA)" class="overlay {{ aktivno == razlagaSegmentEnum.SPECIFIKACIJA ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.SPECIFIKACIJA ? 'hidden' : ''}}">
        <img src="assets/images/razlaga-racuna/plin/plin_specifikacija_open.png" alt="Specifikacija računa" class="w-100 hidden">
      </div>
      <div *ngIf="aktivno === razlagaSegmentEnum.SPECIFIKACIJA" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>SPECIFIKACIJA</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="1"
                                    title="VRSTA ODČITKA">
            <p>Možne vrste odčitka so: Odčitek Energetike Ljubljana, Odčitek stranke, Ocena odčitka in Odčitek upravnik/pooblaščenec. Ste oddali stanje plinomera in se vam na računu vseeno pojavi tudi ocena odčitka? Preberite, <a href="https://www.bivanjudajemoutrip.si/novice/69/kako-obracunamo-vaso-porabo-plina">zakaj</a>.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="2"
                                    title="FAKTOR">
            <p>Kako poteka pretvorba iz odčitka na merilni napravi do količine, ki jo obračunamo v kWh? Preberite <a href="https://www.energetika.si/plin/preracun-porabe-zemeljskega-plina-iz-m3-v-kwh">tukaj</a>.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="3"
                                    title="VOLUMEN">
            <p>Kako poteka pretvorba iz odčitka na merilni napravi do količine, ki jo obračunamo v kWh? Preberite <a href="https://www.energetika.si/plin/preracun-porabe-zemeljskega-plina-iz-m3-v-kwh">tukaj</a>.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="4"
                                    title="KURILNOST">
            <p>Kako poteka pretvorba iz odčitka na merilni napravi do količine, ki jo obračunamo v kWh? Preberite <a href="https://www.energetika.si/plin/preracun-porabe-zemeljskega-plina-iz-m3-v-kwh">tukaj</a>.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="5"
                                    title="ZNESEK ZA DISTR. - PORABA in ZNESEK ZA DISTR. - PAVŠAL">
            <p>Oba zneska se nanašata na strošek omrežnine oz. distribucije, ki se deli na dva dela:</p>
            <ul style="list-style-image: none;">
              <li>znesek za variabilni del (poraba) in</li>
              <li>znesek za fiksni del (moč in zmogljivost).</li>
            </ul>
            <p>Navedeni zneski se obračunajo glede na uvrstitev odjemalca v odjemno skupino. Odjemna skupina se določi glede na predviden letni odjem zemeljskega plina, ki je ocenjen na podlagi pretekle porabe.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="6"
                                    title="IZVAJANJE MERITEV">
            <p>Postavka vsebuje izvajanje meritev ('najem' plinomera) in predvidoma 1-krat letno odčitavanje količin porabljenega zemeljskega plina.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="7"
                                    title="TROŠARINA">
            <p>Davek, vezan na porabo točno določenih izdelkov, v tem primeru energentov. Znesek trošarine je odvisen od količin porabljenega zemeljskega plina.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="8"
                                    title="OKOLJSKA DAJATEV CO2">
            <p>Taksa obremenjevanja zraka z emisijo ogljikovega dioksida, višina dajatve je odvisna od količin porabljenega zemeljskega plina.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="9"
                                    title="PRISPEVEK SPTE IN OVE">
            <p>Prispevek je namenjen povečevanju energetske učinkovitosti in zagotavljanju prihrankov energije pri končnih odjemalcih. Prispevek je naveden na mesečni položnici, njegovo višino določa država.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="10"
                                    title="PRISP. ZA ENER. UČINKOVITOST">
            <p>Postavka za stroške za prispevek za povečanje energetske učinkovitosti. Zbrana sredstva predstavljajo finančni vir za izvajanje programa za nepovratne finančne spodbude v ukrepe učinkovite rabe energije.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- GRAFI -->
      <div id="grafi" tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.GRAFI)"
           (click)="aktiviraj(razlagaSegmentEnum.GRAFI)" class="overlay {{ aktivno === razlagaSegmentEnum.GRAFI ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.GRAFI ? 'hidden' : '' }}">
        <img src="assets/images/razlaga-racuna/plin/plin_grafi_open.png" alt="Grafi" class="w-100 hidden">
      </div>
      <div *ngIf="aktivno === razlagaSegmentEnum.GRAFI" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>GRAFI</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="1">
            <p>Graf prikazuje razdelitev celotnega stroška na strošek energenta, strošek omrežnine ter prispevke in dajatve.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="2">
            <p>Graf prikazuje porabo v kilovatnih urah (kWh), pretvorjeno v deleže v odstotkih. Levi stolpec predstavlja porabo enakega meseca preteklega leta in služi kot osnova za prikaz ter predstavlja 100 % porabe. Desni stolpec je prikaz obračunanega meseca v tekočem letu v primerjavi z lanskim, pretvorjen v deleže v odstotkih.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.PLIN"
                                    number="3">
            <p>Graf prikazuje gibanje zunanjih temperatur in velja za odjemalce na omrežju Energetike Ljubljana.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">

        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-sm-6 mt-4">
    <div class="p-4 px-5 d-flex flex-row align-items-center extras">
      <p class="mb-0 mr-4 large"><i class="fa-solid fa-angles-right text-white" aria-hidden="true"></i></p>
      <p class="mb-0">Več podatkov o vaši porabi najdete na <a href="https://mojracun.energetika-lj.si" target="_blank" class="font-weight-bold">mojracun.energetika-lj.si</a>.</p>
    </div>
  </div>
  <div class="col-12 col-sm-6 mt-4">
    <div class="p-4 px-5 d-flex flex-row align-items-center extras">
      <p class="mb-0 mr-4 large"><i class="fa-solid fa-phone-volume text-white tilt-45" aria-hidden="true"></i></p>
      <div class="d-flex flex-column">
        <p class="mb-0">Spremembe sporočite na</p>
        <p class="mb-0"><a href="tel:0802882" class="font-weight-bold big d-inline-block">080 28 82</a> <span class="mx-2">ali</span> <a href="mailto:info@energetika.si" class="font-weight-bold big d-inline-block">info&#64;energetika.si</a></p>
      </div>
    </div>
  </div>
</div>

<link rel="prefetch" href="/assets/images/razlaga-racuna/plin/plin_glava_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/plin/plin_odm_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/plin/plin_kljucni_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/plin/plin_specifikacija_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/plin/plin_grafi_close.png">

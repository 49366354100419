import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import * as _ from 'lodash';

@Component({
    selector: 'app-dela-na-omrezju',
    templateUrl: './dela-na-omrezju.component.html',
    styleUrls: ['./dela-na-omrezju.component.scss']
})
export class DelaNaOmrezjuComponent implements OnInit {
    
    public filtersVisible: boolean = false;

    private queryParams: Params;

    constructor(
        private route: ActivatedRoute
    ) { }

    public ngOnInit() {

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;
        });
     }

    toggleFilters() {
        this.filtersVisible = !this.filtersVisible;
    }

    isActiveParamKey(id) {

        if (!_.isUndefined(this.queryParams['energent']) && this.queryParams['energent'] !== null) {
            if (id === null)
                return false;

            let ids = this.queryParams['energent'].split(',');

            return ids.indexOf(id.toString()) !== -1;
        } else if (id === null) {
            return true;
        }

        return false;
    }
}
<div id="main" class="w-100 p-2 p-sm-3">
  <div class="row">
    <div class="col-12">
      <!-- GLAVA -->
      <div id="glava" tabindex="0"
           class="overlay {{ aktivno === razlagaSegmentEnum.GLAVA ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.GLAVA ? 'hidden' : ''}}">
        <img src="assets/images/razlaga-racuna/elektrika/elektrika_glava_open.png" alt="Glava računa" class="w-100 hidden">
      </div>
      <!-- <div class="row my-3" *ngIf="aktivno === razlagaSegmentEnum.GLAVA">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>GLAVA</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="1"
                                    description="Identifikacijska številka odjemalca in naziv odjemalca"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="2"
                                    description="Uradna številka izdanega računa (dokumenta)"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 px-5 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div> -->

      <!-- ODJEMNO MESTO & KLJUCNI PODATKI -->
      <div class="w-100 d-flex flex-row">
        <div id="odm-container">
          <div tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.ODM_PODATKI)"
               (click)="aktiviraj(razlagaSegmentEnum.ODM_PODATKI)" id="odm" class="overlay {{ aktivno === razlagaSegmentEnum.ODM_PODATKI ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.ODM_PODATKI ? 'hidden' : ''}}">
            <img src="assets/images/razlaga-racuna/elektrika/elektrika_odm_open.png" alt="Podatki odjemnega mesta" class="w-100 hidden">
          </div>
        </div>
        <div id="kljucni-container">
          <div id="kljucni" class="overlay {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.KLJUCNI_PODATKI ? 'hidden' : ''}}">
            <img src="assets/images/razlaga-racuna/elektrika/elektrika_kljucni_open.png" alt="Ključni podatki" class="w-100 hidden">
          </div>
        </div>
      </div>

      <!-- ODJEMNO MESTO -->
      <div class="row my-3" *ngIf="aktivno === razlagaSegmentEnum.ODM_PODATKI">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>PODATKI O MERILNEM MESTU</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="1"
                                    title="GSRN MM:">
            <p>Identifikacijska številka merilnega mesta določenega ob priklopu naprave na omrežje.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="2"
                                    title="PRIKLJUČNA MOČ:">
            <p>Najvišja dovoljena vrednost moči, ki jo sme doseči končni odjemalec na merilnem mestu pri odjemu električne energije iz sistema oziroma proizvajalec pri dobavi električne energije v sistem in jo določi distribucijski operater.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="3"
                                    title="VAROVALKA:">
            <p>Moč glavne varovalke</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="4"
                                    title="OPERATER DISTRIBUCIJSKEGA OMREŽJA:">
            <p>Operater distribucijskega omrežja je upravljalec sistema, preko katerega poteka prenos elektrike do končnega odjemalca.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- KLJUCNI PODATKI -->
      <div *ngIf="aktivno === razlagaSegmentEnum.KLJUCNI_PODATKI" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>KLJUČNI PODATKI</strong></h4>
        </div>
        <div class="col-12 col-sm-4 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="1"
                                    title="MESEC POPISA"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-4 px-4 px-sm-0">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="2"
                                    title="ROK PLAČILA"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-4 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="3"
                                    title="ZA PLAČILO"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- SPECIFIKACIJA -->
      <div id="specifikacija" tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.SPECIFIKACIJA)"
           (click)="aktiviraj(razlagaSegmentEnum.SPECIFIKACIJA)" class="overlay {{ aktivno == razlagaSegmentEnum.SPECIFIKACIJA ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.SPECIFIKACIJA ? 'hidden' : ''}}">
        <img src="assets/images/razlaga-racuna/elektrika/elektrika_specifikacija_open.png" alt="Specifikacija računa" class="w-100 hidden">
      </div>
      <div *ngIf="aktivno === razlagaSegmentEnum.SPECIFIKACIJA" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>SPECIFIKACIJA</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="1"
                                    title="VRSTA ODČITKA">
            <p>Podatek o vrsti odčitka nam sporoči operater distribucijskega omrežja.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="2"
                                    title="OBRAČUNSKA MOČ">
            <p>Obračunska moč je izmerjena ali določena glede na varovalko. Lahko je manjša ali enaka priključni moči.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="3"
                                    title="PRISPEVEK ZA DELO. OPER. TRGA">
            <p>Prispevek za delovanje operaterja trga je prispevek, ki ga določa država in je namenjen pokrivanju stroškov operaterja trga Borzen.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="4"
                                    title="PRISP. ZA ENER. UČINKOVITOST">
            <p>Postavka za stroške za prispevek za povečanje energetske učinkovitosti. Zbrana sredstva predstavljajo finančni vir za izvajanje programa za nepovratne finančne spodbude v ukrepe učinkovite rabe energije.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="5"
                                    title="TROŠARINA">
            <p>Davek, vezan na porabo točno določenih izdelkov, v tem primeru energentov. Znesek trošarine je odvisen od količin porabljenega zemeljskega plina.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="6"
                                    title="PRISPEVEK SPTE IN OVE">
            <p>Prispevek je namenjen povečevanju energetske učinkovitosti in zagotavljanju prihrankov energije pri končnih odjemalcih. Prispevek je naveden na mesečni položnici, njegovo višino določa država.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- GRAFI -->
      <div id="grafi" tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.SPECIFIKACIJA)"
           (click)="aktiviraj(razlagaSegmentEnum.GRAFI)" class="overlay {{ aktivno === razlagaSegmentEnum.GRAFI ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.GRAFI ? 'hidden' : '' }}">
        <img src="assets/images/razlaga-racuna/elektrika/elektrika_grafi_open.png" alt="Grafi" class="w-100 hidden">
      </div>
      <div *ngIf="aktivno === razlagaSegmentEnum.GRAFI" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>GRAFI</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="1">
            <p>Graf prikazuje razdelitev celotnega stroška na strošek energenta, strošek omrežnine ter prispevke in dajatve.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.ELEKTRIKA"
                                    number="2">
            <p>Graf prikazuje porabo v kilovatnih urah (kWh), pretvorjeno v odstotke. Levi stolpec vedno prikazuje porabo tarife VT, desni prikazuje tarifo MT. Tarifa VT je oznaka za večjo dnevno tarifo, ki jo števec meri vsak delavnik med 6.00 in 22.00. Tarifa MT je manjša dnevna tarifa, ki velja vsak delavnik med 22.00 in 6.00 naslednji dan ter ob vikendih in dela prostih dneh med 0.00 in 24.00.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-sm-6 mt-4">
    <div class="p-4 px-5 d-flex flex-row align-items-center extras">
      <p class="mb-0 mr-4 large"><i class="fa-solid fa-angles-right text-white" aria-hidden="true"></i></p>
      <p class="mb-0">Več podatkov o vaši porabi najdete na <a href="https://mojracun.energetika-lj.si" target="_blank" class="font-weight-bold">mojracun.energetika-lj.si</a>.</p>
    </div>
  </div>
  <div class="col-12 col-sm-6 mt-4">
    <div class="p-4 px-5 d-flex flex-row align-items-center extras">
      <p class="mb-0 mr-4 large"><i class="fa-solid fa-phone-volume text-white tilt-45" aria-hidden="true"></i></p>
      <div class="d-flex flex-column">
        <p class="mb-0">Spremembe sporočite na</p>
        <p class="mb-0"><a href="tel:0802882" class="font-weight-bold big d-inline-block">080 28 82</a> <span class="mx-2">ali</span> <a href="mailto:info@energetika.si" class="font-weight-bold big d-inline-block">info&#64;energetika.si</a></p>
      </div>
    </div>
  </div>
</div>

<link rel="prefetch" href="/assets/images/razlaga-racuna/elektrika/elektrika_glava_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/elektrika/elektrika_odm_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/elektrika/elektrika_kljucni_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/elektrika/elektrika_specifikacija_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/elektrika/elektrika_grafi_close.png">

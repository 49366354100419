import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

import { AssetsDataService } from '../../services/assets-data.service';

import { NpnPaketFlat, NpnPaketZnamka } from '../../models/npn.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-npn-paketi',
  templateUrl: './npn-paketi.component.html',
  styleUrls: ['./npn-paketi.component.scss']
})
export class NpnPaketiComponent implements OnInit {

  public paketi: NpnPaketFlat[] = [];
  public paketiShown: NpnPaketFlat[] = [];
  public znamke: NpnPaketZnamka[] = [];

  public noFilters: boolean = false;
  public izgradnja: boolean = null;
  public pretStv: boolean = false;
  public akBojStv: boolean = false;
  public komAkSolStv: boolean = false;
  public akStv: boolean = false;
  public pretStvVec: boolean = false;
  public znamkaIds: number[] = [];

  public selectedPaketId: number = null;
  public selectedPaketZnamkaId: number = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: AssetsDataService
  ) { }

  public ngOnInit() {

    this.route.queryParamMap.subscribe(pm => {

      this.izgradnja = pm.has('izg') ? pm.get('izg') === 'true' : null;

      this.pretStv = pm.get('pretStv') === 'true';
      this.akBojStv = pm.get('akBojStv') === 'true';
      this.komAkSolStv = pm.get('komAkSolStv') === 'true';
      this.akStv = pm.get('akStv') === 'true';
      this.pretStvVec = pm.get('pretStvVec') === 'true';

      this.znamkaIds = pm.has('znamke') ? pm.get('znamke').split(',').map(i => parseInt(i)) : [];

      this.loadPaketi();
    });

    this.loadZnamke();
  }

  public selectIzgradnja(v: boolean) {

    if (this.izgradnja === v)
      this.izgradnja = null;
    else
      this.izgradnja = v;

    this.reloadRouter();
  }

  public selectPretocnaStv() {
    this.pretStv = !this.pretStv;
    this.reloadRouter();
  }

  public selectAkumulatorskaBojlerStv() {
    this.akBojStv = !this.akBojStv;
    this.reloadRouter();
  }

  public selectKombAkumulatorskaSolarnaStv() {
    this.komAkSolStv = !this.komAkSolStv;
    this.reloadRouter();
  }

  public selectAkumulatorskaStv() {
    this.akStv = !this.akStv;
    this.reloadRouter();
  }

  public selectPretocnaStvVecStan() {
    this.pretStvVec = !this.pretStvVec;
    this.reloadRouter();
  }

  public deselectStv() {

    this.pretStv = false;
    this.akBojStv = false;
    this.komAkSolStv = false;
    this.akStv = false;
    this.pretStvVec = false;
    this.selectZnamka(null);
  }

  public selectZnamka(id: number) {

    let znamkeIds = this.znamkaIds.map(i => i);

    if (id !== null) {
      let idx = this.znamkaIds.indexOf(id);
      if (idx === -1)
        znamkeIds.push(id);
      else
        znamkeIds.splice(idx, 1);
    } else {
      znamkeIds = [];
    }

    this.reloadRouter(znamkeIds);
  }

  public isZnamkaActive(id: number) {
    return this.znamkaIds.indexOf(id) !== -1;
  }

  public showMore() {

    if (!this.noFilters)
      return;

    this.paketiShown = this.paketi.slice(0, this.paketiShown.length + 12);
  }

  public selectPackage(paket: NpnPaketFlat) {
    paket.selected = !paket.selected;
  }

  public sendSvetovanjePackage(paket: NpnPaketFlat, event: Event) {

    event.stopPropagation();
    this.router.navigate(['/povprasevanje'], {
      queryParams: {
        katId: 23,
        step: 4,
        refUrl: window.location.href,
        refTitle: paket !== null ? `NPN Paketi - ${paket.znamkaIme} ${paket.oznaka}` : 'Splošno o NPN paketih'
      }
    });
  }

  private reloadRouter(znamkaIds: number[] = null) {

    let params: any = {};

    params.izg = _.isBoolean(this.izgradnja) ? this.izgradnja : null;

    params.pretStv = this.pretStv ? true : null;
    params.akBojStv = this.akBojStv ? true : null;
    params.komAkSolStv = this.komAkSolStv ? true : null;
    params.akStv = this.akStv ? true : null;
    params.pretStvVec = this.pretStvVec ? true : null;

    if (znamkaIds !== null)
      params.znamke = znamkaIds.length > 0 ? znamkaIds.join(',') : null;

    this.router.navigate([], { queryParams: params, queryParamsHandling: 'merge', relativeTo: this.route });
  }

  private loadPaketi() {

    this.noFilters = this.izgradnja === null && !this.pretStv && !this.akBojStv && !this.komAkSolStv && !this.akStv && !this.pretStvVec && this.znamkaIds.length === 0;

    this.dataService.getNpnPaketi(this.izgradnja, this.pretStv, this.akBojStv, this.komAkSolStv, this.akStv, this.pretStvVec, this.znamkaIds)
      .pipe(
        map(p => {
          let newP: NpnPaketFlat = _.cloneDeep(p);
          newP.selected = false;
          return p;
        })
      )
      .subscribe(res => {
        this.paketi = res;
        this.paketiShown = this.noFilters ? res.slice(0, 12) : res;
      });
  }

  private loadZnamke() {

    this.dataService.getNpnZnamke().subscribe(res => {
      this.znamke = res;
    });
  }
}

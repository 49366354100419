import { Route } from '@angular/router';

import { NarociloSmsObvescanjeFindComponent } from './find/find.component';
import { NarociloSmsObvescanjeOrderComponent } from './order/order.component';
import { NarociloSmsObvescanjeBrezNaslovaComponent } from './brez-naslova/brez-naslova.component';
import { NarociloSmsObvescanjePogojiComponent } from './pogoji/pogoji.component';

import { Metadata } from '../../../models/metadata.model';

const findMetadata: Metadata = {
    title: 'SMS obvestila o delih na omrežju: Izberi naslov',
    description: 'SMS obvestila o delih na omrežju',
    keywords: ['sms', 'obveščanje', 'obvestila', 'delo na omrežju', 'zemeljski plin', 'daljinsko ogrevanje', 'toplota'],
    imageUrl: 'assets/images/sms-obvescanje.jpg',
    imageWidth: 749,
    imageHeight: 500,
};

const orderMetadata: Metadata = {
    title: 'SMS obvestila o delih na omrežju',
    skipAutoSetup: true
};

const pogojiMetadata: Metadata = {
    title: 'SMS obvestila o delih na omrežju: Pogoji in navodila za uporabo storitve',
    description: 'Pogoji in navodila za uporabo storitve "Sms obveščanje o delih na omrežju"',
    keywords: ['sms', 'obveščanje', 'obvestila', 'delo na omrežju', 'pogoji', 'navodila', 'uporaba storitve'],
    imageUrl: 'assets/images/sms-obvescanje.jpg',
    imageWidth: 749,
    imageHeight: 500
};

const manjkaMetadata: Metadata = {
    title: 'SMS obvestila o delih na omrežju: Manjkajoči naslov',
    description: 'Manjkajoči naslov za uporabo storitve "SMS obvestila o delih na omrežju"',
    keywords: ['sms', 'obveščanje', 'obvestila', 'delo na omrežju', 'zemeljski plin', 'daljinsko ogrevanje', 'toplota', 'manjkajoci naslov'],
    imageUrl: 'assets/images/sms-obvescanje.jpg',
    imageWidth: 749,
    imageHeight: 500
}

export const route: Route = {
    path: 'sms-obvescanje',
    children: [{
        path: '',
        component: NarociloSmsObvescanjeFindComponent,
        data: { metadata: findMetadata }
    }, {
        path: 'naroci/:id',
        component: NarociloSmsObvescanjeOrderComponent,
        data: { metadata: orderMetadata }
    }, {
        path: 'manjkajoci-naslov',
        component: NarociloSmsObvescanjeBrezNaslovaComponent,
        data: { metadata: manjkaMetadata }
    }, {
        path: 'pogoji',
        component: NarociloSmsObvescanjePogojiComponent,
        data: { metadata: pogojiMetadata }
    }]
};
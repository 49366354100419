import { Component, OnInit } from '@angular/core';

import { first, map } from 'rxjs/operators';

import { CmsService } from '../../../services/cms.service';

import { Document } from '../../../models/cms.model';

@Component({
    selector: 'app-klub-zvestoba-ogreva-arhiv',
    templateUrl: './arhiv.component.html',
    styleUrls: ['./arhiv.component.scss']
})
export class KlubZvestobaOgrevaArhivComponent implements OnInit {

    public ugodnostiDocs: Document[] = [];

    constructor(
        private cmsService: CmsService
    ) { }

    ngOnInit() { 

        this.cmsService.getKzoSeznamUgodnostiAsync()
            .pipe(
                map(res => res.length >= 1 ? res.slice(1) : []),
                first()
            )
            .subscribe(res => {
                this.ugodnostiDocs = res;
            });
    }
}
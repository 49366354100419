<div id="main" class="w-100 p-2 p-sm-3">
  <div class="row">
    <div class="col-12">
      <!-- GLAVA -->
      <div id="glava" tabindex="0" class="overlay {{ aktivno === razlagaSegmentEnum.GLAVA ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.GLAVA ? 'hidden' : ''}}">
        <img src="assets/images/razlaga-racuna/toplota/toplota_glava_open.png" alt="Glava računa" class="w-100 hidden">
      </div>
      <!-- <div class="row my-3" *ngIf="aktivno === razlagaSegmentEnum.GLAVA">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>GLAVA</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="1"
                                    description="Identifikacijska številka odjemalca in naziv odjemalca"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="2"
                                    description="Uradna številka izdanega računa (dokumenta)"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 px-5 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div> -->

      <!-- ODJEMNO MESTO & KLJUCNI PODATKI -->
      <div class="w-100 d-flex flex-row">
        <div id="odm-container">
          <div tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.ODM_PODATKI)" (click)="aktiviraj(razlagaSegmentEnum.ODM_PODATKI)"
               id="odm" class="overlay {{ aktivno === razlagaSegmentEnum.ODM_PODATKI ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.ODM_PODATKI ? 'hidden' : ''}}">
            <img src="assets/images/razlaga-racuna/toplota/toplota_odm_open.png" alt="Podatki odjemnega mesta" class="w-100 hidden">
          </div>
        </div>
        <div id="kljucni-container">
          <div id="kljucni" class="overlay {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.KLJUCNI_PODATKI ? 'hidden' : ''}}">
            <img src="assets/images/razlaga-racuna/toplota/toplota_kljucni_open.png" alt="Ključni podatki" class="w-100 hidden">
          </div>
        </div>
      </div>

      <!-- ODJEMNO MESTO -->
      <div class="row my-3" *ngIf="aktivno === razlagaSegmentEnum.ODM_PODATKI">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>PODATKI O MESTU DOBAVE</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="1"
                                    title="PRIKLJUČNA MOČ:">
            <p>Nazivna moč internih toplotnih naprav odjemalca, določena s projektno dokumentacijo strojnih instalacij in strojne opreme internih toplotnih naprav, v skladu z aktom in s tehničnimi zahtevami distributerja toplote o priključitvi stavb na distribucijski sistem.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">

        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- KLJUCNI PODATKI -->
      <div *ngIf="aktivno === razlagaSegmentEnum.KLJUCNI_PODATKI" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>KLJUČNI PODATKI</strong></h4>
        </div>
        <div class="col-12 col-sm-4 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="1"
                                    title="MESEC POPISA"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-4 px-4 px-sm-0">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="2"
                                    title="ROK PLAČILA"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-4 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="3"
                                    title="ZA PLAČILO"></app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- SPECIFIKACIJA -->
      <div id="specifikacija" tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.SPECIFIKACIJA)"
           (click)="aktiviraj(razlagaSegmentEnum.SPECIFIKACIJA)" class="overlay {{ aktivno == razlagaSegmentEnum.SPECIFIKACIJA ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.SPECIFIKACIJA ? 'hidden' : ''}}">
        <img src="assets/images/razlaga-racuna/toplota/toplota_specifikacija_open.png" alt="Specifikacija računa" class="w-100 hidden">
      </div>
      <div *ngIf="aktivno === razlagaSegmentEnum.SPECIFIKACIJA" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>SPECIFIKACIJA</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="1"
                                    title="VRSTA ODČITKA">
            <p>Možne vrste odčitka so: Odčitek Energetike Ljubljana, Odčitek stranke, Ocena odčitka in Odčitek upravnik/pooblaščenec.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="2"
                                    title="DELITEV V %">
            <p>Odstotek porabljene energije na odjemalca. Delež variabilnih stroškov (poraba toplote) se spreminja glede na porabo, delež fiksnih stroškov (obračunska moč, vzdrževanje merilnika) pa je stalen. Podatek dobavitelju posreduje izvajalec delitve, s katerim imajo porabniki podpisan sporazum o načinu in delitvi energije.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="3"
                                    title="DOBAVLJENA TOPLOTA">
            <p>Variabilni del cene (dobavljena toplota) se obračuna glede na dejansko porabo (razlika med odčitkoma). V primeru večstanovanjskih stavb se poraba deli v skladu z delilnikom, podatki o izvajalcu delitve in deležih so navedeni na računu.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="4"
                                    title="OBRAČUNSKA MOČ">
            <p>Obračunska moč je fiksni del cene pri dobavi toplote in se ne spreminja glede na porabo. Obračunska moč je zakupljena in nastavljena moč na toplotni postaji odjemalca, ki je lahko enaka ali manjša od priključne moči.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="5"
                                    title="VZDRŽEVANJE MERILNIKA TOPLOTE">
            <p>Strošek vzdrževanja se obračunava mesečno. Znesek vključuje izvajanje rednih overitev ter kritje stroškov menjave merilnika v primeru okvar.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="6"
                                    title="PRISPEVEK ZA ENERGETSKO UČINKOVITOST">
            <p>Postavka za stroške za prispevek za povečanje energetske učinkovitosti. Zbrana sredstva predstavljajo finančni vir za izvajanje programa za nepovratne finančne spodbude v ukrepe učinkovite rabe energije.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="7"
                                    title="PRISPEVEK SPTE IN OVE">
            <p>Prispevek je namenjen povečevanju energetske učinkovitosti in zagotavljanju prihrankov energije pri končnih odjemalcih. Prispevek je naveden na mesečni položnici, njegovo višino prav tako določa država.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>

      <!-- GRAFI -->
      <div id="grafi" tabindex="0" (keydown)="accessibillityAktiviraj($event, razlagaSegmentEnum.GRAFI)"
           (click)="aktiviraj(razlagaSegmentEnum.GRAFI)" class="overlay {{ aktivno === razlagaSegmentEnum.GRAFI ? 'active' : '' }} {{ aktivno !== razlagaSegmentEnum.NONE && aktivno !== razlagaSegmentEnum.GRAFI ? 'hidden' : '' }}">
        <img src="assets/images/razlaga-racuna/toplota/toplota_grafi_open.png" alt="Grafi" class="w-100 hidden">
      </div>
      <div *ngIf="aktivno === razlagaSegmentEnum.GRAFI" class="row my-3">
        <div class="col-12 px-4 px-sm-5">
          <h4 class="category-title"><strong>GRAFI</strong></h4>
        </div>
        <div class="col-12 col-sm-6 px-4 pl-sm-5">
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="1">
            <p>Graf prikazuje razdelitev celotnega stroška na energent, omrežnino ter prispevke in dajatve.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="2">
            <p>Graf prikazuje primerjavo porabo v megavatnih urah (MWh), pretvorjeno v odstotke. Levi stolpec je osnova za prikaz in predstavlja 100 % porabe. Desni stolpec je prikaz obračunanega meseca.</p>
          </app-razlaga-racuna-tocka>
          <app-razlaga-racuna-tocka [energent]="razlagaEnergentEnum.TOPLOTA"
                                    number="3">
            <p>Graf prikazuje gibanje zunanjih temperatur.</p>
          </app-razlaga-racuna-tocka>
        </div>
        <div class="col-12 col-sm-6 px-4 pr-sm-5">

        </div>
        <div class="col-12 my-2 my-sm-3">
          <div class="text-center">
            <button (click)="zapri()" class="close"><strong>ZAPRI</strong></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-sm-6 mt-4">
    <div class="p-4 px-5 d-flex flex-row align-items-center extras">
      <p class="mb-0 mr-4 large"><i class="fa-solid fa-angles-right text-white" aria-hidden="true"></i></p>
      <p class="mb-0">Več podatkov o vaši porabi najdete na <a href="https://mojracun.energetika-lj.si" target="_blank" class="font-weight-bold">mojracun.energetika-lj.si</a>.</p>
    </div>
  </div>
  <div class="col-12 col-sm-6 mt-4">
    <div class="p-4 px-5 d-flex flex-row align-items-center extras">
      <p class="mb-0 mr-4 large"><i class="fa-solid fa-phone-volume text-white tilt-45" aria-hidden="true"></i></p>
      <div class="d-flex flex-column">
        <p class="mb-0">Spremembe sporočite na</p>
        <p class="mb-0"><a href="tel:0802882" class="font-weight-bold big d-inline-block">080 28 82</a> <span class="mx-2">ali</span> <a href="mailto:info@energetika.si" class="font-weight-bold big d-inline-block">info&#64;energetika.si</a></p>
      </div>
    </div>
  </div>
</div>

<link rel="prefetch" href="/assets/images/razlaga-racuna/toplota/toplota_glava_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/toplota/toplota_odm_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/toplota/toplota_kljucni_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/toplota/toplota_specifikacija_close.png">
<link rel="prefetch" href="/assets/images/razlaga-racuna/toplota/toplota_grafi_close.png">

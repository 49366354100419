import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { CmsService } from '../../services/cms.service';

import { DocumentCategory } from '../../models/cms.model';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-ceniki-dokumenti',
    templateUrl: './ceniki-dokumenti.component.html',
    styleUrls: ['./ceniki-dokumenti.component.scss']
})
export class CenikiDokumentiComponent implements OnInit {
    
    public categoriesAsync: Observable<DocumentCategory[]>;

    public filtersVisible: boolean = false;
    public arhiv: boolean = false;

    public queryParams: Params;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService
    ) { }

    public ngOnInit() {

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;

            this.arhiv = qp.arhiv === 'true';

            let cenik = qp.cenik ? qp.cenik === 'true' : null;
            this.categoriesAsync = this.cmsService.getCmsDocumentCategoriesAsync().pipe(
                map(res => cenik === null ? res : res.filter(r => r.splosno || r.cenik === cenik))
            );
        });
    }

    arhivChange(value) {
        this.router.navigate(['./'], { queryParams: { arhiv: value.checked }, queryParamsHandling: 'merge', relativeTo: this.route });
    }

    toggleFilters() {
        this.filtersVisible = !this.filtersVisible;
    }

    isActiveParamKey(id, key = 'kat') {

        if (!_.isUndefined(this.queryParams[key]) && this.queryParams[key] !== null) {
            if (id === null)
                return false;

            let ids = this.queryParams[key].split(',');

            return ids.indexOf(id.toString()) !== -1;
        } else if (id === null) {
            return true;
        }

        return false;
    }

    updateQueryParams(id, key = 'kat') {
            
        let queryParams = { kat: this.queryParams.kat ? this.queryParams.kat : null };

        if (id !== null) {
            let ids;

            if (!_.isUndefined(this.queryParams[key]) && this.queryParams[key] !== null)
                ids = this.queryParams[key].split(',');
            else
                ids = [];

            let idIdx = ids.indexOf(id.toString());
            if (idIdx === -1)
                ids.push(id.toString());
            else
                ids.splice(idIdx, 1);

            queryParams[key] = ids.join(',');
        } else {
            queryParams[key] = null;
        }

        return queryParams;
    }
}
import { Route } from '@angular/router';

import { AccessibilityStatementComponent } from './accessibility-statement.component';

import { Metadata } from '../../../models/metadata.model';

const metadata: Metadata = {
    title: 'Izjava o dostopnosti',
    description: 'Izjava o dostopnosti na spletnem portalu'
};

export const route: Route = {
    path: 'izjava-o-dostopnosti',
    component: AccessibilityStatementComponent,
    data: {
        metadata: metadata
    }
};

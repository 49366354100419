import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';

import { ThirdPartyService } from '../../../services/3rd-party.service';
import {CookiesService} from '../../../services/cookies.service';

@Component({
    selector: 'app-energetika-nipke',
    templateUrl: './energetika-nipke.component.html',
    styleUrls: ['./energetika-nipke.component.scss']
})
export class EnergetikaVDruzbiNipkeComponent implements OnInit, AfterViewInit {

    public aboutNipkeExtended: boolean = false;

    public nipkePriporocaSelected: NipkeReccommendation;
    public nipkePriporocaItems: NipkeReccommendation[] = [{
        title: 'Top izbor',
        content: '<p><strong><a class="outline-offset outline-inverse" href="https://www.youtube.com/watch?v=DYaq2sWTWAA" target="_blank">Abstract</a></strong></p>' +
            '<p><strong><a class="outline-offset outline-inverse" href="https://www.youtube.com/watch?v=WT7gn6nhsAc" target="_blank">Quincy</a></strong></p>' +
            '<p><strong><a class="outline-offset outline-inverse" href="https://www.youtube.com/watch?v=z7jIOawq8y8" target="_blank">Nina Simone</a></strong></p>' +
            '<p><strong><a class="outline-offset outline-inverse" href="https://www.youtube.com/watch?v=kB15UFO5ebA" target="_blank">Jim and Andy</a></strong></p>',
        iconClass: 'top-izbor'
    }, {
        title: 'Knjige',
        content: '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Jim+Stovall+Največji+dar" target="_blank"><strong>Jim Stovall</strong><br />Največji dar</a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Paulo+Coelho+Alkimist" target="_blank"><strong>Paulo Coelho</strong><br />Alkimist</a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Marci+Shimoff+Ljubezen+kar+tako" target="_blank"><strong>Marci Shimoff</strong><br />Ljubezen kar tako</a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Psi+knjiga" target="_blank"><strong>Psi</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Martin+Kojc+Učbenik+življenja" target="_blank"><strong>Martin Kojc</strong><br />Učbenik življenja</a></p>',
        iconClass: 'knjige'
    }, {
        title: 'Filmi',
        content: '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Any+given+Sunday" target="_blank"><strong>Any given Sunday</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Finding+Forester" target="_blank"><strong>Finding Forester</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Yes+Man" target="_blank"><strong>Yes Man</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=The+Pursuit+Of+Happyness" target="_blank"><strong>The Pursuit Of Happyness</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Dead+Poet+Society" target="_blank"><strong>Dead Poet Society</strong></a></p>',
        iconClass: 'filmi'
    }, {
        title: 'Glasba',
        content: '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Tupac" target="_blank"><strong>Tupac</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Michael+Jackson" target="_blank"><strong>Michael Jackson</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Stevie+Wonder" target="_blank"><strong>Stevie Wonder</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Eric+Clapton" target="_blank"><strong>Eric Clapton</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=J.+Cole" target="_blank"><strong>J. Cole</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Quincy+Jones" target="_blank"><strong>Quincy Jones</strong></a></p>',
        iconClass: 'glasba'
    }, {
        title: 'Športniki',
        content: '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Roger+Federer" target="_blank"><strong>Roger Federer</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Arnold+Schwarzenegger" target="_blank"><strong>Arnold Schwarzenegger</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Andre+Agassi" target="_blank"><strong>Andre Agassi</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Mohamad+Ali" target="_blank"><strong>Mohamad Ali</strong></a></p>' +
            '<p><a class="outline-offset outline-inverse" href="https://www.google.si/search?q=Luka+Dončič" target="_blank"><strong>Luka Dončič</strong></a></p>',
        iconClass: 'sportniki'
    }];

    public showFbContent: boolean = false;

    private nipkeImage: string;

    constructor(
        private elementRef: ElementRef,
        private thirdPartyService: ThirdPartyService,
        private cookiesService: CookiesService
    ) {
        this.nipkePriporocaSelected = this.nipkePriporocaItems[0];
     }

    ngOnInit() {
      this.cookiesService.selectedTheme$.subscribe(res => {
        switch (res) {
          case 'theme-normal':
            this.showFbContent = true;
            this.thirdPartyService.eflsightInit(this.elementRef);
            this.thirdPartyService.fbSdkInit();
            this.nipkeImage = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-black.png';
            break;
          case 'theme-wcag':
          case 'theme-black-white':
          case 'theme-black-beige':
          case 'theme-black-yellow':
            this.nipkeImage = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-black.png';
            break;
          case 'theme-white-black':
            this.nipkeImage = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-white.png';
            break;
          case 'theme-yellow-blue':
            this.nipkeImage = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-yellow.png';
            break;
          case 'theme-lime-black':
            this.nipkeImage = 'assets/images/energetika-v-druzbi/energetika-nipke-logo-lime.png';
            break;

          default:
            this.showFbContent = false;
            break;
        }
      });
    }

    ngAfterViewInit() {

        this.thirdPartyService.eflsightInit(this.elementRef);
        this.thirdPartyService.fbSdkInit();
    }

    public nipkePriporocaSelect(selected: NipkeReccommendation) {
        this.nipkePriporocaSelected = selected;
    }

    accessibilityNipkePriporocaSelect(event: KeyboardEvent, selected: NipkeReccommendation) {
      if (event) {
        switch (event.code) {
          case 'Enter':
            this.nipkePriporocaSelect(selected);
            break;
        }
      }
    }

    aboutNipkeExtend(extend) {
        this.aboutNipkeExtended = extend;
    }


  getEnergetikaNipkeLogo() {
    return this.nipkeImage;
  }
}

interface NipkeReccommendations {
    recommendations: NipkeReccommendation[];
}

interface NipkeReccommendation {
    title: string;
    content: string;
    iconClass: string;
}

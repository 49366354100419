import { Component, OnInit, Input, Output, EventEmitter, PLATFORM_ID, Inject, HostBinding } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { CmsService } from '../../services/cms.service';

import { OfferExtended, CallToActionType } from '../../models/cms.model';
import {CookiesService} from '../../services/cookies.service';

@Component({
    selector: 'action-version',
    templateUrl: './action-version.component.html',
    styleUrls: ['./action-version.component.scss'],
})
export class ActionVersionComponent implements OnInit {

    @Input()
    public offerId: number;

    @Input()
    public version: number = null;

    @Input()
    public preview: boolean = false;

    @Input()
    public offerTitle: string;

    @Input()
    public contentSegmentTitle: string;

    @Input()
    public contentKategorijaTitle: string;

    @Input()
    @HostBinding('class.expandable')
    public isExpandable: boolean = false;

    @Input()
    @HostBinding('class.expanded')
    public expanded: boolean = false;

    @Input()
    public left: boolean = true;

    @Output()
    public onContentExpanded: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public onContentDetailsExpanded: EventEmitter<boolean> = new EventEmitter();

    public isBrowser: boolean = true;
    public cmsContentBasePath: string;

    public offer: OfferExtended;
    public loading: boolean = true;

    public expandedDetails: boolean = false;

    private filterVsebina: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private cmsService: CmsService,
        private cookiesService: CookiesService
    ) { }

    public ngOnInit() {

        this.isBrowser = isPlatformBrowser(this.platformId);
        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.cmsService.getOfferById(this.offerId, this.preview && this.version ? this.version : null)
            .subscribe(res => {
                this.offer = res;
                this.offerTitle = res.akcija.naziv;

                if (this.offer && this.offer.ctaTip && CallToActionType[this.offer.ctaTip].toString() === CallToActionType.DOKUMENT.toString())
                    this.offer.ctaDokument.url = `${this.cmsService.serviceUrl}/dokument/${this.offer.ctaDokument.id}/download`;

                this.loading = false;
            });

      this.cookiesService.selectedTheme$.subscribe(res => {
        switch (res) {
          case 'theme-wcag':
          case 'theme-normal':
            this.filterVsebina = false;
            break;
          case 'theme-white-black':
          case 'theme-black-white':
          case 'theme-black-beige':
          case 'theme-black-yellow':
          case 'theme-yellow-blue':
          case 'theme-lime-black':
            this.filterVsebina = true;
            break;
        }
      });
    }

    public expand() {
        this.expanded = !this.expanded;
        this.onContentExpanded.emit(this.expanded);
    }

    public expandDetails() {
        this.expandedDetails = !this.expandedDetails;
        this.onContentDetailsExpanded.emit(this.expandedDetails);
    }

  accessibilityExpand(event: KeyboardEvent) {
    if (event) {
      switch (event.code) {
        case 'Enter':
          this.expand();
          break;
      }
    }
  }

  getVsebina(vsebina: string): string {
    if (this.filterVsebina) {
      // filter colors and backgrounds on monochromatic
      vsebina = vsebina.replace(/style="backgroun[a-zA-Z0-9:#;\.\s\(\)\-\,]*"/gi, '');
      vsebina = vsebina.replace(/style="colo[a-zA-Z0-9:#;\.\s\(\)\-\,]*"/gi, '');
      vsebina = vsebina.replace(/color="[a-zA-Z0-9:#;\.\s\(\)\-\,]*"/gi, '');

      vsebina = vsebina.replace(/backgroun[a-zA-Z0-9:#;\.\s\(\)\-\,]*;/gi, '');
      vsebina = vsebina.replace(/colo[a-zA-Z0-9:#;\.\s\(\)\-\,]*;/gi, '');
    }
    return vsebina;
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TrustPipe } from './trust.pipe';
import {EllipsisPipe} from './ellipsis.pipe';

@NgModule({
  declarations: [
    TrustPipe,
    EllipsisPipe
  ],
  imports: [
    BrowserModule
  ],
  exports: [
    TrustPipe,
    EllipsisPipe
  ]
})
export class PipesModule { }

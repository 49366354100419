<article>
    <div class="top-background">
        <div class="subpage-title">
            <h1>Dolgoročna partnerstva so nam najpomembnejša.<br />
            <strong>Poti na Šmarno goro vzdržujemo že 15 let.</strong></h1>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block py-4 py-md-6">
                <div class="col-12 col-lg-6">
                    <h2 class="left-md">Gibanje nam daje utrip za življenje.</h2>
                </div>
                <div class="col-12 col-lg-6">
                    <p>Energetika Ljubljana (in pred njo TE-TOL) podpira dejavnost turističnih in športnih delavcev izpod Šmarne gore že od jeseni 2004 dalje. V tem času smo praktično vsako leto dodali drobec v mozaik urejenosti te naše najbolj obiskane gore, enega pomembnejših simbolov zelene Ljubljane.</p>
                    <p>Največ pozornosti je bilo namenjeno urejanju poti, ki jih vodna erozija iz leta v leto bolj najeda. Najobsežnejša tovrstna akcija je potekala v letu 2014, ko smo odpravljali posledice katastrofalnega žledoloma. V letu 2015 smo obnovili vseh 28 lesenih klopi, ki so ob poteh in na izhodiščih na voljo obiskovalcem.</p>
                    <p class="mb-0">V letu 2020 je Planinsko oz. Turistično društvo Šmarna gora kljub epidemiji korona virusa uspelo obnoviti zaščitni premaz na 25 klopeh in obnoviti  vseh 20 informativnih tabel.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="options-list no-title py-6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="pb-0 text-notice">Ni vam potrebno vedno do vrha, <br /><strong>da naredite nekaj za svoje srce.</strong></p>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block pt-4 pt-md-6">
                <div class="col-12 col-lg-6">
                    <h2 class="left-md">Srce Ljubljane je zeleno.</h2>
                </div>
                <div class="col-12 col-lg-6">
                    <p>V letu 2016, ko je Ljubljana prejela častni naziv Zelena prestolnica Evrope 2016, smo prizadevanja za urejenost in varnost poti na Šmarno goro še dodatno prepletli, povezali in tudi tako pustili še eno odgovorno in pozitivno sled v skrbi za naše okolje in s tem za kakovost našega bivanja.</p>
                    <p class="mb-0">Posebno pozornost smo namenili dvema potema, ki imata za Šmarno goro poseben pomen, in sicer najbolj obiskana in obremenjena »Pot čez korenine« in najlepša, najbolj razgledna in najmanj obiskana krožna »Pot Svobode«, ki ima gledano iz ptičje perspektive obliko srca, simbola številnih razsežnosti, med drugim ali predvsem pa tudi simbola projekta Ljubljana – Zelena prestolnica Evrope 2016. Zato smo jo v sklopu projekta imenovali »Pot srca.«</p>
                </div>
            </div>
            <div class="row content-block mt-4 pb-4 pb-md-6">
                <div class="col-12 col-lg-6">
                    <h2 class="left-md">Naj gorski tek na svetu.</h2>
                </div>
                <div class="col-12 col-lg-6">
                    <p class="mb-0">Leta 2020 je prireditev Tek na Šmarno goro na izboru Mednarodne zveze za gorske teke (WMRA) zmagala v konkurenci 24 tekov s celega sveta. Tek na Šmarno goro ima dušo, za njim stoji srčna ekipa, ki ga je katapultirala na prvo mesto svetovnega izbora.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey py-4 py-md-6">
        <div class="container">
            <div class="row content-block poti">
                <div class="col-12 col-md-6 text-center img-margin text-md-right">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-1.jpg" alt="Slika clovek pripravja poti na Šmarno goro" />
                </div>
                <div class="col-12 col-md-6 img-margin text-center text-md-left">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-3.jpg" alt="Slika človeka pripravljata poti na Šmarno goro" />
                </div>
                <div class="col-12 col-md-6 text-center text-md-right img-margin-sm">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-4.jpg" alt="Slika označitev poti na Šmarno goro"/>
                </div>
                <div class="col-12 col-md-6 text-center text-md-left">
                    <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-2.jpg" alt="Slika označitev klopi na Šmarno goro"/>
                </div>
            </div>
        </div>
    </div>

    <div class="options-list no-title py-6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="pb-0 text-notice"><strong>Odkrijte nove poti na Šmarno goro.</strong><br />in izberite svojo.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="">
        <div class="container">
            <div class="row content-block py-4 py-md-6">
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23864898" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-zavrh.png" alt="Zemljevid poti Izhodišče Zavrh" />
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo" />
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Izhodišče Zavrh</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>4,01 km</strong></p>
                            <p>Višinska razlika:<br/><strong>310 m</strong></p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23865040" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-tacen-romarska.png" alt="Zemljevid poti Izhodišče Tacen Romarska"/>
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo" />
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Izhodišče Tacen - Romarska</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>5,31 km</strong></p>
                            <p>Višinska razlika:<br/><strong>354 m</strong></p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23850712" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-tacen-mazijeva.png" alt="Zemljevid poti Izhodišče Tacen Mazijeva" />
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo" />
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Izhodišče Tacen - Mazijeva</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>3,46 km</strong></p>
                            <p>Višinska razlika:<br/><strong>443 m</strong></p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23851561" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-pirnice.png" alt="Zemljevid poti Izhodišče Pirniče"/>
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo" />
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Izhodišče Pirniče</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>4,55 km</strong></p>
                            <p>Višinska razlika:<br/><strong>371 m</strong></p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23850675" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-smartno.png" alt="Zemljevid poti Izhodišče Šmartno" />
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo"/>
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Izhodišče Šmartno</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>4,04 km</strong></p>
                            <p>Višinska razlika:<br/><strong>355 m</strong></p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23864721" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-vikrce.png" alt="Zemljevid poti Izhodišče Vikrče" />
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo" />
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Izhodišče Vikrče</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>4,36 km</strong></p>
                            <p>Višinska razlika:<br/><strong>469 m</strong></p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23851498" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-pot-svobode.png" alt="Zemljevid poti Izhodišče Pot Svobode" />
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo"/>
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Pot Svobode</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>4,34 km</strong></p>
                            <p>Višinska razlika:<br/><strong>260 m</strong></p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-lg-6">
                    <a class="route" href="https://www.strava.com/routes/23864698" target="_blank">
                        <div class="route-image">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-zemljevid-tacen-korenine.png" alt="Zemljevid poti Izhodišče Tacen - čez korenine"/>
                            <div class="google-logo">
                                <img class="img-fluid" src="assets/images/general/google-logo.png" alt="Google Logo"/>
                            </div>
                            <div class="copyright">
                                <span>Podatki na zemljevidu &copy;2020</span>
                                <a href="https://www.google.com/intl/sl-SI_US/help/terms_maps/" target="_blank">Pogoji uporabe</a>
                                <a href="https://www.google.com/maps/@46.1240503,14.46014,14z/data=!5m1!1e4!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3" target="_blank">Javi napako</a>
                            </div>
                        </div>
                        <div class="route-description">
                            <h3>Izhodišče Tacen - čez korenine</h3>
                            <p class="mb-3">Dolžina poti:<br/><strong>1,71 km</strong></p>
                            <p>Višinska razlika:<br/><strong>341 m</strong></p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="background-light-grey">
        <div class="container">
            <div class="row content-block pt-4 pt-md-6">
                <div class="col-12 col-lg-6">
                    <h2 class="mb-0">#smarnagora</h2>
                </div>
            </div>
            <div class="row pb-4 pb-md-6">
                <div class="col-12 social-plugin ig">
                    <div class="elfsight-app-48400455-57ab-4a33-910b-a5b7338e42c7"></div>
                </div>
            </div>
        </div>
    </div>

</article>

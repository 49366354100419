import { Route } from '@angular/router';

import { NotFoundComponent } from './not-found.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Stran ne obstaja',
    description: 'Stran ne obstaja'
};

export const route: Route = {
    path: '404',
    component: NotFoundComponent,
    data: {
        metadata: metadata
    }
};
<main id="content">
    <section class="content">
        <div class="container-flud" *ngIf="loading">
            <div class="row my-5">
                <div class="col-12">
                    <loader></loader>
                </div>
            </div>
        </div>
        <article *ngIf="!loading">
            <div *ngIf="content.ozadje" class="top-background" [ngStyle]="{ 'background-image': 'url(\'' + cmsContentBasePath + content.ozadje.url + '\')' }">
                <div class="subpage-title">
                    <h1>{{ content.naziv }}</h1>
                </div>
            </div>

            <div *ngIf="!content.ozadje" [ngClass]="{ 'background-light-grey': !content.vsebinaDodatno && content.segment.abstrakten }">
                <div class="container">
                    <div class="row">
                        <div class="col-12 subpage-title-no-background">
                            <h1>{{ content.naziv }}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="description" [ngClass]="{ 'border-bottom': !expanded, 'background-light-grey': content.akcije && content.akcije.length > 0, 'no-top-padding': !content.ozadje }">
                <div class="container">
                    <div class="row">

                        <div *ngIf="content.podnaziv" class="col-12">
                            <h2>{{ content.podnaziv }}</h2>
                        </div>

                        <div class="cms-content col-12" [ngClass]="{ 'col-lg-7': content.infografike && content.infografike.length > 0, 'col-lg-12': !content.infografike || content.infografike.length === 0 }">
                            <div documents [innerHTML]="getVsebina(content.vsebina) | trust"></div>
                        </div>
                        <div class="col-12 col-lg-5 infografika">
                            <img *ngFor="let info of content.infografike" class="img-fluid" [src]="cmsContentBasePath + info.url" align="right" [alt]="info.altOpis ? info.altOpis : ''">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="content.cta || content.vsebinaDodatno" class="ctas">
                                <div *ngIf="content.cta"><p>{{ content.ctaOpis }}</p></div>

                                <ng-container [ngSwitch]="content.ctaTip">
                                  <a *ngSwitchCase="'DOKUMENT'" [href]="content.ctaDokument.url" target="_blank" class="povprasevanje"><em>{{ content.ctaNaziv }}</em></a>
                                  <a *ngSwitchCase="'POVEZAVA'" [href]="content.ctaPovezava" target="_blank" class="povprasevanje"><em>{{ content.ctaNaziv }}</em></a>
                                  <a *ngSwitchCase="'NAROCILO_KLUB_ZVESTOBA_OGREVA'" routerLink="/klub-zvestoba-ogreva/prijava" class="povprasevanje"><em>{{ content.ctaNaziv }}</em></a>
                                  <a *ngSwitchCase="'POVPRASEVANJE'" routerLink="/povprasevanje" [queryParams]="content.ctaQueryParams" class="povprasevanje"><em>{{ content.ctaNaziv }}</em></a>
                                  <a *ngSwitchCase="'SVETOVANJE'" routerLink="/povprasevanje" [queryParams]="content.ctaQueryParams" class="povprasevanje"><em>{{ content.ctaNaziv }}</em></a>
                                </ng-container>

                                <button *ngIf="content.vsebinaDodatno" class="razsiri" (click)="expand()">
                                  <ng-container *ngIf="!expanded"><i class="fa fa-plus-circle" aria-hidden="true"></i><em> Več informacij</em></ng-container>
                                    <ng-container *ngIf="expanded"><i class="fa fa-minus-circle" aria-hidden="true"></i><em> Zapri</em></ng-container>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="expanded" class="background-light-grey arrow-down">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div>

            <div *ngIf="expanded" class="dodatne-informacije background-light-grey border-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12" documents [innerHTML]="content.vsebinaDodatno | trust"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="offers.length > 0" class="offers-pricelists">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="text-uppercase">Akcije</h2>
                        </div>
                    </div>
                </div>
                <action-version *ngFor="let akcija of offers; let idx = index"
                    [offerId]="akcija.id"
                    [offerTitle]="akcija.naziv"
                    [left]="idx % 2 === 0"
                    [isExpandable]="true"
                    [contentSegmentTitle]="content.segment.naziv"
                    [contentKategorijaTitle]="content.naziv"></action-version>
            </div>

            <div *ngIf="regularPricelists.length > 0" class="offers-pricelists">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="text-uppercase">Redni ceniki</h2>
                        </div>
                    </div>
                </div>
                <action-version *ngFor="let akcija of regularPricelists; let idx = index"
                    [offerId]="akcija.id"
                    [offerTitle]="akcija.naziv"
                    [left]="idx % 2 === 0"
                    [isExpandable]="true"
                    [contentSegmentTitle]="content.segment.naziv"
                    [contentKategorijaTitle]="content.naziv"></action-version>
            </div>

            <div *ngIf="content.povezaneKategorije && content.povezaneKategorije.length > 0" class="povezani-produkti background-light-grey">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="transform-none">Ponudba za dodatne prihranke in ugodnosti</h2>
                        </div>
                        <div class="col-12 col-lg-4" *ngFor="let kategorija of content.povezaneKategorije">
                            <div class="povezan-produkt">
                                <div class="top">
                                    <div class="heading">
                                      <h3>{{ kategorija.naziv || kategorija.kategorijaNaziv }}</h3>
                                    </div>
                                    <p>{{ kategorija.opis || kategorija.kategorijaOpis }}</p>
                                </div>
                                <div class="bottom">
                                    <div class="image">
                                        <img *ngIf="kategorija.ikona" [src]="getKategorijaImage(kategorija.ikona.imeDatoteke)" [alt]="kategorija.ikona.altOpis ? kategorija.ikona.altOpis : ''">
                                        <img *ngIf="!kategorija.ikona && kategorija.segment.slika" [src]="getKategorijaImage(kategorija.segment.slika.imeDatoteke)" [alt]="kategorija.segment.slika.altOpis ? kategorija.segment.slika.altOpis : ''">
                                    </div>
                                    <div class="ctas">
                                        <a *ngIf="kategorija.povezava" [href]="kategorija.povezava" class="vec-o"><i class="fa fa-plus-circle" aria-hidden="true"></i> Več o ponudbi</a>
                                        <a *ngIf="!kategorija.povezava" [routerLink]="['/vsebina', kategorija.segment.urlPot, kategorija.kategorijaUrlPot]" class="vec-o"><i class="fa fa-plus-circle" aria-hidden="true"></i> Več o ponudbi</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </section>
</main>

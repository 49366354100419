import { Component, OnInit, Input } from '@angular/core';

import { CmsService } from '../../services/cms.service';

import { NewsArticle } from '../../models/cms.model';

@Component({
    selector: 'svetovalec-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class SvetovalecListItemComponent implements OnInit {

    @Input()
    public article: NewsArticle;

    @Input()
    public isNovicePage = false;

    public cmsContentBasePath;

    constructor(private cmsService: CmsService) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;
    }
}

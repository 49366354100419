import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { DocumentIdDirective } from './document-id.directive';
import { DocumentsDirective } from './documents.directive';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({ declarations: [
        DocumentIdDirective,
        DocumentsDirective,
        ClickOutsideDirective
    ],
    exports: [
        DocumentIdDirective,
        DocumentsDirective,
        ClickOutsideDirective
    ], imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class DirectivesModule { }

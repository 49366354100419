<article>
    <div class="top-banner ugodnost" style="background-image: url('assets/images/klub-zvestoba-ogreva/background-geodetski-nacrt.jpg')">
        <div class="layer">
            <img class="img-fluid" src="assets/images/klub-zvestoba-ogreva/logo-big-white.png" alt="Logo Klub zvestoba ogreva">
            <h1>
                <strong>30 % popust</strong> na izdelavo <strong>geodetskega načrta</strong>
            </h1>
        </div>
    </div>

    <div class="pas-vsebina">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1 offset-lg-2 offset-lg-3">
                    <div>
                        <p>Člani Kluba Zvestoba ogreva ste upravičeni do <strong>30 % popusta na izdelavo geodetskega načrta pri Energetiki Ljubljana. *</strong></p>
                    </div>
                    <div class="mt-4">
                        <p>Izdelava geodetskega načrta vsebuje:</p><ul><li>pridobitev podatkov s strani Geodetske uprave RS,</li><li>terenske meritve,</li><li>prenos podatkov s terena v računalnik in izdelava grafičnega prikaza v .dwg verziji,</li><li>3 kopije geodetskega načrta v papirnati verziji (grafični del + certifikat),</li><li>dodatna terenska meritev (če je na zahtevo projektanta potrebna dopolnitev grafičnega dela).</li></ul>
                    </div>
                    <div class="mt-4">
                        <p class="notes">* Končna cena geodetskega načrta se določi:</p><ul class="notes"><li>po priporočenem ceniku matične sekcije geodetov na Inženirski zbornici Slovenije,</li><li>na podlagi ogleda strokovnjaka Energetike Ljubljana na terenu.</li></ul>
                    </div>
                    <div class="mt-4">
                        <p>Načrtujete novogradnjo ali obnovo obstoječega objekta?  Ob izdelavi projektne dokumentacije za pridobitev gradbenega dovoljenja boste verjetno potrebovali geodetski načrt obstoječega stanja. Geodetski načrt je prikaz fizičnih struktur in pojavov na zemeljskem površju, nad in pod njim v pomanjšanem merilu po kartografskih pravilih. Vsebino geodetskega načrta lahko najdete na <a href="https://www.uradni-list.si/glasilo-uradni-list-rs/vsebina/48386" target="_blank">tej povezavi</a>.</p>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <div class="row">
                        <div class="col-12 tile">
                            <img class="img-fluid" src="assets/images/klub-zvestoba-ogreva/geodetski-nacrt.jpg" alt="Slika geodetski načrt">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1 offset-lg-2 offset-lg-3 mt-3">
                    <button (click)="toggleAdditionalTxt()" class="btn-blue border-only inline-block">Želim izkoristiti popust</button>
                    <div *ngIf="showAdditionalTxt" class="additional-explanation">
                        <div class="arrow"></div>
                        <div class="text">
                            <p>Pokličite Kontaktni center Energetike Ljubljana na številko 080 28 82 (od 8.00 do 15.00 ure) ali pošljite email na <a href="mailto:klub@bivanjudajemoutrip.si">klub&#64;bivanjudajemoutrip.si</a>. Navedite ime in priimek, naslov, odjemno mesto in kontaktno telefonsko številko na kateri ste dosegljivi.</p><p>Na navedeno kontaktno telefonsko številko vas bomo nato poklicali iz Katastrske službe Energetike Ljubljana in se z vami dogovoril glede ogleda na terenu ter priprave ponudbe za izdelavo geodetskega načrta.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</article>

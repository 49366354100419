<header>
    <nav class="navbar navbar-expand-lg py-0" aria-label="Glavni meni" (clickOutside)="clickOutside($event)">

        <div class="container container-lg">
                <a routerLink="/" class="navbar-brand d-flex">
                    <img class="logo big" [src]="getLogoBivanjeLarge()" alt="Energetika Ljubljana Logo">
                    <img class="logo small" [src]="getLogoBivanjeBig()" alt="Energetika Ljubljana Logo">
                    <img class="logo extra-small" [src]="getLogoBivanjeMiddle()" alt="Energetika Ljubljana Logo">
                    <img class="logo big" *ngIf="isMonochromatic" [src]="getLogoBivanjeLargeBDU()" alt="Energetika Ljubljana Logo">
                </a>
                <a tabindex="0" class="d-block d-lg-none visible-on-focus" [href]="getCurrentUrlContent()" aria-label="Skoči na vsebino">
                  Na vsebino
                </a>
                <a tabindex="0" class="d-block d-lg-none"
                   (click)="toggleDostopnost()" (keydown)="accessibleMenuToggle($event)"
                   matTooltip="Nastavitve za dostopnost"
                   aria-label="Odpri meni za dostopnost">

                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512">
                    <path class="nav-link" fill="currentColor"
                          d="M256 48c114.953 0 208 93.029 208 208 0 114.953-93.029 208-208 208-114.953 0-208-93.029-208-208 0-114.953 93.029-208 208-208m0-40C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 56C149.961 64 64 149.961 64 256s85.961 192 192 192 192-85.961 192-192S362.039 64 256 64zm0 44c19.882 0 36 16.118 36 36s-16.118 36-36 36-36-16.118-36-36 16.118-36 36-36zm117.741 98.023c-28.712 6.779-55.511 12.748-82.14 15.807.851 101.023 12.306 123.052 25.037 155.621 3.617 9.26-.957 19.698-10.217 23.315-9.261 3.617-19.699-.957-23.316-10.217-8.705-22.308-17.086-40.636-22.261-78.549h-9.686c-5.167 37.851-13.534 56.208-22.262 78.549-3.615 9.255-14.05 13.836-23.315 10.217-9.26-3.617-13.834-14.056-10.217-23.315 12.713-32.541 24.185-54.541 25.037-155.621-26.629-3.058-53.428-9.027-82.141-15.807-8.6-2.031-13.926-10.648-11.895-19.249s10.647-13.926 19.249-11.895c96.686 22.829 124.283 22.783 220.775 0 8.599-2.03 17.218 3.294 19.249 11.895 2.029 8.601-3.297 17.219-11.897 19.249z"></path>
                  </svg>
                </a>
                <button (click)="toggleDropdown()" class="navbar-toggler" type="button">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>

                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item d-none d-lg-block energetika-v-druzbi border-left">
                            <a routerLink="/energetika-v-druzbi">
                                <img [src]="getEnergetikaVDruzbiLogo()" alt="Energetika v družbi"/>
                            </a>
                        </li>
                        <li class="nav-item d-none d-lg-block klub-zvestoba-ogreva border-left">
                            <a routerLink="/klub-zvestoba-ogreva">
                                <img [src]="getKlubZveztobaLogo()" alt="Energetika Klub zvestoba ogreva" />
                            </a>
                        </li>

                      <li class="nav-item dropdown mega-dropdown dropdown-main border-left"
                          (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
                            <a tabindex="0" (keydown)="accessibleToggleDropdown($event)" (click)="toggleDropdown()"
                               class="d-none d-lg-block">
                                Ponudba
                                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </a>

                            <ul id="dropdownMenu" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" class="dropdown-menu dropdown-subhover mega-dropdown-menu container container-lg dropdown-animation animated fadeIn">
                                <li class="col-lg-3 d-none d-lg-block">
                                    <ul>
                                        <li class="dropdown-header menu-segment unabstract">
                                            <a routerLink="/npn-paketi" (keydown)="accessibleToggleDropdownLink($event)" (click)="anchorClick($event)">Plinski kotel "na ključ"</a>
                                        </li>
                                        <li class="dropdown-header menu-segment unabstract">
                                            <a routerLink="/dela-na-omrezju" (keydown)="accessibleToggleDropdownLink($event)" (click)="anchorClick($event)">Dela na omrežju</a>
                                        </li>
                                        <li class="dropdown-header menu-segment unabstract">
                                            <a routerLink="/poslovni-odjem" (keydown)="accessibleToggleDropdownLink($event)" (click)="anchorClick($event)">Za podjetja</a>
                                        </li>
                                        <ng-container *ngIf="cmsSitemap !== null">
                                            <li *ngFor="let segment of cmsSitemap.unabstract" class="dropdown-header menu-segment unabstract">
                                                <a [routerLink]="['/vsebina', segment.urlPot]" (keydown)="accessibleToggleDropdownLink($event)" (click)="anchorClick($event)">{{ segment.naziv }}</a>
                                            </li>
                                        </ng-container>
                                        <li class="dropdown-header menu-segment unabstract">
                                            <a routerLink="/ceniki-dokumenti" (keydown)="accessibleToggleDropdownLink($event)" (click)="anchorClick($event)">Ceniki in dokumenti</a>
                                        </li>
                                        <li class="dropdown-header menu-segment unabstract">
                                            <a (keydown)="accessibleToggleDropdownLink($event)" (click)="openCampaignSubscribeDialog()" href="javascript:void(0)">Prijava na obveščanje</a>
                                        </li>
                                        <li class="dropdown-header menu-segment unabstract">
                                          <a routerLink="/razlaga-racuna" (keydown)="accessibleToggleDropdownLink($event)" (click)="anchorClick($event)">Razlaga računa</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="col-12 col-lg-9">
                                    <ng-container *ngIf="cmsSitemap !== null">
                                        <ul *ngFor="let row of cmsSitemap.abstract" class="row segment-row">
                                            <li *ngFor="let segment of row; let idx = index" class="col-12 col-md-4 segment" [ngClass]="{ 'offset-lg-3': idx > 1 && idx % 3 === 0 }">
                                                <ul class="menu-segment">
                                                    <li class="dropdown-header">{{ segment.naziv }}</li>
                                                    <li *ngFor="let kategorija of segment.kategorijas">
                                                        <a [routerLink]="['/vsebina', segment.urlPot, kategorija.urlPot]" (keydown)="accessibleToggleDropdownLink($event)" (click)="anchorClick($event)">
                                                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                            {{ kategorija.naziv }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </ng-container>
                                </li>
                                <li class="d-lg-none col-12">
                                    <ul class="row segment-row">
                                        <li class="col-12 col-md-4 segment d-none-xs">
                                            <ul class="menu-segment">
                                                <li class="dropdown-header">
                                                    <a routerLink="/energetika-v-druzbi">Energetika v družbi</a>
                                                </li>
                                            </ul>
                                        </li>
<!--                                        <li class="col-12 col-md-4 segment d-none-xs">-->
<!--                                            <ul class="menu-segment">-->
<!--                                                <li class="dropdown-header">-->
<!--                                                    <a routerLink="/klub-zvestoba-ogreva">Klub Zvestoba ogreva</a>-->
<!--                                                </li>-->
<!--                                            </ul>-->
<!--                                        </li>-->
                                        <li class="col-12 col-md-4 segment d-none-xs">
                                            <ul class="menu-segment">
                                                <li class="dropdown-header">
                                                    <a routerLink="/npn-paketi">Plinski kotel "na ključ"</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="col-12 col-md-4 segment d-none-xs">
                                            <ul class="menu-segment">
                                                <li class="dropdown-header">
                                                    <a routerLink="/dela-na-omrezju">Dela na omrežju</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="col-12 col-md-4 segment d-none-xs">
                                            <ul class="menu-segment">
                                                <li class="dropdown-header">
                                                    <a routerLink="/poslovni-odjem">Za podjetja</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <ng-container *ngIf="cmsSitemap !== null">
                                            <li *ngFor="let kategorija of cmsSitemap.unabstract" class="col-12 col-md-4 segment">
                                                <ul class="menu-segment">
                                                    <li class="dropdown-header">
                                                        <a [routerLink]="['/vsebina', kategorija.urlPot]">{{ kategorija.naziv }}</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ng-container>
                                        <li class="col-12 col-md-4 segment">
                                            <ul class="menu-segment">
                                                <li class="dropdown-header">
                                                    <a routerLink="/ceniki-dokumenti">Ceniki in dokumenti</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="col-12 col-md-4 segment d-none-xs">
                                            <ul class="menu-segment">
                                                <li class="dropdown-header">
                                                    <a (click)="openCampaignSubscribeDialog()" href="javascript:void(0)">Prijava na obveščanje</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="col-12 col-md-4 segment">
                                            <ul class="menu-segment">
                                                <li class="dropdown-header">
                                                    <a routerLink="/razlaga-racuna">Razlaga računa</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item d-xs-none d-lg-block dark-background">
                          <a routerLink="/novice/4/klicni-center-energetike-ljubljana-080-2882" class="ml10 header-phone" aria-label="Klicni center telefon: 080 28 82"><i class="fa fa-phone" aria-hidden="true"></i> <span class="phone-details">&nbsp;080 28 82</span></a>
                        </li>
                        <li class="nav-item d-none d-lg-block border-left">

                          <a class="dostopnost-color" tabindex="0" matTooltip="Nastavitve za dostopnost"
                             (click)="toggleDostopnost()" (keydown)="accessibleMenuToggle($event)" aria-label="Odpri meni za dostopnost">

                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512">
                              <path class="nav-link" fill="currentColor"
                                    d="M256 48c114.953 0 208 93.029 208 208 0 114.953-93.029 208-208 208-114.953 0-208-93.029-208-208 0-114.953 93.029-208 208-208m0-40C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 56C149.961 64 64 149.961 64 256s85.961 192 192 192 192-85.961 192-192S362.039 64 256 64zm0 44c19.882 0 36 16.118 36 36s-16.118 36-36 36-36-16.118-36-36 16.118-36 36-36zm117.741 98.023c-28.712 6.779-55.511 12.748-82.14 15.807.851 101.023 12.306 123.052 25.037 155.621 3.617 9.26-.957 19.698-10.217 23.315-9.261 3.617-19.699-.957-23.316-10.217-8.705-22.308-17.086-40.636-22.261-78.549h-9.686c-5.167 37.851-13.534 56.208-22.262 78.549-3.615 9.255-14.05 13.836-23.315 10.217-9.26-3.617-13.834-14.056-10.217-23.315 12.713-32.541 24.185-54.541 25.037-155.621-26.629-3.058-53.428-9.027-82.141-15.807-8.6-2.031-13.926-10.648-11.895-19.249s10.647-13.926 19.249-11.895c96.686 22.829 124.283 22.783 220.775 0 8.599-2.03 17.218 3.294 19.249 11.895 2.029 8.601-3.297 17.219-11.897 19.249z"></path>
                            </svg>

                          </a>
                        </li>
                    </ul>
                </div>

                <a tabindex="0" class="d-none d-lg-block visible-on-focus" [href]="getCurrentUrlContent()" aria-label="Skoči na vsebino">
                  Na vsebino
                </a>
                <a tabindex="0" class="d-none d-lg-block visible-on-focus" (keydown)="focusOnQuickAccessKeydown($event)" (click)="focusOnQuickAccess()" aria-label="Skoči na meni hitri dostop">
                  Hitri dostop
                </a>
        </div>
    </nav>



  <div class="accessibility-menu-wrapper" *ngIf="showDostopnost === true" >

      <div class="accessibility-menu accessibility-menu-scrolling" >
        <div class="container container-lg" (clickOutside)="accessMenuClickOutside($event)" style="background-color: var(--color-light-gray); padding-top: 20px; padding-bottom: 20px; margin:0; max-width: 100%;">
          <h1>Dostopnost</h1>


        <div class="d-flex flex-wrap">
          <a routerLink="/toc" class="btn btn-blue border-only ml-4" (keydown)="accessibleMenuLinkToggle($event)">Zemljevid strani</a>
          <a routerLink="/izjava-o-dostopnosti" class="btn btn-blue border-only ml-2" (keydown)="accessibleMenuLinkToggle($event)">Izjava o dostopnosti</a>
        </div>
        <ul>
          <li>
            <div class="mt-4"></div>
            <h2>Barvna shema</h2>
            <div class="flex flex-wrap">
              <button tabindex="0" class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="themeClass==='theme-normal'"
                      (keydown)="accessibleSetTheme($event,'theme-normal')" (click)="setTheme('theme-normal')">Privzeta
              </button>
              <button tabindex="0" class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="themeClass==='theme-wcag'"
                      (keydown)="accessibleSetTheme($event,'theme-wcag')" (click)="setTheme('theme-wcag')">Dostopna (WCAG)
              </button>
              <button class="btn btn-sm btn-blue responsive-width mr-3 button-black-white-theme"
                      [class.button-active]="themeClass==='theme-black-white'"
                      (keydown)="accessibleSetTheme($event,'theme-black-white')" (click)="setTheme('theme-black-white')">
                Črna na belem
              </button>
              <button class="btn btn-sm btn-blue responsive-width mr-3 button-white-black-theme"
                      [class.button-active]="themeClass==='theme-white-black'"
                      (keydown)="accessibleSetTheme($event,'theme-white-black')" (click)="setTheme('theme-white-black')">
                Bela na črnem
              </button>
              <button class="btn btn-sm btn-blue responsive-width mr-3 button-black-beige-theme"
                      [class.button-active]="themeClass==='theme-black-beige'"
                      (keydown)="accessibleSetTheme($event,'theme-black-beige')" (click)="setTheme('theme-black-beige')">
                Črna na bež
              </button>
              <button class="btn btn-sm btn-blue responsive-width mr-3 button-yellow-blue-theme"
                      [class.button-active]="themeClass==='theme-yellow-blue'"
                      (keydown)="accessibleSetTheme($event,'theme-yellow-blue')" (click)="setTheme('theme-yellow-blue')">
                Rumena na modrem
              </button>
              <button class="btn btn-sm btn-blue responsive-width mr-3 button-black-yellow-theme"
                      [class.button-active]="themeClass==='theme-black-yellow'"
                      (keydown)="accessibleSetTheme($event,'theme-black-yellow')" (click)="setTheme('theme-black-yellow')">
                Črna na rumenem
              </button>
              <button class="btn btn-sm btn-blue responsive-width mr-3 button-lime-black-theme"
                      [class.button-active]="themeClass==='theme-lime-black'"
                      (keydown)="accessibleSetTheme($event,'theme-lime-black')" (click)="setTheme('theme-lime-black')">
                Zelena na črnem
              </button>
            </div>
            <hr />
          </li>
          <li>
            <h2>Velikost pisave</h2>
            <div>Uporabite funkcijo brskalnika CTRL + in/ali CTRL -</div>
            <hr />
          </li>
          <li>
            <h2>Razmiki teksta</h2>
            <div>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontPaddingClass==='text-paddings-wcag'"
                      (click)="setFontPaddingClass('text-paddings-wcag')"
                      (keydown)="menuDefaultKeydown($event)">WCAG
              </button>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontPaddingClass==='text-normal'"
                      (click)="setFontPaddingClass('text-normal')"
                      (keydown)="menuDefaultKeydown($event)">Navadno
              </button>
            </div>
            <hr />
          </li>
          <li>
            <h2>Stil pisave</h2>
            <div>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontStyleClass==='text-uppercase'"
                      (click)="setFontStyle('text-uppercase')"
                      (keydown)="menuDefaultKeydown($event)">Velike črke</button>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontStyleClass==='text-normal'"
                      (click)="setFontStyle('text-normal')"
                      (keydown)="menuDefaultKeydown($event)">Navadno</button>
            </div>
            <hr />
          </li>
          <li>
            <h2>Tip pisave</h2>
            <div>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontNameClass==='font-system'"
                      (click)="setFontName('font-system')"
                      (keydown)="menuDefaultKeydown($event)">Sistemska</button>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontNameClass==='font-arial'"
                      (click)="setFontName('font-arial')"
                      (keydown)="menuDefaultKeydown($event)">Arial</button>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontNameClass==='font-verdana'"
                      (click)="setFontName('font-verdana')"
                      (keydown)="menuDefaultKeydown($event)">Verdana</button>
              <button class="btn btn-sm btn-blue responsive-width mr-3"
                      [class.button-active]="fontNameClass==='font-opendislexic'"
                      (click)="setFontName('font-opendislexic')"
                      (keydown)="showDostopnostLastKey($event)"
                       >Opendislexic</button>
            </div>
          </li>
        </ul>

        </div>

      </div>
        <div style="height: 100px; width: 100%; padding-bottom: 100px; background-color: var(--color-light-gray);">
        </div>


  </div>

  <div id="" style="background-color: rgba(0,0,0,0.8); height: 100%; width: 100%; position: fixed; top: 82px; z-index: 100;" *ngIf="showDostopnost === true"></div>
</header>

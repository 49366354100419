import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { finalize } from 'rxjs/operators';
import { RecaptchaComponent } from 'ng-recaptcha';

import { environment } from '../../../environments/environment';

import { OrderService } from '../../services/order.service';

import { PoslovniOdjem } from '../../models/order.model';

@Component({
    selector: 'app-poslovni-odjem',
    templateUrl: './poslovni-odjem.component.html',
    styleUrls: ['./poslovni-odjem.component.scss']
})
export class PoslovniOdjemComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptchaCmp: RecaptchaComponent;

    public selectedStoritev: string = null;

    public loading: boolean = false;
    public expanded: boolean = false;

    public showGeneralError: boolean = false;
    public showCaptchaError: boolean = false;
    public showFormValidationError: boolean = false;
    public showZakljucek: boolean = false;

    public form: UntypedFormGroup;

    public captchaKey: string = environment.captchaKey;
    private captchaResponse: string;

    public get nazivPodjetja() { return this.form.get('nazivPodjetja') }
    public get ulica() { return this.form.get('ulica') }
    public get hisnaStevilka() { return this.form.get('hisnaStevilka') }
    public get posta() { return this.form.get('posta') }
    public get postnaStevilka() { return this.form.get('postnaStevilka') }
    public get kontaktnaOseba() { return this.form.get('kontaktnaOseba') }
    public get email() { return this.form.get('email') }
    public get telefonskaStevilka() { return this.form.get('telefonskaStevilka') }
    public get zemeljskiPlin() { return this.form.get('zemeljskiPlin') }
    public get daljinskoOgrevanje() { return this.form.get('daljinskoOgrevanje') }
    public get elektricnaEnergija() { return this.form.get('elektricnaEnergija') }
    public get para() { return this.form.get('para') }
    public get drugo() { return this.form ? this.form.get('drugo') : null }
    public get drugoOpis() { return this.form.get('drugoOpis') }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private orderService: OrderService
    ) { }

    public ngOnInit() {

        this.form = this.fb.group({
            nazivPodjetja: ['', [Validators.required, Validators.maxLength(150)]],
            ulica: ['', [Validators.required, Validators.maxLength(200)]],
            hisnaStevilka: ['', [Validators.required, Validators.maxLength(10)]],
            posta: ['', [Validators.required, Validators.maxLength(100)]],
            postnaStevilka: ['', [Validators.required, Validators.min(1000), Validators.max(9999)]],
            kontaktnaOseba: ['', [Validators.required, Validators.maxLength(150)]],
            email: ['', [Validators.required, Validators.maxLength(254), Validators.email]],
            telefonskaStevilka: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^[0-9-/+() ]+$/)]],
            zemeljskiPlin: [false],
            daljinskoOgrevanje: [false],
            elektricnaEnergija: [false],
            para: [false],
            drugo: [false],
            drugoOpis: ['', [this.requiredIf(() => this.drugo && this.drugo.value === true), Validators.maxLength(500)]]
        });
    }

    public selectStoritev(storitev: string ) {
        this.selectedStoritev = storitev;
    }

    public expand(storitev: string) {
        this.expanded = true;

        if (storitev !== null) {
            switch (storitev) {
                case 'elektrika':
                    this.elektricnaEnergija.setValue(true);
                    this.elektricnaEnergija.updateValueAndValidity();
                    break;
                case 'plin':
                    this.zemeljskiPlin.setValue(true);
                    this.zemeljskiPlin.updateValueAndValidity();
                    break;
                case 'ogrevanje':
                    this.daljinskoOgrevanje.setValue(true);
                    this.daljinskoOgrevanje.updateValueAndValidity();
                    break;
                case 'inzeniring':
                    this.drugo.setValue(true);
                    this.drugo.updateValueAndValidity();
                    break;
            }
        }

        setTimeout(() => {
            this.router.navigate(['./'], { fragment: 'poslovniOdjemPovprasevanje', relativeTo: this.route });
        })
    }

    public requiredIf(isRequired: () => boolean): ValidatorFn {

        return (control: AbstractControl): {[key: string]: any} | null => {
            
            if (!isRequired())
                return null;
            
            return control.value != null && control.value != '' && control.value !== false && `${control.value}` !== 'false' ? null : { 'required': false };
          };
    }

    public captchaResolved(captchaResponse: string) {
        this.captchaResponse = captchaResponse;
    }

    public posljiPovprasevanje() {

        if (this.loading)
            return;

        this.showCaptchaError = false;
        this.showGeneralError = false;

        if (this.form.valid && this.captchaResponse) {
            this.loading = true;

            let po: PoslovniOdjem = {
                recaptchaResponse: this.captchaResponse,
                poslovniOdjem: {
                    nazivPodjetja: this.nazivPodjetja.value,
                    ulica: this.ulica.value,
                    hisnaStevilka: this.hisnaStevilka.value,
                    posta: this.posta.value,
                    postnaStevilka: this.postnaStevilka.value,
                    kontaktnaOseba: this.kontaktnaOseba.value,
                    email: this.email.value,
                    telefonskaStevilka: this.telefonskaStevilka.value,
                    zemeljskiPlin: this.zemeljskiPlin.value,
                    daljinskoOgrevanje: this.daljinskoOgrevanje.value,
                    elektricnaEnergija: this.elektricnaEnergija.value,
                    para: this.para.value,
                    drugo: this.drugo.value === true ? this.drugoOpis.value : null
                }
            }

            this.orderService.orderPoslovniOdjem(po)
                .pipe(
                    finalize(() => this.loading = false)
                )
                .subscribe(res => {
                    this.showZakljucek = true;
                }, err => {

                    if (err.status === 403) {
                        this.showCaptchaError = true;
                    } else {
                        this.showGeneralError = true;
                    }
            
                    this.recaptchaCmp.reset();
                });
        } else {
            event.preventDefault();

            for (let key in this.form.controls) {
                this.form.controls[key].markAsDirty();
            }

            this.recaptchaCmp.reset();
            this.showFormValidationError = true; 
        }
    }
}
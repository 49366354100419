import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'trust'
})
export class TrustPipe implements PipeTransform {
    
    constructor(private sanitizer: DomSanitizer) { }
    
    transform(input: string, type: 'html'|'resource'|'style' = 'html') {
        
        switch (type) {
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(input);
            case 'resource':
                return this.sanitizer.bypassSecurityTrustResourceUrl(input);
            case 'html':
            default:
                return this.sanitizer.bypassSecurityTrustHtml(input);
        }
    }    
}
<div class="container py-4">

    <div class="row">
        <div class="col-12 col-lg-3">
            <a class="content-box ceniki-dokumenti content-box-title" routerLink="/ceniki-dokumenti">
                <div class="div-img racun-img"></div>
                <h1>Ceniki in dokumenti</h1>
            </a>
        </div>
        <div class="col-12 col-lg-9">
            <div class="content-box ceniki-dokumenti content-box-description height-control">
                <p>Na tem mestu so zbrani vsi dokumenti in ceniki. Za ogled starejših dokumentov izberite možnost "prikaži arhivirane".</p>
                <p>V primeru vprašanj izpolnite naš <a routerLink="/povprasevanje">spletni obrazec</a>.</p>
            </div>
        </div>
    </div>

    <div class="row">
        <aside class="col-12 col-md-4 col-lg-3">
            <nav aria-label="Meni: Ceniki in dokumenti" class="d-none d-md-flex">
                <ul class="sidebar-nav list-group w-100">
                    <li class="list-group-item no-padding" [ngClass]="{ active: !queryParams.cenik }">
                        <a routerLink="./" [queryParams]="{ energent: null }">Ceniki in dokumenti</a>
                    </li>
                    <li class="list-group-item" [ngClass]="{ active: queryParams.cenik === 'true' }">
                        <a routerLink="./" [queryParams]="{ cenik: true }">Ceniki</a>
                    </li>
                    <li class="list-group-item" [ngClass]="{ active: queryParams.cenik === 'false' }">
                        <a routerLink="./" [queryParams]="{ cenik: false }">Dokumenti</a>
                    </li>
                </ul>
            </nav>

            <div class="d-none d-md-block">
              <mat-slide-toggle [checked]="arhiv" (change)="arhivChange($event)" aria-labelledby="prikaziArhivirane"></mat-slide-toggle><span id="prikaziArhivirane">Prikaži arhivirane</span>
            </div>
        </aside>

        <div class="col-12 navigation-mobile py-2 mb-3 d-block d-md-none">
            <div class="col-12">
                <button (click)="toggleFilters()" class="btn btn-blue border-only">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                    <ng-container *ngIf="!filtersVisible">Prikaži filtre</ng-container>
                    <ng-container *ngIf="filtersVisible">Skrij filtre</ng-container>
                </button>
            </div>

            <div *ngIf="filtersVisible" class="col-12 navigation-mobile-kategorije">
                <nav aria-label="Meni: Ceniki in dokumenti">
                    <ul class="sidebar-nav list-group w-100">
                        <li class="list-group-item no-padding" [ngClass]="{ active: !queryParams.cenik }">
                            <a routerLink="./" [queryParams]="{ energent: null }">Ceniki in dokumenti</a>
                        </li>
                        <li class="list-group-item" [ngClass]="{ active: queryParams.cenik === 'true' }">
                            <a routerLink="./" [queryParams]="{ cenik: true }">Ceniki</a>
                        </li>
                        <li class="list-group-item" [ngClass]="{ active: queryParams.cenik === 'false' }">
                            <a routerLink="./" [queryParams]="{ cenik: false }">Dokumenti</a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div *ngIf="filtersVisible" class="col-12 navigation-mobile-oznake">
                <nav aria-label="Meni: Vse oznake">
                    <ul class="nav nav-pills novice-tags col-12 text-center">
                        <li class="filter" [ngClass]="{ active: !queryParams.kat }">
                            <a routerLink="./" [queryParams]="{ kat: null }" queryParamsHandling="merge">Vse oznake</a>
                        </li>
                        <li *ngFor="let kat of categoriesAsync | async" class="filter" [ngClass]="{ active: isActiveParamKey(kat.id) }">
                            <a routerLink="./" [queryParams]="updateQueryParams(kat.id)" queryParamsHandling="merge">{{ kat.naziv }}</a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div *ngIf="filtersVisible">
                <mat-slide-toggle [checked]="arhiv" (change)="arhivChange($event)"></mat-slide-toggle> Prikaži arhivirane
            </div>
        </div>

        <main id="content" class="col-12 col-md-8 col-lg-9">
            <div class="container-fluid">
                <div class="row d-none d-md-flex">
                    <div class="col-12">
                        <nav aria-label="Meni: Vse oznake">
                            <ul class="nav nav-pills novice-tags col-12 text-center">
                                <li class="filter" [ngClass]="{ active: !queryParams.kat }">
                                    <a routerLink="./" [queryParams]="{ kat: null }" queryParamsHandling="merge">Vse oznake</a>
                                </li>
                                <li *ngFor="let kat of categoriesAsync | async" class="filter" [ngClass]="{ active: isActiveParamKey(kat.id) }">
                                    <a routerLink="./" [queryParams]="updateQueryParams(kat.id)" queryParamsHandling="merge">{{ kat.naziv }}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <router-outlet></router-outlet>

            </div>
        </main>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { 
    ObracunskaMoc,
    OdjemnaSkupina
} from '../models/codelist.model';

@Injectable()
export class CodelistService {

    private baseUrl: string;

    constructor(private http: HttpClient) { 
        this.baseUrl = `${environment.serviceBasePath}`;
    }

    public getObracunskaMoc(): Observable<ObracunskaMoc[]> {
        return this.http.get<ObracunskaMoc[]>(`${this.baseUrl}/elektrika/obracunska-moc`);
    }

    public getOdjemnaSkupina(): Observable<OdjemnaSkupina[]> {
        return this.http.get<OdjemnaSkupina[]>(`${this.baseUrl}/plin/odjemna-skupina`);
    }
}

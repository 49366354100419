import { Route } from '@angular/router';

import { PrivacyComponent } from './privacy.component';

import { Metadata } from '../../../models/metadata.model';

const metadata: Metadata = {
    title: 'Politika varstva osebnih podatkov',
    description: 'Politika varstva osebnih podatkov v družbi Energetike Ljubljana d.o.o.'
};

export const route: Route = {
    path: 'varstvo-zasebnosti',
    component: PrivacyComponent,
    data: {
        metadata: metadata
    }
};
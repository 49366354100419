<main id="content">

    <div class="results" *ngIf="showResults">
        <div class="background-light-grey">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-10 col-xl-6 offset-lg-1 offset-xl-0 grafi">
                        <div class="heading"><h3>Informativni izračun</h3></div>
                        <div class="row" *ngIf="!loadingCalculation">
                            <div class="col-12 col-md-7 no-padding">
                                <div id="informativniIzracunGraf"></div>
                            </div>
                            <div class="col-12 col-md-5 no-padding">
                                <div id="prihrankiGraf"></div>
                            </div>
                        </div>
                        <div class="row" *ngIf="loadingCalculation">
                            <div class="col-12 my-5">
                                <loader></loader>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-5 col-xl-3 offset-lg-1 offset-xl-0 dodatne-storitve">
                        <div class="heading"><h3>Dodatne ugodnosti</h3></div>
                        <div class="subheading">Poleg ugodne cene vam nudimo tudi številne dodatne storitve in finančne ugodnosti.</div>
                        <div class="storitve">
                            <div *ngFor="let storitev of additionalServiceArray" class="storitev form-group form-check">
                                <input type="checkbox" class="form-check-input"
                                    [ngModel]="storitev.selected"
                                    (ngModelChange)="selectDodatnaStoritev($event, storitev)"
                                    [disabled]="storitev.id === 1 || (storitev.zpOnly && !isZemeljskiPlinChoosen()) || isRefDodatnaStoritevIzbrana(storitev)"
                                    [ngModelOptions]="{ standalone: true }">
                                <label *ngIf="storitev.id === 1 || (!(storitev.zpOnly && !isZemeljskiPlinChoosen()) && !isRefDodatnaStoritevIzbrana(storitev)); else noTooltipLabel" class="form-check-label" [for]="'dodatnaStoritev' + storitev.id" [matTooltip]="storitev.description">{{ storitev.title }}</label>
                                <ng-template #noTooltipLabel>
                                    <label class="form-check-label">{{ storitev.title }}</label>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-5 col-xl-3 informativni-izracun">
                        <div class="heading-custom">
                            <h3>S celovito energetsko oskrbo prihranite</h3>
                        </div>
                        <div class="my-5" *ngIf="loadingCalculation">
                            <loader [small]="true"></loader>
                        </div>
                        <div class="prihranek" *ngIf="!loadingCalculation && infoCalculation">
                            {{ infoCalculation.prihranek | number: '0.2-2' }} € <span class="enota">/leto *</span>
                        </div>
                        <div class="enkratni-prihranki" *ngIf="!loadingCalculation && infoCalculation && infoCalculation.enkratniPrihranki?.length > 0">
                            <strong>Enkratni prihranki:</strong>
                            <ul>
                                <li *ngFor="let prihranek of infoCalculation.enkratniPrihranki">{{ prihranek }}</li>
                            </ul>
                        </div>
                        <div class="dodatno">
                            <p>Odločite se za akcijsko ponudbo - celovita oskrba z več energenti pri Energetiki Ljubljana je za vas cenovno ugodnejša od dobave vsakega energenta posebej. Vaš prihranek lahko še povečate z izborom številnih drugih storitev in ugodnosti, ki poleg prihrankov vplivajo tudi na vašo okoljsko odgovornost in stopnjo udobja.</p>
                        </div>
                        <a class="btn-blue" routerLink="/narocilo/akcija" [queryParams]="getNarociloParams()">
                            <i class="fa fa-check-square-o" aria-hidden="true"></i> Naroči
                        </a>
                        <a class="btn-blue border-only" routerLink="/povprasevanje" [queryParams]="povprasevanjeParams">Več informacij</a>
                    </div>
                </div>
                <div class="row border-top mt-2 pt-2 pb-3">
                    <div class="col-12 col-lg-6">
                        <p class="small-font">
                            <em><strong>Nepovezane storitve -</strong> posamična dobava energentov (redna ponudba Energetike Ljubljana)</em>
                        </p>
                        <p class="small-font">
                            <em><strong>Celovita oskrba -</strong> kombinacija povezanih storitev (akcijska ponudba Energetike Ljubljana)'</em>
                        </p>
                    </div>
                    <div class="col-12 col-lg-6">
                        <p class="small-font"><em><a dokument-id="03F58EAB-F6F9-438F-BC39-65220EBF0161" dokument-icon="true" dokument-text="true" href=""></a></em></p>
                        <p class="small-font"><em><a dokument-id="0A48056D-CEF5-4CDB-AB9B-E71C6580FC7B" dokument-icon="true" dokument-text="true" href=""></a></em></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

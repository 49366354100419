<div id="bg-boxed">
    <div class="boxed">
        <app-header (quickAccessMenuFocus)="focusOnQuickAccess()"></app-header>

        <router-outlet></router-outlet>

        <app-footer></app-footer>
    </div>
</div>

<quick-access #quickAccess></quick-access>

import { Route } from '@angular/router';

import { PovprasevanjeComponent } from './povprasevanje.component';
import { PovprasevanjeInfIzracunEeComponent } from './inf-izracun-ee/inf-izracun-ee.component';
import { PovprasevanjeInfIzracunZpComponent } from './inf-izracun-zp/inf-izracun-zp.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
    title: 'Povpraševanje',
    description: 'Izpolnite naš spletni obrazec za vloge in povpraševanja. Odgovorili vam bomo v najkrajšem možnem času.',
    keywords: ['zamenjava dobavitelja', 'področja', 'račun', 'spremembe podatkov', 'poslovni odjem', 'storitve'],
    canonicalUrl: '/povprasevanje'
};

export const route: Route = {
    path: 'povprasevanje',
    component: PovprasevanjeComponent,
    data: {
        metadata: metadata
    },
    children: [{
        path: 'informativni-izracun/elektrika',
        component: PovprasevanjeInfIzracunEeComponent
    }, {
        path: 'informativni-izracun/plin',
        component: PovprasevanjeInfIzracunZpComponent
    }]
};
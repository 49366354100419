import { Component, OnInit } from '@angular/core';

import { CmsService, CmsSitemap } from '../../services/cms.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

    public cmsContentBasePath: string;

    public sitemap: CmsSitemap;
    public sitemapLoading: boolean = true;

    constructor(
        private cmsService: CmsService
    ) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.cmsService.getCmsSitemapAsync().subscribe(res => {
            this.sitemap = res;
            this.sitemapLoading = false;
        });
    }
}
<div class="novica-seznam">
    <a tabindex="-1" *ngIf="article.slika" [routerLink]="['/novice', article.id, article.urlPot]" class="image"
       [attr.aria-label]="article.slika.altOpis ? article.slika.altOpis : ' '"
       [style.background-image]="'url(\'' + cmsContentBasePath + article.slika.url + '\')' | trust : 'style'"></a>
    <div class="novice-info">
        <a [routerLink]="['/novice', article.id, article.urlPot]" class="heading">
            <h2 *ngIf="isNovicePage">{{ article.naslov | ellipsis:45 }}</h2>
            <h3 *ngIf="!isNovicePage">{{ article.naslov | ellipsis: 45 }}</h3>
        </a>
        <div class="date">
            {{ article.aktivenOd | date: 'dd. MM. yyyy' }}
        </div>
        <div class="tags">
            <div *ngFor="let tag of article.oznake" class="label label-primary">{{ tag.naziv }}</div>
        </div>
        <div class="caption">
            <p>{{ article.predogled | ellipsis: 90 }}</p>
        </div>
    </div>
</div>

<main id="content">
    <section class="content">
        <div class="py-0 py-md-3 py-lg-5 py-xl-7 pt-3 border-top">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 unsubscribe">
                        <h1>
                            Hvala za vaše zaupanje
                        </h1>
                        <p>
                            Uspešno ste se prijavili na seznam prejemnikov e-novic.
                        </p>
                        <p>
                            Vrni se na <a routerLink="/">vstopno stran</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';

import { RecaptchaModule } from 'ng-recaptcha';

// modules
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';

// general
import { HeaderComponent } from './general/header/header.component';
import { FooterComponent } from './general/footer/footer.component';
import { QuickAccessComponent } from './general/quick-access/quick-access.component';

// dialogs
import { ImageHelperDialogComponent } from './dialogs/image-helper/image-helper.component';
import { CampaignSubscribeDialogComponent } from './dialogs/campaign-subscribe/campaign-subscribe.component';

// components
import { LoaderComponent } from './loader/loader.component';
import { SvetovalecListItemComponent } from './svetovalec-list-item/list-item.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ActionVersionComponent } from './action-version/action-version.component';
import { SmsObvescanjeComponent } from './sms-obvescanje/sms-obvescanje.component';
import { KlubZvestobaOgrevaUgodnostiComponent } from './klub-zvestoba-ogreva/ugodnosti/ugodnosti.component';
import { PovprasevanjeObrazecComponent } from './povprasevanje-obrazec/povprasevanje-obrazec.component';
import { PovprasevanjeObrazecPoljeComponent } from './povprasevanje-obrazec-polje/povprasevanje-obrazec-polje.component';
import { RazlagaRacunaTockaComponent } from './razlaga-racuna-tocka/razlaga-racuna-tocka.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';

@NgModule({ declarations: [
        LoaderComponent,
        SvetovalecListItemComponent,
        HeaderComponent,
        FooterComponent,
        PaginationComponent,
        ActionVersionComponent,
        BreadcrumbComponent,
        ImageHelperDialogComponent,
        CampaignSubscribeDialogComponent,
        SmsObvescanjeComponent,
        KlubZvestobaOgrevaUgodnostiComponent,
        QuickAccessComponent,
        PovprasevanjeObrazecComponent,
        PovprasevanjeObrazecPoljeComponent,
        RazlagaRacunaTockaComponent
    ],
    exports: [
        LoaderComponent,
        SvetovalecListItemComponent,
        HeaderComponent,
        FooterComponent,
        PaginationComponent,
        BreadcrumbComponent,
        ActionVersionComponent,
        ImageHelperDialogComponent,
        CampaignSubscribeDialogComponent,
        SmsObvescanjeComponent,
        KlubZvestobaOgrevaUgodnostiComponent,
        QuickAccessComponent,
        PovprasevanjeObrazecComponent,
        PovprasevanjeObrazecPoljeComponent,
        RazlagaRacunaTockaComponent
    ], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatSliderModule,
        MatDialogModule,
        MatTooltipModule,
        RecaptchaModule,
        DirectivesModule,
        PipesModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ComponentsModule { }

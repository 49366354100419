import { Directive, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

import { DocumentsService } from '../services/documents.service';

@Directive({
    selector: '[dokument-id]'
})
export class DocumentIdDirective implements OnInit {

    @Input('dokument-id')
    public documentIdInput: string;

    @Input('dokument-text')
    public documentTextInput: string;

    @Input('dokument-icon')
    public documentIconInput: string;

    public loading: boolean = true;
    
    private useDocumentText: boolean = true;
    private useDocumentIcon: boolean = true;

    constructor(
        private elementRef: ElementRef,
        private documentsService: DocumentsService
    ) { }

    public ngOnInit() {

        this.useDocumentText = this.documentTextInput === 'true';
        this.useDocumentIcon = this.documentIconInput === 'true';

        this.documentsService.parseDocumentAnchorElementRef(this.elementRef, this.documentIdInput, this.useDocumentText, this.useDocumentIcon);
    }
}
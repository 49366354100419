export const environment = {
  production: true,
  envName: 'test',
  pageBaseUrl: 'https://energetika-lj.cloud.si',
  siteName: 'Energetika Ljubljana',
  defaultPageTitle: 'Bivanju Dajemo Utrip',
  serviceBasePath: 'https://energetika-lj.cloud.si/api',
  cmsServiceBasePath: 'https://energetika-lj.cloud.si/cms/api',
  cmsContentBasePath: '',
  captchaKey: '6Ld3yRkTAAAAAC0ng7_w4nxjBjZKXlGw8ER0wQNi'
};

<article>
    <div class="top-banner ugodnost" style="background-image: url('assets/images/carousel/background04.jpg')">
        <div class="layer">
            <img class="img-fluid" src="assets/images/klub-zvestoba-ogreva/logo-big-white.png" alt="Logo Klub zvestoba ogreva">
            <h1>
              <strong>Popust</strong> na izdelavo <strong>energetske izkaznice</strong>
            </h1>
        </div>
    </div>

    <div class="pas-vsebina">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-6 col-xl-4 offset-lg-1 offset-xl-2">
                    <div>
                        <p>Člani Kluba Zvestoba ogreva ste upravičeni do <strong>20 % popusta na izdelavo energetske izkaznice pri Energetiki Ljubljana.</strong></p>
                    </div>
                    <div class="mt-4">
                        <p>Postopek izdelave energetske izkaznice obsega:</p>
                        <ul>
                            <li>pregled stavbe in naprav (ni obvezen za stanovanja in novogradnje),</li>
                            <li>analizo podatkov o stavbi in rabi energije,</li>
                            <li>izračun potrebnih energijskih kazalnikov in</li>
                            <li>vpis podatkov v register.</li>
                        </ul>
                    </div>
                    <div class="mt-3">
                        <p>
                            Cena za eno, dvo in tro stanovanjske hiše: 98,00 EUR *.<br />
                            Cena za stanovanje v bloku: 56,00 EUR *.
                        </p>
                        <p>Cenik za izdelavo energetskih izkaznic velja za stavbe na geografskem območju Mestne občine Ljubljana, Občine Brezovica, Občine Dobrova - Polhov Gradec, Občine Dol pri Ljubljani, Občine Ig, Občine Medvode, Občine Škofljica, Občine Log -  Dragomer in Občine Grosuplje. Za stavbe, ki se nahajajo izven navedenega območja, izdelava energetskih izkaznic ni mogoča.</p>
                        <p class="notes mt-2">* Končna cena s popustom in davkom na dodano vrednost</p>
                    </div>
                    <div class="mt-3">
                        <button (click)="toggleAdditionalTxt()" class="btn-blue border-only inline-block">Želim izkoristiti popust</button>
                        <div *ngIf="showAdditionalTxt" class="additional-explanation">
                            <div class="arrow"></div>
                            <div class="text">
                                <p>Pokličite Kontaktni center Energetike Ljubljana na številko 080 28 82 (od 8.00 do 15.00 ure) ali pošljite email na <a href="mailto:klub@bivanjudajemoutrip.si">klub&#64;bivanjudajemoutrip.si</a>. Navedite ime in priimek, naslov, odjemno mesto in kontaktno telefonsko številko na kateri ste dosegljivi.</p>
                                <p>Poklicali vas bomo na navedeno kontaktno številko ter in se z vami dogovoril o pripravi ponudbe za izdelavo energetske izkaznice.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="row">
                        <div class="col-12 tile">
                            <img src="assets/images/klub-zvestoba-ogreva/ugodnosti-energetska-izkaznica.jpg" alt="">
                        </div>

                        <div class="mt-4">
                            <p><a dokument-id="99D09A3A-150F-4133-A450-9DADA48BF98C" dokument-icon="true" dokument-text="true" href=""></a></p>
                            <p>Več o energetski izkaznici: <a href="http://energetskaizkaznica.si/" target="_blank">http://energetskaizkaznica.si/</a>.</p>
                            <p><a href="http://www.pisrs.si/Pis.web/pregledPredpisa?id=PRAV11883" target="_blank">Pravilnik o metodologiji izdelave in izdaji energetskih izkaznic stavb (Uradni list RS, št. 92/2014)</a>.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</article>

<main id="content">
    <section class="content theme-2">
        <router-outlet #childOutlet="outlet"></router-outlet>
        <article *ngIf="!childOutlet.isActivated">
            <div class="background-light-grey">
                <div class="container">
                    <div class="row">
                        <div class="col-12 logo">
                            <h1><img [src]="getEnergetikaVDruzbiLogo()" alt="Energetika v družbi" /></h1>
                        </div>
                    </div>
                    <nav aria-label="Meni z povezavami: Energetika v družbi" class="row">
                        <a routerLink="./matevz-lenarcic" class="col-12 col-lg-6">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/matevz-lenarcic-thumbnail.jpg" alt="Slika Okoljska misija" />
                            <div class="logo lenarcic">
                              <span>Okoljska misija GreenLightWorldFlight</span>
                            </div>
                        </a>

                        <a routerLink="./smarna-gora" class="col-12 col-lg-6">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/smarna-gora-thumbnail.jpg" alt="Slika Šmarna Gora" />
                            <div class="logo">
                              <span>Šmarna Gora</span>
                            </div>
                        </a>

                        <a routerLink="./energetika-nipke" class="col-12 col-lg-6">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/energetika-nipke-thumbnail.jpg" alt="Slika izvajalca Nipke" />
                            <div class="logo">
                              <span><img class="img-fluid" [src]="getNipkeLogo()" alt="Slika s tekstom: energetika + nipke" /></span>
                            </div>
                        </a>

                        <a routerLink="./tople-tacke" class="col-12 col-lg-6">
                            <img class="img-fluid" src="assets/images/energetika-v-druzbi/tople-tacke-thumbnail.jpg" alt="Slika človek s psom"/>
                            <div class="logo">
                                <span><img class="img-fluid" [src]="getTackeLogo()" alt="Slika s tekstom Tople tačke"/></span>
                            </div>
                        </a>
                    </nav>
                </div>
            </div>
        </article>
    </section>
</main>

import { Route } from '@angular/router';

import { NpnPaketiComponent } from './npn-paketi.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
  title: 'Paketi za izgradnjo ali obnovo notranje plinske napeljave',
  description: 'V Energetiki Ljubljana se s plinom ukvarjamo že od leta 1861, zaupajte nam ogrevanje svojega doma tudi vi!',
  keywords: ['plinska napeljava', 'notranja', 'obnova', 'izgradnja', 'npn', 'plinski kotel', 'na ključ', 'financiranje', 'financni najem', 'komercialni popust'],
  imageUrl: 'assets/images/npn/background-top.jpg',
  imageWidth: 1920,
  imageHeight: 601
};

export const route: Route = {
  path: 'npn-paketi',
  component: NpnPaketiComponent,
  data: {
    metadata: metadata
  }
};

import {
  Component,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  Inject,
  OnDestroy,
  HostListener,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';

import { combineLatest, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

declare var $ : any;

import { kzoCenaPlina } from '../../components/klub-zvestoba-ogreva/ugodnosti/ugodnosti.component';

import { CmsService, CmsSitemap } from '../../services/cms.service';

import { NewsArticle, NewsTag } from '../../models/cms.model';
import {CookiesService} from '../../services/cookies.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

    public cmsSitemap: CmsSitemap = null;
    public news: NewsArticle[] = [];
    public newsTags: NewsTag[] = [];

    public newsLoading: boolean = true;
    public sitemapLoading: boolean = true;

    public kzoCenaPlina: string = kzoCenaPlina;

    public cmsContentBasePath;

    private revolutionSlider: any = null;

    public sliderStopped: boolean = false;
    private routerEventsSubscription: Subscription = null;
    public showMenuImages: boolean = false;

    private energentiImage: string;
    private upravljanjeRacunaImage: string;
    private investicijeImage: string;
    private financneUgodnostiImage: string;
    private ucinkovitaRabaEnergijeImage: string;
    private inzeniringImage: string;

    constructor(
        @Inject(PLATFORM_ID) private platfromId,
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService,
        private cookiesService: CookiesService,
    ) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.cmsService.getCmsSitemapAsync()
            .subscribe(res => {
                this.cmsSitemap = res;
                this.sitemapLoading = false;
            });

        combineLatest(this.cmsService.getCmsNewsTagsAsync(), this.cmsService.getNewsLatestAsync())
            .pipe(
                finalize(() => {
                    this.newsLoading = false;
                })
            )
            .subscribe(([tags, news]) => {
                this.newsTags = tags.filter(e => !e.kategorija);
                this.news = news;
                this.newsLoading = false;
            });

        this.route.queryParams.subscribe(qp => {

            if (qp.carouselLink) {
                let newLink = qp.carouselLink;

                if (!newLink.startsWith('/'))
                    newLink = `/${newLink}`;

                this.router.navigate([newLink], { replaceUrl: true })
            }
        });

        this.cookiesService.selectedTheme$.subscribe(res => {

          switch (res) {
            case 'theme-wcag':
              this.energentiImage = 'assets/images/menu/ikona-plamen-blue-light-wcag.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-light-wcag.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-light-wcag.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-light-wcag.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-light-wcag.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-light-wcag.png';
              break;
            case 'theme-normal':
              this.energentiImage = 'assets/images/ikone/plamen-blue-light.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-light.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-light.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-light.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-light.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-light.png';
              break;
            case 'theme-white-black':
              this.energentiImage = 'assets/images/ikone/wcag/plin-white-black.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-white-black.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-white-black.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-white-black.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-white-black.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-white-black.png';
              break;
            case 'theme-black-white':
              this.energentiImage = 'assets/images/ikone/wcag/plin-black.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-black-white.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-black-white.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-black-white.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-black-white.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-black-white.png';
              break;
            case 'theme-black-beige':
              this.energentiImage = 'assets/images/ikone/wcag/plin-beige.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-black-beige.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-black-beige.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-black-beige.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-black-beige.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-black-beige.png';
              break;
            case 'theme-black-yellow':
              this.energentiImage = 'assets/images/ikone/wcag/plin-black-yellow.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-black-yellow.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-black-yellow.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-black-yellow.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-black-yellow.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-black-yellow.png';
              break;
            case 'theme-yellow-blue':
              this.energentiImage = 'assets/images/ikone/wcag/plin-yellow.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-yellow-blue.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-yellow-blue.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-yellow-blue.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-yellow-blue.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-yellow-blue.png';
              break;
            case 'theme-lime-black':
              this.energentiImage = 'assets/images/ikone/wcag/plin-lime.png';
              this.upravljanjeRacunaImage = 'assets/images/menu/ikona-racun-blue-lime-black.png';
              this.investicijeImage = 'assets/images/menu/ikona-nastavitve-blue-lime-black.png';
              this.financneUgodnostiImage = 'assets/images/menu/ikona-cekini-blue-lime-black.png';
              this.ucinkovitaRabaEnergijeImage = 'assets/images/menu/ikona-zarnica-blue-lime-black.png';
              this.inzeniringImage = 'assets/images/menu/ikona-hisa-blue-lime-black.png';
              break;
          }
      });

    }

    ngAfterViewInit() {

        // intialize carousels
        if (isPlatformBrowser(this.platfromId)) {
            this.revolutionSlider = $("#homeSlider").revolution({
                sliderType:"standard",
                startDelay: 500,
                spinner:"spinner2",
                sliderLayout:"auto",
                viewPort:{
                    enable: false,
                    outof: 'wait',
                    visible_area: '100%'
                },
                delay:9000,
                navigation: {
                    keyboardNavigation:"on",
                    keyboard_direction: "horizontal",
                    mouseScrollNavigation:"off",
                    onHoverStop:"off",
                    arrows: {
                        style:"erinyen",
                        enable:true,
                        hide_onmobile:true,
                        hide_under:768,
                        hide_onleave:false,
                        hide_delay:200,
                        hide_delay_mobile:1200,
                        tmp:'<div class="tp-title-wrap">  	<div class="tp-arr-imgholder"></div>    <div class="tp-arr-img-over"></div>	<span class="tp-arr-titleholder">{{title}}</span> </div>',
                        left: {
                            h_align:"left",
                            v_align:"center",
                            h_offset:30,
                            v_offset:0
                        },
                        right: {
                            h_align:"right",
                            v_align:"center",
                            h_offset:30,
                            v_offset:0
                        }
                    },
                    touch:{
                        touchenabled:"on",
                        swipe_treshold : 75,
                        swipe_min_touches : 1,
                        drag_block_vertical:false,
                        swipe_direction:"horizontal"
                    },
                    bullets: {
                        enable:true,
                        hide_onmobile:true,
                        hide_under:768,
                        style:"hermes",
                        hide_onleave:false,
                        hide_delay:200,
                        hide_delay_mobile:1200,
                        direction:"horizontal",
                        h_align:"center",
                        v_align:"bottom",
                        h_offset:0,
                        v_offset:30,
                        space:5
                    }
                },
                gridwidth: [1200, 992, 768, 480],
                gridheight: [480, 400, 350, 300],
                responsiveLevels: [1200, 992, 768, 480]
            });

            var that = this;

            this.revolutionSlider.bind("revolution.slide.onloaded",function (e) {

              var slider = $(this);

              // Add tab suppport to bullets and arrows
              var bulletsArrows = slider.find( '.tp-bullet, .tparrows' );

              bulletsArrows.attr( 'tabindex', '0' );
              bulletsArrows.attr( 'role', 'button' );
              bulletsArrows.keypress( function( event ) {
                if( event.keyCode == 13 ) {
                  $( this ).click();
                }
              } );


              // Add aria-label to bullets
              var bullets = slider.find( '.tp-bullet' );
              $.each( bullets, function( index, val ) {
                var slideNumber = parseInt( index + 1 );
                $(this).attr( 'aria-label', 'Poglej ponudbo št. ' + slideNumber );
              });

              // Add label to next arrow
              slider.find( '.tp-leftarrow' ).attr( 'aria-label', 'Prejšnja ponudba' );

              // Add label to prev arrow
              slider.find( '.tp-rightarrow' ).attr( 'aria-label', 'Naslednja ponudba' );

            } );

          this.revolutionSlider.bind("revolution.slide.onchange",function (e) {

            var slider = $(this);

            if (that.sliderStopped) {
              slider.revpause();
            }

          } );
        }
    }

    ngOnDestroy() {

        if (this.revolutionSlider) {
            this.revolutionSlider.revkill();
        }
    }

  empty() {

  }

  toggleRevProgress() {
    if (this.revolutionSlider) {
      if (this.sliderStopped) {
        this.revolutionSlider.revresume();
        this.sliderStopped = false;
      } else {
        this.revolutionSlider.revpause();
        this.sliderStopped = true;
      }
      this.revolutionSlider.sliderStopped = this.sliderStopped;
    }
  }

  stopSlider() {
    if (!this.sliderStopped) {
      this.revolutionSlider.revpause();
      this.sliderStopped = true;
    }
  }

  getMenuImage(naziv: string): string {
    switch (naziv) {
      case 'Energenti':
        return this.energentiImage;
      case 'Upravljanje računa':
        return this.upravljanjeRacunaImage;
      case 'Investicije in vzdrževanje':
        return this.investicijeImage;
      case 'Finančne ugodnosti':
        return this.financneUgodnostiImage;
      case 'Učinkovita raba energije':
        return this.ucinkovitaRabaEnergijeImage;
      case 'Inženiring in geodetske storitve':
        return this.inzeniringImage;
    }
  }

}

<footer class="footer-dark">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="heading-footer"><span>O nas</span></div>
                            <p>
                                JAVNO PODJETJE ENERGETIKA LJUBLJANA d.o.o.
                                <br />Verovškova ulica 62
                                <br />1000 Ljubljana
                            </p>
                        </div>

                        <div class="col-12 col-md-4 mt-4 mt-md-0">
                            <div class="heading-footer"><span>Kontakt</span></div>
                            <p>
                                <i class="fa fa-envelope footer-info-icons" aria-hidden="true"></i><small class="address"><a href="mailto:info@energetika.si">info&#64;energetika.si</a></small><br />
                                <i class="fa fa-phone footer-info-icons" aria-hidden="true"></i><small class="address"><a href="tel:0802882" aria-label="Kontakt tel.:080 28 82">080 28 82</a></small>
                            </p>
                            <p>
                                <i class="fa fa-facebook footer-info-icons" aria-hidden="true"></i><small class="address"><a href="https://www.facebook.com/energetika.ljubljana" target="_blank">energetika.ljubljana</a></small><br />
                                <i class="fa fa-globe footer-info-icons" aria-hidden="true"></i><small class="address"><a href="http://www.bivanjudajemoutrip.si" target="_blank">www.bivanjudajemoutrip.si</a></small><br />
                                <i class="fa fa-globe footer-info-icons" aria-hidden="true"></i><small class="address"><a href="http://www.energetika.si" target="_blank">www.energetika.si</a></small>
                            </p>
                        </div>

                        <div class="col-12 col-md-4 mt-4 mt-md-0">
                            <div class="heading-footer"><span>Dežurna služba</span></div>
                            <p>
                                <strong>Zemeljski plin:</strong><br />
                                <i class="fa fa-phone footer-info-icons" aria-hidden="true"></i><small class="address"><a href="tel:01/5889446" aria-label="Zemeljski plin tel:01/ 5889 446">01/ 5889 446</a></small><br />
                                <i class="fa fa-phone footer-info-icons" aria-hidden="true"></i><small class="address"><a href="tel:0802882" aria-label="Zemeljski plin tel. 2:080 28 82">080 28 82</a> (izberite 4, nato izberite 1)</small>
                            </p>
                            <p>
                                <strong>Toplota:</strong><br />
                                <i class="fa fa-phone footer-info-icons" aria-hidden="true"></i><small class="address"><a href="tel:01/5889537" aria-label="Toplota tel.:01/ 5889 537">01/ 5889 537</a></small><br />
                                <i class="fa fa-phone footer-info-icons" aria-hidden="true"></i><small class="address"><a href="tel:0802882" aria-label="Toplota tel. 2:080 28 82">080 28 82</a> (izberite 4, nato izberite 2)</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2 d-none d-md-block">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                          <div class="image-height zelena-prestolnica-img" role="img" aria-label="Ljubljana Zelena prestolnica evrope Logo" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <hr>
            <div class="col-sm-11 col-xs-10">
                <p class="copyright">
                    © 2016-{{ currentYear }} Energetika Ljubljana. Vse pravice pridržane.&nbsp;&nbsp; |
                    &nbsp;&nbsp;<a routerLink="/pogoji-uporabe">Pogoji uporabe</a>&nbsp;&nbsp; |
                    &nbsp;&nbsp;<a routerLink="/varstvo-zasebnosti">Varstvo zasebnosti in osebnih podatkov</a>&nbsp;&nbsp; |
                    &nbsp;&nbsp;<a routerLink="/uporaba-piskotki">Uporaba piškotkov</a>&nbsp;&nbsp; |
                    &nbsp;&nbsp;<a routerLink="/izjava-o-dostopnosti">Izjava o dostopnosti</a>&nbsp;&nbsp; |
                    &nbsp;&nbsp;<a routerLink="/toc">Zemljevid strani</a>
                </p>
            </div>
            <div class="col-sm-1 col-xs-2 text-right">
                <a class="scroll-top" tabindex="0" (keydown)="scrollToTop()" (click)="scrollToTop()" role="link" aria-label="Skoči na vrh strani">
                    <div class="footer-scrolltop-holder">
                        <i class="fa fa-chevron-up" aria-hidden="true"></i>
                    </div>
                </a>
            </div>
        </div>

    </div>
</footer>

<div class="container py-4">

    <div class="row">
        <div class="col-12 col-lg-3">
            <a class="content-box dela-na-omrezju content-box-title" routerLink="/dela-na-omrezju">
                <div class="div-img delovna-celada-img" aria-label="Delovna čelada Logo"></div>
                <h1>Dela na omrežju</h1>
            </a>
        </div>
        <div class="col-12 col-lg-9">
            <div class="content-box dela-na-omrezju content-box-description height-control">
                <p>Naročite se na obvestila o delih na omrežju na vašem naslovu. Obveščanje poteka preko brezplačnih SMS sporočil za energenta plin in toplota. Naročite se lahko na <a routerLink="/narocilo/sms-obvescanje" aria-label="SMS obveščanje na tej povezavi">tej povezavi</a>.</p>
            </div>
        </div>
    </div>

    <div class="row">
        <aside class="col-12 col-md-4 col-lg-3">
            <nav aria-label="Meni: Vsi energenti" class="d-none d-md-flex">
                <ul class="sidebar-nav list-group w-100">
                    <li class="list-group-item no-padding" [ngClass]="{ active: isActiveParamKey(null) }">
                        <a routerLink="/dela-na-omrezju" [queryParams]="{ energent: null }">Vsi energenti</a>
                    </li>
                    <li class="list-group-item" [ngClass]="{ active: isActiveParamKey('plin') }">
                        <a routerLink="/dela-na-omrezju" [queryParams]="{ energent: 'plin' }">Plinovodno omrežje</a>
                    </li>
                    <li class="list-group-item" [ngClass]="{ active: isActiveParamKey('toplota') }">
                        <a routerLink="/dela-na-omrezju" [queryParams]="{ energent: 'toplota' }">Vročevodno omrežje</a>
                    </li>
                </ul>
            </nav>
            <p class="sms-navigation">
                <a routerLink="/narocilo/sms-obvescanje">SMS obveščanje o delih na omrežju</a>
            </p>
            <p class="sms-navigation">
              <a dokument-id="41971357-F414-4121-962F-4EF6712141AE" dokument-icon="false" dokument-text="true" href=""></a>
            </p>
        </aside>

        <div class="col-12 navigation-mobile py-2 mb-3 d-block d-md-none">
            <div class="col-12">
                <button (click)="toggleFilters()" class="btn btn-blue border-only">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                    <ng-container *ngIf="!filtersVisible">Prikaži filtre</ng-container>
                    <ng-container *ngIf="filtersVisible">Skrij filtre</ng-container>
                </button>
            </div>

            <div *ngIf="filtersVisible" class="col-12 navigation-mobile-kategorije">
                <nav aria-label="Meni: Vsi energenti">
                    <ul class="sidebar-nav list-group w-100">
                        <li class="list-group-item no-padding" [ngClass]="{ active: isActiveParamKey(null) }">
                            <a routerLink="/dela-na-omrezju" [queryParams]="{ energent: null }">Vsi energenti</a>
                        </li>
                        <li class="list-group-item" [ngClass]="{ active: isActiveParamKey('plin') }">
                            <a routerLink="/dela-na-omrezju" [queryParams]="{ energent: 'plin' }">Plinovodno omrežje</a>
                        </li>
                        <li class="list-group-item" [ngClass]="{ active: isActiveParamKey('toplota') }">
                            <a routerLink="/dela-na-omrezju" [queryParams]="{ energent: 'toplota' }">Vročevodno omrežje</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <main id="content" class="col-12 col-md-8 col-lg-9">
            <div class="container-fluid">

                <router-outlet></router-outlet>

            </div>
        </main>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="svetovalec content-box content-box-description text-center">
                <p><strong>Hvala, ker nas berete, všečkate in delite.</strong> Vaša mnenja, predloge ter vprašanja nam lahko posredujete na <a href="mailto:info@energetika.si" class="svetovalec-link">info&#64;energetika.si</a>, s pripisom: Za dela na omrežju</p>
            </div>
        </div>
    </div>
</div>

<div *ngFor="let u of ugodnostiAsync | async; let idx = index" class="col-12 col-xl-10 offset-xl-1">
    <div class="row">
        <div class="col-12 col-lg-6 col-xl-5" [class.offset-xl-1]="idx % 2 === 0" [class.order-2]="idx % 2 === 1">
            <div class="tile">
                <h2>{{ u.naziv }}</h2>
                <div [innerHTML]="u.vsebina"></div>
                <div *ngIf="u.cta">
                    <ng-container [ngSwitch]="u.ctaTip">
                        <button *ngSwitchCase="'NAROCILO_UGODNOSTI'" (click)="showActionAdditional(u.id)" class="btn-blue border-only inline-block mt-3">{{ u.ctaNaziv || 'Želim izkoristiti popust' }}</button>
                        <a *ngSwitchCase="'POVEZAVA'" [href]="u.ctaPovezava" target="_blank" class="btn-blue border-only inline-block mt-3">{{ u.ctaNaziv || 'Želim izkoristiti popust' }}</a>
                        <a *ngSwitchCase="'KZO_PRIJAVA'" routerLink="/klub-zvestoba-ogreva/prijava" class="btn-blue border-only inline-block mt-3">{{ u.ctaNaziv || 'Želim izkoristiti popust' }}</a>
                    </ng-container>
                </div>
                <div *ngIf="u.cta && u.ctaTip === 'NAROCILO_UGODNOSTI' && activeActionId === u.id" class="additional-explanation">
                    <div class="arrow"></div>
                    <div *ngIf="u.ctaVsebina; else ctaVsebinaDefault" class="text">
                        <p [innerHTML]="u.ctaVsebina"></p>
                    </div>
                    <ng-template #ctaVsebinaDefault>
                        <div class="text">
                            <p>Pokličite Kontaktni center Energetike Ljubljana na številko 080 28 82 (od 8.00 do 15.00 ure) ali pošljite email na <a href="mailto:klub@bivanjudajemoutrip.si">klub&#64;bivanjudajemoutrip.si</a> s pripisom za katero ugodnost gre. Navedite ime in priimek, naslov ter odjemno mesto.</p><p>Na vaš elektronski naslov boste nato prejeli potrditev o koriščenju popusta.</p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="u.slika" class="col-12 col-lg-6 col-xl-5" [class.offset-xl-1]="idx % 2 === 1" [class.order-1]="idx % 2 === 1">
            <div *ngIf="!u.slikaCta; else slikaCta"
                [class]="'tile image' + (u.imageClass ? ' ' + u.imageClass : '')"
                [ngStyle]="{ 'background-image': 'url(' + cmsContentBasePath + u.slika.url + ')' }"></div>
            <ng-template #slikaCta>
                <ng-container [ngSwitch]="u.slikaCtaTip">
                    <a *ngSwitchCase="'NAROCILO_UGODNOSTI'" (click)="showActionAdditional(u.id)"
                        class="tile image"
                        [ngStyle]="{ 'background-image': 'url(' + cmsContentBasePath + u.slika.url + ')' }"></a>
                    <a *ngSwitchCase="'POVEZAVA'" [href]="u.slikaCtaPovezava" target="_blank"
                        class="tile image"
                        [ngStyle]="{ 'background-image': 'url(' + cmsContentBasePath + u.slika.url + ')' }"></a>
                    <a *ngSwitchCase="'KZO_PRIJAVA'" routerLink="/klub-zvestoba-ogreva/prijava"
                        class="tile image"
                        [ngStyle]="{ 'background-image': 'url(' + cmsContentBasePath + u.slika.url + ')' }"></a>
                </ng-container>
            </ng-template>
        </div>
    </div>

    <div class="col-10 col-lg-8 col-xl-6 offset-1 offset-md-2 offset-xl-3 border-top divider my-5"></div>
</div>

<main id="content">
    <section class="content">
        <div class="background-light-grey">
            <div class="container">
                <div class="title">
                    <h1>SMS obvestila o delih na omrežju</h1>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <img class="img-fluid" src="assets/images/sms-obvescanje.jpg" alt="Slika SMS obveščanje">
                        <p class="mt-3">Naročite se na obvestila o delih na omrežju na vašem naslovu. Obveščanje poteka preko brezplačnih SMS sporočil za energenta plin in toplota. Izberite svoj ali sebi najbližji naslov, vnesite mobilno telefonsko število, jo potrdite z verifikacijsko kodo in vstopite v urejevalnik SMS obvestil za izbrani naslov.</p>
                    </div>

                    <div class="col-12 col-md-6">
                        <sms-obvescanje [naslovId]="naslovId" (onNaslovSelected)="onNaslovSelected($event)"></sms-obvescanje>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Ulica, HisnaStevilka, Naslov, NaslovVerifikacija, Narocnik, NaslovNarocnikInput } from '../models/sms-obvescanje.model';

@Injectable()
export class SmsObvescanjeService {

    private baseUrl: string;

    constructor(private http: HttpClient) { 
        this.baseUrl = `${environment.serviceBasePath}/sms-obvescanje`;
    }

    public getUlice(q: string = null): Observable<Ulica[]> {

        let params: any = {};

        if (q)
            params.q = q;

        return this.http.get<Ulica[]>(`${this.baseUrl}/ulica`, { params: params });
    }

    public getHisneStevilke(id: number): Observable<HisnaStevilka[]> {
        return this.http.get<HisnaStevilka[]>(`${this.baseUrl}/ulica/${id}`);
    }

    public getNaslov(id: number): Observable<Naslov> {
        return this.http.get<Naslov>(`${this.baseUrl}/ulica/naslov/${id}`);
    }

    public telefonskaStevilkaVerifikacija(input: NaslovVerifikacija): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/narocnik/verifikacija`, input);
    }

    public getNarocnik(input: NaslovVerifikacija): Observable<Narocnik> {
        return this.http.post<Narocnik>(`${this.baseUrl}/narocnik`, input);
    }

    public putNarocnik(input: Narocnik): Observable<Narocnik> {
        return this.http.put<Narocnik>(`${this.baseUrl}/narocnik`, input);
    }

    public postMissingAddress(input: NaslovNarocnikInput): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/naslov`, input);
    }
}
import { Component, OnInit } from '@angular/core';

import { first, map } from 'rxjs/operators';

import { CmsService } from '../../../services/cms.service';

import { Document } from '../../../models/cms.model';

@Component({
    selector: 'app-klub-zvestoba-ogreva-ugodnosti',
    templateUrl: './ugodnosti.component.html',
    styleUrls: ['./ugodnosti.component.scss']
})
export class KlubZvestobaOgrevaUgodnostiComponent implements OnInit {

    public currentUgodnostiDoc: Document = null;

    constructor(
        private cmsService: CmsService
    ) { }

    ngOnInit() {

        this.cmsService.getKzoSeznamUgodnostiAsync()
            .pipe(
                map(res => res.length >= 1 ? res[0] : null),
                first()
            )
            .subscribe(res => {
                this.currentUgodnostiDoc = res;
            });
    }
}
import { Route } from '@angular/router';

import { route as smsObvescanjeRoutes } from './sms-obvescanje/routes';

export const route: Route = {
    path: 'narocilo',
    children: [
        // akcijaRoutes,
        smsObvescanjeRoutes
    ]
};

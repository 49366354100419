<article>
    <div class="top-banner ugodnost" style="background-image: url('assets/images/klub-zvestoba-ogreva/background-minicity.jpg')">
        <div class="layer">
            <img class="img-fluid" src="assets/images/klub-zvestoba-ogreva/logo-big-white.png" alt="Logo Klub zvestoba ogreva">
            <h1>
                <strong>30 % popusta</strong> za vstopnico pri obisku <strong>Minicityja</strong>
            </h1>
        </div>
    </div>

    <div class="pas-vsebina">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1 offset-lg-2 offset-xl-3">
                    <p>
                        Člani Kluba Zvestoba ogreva ste upravičeni do 30-odstotnega popusta pri nakupu celodnevne vstopnice za obisk Minicityja v Ljubljani. Popust lahko koristite za nakup do dveh vstopnic za otroke od dopolnjenega 2. leta naprej (z enim spremljevalcem) na blagajni Minicity Ljubljana, BTC City, Dvorana A, Šmartinska 152, Ljubljana.
                    </p>
                    <p>
                        Za prejem popusta sledite spodnji povezavi in na vaš elektronski naslov boste prejeli potrditev koriščenja popusta.
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-xl-3">
                    <div class="row">
                        <div class="col-12 tile">
                            <img src="assets/images/klub-zvestoba-ogreva/minicity-logo.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-md-1 offset-lg-2 offset-xl-3 mt-3">
                    <button (click)="toggleAdditionalTxt()" class="btn-blue border-only inline-block">Želim izkoristiti popust</button>
                    <div *ngIf="showAdditionalTxt" class="additional-explanation">
                        <div class="arrow"></div>
                        <div class="text">
                            <p>Pokličite Kontaktni center Energetike Ljubljana na številko 080 28 82 (od 8.00 do 15.00 ure) ali pošljite email na <a href="mailto:klub@bivanjudajemoutrip.si">klub&#64;bivanjudajemoutrip.si</a>. Navedite ime in priimek, naslov ter odjemno mesto.</p><p>Na vaš elektronski naslov boste nato prejeli potrditev o koriščenju popusta.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</article>

<main id="content">
    <section class="content theme-2 povprasevanja">
        <article>
            <div class="title-banner">
                <div class="row align-items-center">
                    <div class="col-12">
                        <a routerLink="./" queryParamsHandling="" class="d-inline-block outline-inverse">
                            <h1>Kako vam lahko pomagamo?</h1>
                            <p class="mb-0 mt-4">
                                Izpolnite naš spletni obrazec za vloge in povpraševanja.<br />
                                Odgovorili vam bomo v najkrajšem možnem času.
                        </p>
                    </a>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-7 order-1 order-md-0 left-banner py-3 py-md-5 pr-md-5">
                        <div class="row justify-content-md-end">
                            <div class="col px-4 flex-shrink-1 flex-grow-0">
                                <ng-container *ngIf="!isChildRouteActivated">
                                    <ng-container *ngIf="kategorija === null || !kategorija.koncnaKategorija; else vnosPodatkovPovezava">

                                        <p>Izberite področje:</p>
                                        <ng-container *ngIf="!loadingKategorije; else kategorijaLoader">
                                            <ng-container *ngFor="let kat of kategorije">
                                                <ng-container *ngIf="kat.koncnaKategorija && kat.povezava && !kat.povezavaNaziv; else clickKategorija">
                                                    <a *ngIf="kat.povezavaUrl?.startsWith('./'); else kategorijaPovezavaZunanja" class="btn btn-default w-100 mb-2" [routerLink]="kat.povezavaUrl.split('/')" [queryParams]="{ katId: kat.id, step: step + 1 }" queryParamsHandling="merge">{{ kat.naziv }}</a>
                                                    <ng-template #kategorijaPovezavaZunanja>
                                                        <a class="btn btn-default w-100 mb-2" [href]="kat.povezavaUrl" target="_blank">{{ kat.naziv }}</a>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-template #clickKategorija>
                                                    <button (click)="selectKategorija(kat)" class="btn btn-default w-100 mb-2">{{ kat.naziv }}</button>
                                                </ng-template>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #kategorijaLoader>
                                            <div class="my-5">
                                                <loader></loader>
                                            </div>
                                        </ng-template>
                                        <div>
                                            <a *ngIf="hierarhija.length > 0" class="previous text" routerLink="./" [queryParams]="{ step: hierarhija[hierarhija.length - 1].nivo + 1, katId: hierarhija.length > 1 ? hierarhija[hierarhija.length - 2].id : null }"><i class="fa fa-chevron-left" aria-hidden="true"></i> Nazaj</a>
                                        </div>

                                    </ng-container>
                                    <ng-template #vnosPodatkovPovezava>


                                        <ng-container *ngIf="kategorija.povezava && kategorija.povezavaNaziv; else vnosPodatkov">
                                            <p *ngIf="kategorija.povezavaOpomba">{{ kategorija.povezavaOpomba }}</p>
                                            <a *ngIf="kategorija.povezavaUrl?.startsWith('./'); else povezavaZunanja" class="btn btn-default w-100 mb-2" [routerLink]="['./', kategorija.povezavaUrl.split('./')[1]]">{{ kategorija.povezavaNaziv }}</a>
                                            <ng-template #povezavaZunanja>
                                                <a class="btn btn-default w-100 mb-2 asd" [href]="kategorija.povezavaUrl" target="_blank">{{ kategorija.povezavaNaziv }}</a>
                                            </ng-template>
                                        </ng-container>

                                        <ng-template #vnosPodatkov>
                                            <div class="my-5" *ngIf="loadingObrazecUpdate">
                                                <loader></loader>
                                            </div>
                                            <ng-container *ngFor="let o of obrazci; let oi = index">
                                                <povprasevanje-obrazec *ngIf="!loadingObrazecUpdate && step === kategorija.nivo + 1 + oi + 1"
                                                    [obrazec]="o"
                                                    [vsiObrazci]="obrazci"
                                                    [vrednosti]="podrocjeVrednosti"
                                                    [isLastStep]="step === maxSteps"
                                                    [showErrors]="obrazecShowError"
                                                    [povprasevanje]="povprasevanje"
                                                    (onDescriptionUpdate)="onDescriptionUpdate($event)"
                                                    (onSubmit)="onObrazecSubmit($event, o)"
                                                    (onBack)="onObrazecBack($event, o)"></povprasevanje-obrazec>
                                            </ng-container>
                                            <ng-container *ngIf="!loadingObrazecUpdate && obrazecSubmitted">
                                                <ng-container *ngIf="kategorija.zahvalaOpis; else zahvalaText">
                                                    <div [innerHTML]="kategorija.zahvalaOpis"></div>
                                                </ng-container>
                                                <ng-template #zahvalaText>
                                                    <ng-container *ngIf="!obrazecSubmitError else obrazecSubmitErr">
                                                        <p>Vaš obrazec je bil uspešno oddan!</p>
                                                        <p>V primeru, da nadaljevanje postopka zahteva vaš podpis, ste na navedeni elektronski naslov prejeli izpolnjen obrazec in nadaljnja navodila.</p>
                                                    </ng-container>
                                                    <ng-template #obrazecSubmitErr>
                                                        <div class="alert alert-warning">
                                                            <p>Prišlo je do napake na strežniku med procesiranjem zahteve. Prosimo, preglejte obrazce na prejšnjih korakih ter preverite veljavnost podatkov, ter poskusite ponovno oddati povpraševanje.</p>
                                                        </div>
                                                    </ng-template>
                                                </ng-template>
                                            </ng-container>
                                        </ng-template>




                                    </ng-template>

                                    <nav class="text-center mt-4 mt-md-7" aria-label="Navigacijski meni za povpraševanje" *ngIf="step <= maxSteps">
                                        <i class="fa" [class.fa-circle]="s === step" [class.fa-circle-o]="s !== step" *ngFor="let s of steps" aria-hidden="true"></i>
                                    </nav>

                                  <ng-container *ngIf="kategorija === null || !kategorija.koncnaKategorija; else vnosPodatkovPovezavaDescription">
                                    <p class="mt-4">
                                      Ob izbiri gumba področja, obrazec nadaljuje na naslednji korak.
                                      Izbrana področja se prikažejo v delu "Vnešeni podatki".
                                    </p>
                                  </ng-container>

                                  <ng-template #vnosPodatkovPovezavaDescription>
                                    <p *ngIf="step !== maxSteps" class="mt-4">
                                     Vnesite podatke in ob izbiri gumba "Naprej" obrazec se nadaljuje na naslednji korak.
                                    </p>

                                    <p *ngIf="step === maxSteps" class="mt-4">
                                      Vnesite podatke in ob izbiri gumba "Oddaj" oddajte podatke.
                                    </p>
                                  </ng-template>


                                </ng-container>

                                <router-outlet (activate)="onChildRouteActivate($event)" (deactivate)="onChildRouteDeactivate($event)"></router-outlet>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-5 order-0 order-md-1 right-banner py-3 py-md-5 p-relative" [class.extended]="isAbstractExtended">
                        <div class="row">
                            <div class="col px-4 flex-shrink-1 flex-grow-0">
                                <p class="mb-0">Vnešeni podatki:</p>
                                <ul class="mt-2 mt-md-3 mb-0" *ngIf="hierarhija.length > 0">
                                    <li *ngFor="let kat of hierarhija" class="mb-0 mb-md-2">
                                        <a *ngIf="kat.povezavaUrl; else hiearhijaPovezava" class="text" [routerLink]="kat.povezavaUrl.split('/')" [queryParams]="{ katId: kat.id, step: kat.nivo + 2 }">{{ kat.naziv }}</a>
                                        <ng-template #hiearhijaPovezava>
                                            <a class="text" routerLink="/povprasevanje" [queryParams]="{ katId: kat.id, step: kat.nivo + 2 }">{{ kat.naziv }}</a>
                                        </ng-template>
                                    </li>
                                </ul>
                                <ng-container *ngIf="povprasevanje?.naziv || povprasevanje?.email || povprasevanje?.telefonskaStevilka">
                                    <p class="mt-3 mt-md-4 mb-0">Podatki o stranki:</p>
                                    <p class="mt-2 mt-md-3 mb-0">
                                        {{ povprasevanje?.naziv }}<br *ngIf="povprasevanje?.email" />
                                        {{ povprasevanje?.email }}<br *ngIf="povprasevanje?.telefonskaStevilka" />
                                        {{ povprasevanje?.telefonskaStevilka }}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="read-more" (click)="abstractExtend()">
                            <i class="fa" [class.fa-chevron-down]="!isAbstractExtended" [class.fa-chevron-up]="isAbstractExtended" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </section>
</main>

export class HtmlUtils {

    public static stripHtmlToMaxLength(str: string, maxLen: number) {
        
        let dummyElement = document.createElement('div');
        dummyElement.innerHTML = str.replace(/\s\s+/g, ' ');

        let res = dummyElement.textContent;

        if (res.length > maxLen) {
            let nextSpaceIdx = res.indexOf(' ', maxLen);

            if (nextSpaceIdx > maxLen + 15)
                nextSpaceIdx = maxLen

            res = res.substring(0, nextSpaceIdx) + " ...";
        }

        return res;
    }

    public static stripHtmlToMaxLengthNoDocument(str: string, maxLen: number) {

        let res = str.replace(/(<([^>]+)>)/ig, '');

        if (res.length > maxLen) {
            let nextSpaceIdx = res.indexOf(' ', maxLen);

            if (nextSpaceIdx > maxLen + 15)
                nextSpaceIdx = maxLen

            res = res.substring(0, nextSpaceIdx) + " ...";
        }

        return res;
    }
}
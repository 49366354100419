import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { VnosnoPoljeControl } from '../povprasevanje-obrazec/povprasevanje-obrazec.component';

@Component({
    selector: 'povprasevanje-obrazec-polje',
    templateUrl: './povprasevanje-obrazec-polje.component.html',
    styleUrls: ['./povprasevanje-obrazec-polje.component.scss'],
})
export class PovprasevanjeObrazecPoljeComponent implements OnInit {

    public readonly odjemnaMestaMax: number = 10;

    @ViewChild('fileInput') public fileInputElementRef: ElementRef;

    @Input() public polje: VnosnoPoljeControl;
    @Input() public skupina: string = null;

    public today: Date = new Date();

    constructor(
    ) { }

    public ngOnInit() {
    }

    public browseFiles() {

		if (this.fileInputElementRef)
			this.fileInputElementRef.nativeElement.click();
    }

    public onFileChange(event: any, vp: VnosnoPoljeControl) {
       
		if (event.target.files && event.target.files.length) {
			const [file] = event.target.files;
			
			vp.file = file;

			vp.control.patchValue(file.name);
		}
	}
}
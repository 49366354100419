import { Route } from '@angular/router';

import { RazlagaRacunaComponent } from './razlaga-racuna.component';
import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
  title: 'Razlaga računa',
  keywords: ['razlaga računa', 'plin', 'elektrika', 'toplota']
};

export const route: Route = {
  path: 'razlaga-racuna',
  children: [
    {
      path: '',
      component: RazlagaRacunaComponent,
      data: {
        metadata: metadata
      }
    }
  ]
};

<div class="row">
    <div class="col-12">
        <form *ngIf="showNaslov">
            <div class="form-group">
                <label for="ulica">Ulica <strong>*</strong></label>
                <div class="dropdown full-width" [class.show]="showUliceDropdown" (clickOutside)="closeUlicaDropdown($event)">
                    <input id="ulica" type="text" autocomplete="off" class="form-control dropdown-toggle"
                        placeholder="Poiščite svojo ulico..."
                        [ngModel]="ulicaSearch"
                        (ngModelChange)="ulicaSearchChange($event)"
                        [ngModelOptions]="{ standalone: true }"
                        (keydown)="uliceInputToggle($event)"
                        (click)="toggleUlicaDropdown()">

                    <ul class="dropdown-menu" [class.show]="showUliceDropdown">
                        <li class="dropdown-item" *ngIf="!loadingUlice" (click)="selectUlica(null)"><a tabindex="0" (keydown)="checkUlicaTab($event, -1, ulice.length)">Izberite ulico</a></li>
                        <ng-container *ngIf="!loadingUlice">
                            <li class="dropdown-item" *ngFor="let u of ulice;let i=index;" (click)="selectUlica(u)"><a tabindex="0" (keydown)="checkUlicaTab($event, i, ulice.length)">{{ u.label }}</a></li>
                        </ng-container>
                        <li class="my-2" *ngIf="loadingUlice">
                            <loader [small]="true"></loader>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="form-group" *ngIf="selectedUlica">
                <label for="hisnaSt">Hišna številka <strong>*</strong></label>
                <div id="hisnaSt" class="dropdown full-width" [class.show]="showHisneStevilkaDropdown"
                     (clickOutside)="closeHisneStevilkeDropdown()" (keydown.escape)="closeHisneStevilkeDropdown()">
                    <button (keydown)="hisneStevilkeKeydown($event)" (click)="toggleHisneStevilkeDropdown()"
                            type="button" class="form-control dropdown-toggle"
                            [ngClass]="{ placeholder: !selectedHisnaStevilka }">{{ selectedHisnaStevilka ? selectedHisnaStevilka.label : 'Izberite hišno številko' }}</button>

                    <ul class="dropdown-menu" [class.show]="showHisneStevilkaDropdown">
                        <li class="dropdown-item" *ngIf="!loadingHisneStevilke" (click)="selectHisnaStevilka(null)"><a tabindex="0" (keydown)="checkHsTab($event, -1, hisneStevilke.length)">Izberite hišno številko</a></li>
                        <ng-container *ngIf="!loadingHisneStevilke">
                            <li class="dropdown-item" *ngFor="let hs of hisneStevilke;let i=index;" (click)="selectHisnaStevilka(hs)"><a tabindex="0" (keydown)="checkHsTab($event, i, hisneStevilke.length)">{{ hs.label }}</a></li>
                        </ng-container>
                        <li class="my-2" *ngIf="loadingHisneStevilke">
                            <loader [small]="true"></loader>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="form-group" *ngIf="selectedUlica && selectedHisnaStevilka">
                <div *ngIf="loadingNaslov">
                    <loader></loader>
                </div>
                <p *ngIf="!loadingNaslov">Na naslovu <strong>{{ selectedNaslov.street }} {{ selectedNaslov.number }} ({{ selectedNaslov.municipality }})</strong> je na voljo naročanje na obvestila za energente:</p>
                <ul *ngIf="!loadingNaslov">
                    <li *ngIf="selectedNaslov.heat">Daljinsko ogrevanje (toplota)</li>
                    <li *ngIf="selectedNaslov.gas">Zemeljski plin</li>
                </ul>
            </div>

            <a class="btn-blue responsive-width mt-3" *ngIf="selectedNaslov" [routerLink]="['./naroci', selectedNaslov.id]">
                <i class="fa fa-paper-plane-o" aria-hidden="true"></i> Naroči se na obveščanje
            </a>

            <p>
                <a routerLink="./manjkajoci-naslov" [queryParams]="{ naslovId: selectedNaslov ? selectedNaslov.id : null }">Ne najdete naslova ali energenta na vašem naslovu?</a>
            </p>

            <p>
                Imate težave pri prijavi ali odjavi prejemanja SMS obvestil? <br />
                Pokličite <a href="tel:0802882">080 28 82</a>.
            </p>

            <p>
                <a routerLink="./pogoji">Pogoji storitve SMS obvestila o delih na omrežju.</a>
            </p>

        </form>

        <div *ngIf="!showNaslov && loadingNaslov">
            <loader></loader>
        </div>
        <div *ngIf="!showNaslov && !loadingNaslov && selectedNaslov" class="thumbnail">
            <h3>Podatki o naročniku</h3>
            <p><strong>Naslov:</strong> {{ selectedNaslov.street }} {{ selectedNaslov.number }} ({{ selectedNaslov.municipality }})</p>
            <p>Energenti na izbranem naslovu:</p>
            <ul>
                <li *ngIf="selectedNaslov.gas">Zemeljski plin</li>
                <li *ngIf="selectedNaslov.heat">Daljinsko ogrevanje (toplota)</li>
            </ul>
            <p *ngIf="!showTelefon"><strong>Telefonska številka:</strong> {{ msisdn }}</p>
        </div>

        <form *ngIf="showTelefon" [formGroup]="telefonForm" (ngSubmit)="shraniTelefonskaStevilka($event)">
            <div *ngIf="!loadingAvtentikacija" class="form-group" [ngClass]="{ 'has-error': telefonFormTelefonskaStevilka?.dirty && !telefonFormTelefonskaStevilka?.valid }">
              <label for="telSt">Telefonska številka <strong>*</strong></label>
                <input id="telSt" type="text" [formControl]="telefonFormTelefonskaSt" class="form-control" autocomplete="tel">
              <p *ngIf="telefonFormTelefonskaStevilka?.dirty && telefonFormTelefonskaStevilka?.errors?.required" class="help-block error" role="alert"><em>Polje je obvezno</em></p>
              <p *ngIf="telefonFormTelefonskaStevilka?.dirty && telefonFormTelefonskaStevilka?.errors?.minlength" class="help-block error" role="alert"><em>Vnos je prekratek, zahteva se vsaj 9 znakov</em></p>
            </div>

            <p *ngIf="!loadingAvtentikacija" class="help-block">Na vneseno telefonsko številko vam bomo poslali verifikacijsko kodo. S to kodo lahko nato dostopate do podrobnosti v katerih lahko izbirate energente, za katere želite prejemati SMS obvestila.</p>

            <div class="alert alert-warning" *ngIf="errorAuthReqFail && !loadingAvtentikacija">
                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka pri pošiljanju verifikacijskega sporočila</h4>
                <p>Prišlo je do napake pri pošiljanje SMS sporočila s potrditveno kodo na vašo telefonsko številko. Prosimo, preverite vaš vnos.</p>
            </div>

            <div *ngIf="loadingAvtentikacija">
                <loader></loader>
            </div>

            <button class="btn-blue responsive-width px-2 mt-3" [disabled]="!telefonForm.valid" type="submit" [ngClass]="{ disabled: !telefonForm.valid }">
                <i class="fa fa-paper-plane-o" aria-hidden="true"></i> Pošlji verifikacijsko kodo
            </button>
        </form>

        <form [formGroup]="authForm" *ngIf="showAuth" (ngSubmit)="shraniVerifikacijo($event)">
            <div *ngIf="!loadingDetails" class="form-group" [ngClass]="{ 'has-error': authFormVerikacijskaKoda?.dirty && !authFormVerikacijskaKoda?.valid }">
                <label for="verifikacijskaKoda">Verifikacijska koda <strong>*</strong></label>
                <input id="verifikacijskaKoda" type="text" [formControl]="authFormVerikacijskaKoda" class="form-control">
              <p *ngIf="authFormVerikacijskaKoda?.dirty && authFormVerikacijskaKoda?.errors?.required" class="help-block error" role="alert"><em>Polje je obvezno</em></p>
              <p *ngIf="authFormVerikacijskaKoda?.dirty && authFormVerikacijskaKoda?.errors?.minlength" class="help-block error" role="alert"><em>Vnos je prekratek, zahteva se vsaj 4 znake</em></p>
            </div>

            <p *ngIf="!loadingDetails" class="help-block">Vnesite verifikacijsko kodo, ki ste jo prejeli na vašo telefonsko številko.</p>

            <div class="alert alert-warning" *ngIf="errorAuthConfFail && !loadingDetails" role="alert">
                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka pri preverjanju verifikacijske kode</h4>
                <p>Vnešena verifikacijska koda ni pravilna. Prosimo, preverite vnešeno kodo.</p>
            </div>

            <div class="alert alert-warning" *ngIf="errorGeneralFail && !loadingDetails" role="alert">
                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka na strežniku</h4>
                <p>Prišlo je do napake na strežniku med procesiranjem zahteve. Prosimo, poskusite znova.</p>
            </div>

            <div *ngIf="loadingDetails">
                <loader></loader>
            </div>

            <button class="btn-blue responsive-width mt-3" [disabled]="!authForm.valid" type="submit" [ngClass]="{ disabled: !authForm.valid }">
                <i class="fa fa-check" aria-hidden="true"></i> Potrdi
            </button>
        </form>

        <form *ngIf="showDetails" (ngSubmit)="updateNarocnik($event)">
            <div *ngIf="selectedNaslov.heat && !loadingUpdate" class="form-check mb-2">
                <input id="dalinskoOgrevanjeCheckbox" class="form-check-input" type="checkbox" [ngModel]="narocnik.toplota" (ngModelChange)="changeNarocnikToplota($event)" [ngModelOptions]="{ standalone: true }">
                <label for="dalinskoOgrevanjeCheckbox" class="form-check-label">
                    Daljinsko ogrevanje (toplota)
                </label>
            </div>

            <div *ngIf="selectedNaslov.gas && !loadingUpdate" class="form-check mb-2">
                <input id="zemeljskiPlinCheckbox" class="form-check-input" type="checkbox" [ngModel]="narocnik.plin" (ngModelChange)="changeNarocnikPlin($event)" [ngModelOptions]="{ standalone: true }">
                <label for="zemeljskiPlinCheckbox" class="form-check-label">
                    Zemeljski plin
                </label>
            </div>

            <p *ngIf="!loadingUpdate" class="help-block">Izberite energente, za katere želite prejemati SMS obvestila.</p>

            <div class="alert alert-danger" *ngIf="errorAuthReqExpiredFail && !loadingUpdate" role="alert">
                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Seja je potekla</h4>
                <p>Potekla je seja v kateri velja poslana verifikacijska koda. Prosimo osvežite stran za ponovno overjanje.</p>
            </div>

            <div class="general-error" *ngIf="errorGeneralFail && !loadingUpdate" role="alert">
                <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Napaka na strežniku</h4>
                <p>Prišlo je do napake na strežniku med procesiranjem zahteve. Prosimo, poskusite znova.</p>
            </div>

            <div *ngIf="loadingUpdate">
                <loader></loader>
            </div>

            <button class="btn-blue responsive-width mt-3" [disabled]="!changedNarocnik" type="submit" [ngClass]="{ disabled: !changedNarocnik }">
                <i class="fa fa-check"></i> Posodobi
            </button>
        </form>

    </div>
</div>

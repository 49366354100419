import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { DocumentExtended } from '../models/cms.model';

@Injectable()
export class DocumentsService {

    private baseUrl: string;

    private cache: {
        [key: string]: {
            subject: ReplaySubject<DocumentExtended>,
            value: DocumentExtended
        }
    } = {};

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.cmsServiceBasePath}/public/dokument`;
    }

    getDocumentById(id: string): Observable<DocumentExtended> {

        if (_.isUndefined(this.cache[id])) {
            this.cache[id] = {
                subject: new ReplaySubject(1),
                value: null
            };

            let sub = this.http.get<DocumentExtended>(`${this.baseUrl}/${id}`)
                .pipe(
                    map(d => {
                        d.downloadUrl = `${this.baseUrl}/${d.dokument.id}/download`;
                        return d;
                    })
                )
                .subscribe(res => {
                    this.cache[id].value = res;
                    this.cache[id].subject.next(res);
                });
        }

        return this.cache[id].subject.asObservable();
    }

    parseDocumentAnchorElementRef(elementRef: ElementRef, documentId: string, documentText: boolean = true, documentIcon: boolean = true) {
        this.parseDocumentAnchor(elementRef.nativeElement, documentId, documentText, documentIcon);
    }

    parseDocumentAnchor(element: any, documentId: string, documentText: boolean = true, documentIcon: boolean = true) {

        this.getDocumentById(documentId)
            .subscribe(res => {

                element.setAttribute('href', res.downloadUrl);
                element.setAttribute('target', '_blank');
                element.setAttribute('dokument-resolved', 'true');

                if (documentText) {

                    let innerText = '';

                    if (documentIcon) {
                        switch (res.format) {
                            case 'application/pdf':
                            case 'application/x-pdf':
                                innerText = '<i class="fa fa-file-pdf-o" aria-hidden="true"></i> ';
                                //innerText = '<span class="ion-document-text"></span>&nbsp;&nbsp;';

                                element.setAttribute('aria-label', 'PDF - '.concat(res.naziv));

                                break;
                            case 'application/msword':
                            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                innerText = '<i class="fa fa-file-word-o" aria-hidden="true"></i> ';
                                //innerText = '<span class="ion-document-text"></span>&nbsp;&nbsp;';

                                element.setAttribute('aria-label', 'Dokument WORD - '.concat(res.naziv));

                                break;
                            default:
                                innerText = '<i class="fa fa-external-link" aria-hidden="true"></i> ';
                                //innerText = '<span class="ion-share"></span>&nbsp;&nbsp;';

                                element.setAttribute('aria-label', 'Zunanja povezava - '.concat(res.naziv));

                                break;
                        }
                    }

                    innerText += res.naziv;

                    element.innerHTML = innerText;
                }
            });
    }
}

import {Component, OnInit} from '@angular/core';
import {
  CampaignSubscribeDialogComponent
} from '../../components/dialogs/campaign-subscribe/campaign-subscribe.component';
import { MatDialog } from '@angular/material/dialog';
import {CmsService, CmsSitemap} from '../../services/cms.service';

@Component({
    selector: 'toc',
    templateUrl: './toc.component.html',
    styleUrls: ['./toc.component.scss']
})
export class TocComponent implements OnInit {

  public cmsSitemap: CmsSitemap = null;
  public sitemapLoading: boolean = true;

  constructor(
    private matDialog: MatDialog,
    private cmsService: CmsService
  ) {
  }

  public ngOnInit() {
    this.cmsService.getCmsSitemapAsync()
      .subscribe(res => {
        this.cmsSitemap = res;
        this.sitemapLoading = false;
      });
  }

  openCampaignSubscribeDialog() {
    this.matDialog.open(CampaignSubscribeDialogComponent);
  }
}

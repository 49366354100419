import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

import { Obrazec, VnosnoPolje, PovprasevanjePodrocjeVrednost, Povprasevanje } from '../../models/povprasevanje.model';

@Component({
    selector: 'povprasevanje-obrazec',
    templateUrl: './povprasevanje-obrazec.component.html',
    styleUrls: ['./povprasevanje-obrazec.component.scss'],
})
export class PovprasevanjeObrazecComponent implements OnInit {

    private readonly odjemnaMestaMax: number = 10;
    private readonly odjemnaMestaFieldMaxLength: number = 50;
    private readonly odjemnaMestaStanjeMaxLength: number = 50;
    private readonly odjemnaMestaDatumMaxLength: number = 50;
    private readonly odjemnaMestaZnesekMaxLength: number = 50;
    private readonly odjemnaMestaFieldKeys = ['no', 'st', 'ds', 'zo'];

    private readonly datePatternValidator = /^[0123]?\d\.[01]?\d\.[12]\d{3}$/;
    private readonly ibanValidator = /^[Ss][Ii]56 ?\d{4} ?\d{4} ?\d{4} ?\d{3}$/;
    private readonly taxNumberValidator = /^([Ss][Ii])?\d{8}$/;
    private readonly splitByNonQuotes = /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/;

    @Input() public obrazec: Obrazec;
    @Input() public vsiObrazci: Obrazec[] = [];
    @Input() public vrednosti: PovprasevanjePodrocjeVrednost[] = [];
    @Input() public isLastStep: boolean = false;
    @Input() public showErrors: boolean = false;
    @Input() public povprasevanje: Povprasevanje = null;

    @Output() public onDescriptionUpdate: EventEmitter<string> = new EventEmitter();
    @Output() public onSubmit: EventEmitter<any> = new EventEmitter();
    @Output() public onBack: EventEmitter<any> = new EventEmitter();

    public showDescription: boolean = true;
    public descriptionForm: UntypedFormGroup;
    public form: UntypedFormGroup;
    public controls: VnosnoPoljeControl[] = [];

    formSubmitted = false;

    constructor(
        private fb: UntypedFormBuilder
    ) { }

    public ngOnInit() {

        let descriptionInit = this.povprasevanje && this.povprasevanje.opis ? this.povprasevanje.opis : null;
        this.descriptionForm = this.fb.group({
            'opis': [descriptionInit ? descriptionInit : '', { validators: Validators.maxLength(2000), updateOn: 'blur' }]
        });

        this.descriptionForm.get('opis').valueChanges
            .pipe(
                startWith(descriptionInit),
                distinctUntilChanged()
            )
            .subscribe(v => {
                if (this.descriptionForm.valid)
                    this.onDescriptionUpdate.emit(v);
                else
                    this.onDescriptionUpdate.emit(null);
            });

        this.buildForm();
    }

    public submit() {

      if (this.isLastStep) {

        this.controls.forEach(c => {
            if (c.tip === 'group' && c.control.hasError('required')) {
              const dependantControls = this.controls.filter(dc => dc.skupina === c.koda && (dc.tip === 'checkbox' || dc.tip === 'radio'));

              dependantControls.forEach(dc => {
                dc.control.setErrors({'required': true});
              });
            }
        });

        this.formSubmitted = true;
      }

        if (this.form.valid) {
            this.onSubmit.next(this.prepareOutputValue());
        } else {
            this.markControlsAsDirty();
        }
    }

    public back() {
        this.onBack.next(this.prepareOutputValue());
    }

    public copy() {

        for (let c of this.controls) {
            let cc = this.vrednosti.find(v => v.obrazec === this.obrazec.kopirajKoda && v.koda === c.koda);

            if (cc) {
                if (c.tip === 'postal_code_city') {
                    let initValueSplit = cc.vrednost.split(',')
                    let initValue = cc.vrednost;
                    let initValueSec = '';

                    if (initValueSplit.length > 1) {
                        initValue = initValueSplit[1].trim();
                        initValueSec = initValueSplit[0];
                    }

                    c.control.setValue(initValue);
                    c.secondaryControl.setValue(initValueSec);
                } else {
                    c.control.setValue(cc.vrednost);
                }
            }
        }
    }

    public addDescription() {
        this.showDescription = true;
    }

    public hideDescription() {
        this.showDescription = false;
    }

    private prepareOutputValue(): any {

        let output: any = _.cloneDeep(this.form.value);

        for (let c of this.controls) {
            if (c.file) {
                let val = output[c.koda];

                output[c.koda] = {
                    value: val,
                    file: c.file
                };
            } else if (c.secondaryControl && c.tip === 'postal_code_city') {
                output[c.koda] = `${output[`${c.koda}Secondary`]}, ${output[c.koda]}`;
                delete output[`${c.koda}Secondary`];
            } else if (c.control instanceof UntypedFormArray) {

            }
        }

        return output;
    }

    private buildForm() {

        let controls: VnosnoPoljeControl[] = [];

        for (let vp of this.obrazec.polja) {
            let c = <VnosnoPoljeControl> {
                koda: vp.koda,
                naziv: vp.naziv,
                tip: vp.tip,
                dolzina: vp.dolzina,
                dolzinaMin: vp.dolzinaMin,
                skupina: vp.skupina ? vp.skupina : null,
                obvezno: vp.obvezno,
                polja: vp.tip === 'group' ? [] : null,
                autocomplete: vp.autocomplete,
                pomoc: vp.pomoc,
                placeholder: vp.placeholder,
                privzetaVrednost: vp.privzetaVrednost,
                vrednosti: vp.vrednosti,
                obveznoPovezanoPolje: vp.obveznoPovezanoPolje,
                obveznoPovezanoPoljeVrednost: vp.obveznoPovezanoPoljeVrednost,
                obveznoPovezanoPoljeNegirano: vp.obveznoPovezanoPoljeNegirano,
                obveznoPovezanoPoljeSkrito: vp.obveznoPovezanoPoljeSkrito,
                updateOn: 'blur'
            };

            if (vp.skupina) {
                let sk = controls.find(c => c.koda === vp.skupina);
                if (sk) sk.polja.push(c);
            }

            controls.push(c);
        }

        let formControls: any = {};

        // polja, od katerih so odvisna druga polja, morajo imeti updateOn nastavljen na
        // 'change', ker sicer so težave pri dinamičnem nastavljanju required in visible polj
        for (let c of controls) {
            if (c.obveznoPovezanoPolje) {
                let dependantFieldCodes = c.obveznoPovezanoPolje.split('|');
                controls.filter(dc => dependantFieldCodes.indexOf(dc.koda) > -1).forEach(dc => {
                    dc.updateOn = 'change';
                });
            }
        }

        // nastavi polja
        for (let c of controls) {

            this.setFields(c);

            formControls[c.koda] = c.control;
            if (c.secondaryControl) formControls[`${c.koda}Secondary`] = c.secondaryControl;

            if (c.polja && c.polja.length > 0) {
                for (let gc of c.polja) {
                    this.setFields(gc);
                    formControls[gc.koda] = gc.control;
                }
            }
        }

        // obvezna povezana polja
        for (let c of controls) {
            if (c.obveznoPovezanoPolje) {
                let dependantFieldCodes = c.obveznoPovezanoPolje.split('|');
                let dependentControls = controls.filter(dc => dependantFieldCodes.indexOf(dc.koda) > -1);

                if (dependentControls) {
                    dependentControls.forEach(dc => {
                        dc.control.valueChanges.pipe(
                            startWith(dc.initValue),
                            distinctUntilChanged()
                        ).subscribe(val => {

                            let isPovezanoPoljeValueMatch = this.convertControlValueForComparison(val) === c.obveznoPovezanoPoljeVrednost;

                            if (!isPovezanoPoljeValueMatch) {
                                for (let i = 0; i < dependentControls.length; i++) {
                                    if (dependentControls[i].koda === dc.koda)
                                        continue;

                                    isPovezanoPoljeValueMatch = this.convertControlValueForComparison(dependentControls[i].control.value) === c.obveznoPovezanoPoljeVrednost;

                                    if (isPovezanoPoljeValueMatch)
                                        break;
                                }
                            }

                            if (c.obveznoPovezanoPoljeNegirano) isPovezanoPoljeValueMatch = !isPovezanoPoljeValueMatch;

                            const isRequired = c.obvezno && isPovezanoPoljeValueMatch;
                            const isHidden = c.obveznoPovezanoPoljeSkrito && !isPovezanoPoljeValueMatch;

                            c.skrito = isHidden;
                            let validators = isRequired ? c.validators.concat([Validators.required]) : c.validators;

                            if (c.control instanceof UntypedFormArray) {
                                for (let cfa of c.control.controls) {
                                    if (cfa.get(this.odjemnaMestaFieldKeys[0])) {
                                        cfa.get(this.odjemnaMestaFieldKeys[0]).setValidators((isRequired ? [Validators.required] : []).concat(Validators.minLength(6), Validators.maxLength(11)));
                                        cfa.get(this.odjemnaMestaFieldKeys[0]).updateValueAndValidity();
                                    }

                                    cfa.setValidators(validators);
                                    cfa.updateValueAndValidity();
                                }
                            } else {
                                c.control.setValidators(validators);
                                c.control.updateValueAndValidity();
                            }
                        });
                    });
                }
            }

            if (c.tip === 'group') {
                let dependantControls = controls.filter(dc => dc.skupina === c.koda && (dc.tip === 'checkbox' || dc.tip === 'radio' || dc.tip === 'date'));

                if (dependantControls.length > 0) {
                    let isCheckbox = dependantControls.some(dc => dc.tip === 'checkbox');
                    let isRadio = dependantControls.some(dc => dc.tip === 'radio');
                    let isDate = dependantControls.some(dc => dc.tip === 'date');

                    if (isRadio) {
                        let dependantControlRadios = dependantControls.filter(dc => dc.tip === 'radio');

                        for (let dc of dependantControlRadios) {
                            dc.control.valueChanges.pipe(
                                startWith(dc.initValue),
                                distinctUntilChanged()
                            ).subscribe(val => {
                                if (val) {
                                    dependantControlRadios.forEach(dc2 => {
                                        if (dc2.koda !== dc.koda)
                                            dc2.control.patchValue(false);
                                    });

                                    c.control.patchValue(dc.koda);
                                }
                            });
                        }
                    }

                    if (c.obvezno) {
                        c.control.setErrors({ requred: true });

                        for (let dc of dependantControls) {
                            dc.control.valueChanges.pipe(
                                startWith(dc.initValue),
                                distinctUntilChanged()
                            ).subscribe(val => {
                                let groupVal = null;

                                if (isCheckbox || isRadio) groupVal = dependantControls.filter(dc => dc.control.value === true).map(dc => dc.koda).join(',');
                                if (isDate) groupVal = dependantControls.filter(dc => dc.control.value != null).map(dc => dc.control.value).join(',');

                                c.control.patchValue(groupVal);
                            });
                        }
                    }
                }
            }
        }

        this.form = this.fb.group(formControls);
        this.controls = controls;

        if (this.showErrors)
            setTimeout(() => this.markControlsAsDirty());
    }

    private convertControlValueForComparison(val: any) {

        let value = val;

        if (typeof value === 'boolean' || typeof value === 'number')
            value = value.toString();
        if (typeof value === 'string')
            value = value.trim();

        if (typeof value === 'undefined' || value === null || value.length === 0)
            value = null;

        return value;
    }

    private markControlsAsDirty() {
        for (let c of this.controls) {
            if (c.control instanceof UntypedFormArray) {
                for (let cfa of c.control.controls) {
                    if (cfa instanceof UntypedFormGroup) {
                        for (let cfak in cfa.controls)
                            cfa.get(cfak).markAsDirty();
                    } else {
                        cfa.markAsDirty();
                    }
                }
            } else {
                c.control.markAsDirty();
            }
        }
    }

    private setFields(c: VnosnoPoljeControl) {

        let validators = [];
        let isRequired = false;

        let foundVal = this.findValue(c.koda);
        let initValue: any = foundVal ? foundVal : '';

        if (c.obvezno && !c.obveznoPovezanoPolje) {
            isRequired = true;
            validators.push(Validators.required);
        }

        if (c.dolzina)
            validators.push(Validators.maxLength(c.dolzina));

        if (c.dolzinaMin)
            validators.push(Validators.minLength(c.dolzinaMin));

        switch (c.tip) {
            case 'text':
            case 'group':
            case 'textarea':
                break;
            case 'yes/no':
                validators = [];
                c.updateOn = 'change';
                break;
            case 'checkbox':
                initValue = initValue === 'true';
                c.updateOn = 'change';
                break;
            case 'radio':
                initValue = initValue === 'true';
                c.updateOn = 'change';
                break;
            case 'number':
                initValue = initValue && typeof initValue === 'string' && initValue !== '' ? parseFloat(initValue) : initValue;
                break;
            case 'postal_code_city':
                let initValueSplit = initValue.split(',');
                let initValueSec = '';

                if (initValueSplit.length > 1) {
                    initValue = initValueSplit[1].trim();
                    initValueSec = initValueSplit[0];
                }

                let secondaryValidators = [Validators.min(1000), Validators.max(9999)];

                if (isRequired)
                    secondaryValidators.push(Validators.required);

                c.secondaryControl = this.fb.control(initValueSec, { validators: secondaryValidators, updateOn: c.updateOn });

                let autocompletes = c.autocomplete ? c.autocomplete.split(this.splitByNonQuotes) : [];

                if (autocompletes.length > 1) {
                    c.autocomplete = autocompletes[1];
                    c.autocompleteSecondary = autocompletes[0];
                }

                break;
            case 'iban':
                validators.push(Validators.pattern(this.ibanValidator));
                break;
            case 'tax_number':
                validators.push(Validators.pattern(this.taxNumberValidator));
                break;
            case 'email':
                validators.push(Validators.email);
                break;
            case 'phone':
                validators.push(Validators.pattern(/^[0-9-/+() ]+$/));
                break;
            case 'select':
                // if (c.obvezno) validators[0] = this.requiredSelect();
                c.selectVrednosti = c.vrednosti ? c.vrednosti.split(this.splitByNonQuotes)
                    .map(s => s.startsWith('"') ? s.substring(1) : s)
                    .map(s => s.endsWith('"') ? s.substring(0, s.length - 1) : s) : [];
                break;
            case 'merilna_mesta':
            case 'merilna_mesta_stanje':
            case 'merilna_mesta_stanje_datum':
            case 'merilna_mesta_stanje_datum_znesek':
            case 'merilna_mesta_copy':
                let hasCopy = c.tip === 'merilna_mesta_copy';
                let hasStanje = c.tip === 'merilna_mesta_stanje' || c.tip === 'merilna_mesta_stanje_datum' || c.tip === 'merilna_mesta_stanje_datum_znesek';
                let hasDatum = c.tip === 'merilna_mesta_stanje_datum' || c.tip === 'merilna_mesta_stanje_datum_znesek';
                let hasZnesek = c.tip === 'merilna_mesta_stanje_datum_znesek';

                if (isRequired)
                    validators = [Validators.required];
                else
                    validators = [];

                let perItemLength = this.odjemnaMestaFieldMaxLength;
                if (hasStanje) perItemLength += this.odjemnaMestaStanjeMaxLength;
                if (hasDatum) perItemLength += this.odjemnaMestaDatumMaxLength;
                if (hasZnesek) perItemLength += this.odjemnaMestaZnesekMaxLength;

                let maxItems = Math.floor(c.dolzina / perItemLength);
                if (maxItems > this.odjemnaMestaMax) maxItems = this.odjemnaMestaMax;
                c.formArrayMax = maxItems;

                let initValues: any[] = [{}];

                if (initValue && initValue.length > 0) {
                    try {
                        let vJson = JSON.parse(initValue);

                        if (_.isArray(vJson))
                            initValues = vJson;
                    } catch (err) {
                        console.log('Could not parse JSON');
                    }

                }

                if (hasCopy) {
                    c.tip = 'merilna_mesta';

                    if (!initValue) {
                        let valKeys = this.vsiObrazci.filter(o => o.koda !== this.obrazec.koda).reduce((acc, o) => {
                            return acc.concat(o.polja.filter(p => p.tip === 'merilna_mesta' || p.tip === 'merilna_mesta_stanje' ||
                                    p.tip === 'merilna_mesta_stanje_datum' || p.tip === 'merilna_mesta_stanje_datum_znesek')
                                .map(p => <any>{ koda: p.koda, obrazec: o.koda }));
                        }, []);

                        initValues = this.vrednosti.filter(v => valKeys.some(vk => vk.koda === v.koda && vk.obrazec === v.obrazec))
                            .reduce((arr, v) => {
                                try {
                                    let vObj = JSON.parse(v.vrednost);

                                    if (_.isArray(vObj) && vObj.length > 0 && _.isObject(vObj[0]))
                                        return arr.concat(vObj
                                            .map(vv => _.isObject(vv) ? vv[this.odjemnaMestaFieldKeys[0]] : vv)
                                            .filter(vv => _.isString(vv) && vv.length > 0));
                                    else if (_.isObject(vObj) && vObj[this.odjemnaMestaFieldKeys[0]])
                                        return arr.concat([{ [this.odjemnaMestaFieldKeys[0]]: vObj[this.odjemnaMestaFieldKeys[0]] }]);
                                } catch (err) {
                                    console.log('Could not parse JSON');
                                }

                                return arr;
                            }, [])
                            .slice(0, c.formArrayMax);
                    }
                }

                c.control = this.fb.array([]);
                c.formArrayAdd = (init: any = {}) => {
                    let fa = c.control as UntypedFormArray;

                    if (fa.length < c.formArrayMax) {
                        if (!hasStanje && !hasDatum && !hasZnesek) {
                            fa.push(this.fb.control(_.isString(init) ? init : '', { validators: [Validators.required, Validators.minLength(6), Validators.maxLength(11)], updateOn: 'blur' }));
                        } else {
                            let initObj = {};

                            if (_.isObject(init)) {
                                initObj = init;
                            } else if (_.isString(init)) {
                                try {
                                    initObj = JSON.parse(init);
                                } catch (err) {
                                    console.log('Could not parse JSON', err);
                                }
                            }

                            let group: any = {
                                [this.odjemnaMestaFieldKeys[0]]: [initObj[this.odjemnaMestaFieldKeys[0]] ? initObj[this.odjemnaMestaFieldKeys[0]] : '', {
                                    validators: validators.concat([Validators.required, Validators.minLength(6), Validators.maxLength(11)]), updateOn: 'blur'
                                }]
                            };

                            if (hasStanje)
                                group[this.odjemnaMestaFieldKeys[1]] = [initObj[this.odjemnaMestaFieldKeys[1]] ? initObj[this.odjemnaMestaFieldKeys[1]] : '', { validators: validators.concat([Validators.maxLength(50)]), updateOn: 'blur' }];

                            if (hasDatum)
                                group[this.odjemnaMestaFieldKeys[2]] = [initObj[this.odjemnaMestaFieldKeys[2]] ? initObj[this.odjemnaMestaFieldKeys[2]] : '', { validators: validators.concat([Validators.pattern(this.datePatternValidator)]), updateOn: 'blur' }];

                            if (hasZnesek)
                                group[this.odjemnaMestaFieldKeys[3]] = [initObj[this.odjemnaMestaFieldKeys[3]] ? initObj[this.odjemnaMestaFieldKeys[3]] : '', { validators: [Validators.maxLength(50)], updateOn: 'blur' }];

                            fa.push(this.fb.group(group));
                        }
                    }
                };
                c.formArrayRemove = (idx: number) => {

                    let fa = c.control as UntypedFormArray;

                    if (fa.length <= 1)
                        return;

                    fa.removeAt(idx);
                };

                for (let v of initValues)
                    c.formArrayAdd(v);
                break;
            case 'file':
                c.file = null;
                break;
            case 'date':
                validators.push(Validators.pattern(this.datePatternValidator));
                break;
            default:
                console.error(`Unsupported field type ${c.tip} for ${c.naziv}`);
                break;
        }

        c.initValue = initValue;
        c.validators = validators;

        if (!c.control) c.control = this.fb.control(c.initValue, { validators: c.validators, updateOn: c.updateOn });
    }

    private findValue(koda: string): string {
        let v = this.vrednosti.find(kv => kv.koda == koda && kv.obrazec === this.obrazec.koda && kv.id === this.obrazec.id);
        return v ? v.vrednost : undefined;
    }
}

export interface VnosnoPoljeControl extends VnosnoPolje {
    validators: any;
    initValue: any;
    control: AbstractControl;
    secondaryControl?: AbstractControl;
    autocompleteSecondary?: string;
    formArrayMax?: number;
    formArrayAdd?: Function;
    formArrayRemove?: Function;
    file?: File;
    polja?: VnosnoPoljeControl[];
    skrito?: boolean;
    selectVrednosti: string[];
    updateOn: 'change'|'blur'|'submit';
}

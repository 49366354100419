<article>
    <div class="top-banner" style="background-image: url('assets/images/vozim-na-metan/background01.jpg')">
        <div class="layer">
            <img class="img-fluid" src="assets/images/vozim-na-metan/infografika-top-1.png" alt="Slika s tekstom:  'Vozim na metan. In polnim kar doma! Ugodno. Udobno. Okolju prijazno.'">
        </div>
    </div>

    <div class="background-light-grey obmocje-pas">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-xl-10 offset-xl-1">
                    <div class="row">
                        <div class="col-12 col-xl-10 offset-xl-1">
                            <h1>Metan oz. stisnjen zemeljski plin (CNG)</h1>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
                            <div class="tile">
                                <p class="text-icon-cekin">
                                    uporabljamo v vozilih kot <strong>pogonsko gorivo</strong> namesto bencina ali plinskega olja. Stroški in poraba goriva so <strong>nižji</strong>, motor in katalizator pa imata <strong>daljšo življenjsko dobo</strong>.
                                </p>
                                <div class="text-icon-wrapper-delimiter"></div>
                                <p class="text-icon-drevo">
                                    Vozila na metan so <strong>okolju prijazna</strong> vozila, ker proizvedejo <strong>manj škodljivih emisij, ne tvorijo trdih delcev</strong> in povzročajo <strong>manj hrupa</strong>.
                                </p>
                            </div>
                        </div>

                        <div class="col-12 col-lg-5 col-xl-5">
                            <div class="tile image-center">
                                <img src="assets/images/vozim-na-metan/infografika-cng-primerjava-2018-06-11.png" alt="Slika primerjava cen goriva.">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="background-dark-grey obmocje-pas">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-xl-10 offset-xl-1 table-view">
                    <div class="row">
                        <div class="col-12 col-lg-8 col-xl-6 offset-lg-2 offset-xl-3 title px-3">
                            <h2>+ prednosti</h2>
                        </div>

                        <div class="col-6 col-lg-4 col-xl-3 offset-lg-2 offset-xl-3 left pl-3">
                            <ul>
                                <li>daljša življenjska doba motorja in katalizatorja</li>
                                <li>parkiranje v podzemni garažni hiši je dovoljeno</li>
                                <li>manj emisij CO<sub>2</sub>, CO in ogljikovodikov</li>
                                <li>ne povzroča trdih delcem PM<sub>10</sub></li>
                            </ul>
                        </div>
                        <div class="col-6 col-lg-4 col-xl-3 right pr-3">
                            <ul>
                                <li>uporaba zemeljskega plina - okolju prijaznega in najčistejšega fosilnega goriva</li>
                                <li>lahko ga napolnite kar doma z domačo mini polnilnico na zemeljski plin</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="background-light-grey obmocje-pas">
        <div class="container-fluid">
            <div class="row">

                <div class="col-12 col-xl-10 offset-xl-1">
                    <div class="row">
                        <div class="col-12 col-lg-6 offset-lg-3">
                            <div class="tile">
                                <div class="row">
                                    <div class="col-8">
                                        <h2>Domača polnilnica na zemeljski plin</h2>
                                        <ul>
                                            <li>preprosta in hitra montaža</li>
                                            <li>zadostuje polnenje čez noč</li>
                                        </ul>
                                    </div>
                                    <div class="col-4 image-center">
                                        <img src="assets/images/vozim-na-metan/infografika-avto-garaza.png" alt="Ikona avto v garaži prikloplen na polnilnico">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="small-margin">Javne polnilnice, fast-fill, 24/7</h2>
                                    </div>
                                    <div class="col-12 table-view">
                                        <div class="row">
                                            <div class="col-3 left image-center pl-3"><img src="assets/images/vozim-na-metan/ikona-crpalka.png" alt="Ikona črpalka"></div>
                                            <div class="col-9 right pr-3"><p>Mreža javnih polnilnic v Sloveniji se širi. V Evropi pa je skupaj že <strong>več kot 3.400 polnilnic</strong>.</p></div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                      <p class="info"><em>V Sloveniji imamo poleg dveh ljubljanskih polnilnic še polnilnice v Mariboru in na Jesenicah.</em></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</article>

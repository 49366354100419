import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-klub-zvestoba-ogreva',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class KlubZvestobaOgrevaComponent implements OnInit {

    constructor(
    ) { }

    ngOnInit() { }
}
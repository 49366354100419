import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ThirdPartyService } from '../../../services/3rd-party.service';

@Component({
    selector: 'app-mailing-unsubscribe',
    templateUrl: './unsubscribe.component.html',
    styleUrls: ['./unsubscribe.component.scss']
})
export class MailingUnsubscribeComponent implements OnInit {

    public type: string = 'novice';
    public contentTypes: any = {
        novice: 'Uspešno ste bili odstranjeni iz seznama naročnikov na e-novice. Na vaš e-poštni naslov ne boste več prejemali naših sporočil.',
        'stanje-plinomera': 'Uspešno ste bili odstranjeni iz seznama naročnikov na "obvestilo o terminu sporočanja stanja plinomera". Na vaš e-poštni naslov ne boste več prejemali naših sporočil.'
    };

    constructor(
        private route: ActivatedRoute,
        private thirdPartyService: ThirdPartyService
    ) { }

    public ngOnInit() {

        this.type = this.route.snapshot.paramMap.has('type') ? this.route.snapshot.paramMap.get('type') : this.type;

        setTimeout(() => {
            this.thirdPartyService.emailingUnsubscribeInit();
        })
    }
}
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';

import { ThirdPartyService } from '../../../services/3rd-party.service';
import {CookiesService} from '../../../services/cookies.service';

@Component({
    selector: 'app-tople-tacke',
    templateUrl: './tople-tacke.component.html',
    styleUrls: ['./tople-tacke.component.scss']
})
export class EnergetikaVDruzbiTopleTackeComponent implements OnInit, AfterViewInit {

    private topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-blue.png';
    private topleTackePes =   'assets/images/energetika-v-druzbi/tople-tacke-pes.png';
    constructor(
        private elementRef: ElementRef,
        private thirdPartyService: ThirdPartyService,
        private cookiesService: CookiesService
    ) {
     }

    ngOnInit() {


      this.cookiesService.selectedTheme$.subscribe(res => {
        switch (res) {
          case 'theme-wcag':
          case 'theme-normal':
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-blue.png';
            this.topleTackePes = 'assets/images/energetika-v-druzbi/tople-tacke-pes.png';
            break;
          case 'theme-white-black':
            this.topleTackeLogo = 'assets/images/energetika-v-druzbi/tople-tacke-logo-white.png';
            this.topleTackePes = 'assets/images/energetika-v-druzbi/tople-tacke-pes-white-black.png';
            break;
          case 'theme-black-white':
            this.topleTackeLogo =  'assets/images/energetika-v-druzbi/tople-tacke-logo-black.png';
            this.topleTackePes = 'assets/images/energetika-v-druzbi/tople-tacke-pes-black-white.png';
            break;
          case 'theme-black-beige':
            this.topleTackeLogo =  'assets/images/energetika-v-druzbi/tople-tacke-logo-black.png';
            this.topleTackePes = 'assets/images/energetika-v-druzbi/tople-tacke-pes-black-beige.png';
            break;
          case 'theme-black-yellow':
            this.topleTackeLogo =  'assets/images/energetika-v-druzbi/tople-tacke-logo-black.png';
            this.topleTackePes = 'assets/images/energetika-v-druzbi/tople-tacke-pes-black-yellow.png';
            break;
          case 'theme-yellow-blue':
            this.topleTackeLogo =  'assets/images/energetika-v-druzbi/tople-tacke-logo-yellow.png';
            this.topleTackePes = 'assets/images/energetika-v-druzbi/tople-tacke-pes-blue-yellow.png';
            break;
          case 'theme-lime-black':
            this.topleTackeLogo =  'assets/images/energetika-v-druzbi/tople-tacke-logo-lime.png';
            this.topleTackePes = 'assets/images/energetika-v-druzbi/tople-tacke-pes-lime.png';
            break;
        }
      });
    }

    ngAfterViewInit() {

        this.thirdPartyService.eflsightInit(this.elementRef);
    }

  getTopleTackeLogo() {
    return this.topleTackeLogo;
  }

  getTopleTackeImage() {
    return this.topleTackePes;
  }
}

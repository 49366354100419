<form [formGroup]="form" (ngSubmit)="submit()" *ngIf="form">
    <div class="form-group mb-3" *ngIf="obrazec.naziv">
        <strong>{{ obrazec.naziv }}</strong>
    </div>
    <div class="form-group my-3" *ngIf="obrazec.kopirajKoda">
        <button (click)="copy()" class="btn btn-next btn-sm" type="button">{{ obrazec.kopirajNaziv || 'Kopiraj' }}</button>
    </div>
    <div class="form-group my-3" *ngIf="obrazec.tekstPred" [innerHTML]="obrazec.tekstPred"></div>
    <povprasevanje-obrazec-polje *ngFor="let c of controls" [polje]="c"></povprasevanje-obrazec-polje>
    <div class="form-group my-3" *ngIf="obrazec.tekstPo" [innerHTML]="obrazec.tekstPo"></div>

    <div class="form-group mt-4" *ngIf="descriptionForm && showDescription && isLastStep">
        <div [formGroup]="descriptionForm" class="mb-0">
            <div class="form-group mb-0">
               <label class="transform-capitalize">Nam želite sporočiti še kaj?</label>
                <textarea rows="5" class="form-control" formControlName="opis" [ngClass]="{ 'error-border': descriptionForm.get('opis').dirty && !descriptionForm.get('opis').valid }"></textarea>
                <p *ngIf="descriptionForm.get('opis').dirty && descriptionForm.get('opis').errors?.maxlength" class="help-block error"><em>Vnos je predolg, dolžina je omejena na 2000 znakov</em></p>
            </div>
        </div>
    </div>

    <div *ngIf="isLastStep">
      <p *ngIf="formSubmitted && form.invalid" class="help-block error"><em>Potrebno je izbrati vse zahtevane opcije</em></p>
    </div>

    <div class="row">
        <div class="col-4 mt-3">
            <button (click)="back()" class="btn btn-sm as-link" type="button"><i class="fa fa-chevron-left" aria-hidden="true"></i> Nazaj</button>
        </div>
        <div class="col-8 mt-3 text-right">
            <button type="submit" class="btn btn-next">{{ obrazec.potrditev ? obrazec.potrditev : (isLastStep ? 'Oddaj' : 'Naprej') }}</button>
        </div>
    </div>
</form>

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-narocilo-sms-obvescanje-pogoji',
    templateUrl: './pogoji.component.html',
    styleUrls: ['./pogoji.component.scss']
})
export class NarociloSmsObvescanjePogojiComponent implements OnInit {

    constructor() { }

    public ngOnInit() { }
}
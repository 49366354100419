export class CtaUtils {

    public static getPovprasevanjeQueryParams(povezava: string): any {

        if (povezava && povezava.indexOf('/povprasevanje') > -1 && povezava.indexOf('?') > -1) {
            let searchParams = povezava.split('?')[1].split('&').filter(p => p.length > 0);

            if (searchParams.length > 0) {
                let ctaQueryParams = {};

                for (let p of searchParams) {
                    let pSplit = p.split('=');

                    if (pSplit.length === 2) {
                        ctaQueryParams[pSplit[0]] = pSplit[1];
                    }
                }

                return ctaQueryParams;
            }
        }

        return null;
    }
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import * as _ from 'lodash';

import { finalize } from 'rxjs/operators';

import { CmsService } from '../../../services/cms.service';

import { Document } from '../../../models/cms.model';

@Component({
    selector: 'app-ceniki-dokumenti-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class CenikiDokumentiListComponent implements OnInit {

    public documents: Document[] = [];
    public loading: boolean = true;
    public pages: number[] = [];
    public currentPage: number = 1;
    public lastPage = null;
    public totalCount: number = 0;
    public maxPages: number = 0;
    public maxItemsPerPage: number = 20;
    public kategorije: number[] = [];

    private queryParams: Params;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService
    ) { }

    public ngOnInit() {

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;
            this.currentPage = qp.stran ? parseInt(qp.stran) : 1;

            this.loadItems();
        });
    }

    public loadItems() {

        this.loading = true;

        let skip = null;

        if (this.currentPage !== 1)
            skip = (this.currentPage - 1) * this.maxItemsPerPage;

        this.cmsService.getDocumentsAsync(this.queryParams.cenik, this.queryParams.arhiv, this.queryParams.kat, skip, undefined, "naziv", "ASC")
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(res => {
                this.totalCount = res.count;
                this.documents = res.items;
            });
    }

    changePage(pageNum) {
            
        this.currentPage = pageNum;
        window.scrollTo(0, 0);

        this.router.navigate(['./'], {
            queryParams: { stran : pageNum !== 1 ? pageNum : null }, 
            queryParamsHandling: 'merge', 
            relativeTo: this.route 
        });
    }

    // checkHsTab(event: KeyboardEvent, index, size) {
    //     if (event) {
    //       let activeEl = document.activeElement;
    //       switch (event.code) {
    //         case 'Escape':
    //           this.showHisneStevilkaDropdown = false;
    //           break;
    //         case 'Enter':
    //           if (index !== -1) {
    //             this.selectHisnaStevilka(this.hisneStevilke[index]);
    //           } else {
    //             this.selectHisnaStevilka(null);
    //           }
    //           break;
    //         case 'ArrowDown':
    //           this.openHisneStevilkeDropdown();
    //           event.preventDefault();
    //           this.tabNextElement(activeEl.parentElement.nextElementSibling);
    //           break;
    //         case 'ArrowUp':
    //           event.preventDefault();
    //           this.tabNextElement(activeEl.parentElement.previousElementSibling);
    //           break;
    //         case 'Tab':
    //           if (!event.shiftKey) {
    //             if (index === size - 1) {
    //               this.closeHisneStevilkeDropdown();
    //             }
    //           }
    //           break;
    //       }
    //     }
    //   }

    private tabNextElement(siblingElement: Element) {
        if (siblingElement) {
            let nextAnchor = siblingElement.firstElementChild;
            if (nextAnchor) {
            (nextAnchor as HTMLElement).focus();
            }
        }
    }
}
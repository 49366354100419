import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import * as _ from 'lodash';

import { finalize } from 'rxjs/operators';

import { CmsService } from '../../../services/cms.service';

import { DeloNaOmrezju } from '../../../models/cms.model';
import { HtmlUtils } from '../../../utils/html.utils';

@Component({
    selector: 'app-dela-na-omrezju-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class DelaNaOmrezjuListComponent implements OnInit {

    public networkJobs: DeloNaOmrezju[] = [];
    public loading: boolean = true;
    public pages: number[] = [];
    public currentPage: number = 1;
    public lastPage = null;
    public totalCount: number = 0;
    public maxPages: number = 0;
    public maxItemsPerPage: number = 20;

    public cmsContentBasePath;

    private queryParams: Params;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private route: ActivatedRoute,
        private cmsService: CmsService
    ) { }

    public ngOnInit() {

        this.cmsContentBasePath = this.cmsService.contentBasePath;

        this.route.queryParams.subscribe(qp => {
            this.queryParams = qp;
            this.currentPage = qp.stran ? parseInt(qp.stran) : 1;

            this.loadItems();
        });
    }

    public loadItems() {

        this.loading = true;

        let skip = null;

        if (this.currentPage !== 1)
            skip = (this.currentPage - 1) * this.maxItemsPerPage;

        this.cmsService.getNetworkJobsAsync(this.queryParams.energent, skip)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(res => {
                let maxVsebinaLength = 250;

                this.totalCount = res.count;
                this.networkJobs = res.items.map(item => {

                    if (item.vsebina) {
                        let vsebina = '<p>' + (isPlatformBrowser(this.platformId) ? 
                            HtmlUtils.stripHtmlToMaxLength(item.vsebina, maxVsebinaLength) : 
                            HtmlUtils.stripHtmlToMaxLengthNoDocument(item.vsebina, maxVsebinaLength)) + '</p>';

                        item.vsebina = vsebina;
                    }

                    return item;
                });
            });
    }

    changePage(pageNum) {
            
        this.currentPage = pageNum;
        window.scrollTo(0, 0);

        this.router.navigate(['./'], { 
            queryParams: { stran : pageNum !== 1 ? pageNum : null }, 
            queryParamsHandling: 'merge', 
            relativeTo: this.route 
        });
    }
}
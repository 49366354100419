import { Route } from '@angular/router';

import { TocComponent } from './toc.component';

import { Metadata } from '../../models/metadata.model';

const metadata: Metadata = {
  title: 'Vsebine',
  description: 'Kazalo vsebine',
  keywords: ['kazalo vsebine', 'vsebine'],
  canonicalUrl: '/toc'
};

export const route: Route = {
  path: 'toc',
  component: TocComponent,
  data: {metadata: metadata}
};

export interface Metadata {
    title?: string;
    siteName?: string;
    description?: string;
    keywords?: string[];
    canonicalUrl?: string;
    imageUrl?: string;
    imageWidth?: number;
    imageHeight?: number;
    ogType?: string;
    ogArticleSection?: string;
    ogArticlePublishedTime?: string;
    ogArticleTag?: string[];
    skipPageTitle?: boolean;
    skipAutoSetup?: boolean;
}

export class MetadataConfig {
    public title: string = null;
    public siteName: string = undefined;
    public description: string = null;
    public keywords: string[] = [];

    public canonicalUrl: string = null;
    public imageUrl: string = null;
    public imageWidth: number = null;
    public imageHeight: number = null;

    public ogType: string = 'website';
    public ogArticleSection: string = null;
    public ogArticlePublishedTime: string = null;
    public ogArticleTag: string[] = null;

    public skipPageTitle: boolean = false;
    public skipAutoSetup: boolean = false;

    public static fromMetadata(metadata: Metadata): MetadataConfig {
        
        let config = new MetadataConfig();

        if (metadata.title) config.title = metadata.title;
        if (metadata.siteName) config.siteName = metadata.siteName;
        if (metadata.description) config.description = metadata.description;
        if (metadata.keywords) config.keywords = metadata.keywords;

        if (metadata.canonicalUrl) config.canonicalUrl = metadata.canonicalUrl;
        if (metadata.imageUrl) config.imageUrl = metadata.imageUrl;
        if (metadata.imageWidth) config.imageWidth = metadata.imageWidth;
        if (metadata.imageHeight) config.imageHeight = metadata.imageHeight;

        if (metadata.ogType) config.ogType = metadata.ogType;
        if (metadata.ogArticleSection) config.ogArticleSection = metadata.ogArticleSection;
        if (metadata.ogArticlePublishedTime) config.ogArticlePublishedTime = metadata.ogArticlePublishedTime;
        if (metadata.ogArticleTag) config.ogArticleTag = metadata.ogArticleTag;

        if (typeof metadata.skipPageTitle !== 'undefined') config.skipPageTitle = metadata.skipPageTitle;
        if (typeof metadata.skipAutoSetup !== 'undefined') config.skipAutoSetup = metadata.skipAutoSetup;

        return config;
    }
}
import { Component } from '@angular/core';

@Component({
    selector: 'app-terms-use',
    templateUrl: './terms-use.component.html',
    styleUrls: ['./terms-use.component.scss']
})
export class TermsUseComponent {

    constructor(
    ) { }
}
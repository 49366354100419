import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

import * as _ from 'lodash';

import { environment } from '../../environments/environment';

import { Metadata, MetadataConfig } from '../models/metadata.model';

@Injectable()
export class MetadataService {

    private readonly metaDescription: string = 'description';
    private readonly metaKeywords: string = 'keywords';
    
	private readonly metaOgUrl: string = 'og:url';
    private readonly metaOgTitle: string = 'og:title';
    private readonly metaOgSiteName: string = 'og:site_name';
    private readonly metaOgType: string = 'og:type';
    private readonly metaOgDescription: string = 'og:description';
    private readonly metaOgImage: string = 'og:image';
    private readonly metaOgImageWidth: string = 'og:image:width';
    private readonly metaOgImageHeight: string = 'og:image:height';
    private readonly metaOgArticlePublishedTime: string = 'article:published_time';
    private readonly metaOgArticleSection: string = 'article:section';
    private readonly metaOgArticleTag: string = 'article:tag';

    private readonly defaultSiteName = environment.siteName;
    private readonly defaultPageName = environment.defaultPageTitle;
    private readonly defaultOgType = 'website';

    constructor(
        private router: Router,
        private title: Title,
        private meta: Meta,
        @Inject(DOCUMENT) private document,
        @Inject(PLATFORM_ID) private platformId
    ) { 
    }

    public setFromRouteData(data: any) {

        if (_.isObject(data) && !_.isUndefined(data.metadata) && (<Metadata>data.metadata).skipAutoSetup !== true)
            this.setPageMetadata(MetadataConfig.fromMetadata(data.metadata));
        else 
            this.setPageDefaultMetadata();
    }

    public setPageMetadata(metadata: MetadataConfig) {

        this.setTitle(metadata.title, metadata.siteName, metadata.skipPageTitle);
        this.setDescription(metadata.description);
        this.setKeywords(metadata.keywords);

        this.setCanonicalUrl(metadata.canonicalUrl ? metadata.canonicalUrl : this.router.url);
        this.setImage(metadata.imageUrl, undefined, metadata.imageWidth, metadata.imageHeight);

        this.setOgType(metadata.ogType);
        this.setOgArticle(metadata.ogType, metadata.ogArticleSection, metadata.ogArticlePublishedTime, metadata.ogArticleTag);
    }

    public setTitle(title: string = this.defaultPageName, siteName: string = this.defaultSiteName, 
        skipSiteName: boolean = false) {

        let newTitle = '';

        if (title)
            newTitle += title;

        if (siteName && !skipSiteName)
            newTitle += `${newTitle.length > 0 ? ' - ' : ''}${siteName}`;

        this.title.setTitle(newTitle);
        this.setOgTitle(title, siteName);
    }

    public setOgTitle(title: string = this.defaultPageName, siteName: string = this.defaultSiteName) {
        this.setMetaTagByProperty(this.metaOgTitle, title);
        this.setMetaTagByProperty(this.metaOgSiteName, siteName);
    }

    public setDescription(value: string) {
        this.setMetaTagByName(this.metaDescription, value);
        this.setMetaTagByProperty(this.metaOgDescription, value);
    }

    public setKeywords(value: string[]) {
        this.setMetaTagByName(this.metaKeywords, value ? value.join(',') : null);
    }

    public setImage(path: string, baseUrl: string = null, width: number = null, height: number = null, skipBaseUrl: boolean = false) {

        if (!path) {
            this.setMetaTagByProperty(this.metaOgImage, null);
            this.setMetaTagByProperty(this.metaOgImageWidth, null);
            this.setMetaTagByProperty(this.metaOgImageHeight, null);            
            return;
        }

        if (!skipBaseUrl && !baseUrl)
            baseUrl = this.getBaseUrl();

        if (path.startsWith('/'))
            path = path.substring(1);

        let url = skipBaseUrl ? path : `${baseUrl}/${path}`;
        this.setMetaTagByProperty(this.metaOgImage, url);

        if (width && height) {
            this.setMetaTagByProperty(this.metaOgImageWidth, width.toString());
            this.setMetaTagByProperty(this.metaOgImageHeight, height.toString());
        }
    }

    public setCanonicalUrl(path: string = null, baseUrl: string = null) {

        let linkEl = this.document.querySelector('link[rel="canonical"]');

        if (path === null && this.document) {
            if (linkEl)
                this.document.head.removeChild(linkEl);

            this.setMetaTagByProperty(this.metaOgUrl, null);
        } else {
            if (!baseUrl)
                baseUrl = this.getBaseUrl();

            if (path.startsWith('/'))
                path = path.substring(1);

            let linkEl = this.document.querySelector('link[rel="canonical"]');

            if (!linkEl) {
                linkEl = this.document.createElement('link');
                linkEl.setAttribute('rel', 'canonical');
                this.document.head.appendChild(linkEl);
            }

            let url = `${baseUrl}/${path}`;
            linkEl.setAttribute('href', url);

            this.setMetaTagByProperty(this.metaOgUrl, url);
        }
    }

    public setOgType(value: string = this.defaultOgType) {
        this.setMetaTagByProperty(this.metaOgType, value);
    }

    public setOgArticle(ogType: string = this.defaultOgType, ogArticleSection: string = null, ogArticlePublishedTime: string = null, 
        ogArticleTag: string[] = null) {

        if (ogType !== 'article') {
            this.setMetaTagByProperty(this.metaOgArticleSection, null);
            this.setMetaTagByProperty(this.metaOgArticlePublishedTime, null);
            this.setMetaTagByProperty(this.metaOgArticleTag, null);
        } else {
            this.setMetaTagByProperty(this.metaOgArticleSection, ogArticleSection);
            this.setMetaTagByProperty(this.metaOgArticlePublishedTime, ogArticlePublishedTime);
            this.setMetaTagByProperty(this.metaOgArticleTag, ogArticleTag);
        }
    }

    public setMetaTagByName(key: string, value: string) {
        this.setMetaTag(value, key);
    }

    public setMetaTagByProperty(key: string, value: string|string[]) {
        this.setMetaTag(value, null, key);
    }

    private setPageDefaultMetadata() {
        
        this.setTitle();
        this.setDescription(null);
        this.setKeywords(null);

        this.setCanonicalUrl();
        this.setImage(null);

        this.setOgType();
        this.setOgArticle();
    }

    private setMetaTag(content: string|string[], name: string = null, property: string = null) {
        
        if (!name && !property)
            return;

        let selector = name ? `name="${name}"` : `property="${property}"`;

        if (content) {
            if (typeof content === 'string') {
                let tag = this.meta.getTag(selector);

                if (tag) {
                    this.meta.updateTag({ content: content }, selector);
                } else {
                    let tag: MetaDefinition = { content: content };
    
                    if (name)
                        tag.name = name;
                    else if (property)
                        tag.property = property;
    
                    this.meta.addTag(tag);
                }
            } else {
                this.meta.removeTag(selector);

                for (let c of content) {
                    let tag: MetaDefinition = { content: c };

                    if (name)
                        tag.name = name;
                    else if (property)
                        tag.property = property;

                    this.meta.addTag(tag);
                }
            }
        } else {
            this.meta.removeTag(selector);
        }
    }

    private getBaseUrl(): string {
        
        let baseUrl = '';

        if (isPlatformBrowser(this.platformId) && window.location) {
            baseUrl = `${window.location.protocol}//${window.location.hostname}`;

            if (window.location.port.length > 0)
                baseUrl += `:${window.location.port}`;
        } else {
            baseUrl = environment.pageBaseUrl;
        }

        return baseUrl;
    }

}